import React from 'react';
import VaultHeader from '../Components/Vault/Data/VaultHeader';
import VaultDocTabs from '../Components/Vault/Data/VaultDoc';

const VaultDoc = () => {
  return (
    <div className="p-4">
      {/* Header component */}
      <VaultHeader />

      {/* Tabs component with some spacing below the header */}
      <div className="mt-4">
        <VaultDocTabs />
      </div>

      {/* Placeholder for additional Vault Document content */}
      <div className="mt-6">
        <p>Vault document content goes here.</p>
      </div>
    </div>
  );
};

export default VaultDoc;