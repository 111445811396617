import React from 'react';

const DocLoading = () => {
  const loadingItems = new Array(8).fill(0); // Creates 8 placeholders for the loading items

  return (
    <div className="flex flex-col gap-4 w-full">
      {loadingItems.map((_, index) => (
        <div
          key={index}
          className="flex items-center justify-between p-4 rounded-lg bg-gray-100 shadow-md"
        >
          {/* Left side: Avatar/Thumbnail Placeholder */}
          <div className="w-12 h-12 rounded-full bg-gray-300"></div>

          {/* Main Content Section */}
          <div className="flex flex-col flex-grow ml-4">
            {/* Placeholder for title */}
            <div className="bg-gray-300 rounded-md h-4 w-3/5 mb-2"></div>
            {/* Placeholder for subtitle */}
            <div className="bg-gray-300 rounded-md h-4 w-2/5"></div>
          </div>

          {/* Right Side: Metadata Placeholders */}
          <div className="flex flex-row items-center gap-3 ml-4">
            <div className="w-6 h-6 bg-gray-300 rounded-md"></div>
            <div className="w-6 h-6 bg-gray-300 rounded-md"></div>
            <div className="w-6 h-6 bg-gray-300 rounded-md"></div>
          </div>

          {/* Right-side Circle Placeholder */}
          <div className="w-8 h-8 rounded-full bg-gray-300 ml-4"></div>
        </div>
      ))}
    </div>
  );
};

export default DocLoading;
