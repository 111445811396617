import React, { useState } from 'react';
import GeneralSettings from './General'; // General Settings component
import Audit from './Audit'; // Audit component
import SuperAdminPage from './SuperAdminPage'; // SuperAdmin component
import AdminPage from './AdminPage'; // Admin component
import { useAuth } from '../../authcontext'; // Auth context for userRole

const SettingsHeader = () => {
  // State to track the active tab
  const [activeTab, setActiveTab] = useState('general'); // Default tab is 'general'
  const { userRole } = useAuth(); // Fetch the userRole from auth context

  // Define tabs based on userRole
  const tabs = [
    { name: 'general', label: 'General Settings', visible: true },
    { name: 'audit', label: 'Audit Trail', visible: userRole === 'superadmin' },
    { name: 'admin', label: 'Admin', visible: userRole === 'admin'},
    { name: 'superadmin', label: 'Superadmin', visible: userRole === 'superadmin' },
  ];

  return (
    <div className="flex flex-col p-4">
      {/* Heading */}
      <h1 className="text-xl font-bold text-gray-700 mb-4">Settings</h1>

      {/* Tabs */}
      <div className="inline-flex items-center gap-4 flex-shrink-0 h-8">
        {/* Render tabs based on visibility */}
        {tabs.map(
          (tab) =>
            tab.visible && (
              <button
                key={tab.name}
                onClick={() => setActiveTab(tab.name)}
                className={`pb-1 font-semibold ${
                  activeTab === tab.name
                    ? 'text-[#007768] border-b-2 border-[#007768]'
                    : 'text-[#59616E]'
                }`}
              >
                {tab.label}
              </button>
            )
        )}
      </div>

      {/* Content based on the active tab */}
      <div className="mt-6">
        {activeTab === 'general' && <GeneralSettings />}
        {activeTab === 'audit' && <Audit />}
        {activeTab === 'admin' && <AdminPage />}
        {activeTab === 'superadmin' && <SuperAdminPage />}
      </div>
    </div>
  );
};

export default SettingsHeader;
