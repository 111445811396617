import React, { useState } from 'react';
import { Modal, Button, Dropdown, Menu, Avatar, Input } from 'antd';
import { GlobalOutlined, TeamOutlined, DownOutlined, LinkOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const ShareVersionModal = ({ isVisible, handleCancel }) => {
  const [selectedPermission, setSelectedPermission] = useState('Can View'); 
  const [selectedTeamPermission, setSelectedTeamPermission] = useState('2 Persons'); 

  const handleMenuClick = (e) => {
    setSelectedPermission(e.key);
  };

  const handleTeamMenuClick = (e) => {
    setSelectedTeamPermission(e.key);
  };

  const permissionMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="Can View">Can View</Menu.Item>
      <Menu.Item key="Can Modify">Can Modify</Menu.Item>
    </Menu>
  );

  const teamPermissionMenu = (
    <Menu onClick={handleTeamMenuClick}>
      <Menu.Item key="2 Persons">2 Persons</Menu.Item>
      <Menu.Item key="5 Persons">5 Persons</Menu.Item>
    </Menu>
  );

  return (
    <Modal
      title="Share this file"
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
      width={520}
    >
      <h4 className="text-[#768090] font-semibold mb-2">Who has access</h4>
      <div className="flex gap-4 mb-4">
        {/* Organization Card */}
        <div className="flex flex-col justify-center items-center gap-2 p-4 border rounded-lg" style={{ flex: '1 0 0', borderRadius: '8px', border: '1px solid #D9DEE7', height: '128px' }}>
          <GlobalOutlined style={{ fontSize: '24px', color: '#798897' }} />
          <p className="text-center mt-2">Anyone in the Organization</p>
        
            <Button className="flex items-center justify-center gap-1" style={{ border: 'none', boxShadow: 'none' }}>
              {selectedPermission} <DownOutlined />
            </Button>
       
        </div>

        {/* Team Card */}
        <div className="flex flex-col justify-center items-center gap-2 p-4 border rounded-lg" style={{ flex: '1 0 0', borderRadius: '8px', border: '1px solid #D9DEE7', height: '128px' }}>
          <TeamOutlined style={{ fontSize: '24px', color: '#798897' }} />
          <p className="text-center mt-2">Anyone in the Team</p>
       
            <Button className="flex items-center justify-center gap-1" style={{ border: 'none', boxShadow: 'none' }}>
              {selectedTeamPermission} <DownOutlined />
            </Button>
        
        </div>
      </div>

      {/* People List */}
      <div className="mt-4">
        <div className="flex items-center justify-between gap-2 mb-2">
          <div className="flex items-center gap-2">
            <Avatar style={{ backgroundColor: '#007768' }}>A</Avatar>
            <span className="text-[#1B2028]">Purvi</span>
          </div>
          <span className="text-[#1B2028]">Owner</span>
        </div>
        <div className="flex items-center justify-between gap-2 mb-2">
          <div className="flex items-center gap-2">
            <Avatar style={{ backgroundColor: '#FCE4E0' }}>B</Avatar>
            <span className="text-[#1B2028]">Abcd</span>
          </div>
         
            <Button style={{ border: 'none', boxShadow: 'none' }}>
              Can Modify <DownOutlined />
            </Button>
      
        </div>
        <div className="flex items-center justify-between gap-2 mb-2">
          <div className="flex items-center gap-2">
            <Avatar style={{ backgroundColor: '#D1F8F0' }}>X</Avatar>
            <span className="text-[#1B2028]">Xyz</span>
          </div>
        
            <Button style={{ border: 'none', boxShadow: 'none' }}>
              Can View <DownOutlined />
            </Button>
    
        </div>
      </div>

            {/* Input for sharing by name or email */}
            <div className="mt-4 flex items-center gap-2">
        <Input
          placeholder="Share By Name or email"
          style={{
            border: '1px solid #41EAD4',
            flexGrow: 1, 
            height: '40px',
          }}
        />
        <Button
          type="primary"
          style={{
            backgroundColor: '#41EAD4',
            borderColor: '#41EAD4',
            color:'#192028',
            height: '40px',
            padding: '0 20px',
            whiteSpace: 'nowrap', 
          }}
        >
          Share
        </Button>
      </div>



      {/* Link Section */}
      <div className="mt-4 flex items-center justify-between">
        <TextArea
          value="http://sub.example.net/page?query=value"
          autoSize={{ minRows: 1, maxRows: 2 }}
          bordered={false}
          style={{ backgroundColor: 'transparent', color: '#768090', textAlign: 'left', width: '80%' }}
        />
        <Button icon={<LinkOutlined />} type="link" style={{ color: '#1677FF' }}>
          Copy link
        </Button>
      </div>
    </Modal>
  );
};

export default ShareVersionModal;
