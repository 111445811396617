import React from 'react';
import ScrollTeam from './Common/ScrollTeam';
import KnowTeam from './Common/KnowTeam';
import TeamMembers from './Common/TeamMembers';
import ViewAll from './Common/ViewAll';
import { useState } from 'react';

const Joined = ({ viewAll }) => { // Accept viewAll as a prop
  const [memberIds, setMemberIds] = useState([]);

  return (
    <div>
      {/* Conditionally render based on viewAll prop */}
      {!viewAll ? ( 
        <div className="p-4">
          {/* First Row: ScrollTeam component */}
          <div>
            <ScrollTeam type="joined" onTeamSelect={(members) => setMemberIds(members)} />
          </div>

          {/* Second Row: KnowTeam takes 1/3rd, TeamMembers takes 2/3rd */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="md:col-span-1">
              <KnowTeam />
            </div>
            <div className="md:col-span-2">
            <TeamMembers memberIds={memberIds} />
            </div>
          </div>
        </div>
      ) : (
        <ViewAll type="joined" /> // Render the ViewAll component when viewAll is true
      )}
    </div>
  );
};

export default Joined;
