import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, Select, Spin } from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useAuth } from '../../authcontext';
import useDebounceFetch from '../../Services/Utils/Debouncing';

const { Option } = Select;

const EditTeamModal = ({ visible, onClose, teamId, teamName = '', teamDescription = '', teamMembers = [] }) => {
  const { userRole } = useAuth(); // Context to get the user role
  const [description, setDescription] = useState(teamDescription);
  const [members, setMembers] = useState(teamMembers);
  const [title, setTitle] = useState(teamName);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);

  useEffect(() => {
    setDescription(teamDescription || '');
    setMembers(teamMembers || []);
    setTitle(teamName || '');
  }, [teamDescription, teamMembers, teamName]);

  // Use the custom hook for fetching and debouncing results
  const { debouncedTerm, results, loading, fetchMore, moreToCome } = useDebounceFetch(
    searchTerm,
    page,
    setPage
  );

  // Handle save team details
  const handleSave = async () => {
    try {
      const endpoint = `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/${teamId}`;
      const data = {
        name: title,
        description,
        addMembers: members, // Assuming members contain user IDs/emails
      };

      const response = await axios.patch(endpoint, data);
      if (response.status === 200) {
        console.log("Team updated successfully");
        onClose(); // Close the modal after saving
      }
    } catch (error) {
      console.error("Error updating team:", error);
    }
  };

  const handleEditTitle = () => {
    setIsEditingTitle(true);
  };

  const handleSaveTitle = () => {
    setIsEditingTitle(false);
  };

  const handleMemberChange = (selectedMembers) => {
    setMembers(selectedMembers);
  };

  return (
    <Modal
      title={
        <div className="flex items-center justify-center gap-2">
          {isEditingTitle ? (
            <div className="flex items-center gap-2">
              <Input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-auto text-center"
              />
              <CheckOutlined onClick={handleSaveTitle} className="cursor-pointer" />
            </div>
          ) : (
            <div className="flex items-center justify-center gap-2">
              <span className="text-center">{title}</span>
              <EditOutlined onClick={handleEditTitle} className="cursor-pointer" />
            </div>
          )}
        </div>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
      width={600}
    >
      <div className="p-5">
        <div className="text-right text-gray-400 text-sm">{description?.length || 0}/100</div>

        {/* Description Section */}
        <div className="mb-4">
          <label className="block text-gray-500">Description (Optional)</label>
          <Input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description"
            maxLength={100}
            className="w-full mt-1 p-2 border rounded-md"
          />
        </div>

        {/* Add Members Section */}
        <div className="mb-4">
          <label className="block text-gray-500">Add Members</label>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Search and select members"
            value={members}
            onSearch={(value) => setSearchTerm(value)}
            onChange={handleMemberChange}
            className="mt-1"
            onPopupScroll={(e) => {
              const target = e.target;
              if (target.scrollTop + target.offsetHeight === target.scrollHeight && moreToCome) {
                fetchMore();
              }
            }}
            notFoundContent={loading ? <Spin /> : null}
            filterOption={false}
          >
            {results.map((user) => (
              <Option key={user._id} value={user._id}>
                {user.email}
                {user.name ? ` (${user.name})` : ''}
              </Option>
            ))}
          </Select>
          <div className="text-[#1B2028] text-sm mt-2">
            *Team Members will be notified via email
          </div>
        </div>

        {/* Buttons */}
        <div className="flex justify-end gap-4 mt-6">
          <Button
            onClick={onClose}
            className="bg-gray-300 text-gray-600 px-6 py-2 rounded-md"
          >
            Close
          </Button>
          <Button
            type="primary"
            onClick={handleSave}
            className="bg-[#41EAD4] text-[#192028] px-6 py-2 rounded-md"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditTeamModal;
