import React, { useState } from 'react';
import { Typography } from 'antd';
import AddFolderModal from '../../Modal/AddFolderModal';
import FoldersSvg from '../../../../assets/SVG/FolderEmptyState.svg'; // Adjust the path
import { useAuth } from '../../../authcontext';

const { Title, Text } = Typography;

const AllFoldersEmpty = () => {
  const { userId } = useAuth();
  const [isAddFolderModalVisible, setIsAddFolderModalVisible] = useState(false);

  // Function to open the modal
  const showAddFolderModal = () => {
    setIsAddFolderModalVisible(true);
  };

  // Function to handle modal close
  const closeAddFolderModal = () => {
    setIsAddFolderModalVisible(false);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      {/* Dashed box container */}
      <div className="border-dashed border-2 border-gray-300 rounded-lg p-12 flex flex-col items-center cursor-pointer" onClick={showAddFolderModal}>
        <img
          src={FoldersSvg}
          alt="Folder Icon"
          className="w-16 h-16 mb-4"
        />
        <Text className="text-gray-500 font-medium">Create a New Folder</Text>
      </div>

      {/* Text below the dashed box */}
      <Title level={4} className="mt-8 font-semibold text-gray-600">No Folders Here Yet</Title>
      <Text className="text-center text-gray-400 max-w-md">
        It looks like this space is empty. Start by creating a new folder to organize your files.
      </Text>

      <AddFolderModal
        isVisible={isAddFolderModalVisible}
        userId={userId}
        handleCancel={closeAddFolderModal}
      />
    </div>
  );
};

export default AllFoldersEmpty;
