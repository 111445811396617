import React from 'react';

const FolderGridLoading = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
      {Array.from({ length: 15 }).map((_, index) => (
        <div key={index} className="p-4 bg-white border rounded-lg shadow-sm">
          <div className="flex items-center justify-between mb-4">
            <div className="w-6 h-6 bg-gray-300 rounded-full"></div>
            <div className="w-5 h-5 bg-gray-300 rounded-full"></div>
          </div>
          <div className="w-full h-12 bg-gray-200 rounded-md mb-4"></div>
          <div className="space-y-2">
            <div className="w-3/4 h-4 bg-gray-200 rounded-md"></div>
            <div className="w-2/4 h-4 bg-gray-200 rounded-md"></div>
            <div className="w-full h-4 bg-gray-200 rounded-md"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FolderGridLoading;
