import React, { useState } from "react";
import { message, Spin } from "antd"; // Added Spin for spinner
import LockIcon from "../../../../assets/SVG/Vault.svg"; // Ensure this is the correct path to your SVG
import { useAuth } from "../../../authcontext";

const ForgotPin = ({ onBack }) => {
  const { userId } = useAuth(); // Get userId from auth context
  const [requestSent, setRequestSent] = useState(false); // State to track if request was sent successfully
  const [isLoading, setIsLoading] = useState(false); // State to track loading

  const handleSendClick = async () => {
    setIsLoading(true); // Start spinner
    try {
      const token = localStorage.getItem("sessionToken");
      const response = await fetch(
        `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/vault/pin-reset-request`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            userId: userId,
          }),
        }
      );

      const responseData = await response.json();

      if (response.status === 200) {
        message.success("PIN reset request sent successfully");
        setRequestSent(true); // Update state to indicate request was successful
      } else if (response.status === 400) {
        message.error(responseData.error?.message || "Bad request. Please try again.");
      } else if (response.status === 401) {
        message.error("Unauthorized access. Please log in again.");
      } else if (response.status === 404) {
        message.error("User not found. Please check your account details.");
      } else if (response.status === 500) {
        message.error("Internal server error. Please try again later.");
      } else {
        message.error("An unexpected error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error during PIN reset request:", error);
      message.error("Network error. Please check your connection and try again.");
    } finally {
      setIsLoading(false); // Stop spinner
    }
  };

  return (
    <div className="flex justify-center items-center w-full h-full p-24">
      <div className="flex flex-col justify-center items-center gap-6 w-[428px] p-[60px] h-[418px] bg-white rounded-lg shadow-lg">
        {/* Heading */}
        <h2 className="text-2xl font-semibold text-[#59616E]">Forgot Pin?</h2>

        {/* SVG Lock Icon */}
        <div className="my-4">
          <img src={LockIcon} alt="Lock Icon" className="w-[64px] h-[64px]" />
        </div>

        {/* Main Text */}
        <p className="text-center text-[#798897] font-semibold whitespace-nowrap">
          Send a request to Super-Admin to reset your PIN.
        </p>

        {/* Secondary Text */}
        <p className="text-center text-[#798897] leading-relaxed">
          For security reasons, only the Super-Admin can approve PIN resets to ensure sensitive information remains protected.
        </p>

        {/* Buttons */}
        <div className="flex justify-end items-center gap-3 w-full mt-2">
          <button
            className="px-8 py-2 rounded-md bg-[#C4CBD8] text-[#1B2028]"
            onClick={onBack} // Trigger the back action
            style={{ minWidth: "120px", minHeight: "40px" }} // Maintain consistent button size

          >
            Back
          </button>
          <button
            className={`flex items-center justify-center px-8 py-2 rounded-md ${
              requestSent ? "bg-gray-400" : "bg-[#41EAD4]"
            } text-[#192028]`}
            onClick={handleSendClick}
            disabled={requestSent || isLoading} // Disable the button if the request is sent or loading
            style={{ minWidth: "120px", minHeight: "40px" }} // Maintain consistent button size
          >
            {isLoading ? (
              <Spin size="small" /> // Spinner when loading
            ) : requestSent ? (
              "Request Sent"
            ) : (
              "Send"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPin;