import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  FilterOutlined,
  SortAscendingOutlined,
  SearchOutlined,
  MoreOutlined,
  PlusOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu, Skeleton } from 'antd';
import ProfileDetail from './ProfileDetail';
import AddNewTeamMemberModal from '../../../Services/Modal/AddNewTeamMember';
import MakeCoManagerModal from '../../../Services/EditModal/MakeCoManagerModal';
import RemoveCoManagerModal from '../../../Services/EditModal/RemoveCoManagerModal';
import axiosDocInstance from '../../../ApiServices/AxiosDocInstance';

const TeamMembers = ({ memberIds }) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCoManagerModalVisible, setIsCoManagerModalVisible] = useState(false);
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState(null);

  useEffect(() => {
    const fetchMembers = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/generic/teamMembers`,
          { memberIds }
        );
        setMembers(response.data.data.memberDetails); // Updated to match API response structure
      } catch (error) {
        console.error('Error fetching members:', error);
      } finally {
        setLoading(false);
      }
    };

    if (memberIds.length > 0) {
      fetchMembers();
    }
  }, [memberIds]);

  const handleNameClick = (member) => {
    setSelectedMember(member);
  };

  const handleCloseProfile = () => {
    setSelectedMember(null);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleMenuClick = ({ key }, member) => {
    if (key === 'makeCoManager') {
      setIsCoManagerModalVisible(true);
    } else if (key === 'removeMember') {
      setMemberToRemove(member);
      setIsRemoveModalVisible(true);
    }
  };

  const handleCloseCoManagerModal = () => {
    setIsCoManagerModalVisible(false);
  };

  const handleCloseRemoveModal = () => {
    setIsRemoveModalVisible(false);
    setMemberToRemove(null);
  };

  const handleRemoveMember = () => {
    console.log('Member removed:', memberToRemove);
    setIsRemoveModalVisible(false);
  };

  const menu = (member) => (
    <Menu onClick={(e) => handleMenuClick(e, member)}>
      <Menu.Item key="makeCoManager" icon={<UserAddOutlined />}>
        Make Co-Manager
      </Menu.Item>
      <Menu.Item key="removeMember" icon={<UserDeleteOutlined />}>
        Remove Member
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="relative w-full">
      <div className="flex justify-end mb-6">
        <button
          className="flex items-center gap-2 px-4 py-2 border-2 border-[#FF6F61] text-[#FF6F61] rounded-lg bg-white shadow-sm"
          onClick={showModal}
        >
          <PlusOutlined />
          Add New Member
        </button>
      </div>

      <div className="flex flex-col max-w-[762px] h-auto p-4 bg-white rounded-lg shadow-md gap-4">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-semibold text-gray-900">Team Members</h2>
          <div className="flex items-center gap-4">
            <div className="flex items-center border border-gray-300 rounded-lg px-3 py-2 gap-2 w-64">
              <input
                type="text"
                placeholder="Search for team member"
                className="w-full outline-none text-gray-600"
              />
              <SearchOutlined className="text-gray-400" />
            </div>
            <Dropdown overlay={<Menu><Menu.Item key="1">Filter Option</Menu.Item></Menu>}>
              <button className="flex items-center px-4 py-2 border border-gray-300 rounded-lg text-gray-500">
                Filter <FilterOutlined className="ml-2" />
              </button>
            </Dropdown>
            <Dropdown overlay={<Menu><Menu.Item key="1">Sort Option</Menu.Item></Menu>}>
              <button className="flex items-center px-4 py-2 border border-gray-300 rounded-lg text-gray-500">
                Sort by <SortAscendingOutlined className="ml-2" />
              </button>
            </Dropdown>
          </div>
        </div>
        <div className="overflow-auto" style={{ maxHeight: '400px' }}>
          {loading ? (
            [...Array(4)].map((_, index) => (
              <div key={index} className="flex items-center gap-3 py-3">
                <Skeleton.Avatar active size={40} />
                <Skeleton.Input active size="small" style={{ width: 100 }} />
                <Skeleton.Input active size="small" style={{ width: 150 }} />
              </div>
            ))
          ) : (
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="text-left py-3 px-4">Name</th>
                  <th className="text-left py-3 px-4">Email</th>
                  <th className="text-left py-3 px-4">Department</th>
                  <th className="py-3 px-4"></th>
                </tr>
              </thead>
              <tbody>
                {members.map((member, index) => (
                  <tr key={index}>
                    <td className="py-3 px-4 flex items-center gap-2">
                      <img
                        src={member.avatar || 'https://via.placeholder.com/40'}
                        alt={member.name}
                        className="w-10 h-10 rounded-full"
                      />
                      <span
                        onClick={() => handleNameClick(member)}
                        className="cursor-pointer"
                      >
                        {member.name} {member.lastName}
                      </span>
                    </td>
                    <td className="py-3 px-4">{member.email}</td>
                    <td className="py-3 px-4">N/A</td>
                    <td className="py-3 px-4 text-right">
                      <Dropdown overlay={menu(member)} trigger={['click']}>
                        <MoreOutlined className="cursor-pointer" />
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {selectedMember && (
        <ProfileDetail profile={selectedMember} onClose={handleCloseProfile} />
      )}

      <AddNewTeamMemberModal visible={isModalVisible} onClose={handleCloseModal} />
      <MakeCoManagerModal visible={isCoManagerModalVisible} onClose={handleCloseCoManagerModal} />
      <RemoveCoManagerModal
        visible={isRemoveModalVisible}
        onClose={handleCloseRemoveModal}
        onRemove={handleRemoveMember}
      />
    </div>
  );
};

export default TeamMembers;
