import React from 'react';
import { Modal, Button } from 'antd';
import { UserDeleteOutlined } from '@ant-design/icons'; // Import the icon

const RemoveCoManagerModal = ({ visible, onClose, onRemove }) => {
  return (
    <Modal
      title={null}
      visible={visible} // The prop to control modal visibility
      onCancel={onClose}
      footer={null}
      centered
      bodyStyle={{
        padding: 0,
      }}
    >
      <div className="flex flex-col items-center gap-3 p-3 w-[484px] bg-white">
        {/* Icon and Title */}
        <div className="flex items-center justify-center gap-2 mb-2">
          <UserDeleteOutlined style={{ fontSize: '20px', color: '#59616E' }} />
          <h2 className="text-lg font-semibold text-gray-900">Remove Saurav</h2>
        </div>

        <p className="text-base leading-6 text-center text-gray-400 mb-8">
          Are you sure you want to remove Saurav?
          <br />
          He will no longer have the authority to add or remove members or will be a part of this Team.
        </p>

        {/* Buttons aligned to the right */}
        <div className="flex justify-end gap-4 w-full pr-4">
          <Button
            onClick={onClose}
            className="w-24 h-10 bg-gray-100 text-gray-500 border-none rounded-md"
          >
            Close
          </Button>
          <Button
            type="primary"
            onClick={onRemove}
            className="w-24 h-10 bg-[#FF6F61] text-white border-none rounded-md"
          >
            Remove
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveCoManagerModal;