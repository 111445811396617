import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import axios from 'axios';
import DocViewerComponent from '../Components/Versions/DocViewer';
import VersionCrud from '../Components/Versions/VersionCrud';
import VersionHistory from '../Components/Versions/VersionHistory';
import { useParams } from 'react-router-dom';
import { useAuth } from '../authcontext';

const VersionPage = () => {
  const { docId } = useParams(); // Get document ID from the URL
  const { userId } = useAuth(); // Get user authentication details

  const [documentInfo, setDocumentInfo] = useState(null); // Store document info
  const [selectedVersion, setSelectedVersion] = useState(null); // Store the selected version
  const [error, setError] = useState(null); // Store error messages

  // Fetch document information including version history
  useEffect(() => {
    const fetchDocumentInfo = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}`
        );
        const data = response.data.data[0]; // Get the first document
        setDocumentInfo(data); // Set document info
        setSelectedVersion(data.currentVersion); // Set the current version
      } catch (error) {
        setError(error.message); // Handle error
      }
    };

    if (docId) {
      fetchDocumentInfo();
    }
  }, [docId, userId]);

  return (
    <div style={{ padding: '40px' }}>
      <Row gutter={16}>
        {/* Column 2: DocViewer in the center */}
        <Col span={16}>
          {documentInfo && (
            <DocViewerComponent
              selectedVersion={selectedVersion}
              documentInfo={documentInfo}
            />
          )}
        </Col>

        {/* Column 3: Split into two rows for VersionCrud and VersionHistory */}
        <Col span={8}>
          <Row gutter={[0, 16]}>
            {/* First row: VersionCrud for managing versions */}
            <Col span={24}>
              {documentInfo && (
                <VersionCrud
                  documentInfo={documentInfo}
                  selectedVersion={selectedVersion}
                  onVersionUploaded={() => {
                    // Refresh document info after uploading a new version
                    axios
                      .get(`${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}`)
                      .then((response) => {
                        const updatedData = response.data.data[0];
                        setDocumentInfo(updatedData);
                        setSelectedVersion(updatedData.currentVersion);
                      })
                      .catch((error) => setError(error.message));
                  }}
                />
              )}
            </Col>

            {/* Second row: VersionHistory for listing versions */}
            <Col span={24}>
              {documentInfo && (
                <VersionHistory
                  documentInfo={documentInfo}
                  onSelectVersion={(versionId) => setSelectedVersion(versionId)}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Error handling */}
      {error && <div>{error}</div>}
    </div>
  );
};

export default VersionPage;
