import React, { useState } from 'react';
import { Modal, Input, Button, message } from 'antd';

const AddNewUserModal = ({ isVisible, onClose }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({}); // State to track field errors

  // Reset the modal fields
  const resetFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setErrors({});
  };

  const validateFields = () => {
    const newErrors = {};

    if (!firstName.trim()) newErrors.firstName = 'First Name is required';
    if (!lastName.trim()) newErrors.lastName = 'Last Name is required';
    if (!email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = 'Invalid email format';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleCreate = async () => {
    if (!validateFields()) return;

    const token = localStorage.getItem('sessionToken');

    const requestBody = {
      name: firstName,
      lastName,
      email,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/admin/createUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        message.success('User created successfully');
        resetFields(); // Reset the fields after a successful response
        onClose();
      } else {
        const errorData = await response.json();
        const errorMessage = errorData.error?.message || 'An error occurred';
        if (errorMessage === 'user-already-exist-please-regenerate-password-mail') {
          message.error('User already exists. Please regenerate the password mail.');
        } else {
          message.error(`Failed to create user: ${errorMessage}`);
        }
        resetFields(); // Reset fields even after an error
      }
    } catch (error) {
      console.error('Error creating user:', error);
      message.error('An unexpected error occurred while creating the user.');
      resetFields(); // Reset fields on error
    }
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={() => {
        resetFields(); // Reset fields when closing the modal
        onClose();
      }}
      footer={null}
      centered
      width={900}
      className="flex flex-col justify-center items-center p-14 gap-6"
    >
      <h2 className="text-2xl font-semibold text-[#192028] mb-6 text-center">Create New User</h2>
      <div className="flex justify-between w-full gap-6 mb-6">
        <div className="w-full">
          <Input
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className={`w-full p-3 border rounded-md focus:border-teal-400 ${
              errors.firstName ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {errors.firstName && <p className="text-red-500 text-xs mt-1">{errors.firstName}</p>}
        </div>
        <div className="w-full">
          <Input
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className={`w-full p-3 border rounded-md focus:border-teal-400 ${
              errors.lastName ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {errors.lastName && <p className="text-red-500 text-xs mt-1">{errors.lastName}</p>}
        </div>
      </div>
      <div className="flex justify-between w-full gap-6 mb-6">
        <div className="w-full">
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={`w-full p-3 border rounded-md focus:border-teal-400 ${
              errors.email ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
        </div>
      </div>
      <div className="text-[#59616E] text-sm mb-6">*User will be notified via email</div>
      <div className="flex justify-end w-full gap-4">
        <Button
          onClick={() => {
            resetFields(); // Reset fields when closing the modal
            onClose();
          }}
          className="px-6 py-2 bg-gray-300 text-[#1B2028] rounded-md hover:bg-gray-400"
        >
          Close
        </Button>
        <Button
          onClick={handleCreate}
          className="px-6 py-2 bg-[#41EAD4] text-[#192028] rounded-md hover:bg-teal-500"
        >
          Create
        </Button>
      </div>
    </Modal>
  );
};

export default AddNewUserModal;
