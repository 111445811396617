import React, { useEffect, useState } from "react";
import { Tabs, Button, message } from "antd";
import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";
import AddVersionModal from "../../Services/Modal/AddNewVersionModal";
import DeleteVersionModal from "../../Services/DeleteModal/DeleteVersionModal";
import ShareVersionModal from "../../Services/ShareModal/ShareVersionModal";
import { useParams } from "react-router-dom";
import axios from "axios";
import InfoTab from "./Info"; 
import VersionControlTab from "./VersionControl"; 
import AccessControl from "./AccessControl";

const { TabPane } = Tabs;

const VersionCrud = () => {
  const { docId } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [selectedPermission, setSelectedPermission] = useState("Can Modify");
  const [documentData, setDocumentData] = useState(null);
  const [selectedVersionId, setSelectedVersionId] = useState(null);

  const fetchVersionHistory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}`
      );
      setDocumentData(response.data.data[0]);
    } catch (error) {
      console.log("Error Fetching Version History:", error);
    }
  };

  useEffect(() => {
    if (docId) {
      fetchVersionHistory();
    }
  }, [docId]);

  const handleVersionDelete = async (versionId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}?versionId=${versionId}`
      );
      fetchVersionHistory(); // Refresh document data after deletion
      message.success(`Version ${versionId} deleted successfully`);
    } catch (error) {
      message.error("Error deleting version");
      console.error("Error deleting version:", error);
    }
  };

  const renderTabContent = () => {
    if (activeTab === "1") {
      return <InfoTab documentData={documentData} />;
    } else if (activeTab === "2") {
      return <VersionControlTab documentData={documentData} />;
    } else if (activeTab === "3") {
      return (
        <AccessControl
          selectedPermission={selectedPermission}
          setSelectedPermission={setSelectedPermission}
          docId={docId}
        />
      );
    }
  };

  return (
    <div
      className="bg-white rounded-lg p-6 shadow-md"
      style={{ width: "380px", minHeight: "376px" }}
    >
      <div className="flex justify-between items-center mb-4">
        {/* Button for adding a new version */}
        <Button
          onClick={() => setIsModalVisible(true)}
          className="flex items-center justify-center h-8 px-4 gap-2 flex-col rounded-lg bg-[#007768] text-[#FFFFFF] shadow-md"
          style={{
            display: "flex",
            height: "32px",
            padding: "0 var(--Components-Button-Component-paddingInline, 15px)",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            borderRadius: "var(--Components-Button-Global-borderRadius, 6px)",
            boxShadow: "0px 2px 0px 0px rgba(5, 145, 255, 0.10)",
          }}
        >
          <CloudUploadOutlined style={{ color: "#FFFFFF", fontSize: "16px" }} />
          <span style={{ fontSize: "14px", fontWeight: "500" }}>
            New Version
          </span>
        </Button>

        {/* Button for deleting a version */}
        <Button
          onClick={() => setIsDeleteModalVisible(true)}
          className="flex items-center justify-center w-[97px] p-2 border border-[#FCB3A9] bg-[#FCF9F9] rounded-[6px] gap-2"
        >
          <DeleteOutlined style={{ color: "#FF412E", fontSize: "16px" }} />
          <span
            style={{ color: "#FF412E", fontSize: "14px", fontWeight: "500" }}
          >
            Delete
          </span>
        </Button>
      </div>

      {/* Tabs Component */}
      <Tabs activeKey={activeTab} onChange={setActiveTab} tabBarGutter={24}>
        <TabPane tab="Info" key="1" />
        <TabPane tab="Version Control" key="2" />
        <TabPane tab="Access Control" key="3" />
      </Tabs>

      {/* Dynamic Content Display */}
      <div className="mt-4">{renderTabContent()}</div>

      {/* Modal for adding a new version */}
      <AddVersionModal
        isModalVisible={isModalVisible}
        handleCancel={() => setIsModalVisible(false)}
        docId={docId}
        docType={documentData?.docType}
        fetchDocumentInfo={fetchVersionHistory}
      />

      {/* Modal for deleting a version */}
      <DeleteVersionModal
        isModalVisible={isDeleteModalVisible}
        handleCancel={() => setIsDeleteModalVisible(false)}
        versions={documentData?.versions}
        onDelete={(versionId) => handleVersionDelete(versionId)} // Use handleVersionDelete for deletion
      />

      {/* Modal for sharing a version */}
      <ShareVersionModal
        isVisible={isShareModalVisible}
        handleCancel={() => setIsShareModalVisible(false)}
      />
    </div>
  );
};

export default VersionCrud;
