import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Avatar, Typography, Space, Skeleton } from "antd";
import { useAuth } from "../../authcontext";
import { useNavigate } from "react-router-dom";

import Pdf from '../../../assets/SVG/Pdf.svg';
import Jpg from '../../../assets/SVG/JPG.svg';
import zip from '../../../assets/SVG/zip.svg';
import Html from '../../../assets/SVG/Html.svg';
import Docs from '../../../assets/SVG/Docs.svg';
import NoDataSvg from "../../../assets/SVG/RecentModified.svg";

// File type mapping
const fileTypeMapping = {
  'image/jpeg': ['jpg', 'jpeg', 'png'],
  'image/png': ['png', 'jpg', 'jpeg'],
  'application/pdf': ['pdf'],
  'application/vnd.ms-excel': ['xls', 'xlsx'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['xls', 'xlsx'],
  'application/msword': ['doc', 'docx'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['doc', 'docx'],
  'application/vnd.ms-powerpoint': ['ppt', 'pptx'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['ppt', 'pptx'],
  'text/plain': ['txt'],
  'text/csv': ['csv'],
};

// Get the appropriate document icon based on docType
const getDocumentIcon = (docType) => {
  const type = docType.toLowerCase();
  if (fileTypeMapping['application/pdf'].includes(type)) return Pdf;
  if (fileTypeMapping['image/jpeg'].includes(type) || fileTypeMapping['image/png'].includes(type)) return Jpg;
  if (fileTypeMapping['application/vnd.ms-excel'].includes(type)) return Docs;
  if (fileTypeMapping['application/msword'].includes(type)) return Docs;
  if (fileTypeMapping['application/vnd.ms-powerpoint'].includes(type)) return Docs;
  if (fileTypeMapping['text/plain'].includes(type)) return Html;
  return zip;
};

const ListDocument = () => {
  const [documents, setDocuments] = useState([]);
  const { userId, authenticated } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDocumentData = async () => {
      if (authenticated) {
        setLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/list/${userId}?forDashboard=true`
          );
          const documents = response.data.data.finalList;

          const documentsWithVersions = await Promise.all(
            documents.map(async (doc) => {
              try {
                const versionsResponse = await axios.get(
                  `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${doc._id}`
                );
                const documentInfo = versionsResponse.data.data[0];
                return {
                  _id: doc._id,
                  docName: doc.docName,
                  docType: doc.docType.split("/")[1].toUpperCase(),
                  uploadedAt: new Date(doc.uploadedAt).toLocaleDateString("en-GB"),
                  lastModified: new Date(doc.updatedAt).toLocaleDateString("en-GB"),
                  versions: documentInfo?.versions?.length || 1,
                };
              } catch (error) {
                console.error("Error fetching versions for document:", error);
                return {
                  _id: doc._id,
                  docName: doc.docName,
                  docType: doc.docType.split("/")[1].toUpperCase(),
                  uploadedAt: new Date(doc.uploadedAt).toLocaleDateString("en-GB"),
                  lastModified: new Date(doc.updatedAt).toLocaleDateString("en-GB"),
                  versions: 1,
                };
              }
            })
          );

          setDocuments(documentsWithVersions);
        } catch (error) {
          console.error("Error fetching documents:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchDocumentData();
  }, [userId, authenticated]);

  const handleDocumentClick = (docId) => {
    navigate(`/documents/${docId}/versions`);
  };

  const columns = [
    {
      title: "Documents",
      dataIndex: "docName",
      key: "docName",
      render: (text, record) => (
        <Space>
          <img src={getDocumentIcon(record.docType)} alt={record.docType} style={{ width: 24 }} />
          <div
            style={{ cursor: "pointer" }}
            onClick={() => handleDocumentClick(record._id)}
          >
            <Typography.Text>{text || "Content"}</Typography.Text>
            <Typography.Text type="secondary">
              {record.bucketKey || "File Location"}
            </Typography.Text>
          </div>
        </Space>
      ),
      filters: [
        { text: "PDF", value: "PDF" },
        { text: "DOCX", value: "DOCX" },
        { text: "JPEG", value: "JPEG" },
        { text: "ZIP", value: "ZIP" },
      ],
      onFilter: (value, record) => record.docType === value,
    },
    {
      title: "Date",
      dataIndex: "uploadedAt",
      key: "uploadedAt",
      filters: Array.from(new Set(documents.map((doc) => doc.uploadedAt))).map(
        (date) => ({ text: date, value: date })
      ),
      onFilter: (value, record) => record.uploadedAt === value,
    },
    {
      title: "Type",
      dataIndex: "docType",
      key: "docType",
      filters: [
        { text: "PDF", value: "PDF" },
        { text: "DOCX", value: "DOCX" },
        { text: "JPEG", value: "JPEG" },
        { text: "ZIP", value: "ZIP" },
      ],
      onFilter: (value, record) => record.docType === value,
    },
    {
      title: "Versions",
      dataIndex: "versions",
      key: "versions",
      render: (text) => text || 1,
      filters: Array.from(
        new Set(documents.map((doc) => doc.versions || 1))
      ).map((version) => ({
        text: version.toString(),
        value: version.toString(),
      })),
      onFilter: (value, record) => record.versions.toString() === value,
    },
    {
      title: "Last Modified",
      dataIndex: "lastModified",
      key: "lastModified",
      render: (text) => text || "N/A",
      filters: Array.from(
        new Set(documents.map((doc) => doc.lastModified))
      ).map((date) => ({ text: date, value: date })),
      onFilter: (value, record) => record.lastModified === value,
    },
    {
      title: "Team Members",
      dataIndex: "teamMembers",
      key: "teamMembers",
      render: () => (
        <Avatar.Group>
          {[{ name: "Alice", color: "red" }, { name: "Bob", color: "purple" }, { name: "Carol", color: "green" }].map(
            (member, index) => (
              <Avatar key={index} style={{ backgroundColor: member.color, fontSize: 12 }}>
                {member.name.charAt(0)}
              </Avatar>
            )
          )}
        </Avatar.Group>
      ),
    },
  ];

  // Loading state skeleton columns as per PNG design
  const skeletonColumns = [
    {
      title: <Skeleton.Input active style={{ width: "100px" }} />,
      dataIndex: "docName",
      key: "docName",
      render: () => (
        <Space>
          <Skeleton.Avatar active size="small" shape="square" />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Skeleton.Input active style={{ width: "150px", marginBottom: "4px" }} />
            <Skeleton.Input active style={{ width: "100px" }} />
          </div>
        </Space>
      ),
    },
    {
      title: <Skeleton.Input active style={{ width: "80px" }} />,
      dataIndex: "uploadedAt",
      key: "uploadedAt",
      render: () => <Skeleton.Input active style={{ width: "100px" }} />,
    },
    {
      title: <Skeleton.Input active style={{ width: "80px" }} />,
      dataIndex: "docType",
      key: "docType",
      render: () => <Skeleton.Input active style={{ width: "80px" }} />,
    },
    {
      title: <Skeleton.Input active style={{ width: "60px" }} />,
      dataIndex: "versions",
      key: "versions",
      render: () => <Skeleton.Input active style={{ width: "50px" }} />,
    },
    {
      title: <Skeleton.Input active style={{ width: "120px" }} />,
      dataIndex: "lastModified",
      key: "lastModified",
      render: () => <Skeleton.Input active style={{ width: "100px" }} />,
    },
    {
      title: <Skeleton.Input active style={{ width: "120px" }} />,
      dataIndex: "teamMembers",
      key: "teamMembers",
      render: () => (
        <Avatar.Group>
          <Skeleton.Avatar active size="small" shape="circle" />
          <Skeleton.Avatar active size="small" shape="circle" />
          <Skeleton.Avatar active size="small" shape="circle" />
        </Avatar.Group>
      ),
    },
  ];

  return (
    <div style={{ padding: "24px", background: "#fff" }}>
      <Typography.Title level={4}>Recently Modified</Typography.Title>

      {loading ? (
        <Table
          dataSource={[...Array(5)].map((_, index) => ({ key: index }))}
          columns={skeletonColumns}
          pagination={false}
        />
      ) : (
        <Table
          dataSource={documents}
          columns={columns}
          rowKey="_id"
          pagination={false}
          locale={{
            emptyText: (
              <div style={{ textAlign: "center" }}>
                <img src={NoDataSvg} alt="No Data" />
                {/* <Typography.Text>No Documents Available</Typography.Text> */}
              </div>
            ),
          }}
        />
      )}
    </div>
  );
};

export default ListDocument;
