import React from 'react';
import { Modal, Form, Select, Button, Avatar } from 'antd';
import { UserAddOutlined, DownOutlined } from '@ant-design/icons';

const AddCoManagerModal = ({ visible, onClose }) => {
  const [form] = Form.useForm();

  const handleCreate = () => {
    form.validateFields().then((values) => {
      console.log('Form Values:', values);
      onClose();
    });
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={800}
      bodyStyle={{
        padding: '50px 100px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      {/* Header */}
      <h2 className="text-2xl font-semibold flex items-center">
        Add Co-Manager <UserAddOutlined className="ml-2 text-2xl" />
      </h2>
      <p className="text-sm text-gray-500">Team XYZ</p>

      {/* Form Section */}
      <Form form={form} layout="vertical" className="w-full">
        <Form.Item
          name="username"
          label="User Name"
          rules={[{ required: true, message: 'Please input the user name!' }]}
        >
          <Select
            placeholder="@Saurav.S.N"
            suffixIcon={<DownOutlined />}
            className="w-full"
            dropdownClassName="custom-dropdown"
            dropdownRender={(menu) => <div className="p-2">{menu}</div>}
          >
            {/* First Option */}
            <Select.Option value="saurav">
              <div className="flex items-center p-2 hover:bg-[#D1F8F0] rounded-md">
                <Avatar src="https://i.pravatar.cc/40?img=10" />
                <span className="ml-3 text-gray-700 text-lg">Saurav.S.N</span>
              </div>
            </Select.Option>

            {/* Second Option */}
            <Select.Option value="sunil">
              <div className="flex items-center p-2 hover:bg-[#D1F8F0] rounded-md">
                <Avatar src="https://i.pravatar.cc/40?img=11" />
                <span className="ml-3 text-gray-700 text-lg">Sunil.S.N</span>
              </div>
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>

      {/* Action Buttons */}
      <div className="flex justify-end w-full mt-5 gap-3">
        <Button
          onClick={onClose}
          className="bg-gray-300 text-[#1B2028] h-10 px-5 rounded-md hover:bg-gray-400"
        >
          Close
        </Button>
        <Button
          type="primary"
          onClick={handleCreate}
          className="bg-[#41EAD4] text-[#1B2028] h-10 px-5 rounded-md hover:bg-teal-500"
        >
          Create
        </Button>
      </div>
    </Modal>
  );
};

export default AddCoManagerModal;