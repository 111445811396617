// import React, { useState } from 'react';
// import { MoreOutlined, TeamOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
// import { Dropdown, Menu, Button } from 'antd';
// import EditTeamModal from '../../Services/EditModal/EditTeamModal';
// import DeleteTeamModal from '../../Services/DeleteModal/DeleteTeamModal';
// import LeaveTeamModal from '../../Services/DeleteModal/LeaveTeamModal'; // Correct path to the modal
// import { useAuth } from '../../authcontext';
// import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

// const TeamCard = ({ team, onEditTeam, onDeleteTeam, onLeaveTeam, viewAll}) => {
//   const { userId } = useAuth(); // Get the user ID from the AuthContext
//   const navigate = useNavigate(); // Initialize useNavigate hook for redirection
//   const [visibleDropdown, setVisibleDropdown] = useState(null); // Handle dropdown visibility
//   const [isEditModalVisible, setIsEditModalVisible] = useState(false); // Handle edit modal visibility
//   const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false); // Handle delete modal visibility
//   const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false); // Handle leave modal visibility
//   const [hoveredItem, setHoveredItem] = useState(null); // Handle hover state on menu items

//   // Check if the current user is a co-manager of the team
//   const isCoManager = team.comanagerId.includes(userId);

//   // Handle menu actions (edit or delete)
//   const handleMenuClick = (e) => {
//     if (e.key === 'edit') {
//       setIsEditModalVisible(true); // Show the edit modal
//       setVisibleDropdown(null); // Close the dropdown
//     } else if (e.key === 'delete') {
//       setIsDeleteModalVisible(true); // Show the delete modal
//       setVisibleDropdown(null); // Close the dropdown
//     }
//   };

//   // Close the Edit Modal
//   const handleCloseEditModal = () => {
//     setIsEditModalVisible(false);
//   };

//   // Close the Delete Modal
//   const handleCloseDeleteModal = () => {
//     setIsDeleteModalVisible(false);
//   };

//   // Handle successful deletion of the team
//   const handleDeleteSuccess = () => {
//     onDeleteTeam(team._id); // Notify the parent component about the successful deletion
//   };

//   // Redirect to the team detail page on card click
//   const handleCardClick = () => {
//    if(viewAll){ navigate(`/app/teams/${team._id}`);}// Navigate to the team page using the team ID
//   };

//   // Create the dropdown menu for editing and deleting the team
//   const menu = (
//     <Menu
//       onClick={handleMenuClick} // Handle menu actions
//       style={{
//         display: 'flex',
//         flexDirection: 'column',
//         background: '#FFF',
//         borderRadius: '8px',
//         boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.08)',
//         padding: '4px',
//       }}
//     >
//       <Menu.Item
//         key="edit"
//         icon={<EditOutlined />}
//         onMouseEnter={() => setHoveredItem('edit')} // Handle hover state
//         onMouseLeave={() => setHoveredItem(null)} // Remove hover state
//         style={{
//           backgroundColor: hoveredItem === 'edit' ? '#D1F8F0' : '#FFF',
//           padding: '8px 16px',
//           color: hoveredItem === 'edit' ? '#1B2028' : '#192028',
//         }}
//       >
//         Edit
//       </Menu.Item>
//       {team.managerId === userId && (
//         <Menu.Item
//           key="delete"
//           icon={<DeleteOutlined />}
//           onMouseEnter={() => setHoveredItem('delete')} // Handle hover state
//           onMouseLeave={() => setHoveredItem(null)} // Remove hover state
//           style={{
//             backgroundColor: hoveredItem === 'delete' ? '#D1F8F0' : '#FFF',
//             padding: '8px 16px',
//             color: hoveredItem === 'delete' ? '#1B2028' : '#59616E',
//           }}
//         >
//           Delete
//         </Menu.Item>
//       )}
//     </Menu>
//   );

//   return (
//     <div
//       key={team._id}
//       className="rounded-md shadow-md bg-white border p-4 cursor-pointer"
//       style={{
//         width: '364px', // Fixed width for the card
//         border: '1px solid #E8EAF6', // Light border for visibility
//         borderRadius: '8px',
//         overflow: 'hidden', // Ensure nothing spills out of the card
//         scrollSnapAlign: 'start', // Snap to this card when scrolling
//       }}
//       onClick={handleCardClick} // Trigger redirection when clicking on the card
//     >
//       {/* Team Card Header */}
//       <div className="flex justify-between items-center">
//         <div className="flex justify-center items-center bg-[#FFFFFF] rounded-lg w-10 h-10">
//           <TeamOutlined className="text-xl text-teal-400" />
//         </div>

//         {/* Conditionally render the 3-dot menu for manager or co-manager, and leave button for regular members */}
//         {(team.managerId === userId || isCoManager) ? (
//           <Dropdown
//             overlay={menu}
//             trigger={['click']}
//             onVisibleChange={(flag) => setVisibleDropdown(flag ? team._id : null)} // Control dropdown visibility
//             visible={visibleDropdown === team._id && !isEditModalVisible && !isDeleteModalVisible}
//             placement="bottomRight"
//             onClick={(e) => e.stopPropagation()} // Prevent card click event when clicking the dropdown
//           >
//             <MoreOutlined className="text-gray-400 cursor-pointer" />
//           </Dropdown>
//         ) : (
//           <Button
//             className="text-red-500 border border-red-500"
//             onClick={(e) => {
//               e.stopPropagation(); // Prevent card click event when clicking the leave button
//               setIsLeaveModalVisible(true); // Show leave modal
//             }}
//           >
//             Leave
//           </Button>
//         )}
//       </div>

//       {/* Team Information */}
//       <div className="text-center mt-4">
//         <h3 className="font-semibold text-lg text-[#192028]">{team.name}</h3>
//         <p className="text-sm text-[#59616E]">{team.description || 'No description available'}</p>
//         <p className="text-sm text-[#59616E]">
//           Owner: <span className="text-[#192028]">{team.managerId === userId ? 'Manager' : 'N/A'}</span>
//         </p>
//       </div>

//       {/* Members Section */}
//       <div className="flex justify-center items-center mt-4 gap-4">
//         <p className="font-semibold text-sm text-[#192028]">Members:</p>
//         <div className="flex items-center -space-x-3">
//           {team.members.slice(0, 3).map((member, index) => (
//             <div key={index} className="w-6 h-6 rounded-full flex justify-center items-center bg-gray-300 text-white">
//               {member[0]}
//             </div>
//           ))}
//           {team.members.length > 3 && (
//             <div
//               className="w-6 h-6 rounded-full flex justify-center items-center text-[#192028]"
//               style={{ backgroundColor: '#E8EAF6' }}
//             >
//               +{team.members.length - 3}
//             </div>
//           )}
//         </div>
//       </div>

//       {/* Footer with Date and Department */}
//       <div className="flex justify-between text-sm text-[#192028] mt-4">
//         <p>Date: <span className="text-[#59616E]">{new Date(team.createdAt).toLocaleDateString()}</span></p>
//         <p>Department: <span className="text-[#59616E]">N/A</span></p>
//       </div>

//       {/* Leave Team Modal */}
//       <LeaveTeamModal
//         visible={isLeaveModalVisible}
//         onCancel={() => setIsLeaveModalVisible(false)} // Close modal on cancel
//         onConfirm={() => {
//           setIsLeaveModalVisible(false); // Close modal on confirm
//           if (onLeaveTeam) {
//             onLeaveTeam(team._id); // Call the parent function with the team ID
//           }
//         }}
//         teamId={team._id} // Pass team ID to the modal
//         teamName={team.name} // Pass team name to the modal
//       />

//       {/* Edit and Delete modals */}
//       <EditTeamModal visible={isEditModalVisible} onClose={handleCloseEditModal} team={team} teamId={team._id} />
//       <DeleteTeamModal
//         visible={isDeleteModalVisible}
//         onClose={handleCloseDeleteModal}
//         onDeleteSuccess={handleDeleteSuccess} // Pass the delete success handler
//         teamId={team._id} // Pass the team ID for deletion
//       />
//     </div>
//   );
// };

// export default TeamCard;












import React, { useState } from 'react';
import { MoreOutlined, UserOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Button } from 'antd';
import EditTeamModal from '../../Services/EditModal/EditTeamModal';
import DeleteTeamModal from '../../Services/DeleteModal/DeleteTeamModal';
import LeaveTeamModal from '../../Services/DeleteModal/LeaveTeamModal';
import { useAuth } from '../../authcontext';
import { useNavigate } from 'react-router-dom'; 

const TeamCard = ({ team, onEditTeam, onDeleteTeam, onLeaveTeam, viewAll }) => {
  const { userId } = useAuth();
  const navigate = useNavigate();
  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isLeaveModalVisible, setIsLeaveModalVisible] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);

  // Check if the current user is a co-manager of the team
  const isCoManager = team.comanagerId.includes(userId);

  const handleCardClick = () => {
   if(viewAll){ navigate(`/app/teams/${team._id}`);}// Navigate to the team page using the team ID
  };

  const handleMenuClick = (e) => {
    if (e.key === 'edit') {
      setIsEditModalVisible(true);
      setVisibleDropdown(null);
    } else if (e.key === 'delete') {
      setIsDeleteModalVisible(true);
      setVisibleDropdown(null);
    }
  };

  const handleCloseEditModal = () => {
    setIsEditModalVisible(false);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const handleDeleteSuccess = () => {
    onDeleteTeam(team._id);
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      style={{
        display: 'flex',
        flexDirection: 'column',
        background: '#FFF',
        borderRadius: '8px',
        boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.08)',
        padding: '4px',
      }}
    >
      <Menu.Item
        key="edit"
        icon={<EditOutlined />}
        onMouseEnter={() => setHoveredItem('edit')}
        onMouseLeave={() => setHoveredItem(null)}
        style={{
          backgroundColor: hoveredItem === 'edit' ? '#D1F8F0' : '#FFF',
          padding: '8px 16px',
          color: hoveredItem === 'edit' ? '#1B2028' : '#192028',
        }}
      >
        Edit
      </Menu.Item>
      {team.managerId === userId && (
        <Menu.Item
          key="delete"
          icon={<DeleteOutlined />}
          onMouseEnter={() => setHoveredItem('delete')}
          onMouseLeave={() => setHoveredItem(null)}
          style={{
            backgroundColor: hoveredItem === 'delete' ? '#D1F8F0' : '#FFF',
            padding: '8px 16px',
            color: hoveredItem === 'delete' ? '#1B2028' : '#59616E',
          }}
        >
          Delete
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div
      key={team._id}
      className="rounded-md shadow-md bg-white border p-4"
      style={{
        width: '364px', // Fixed width for the card
        border: '1px solid #E8EAF6', // Light border for visibility
        borderRadius: '8px',
        overflow: 'hidden', // Ensure nothing spills out of the card
        scrollSnapAlign: 'start', // Snap to this card when scrolling
    
      }}
      onClick={handleCardClick}
    >
      {/* Team Card Header */}
      <div className="flex justify-between items-center">
        {/* Centered User Icon */}
        <div
          className="flex justify-center items-center mx-auto"
          style={{
            background: '#FFFFFF',
            borderRadius: '8px',
            width: '48px',
            height: '48px',
            border: '1px solid #99EDDE',
          }}
        >
          <UserOutlined style={{ fontSize: '24px', color: '#4ED2BF' }} />
        </div>

        {/* Conditionally render the 3-dot menu for manager or co-manager, and leave button for regular members */}
        {(team.managerId === userId || isCoManager) ? (
          <Dropdown
            overlay={menu}
            trigger={['click']}
            onVisibleChange={(flag) => setVisibleDropdown(flag ? team._id : null)}
            visible={visibleDropdown === team._id && !isEditModalVisible && !isDeleteModalVisible}
            placement="bottomRight"
          >
            <MoreOutlined className="text-gray-400 cursor-pointer" />
          </Dropdown>
        ) : (
          <Button
            className="text-red-500 border border-red-500"
            onClick={() => setIsLeaveModalVisible(true)}
          >
            Leave
          </Button>
        )}
      </div>

      {/* Team Information */}
      <div className="text-center mt-4">
        <h3 className="font-semibold text-lg text-[#192028]">{team.name}</h3>
        <p className="text-sm text-[#59616E]">{team.description || 'No description available'}</p>
        <p className="text-sm text-[#59616E]">
          Owner: <span className="text-[#192028]">{team.managerId === userId ? 'Manager' : 'N/A'}</span>
        </p>
      </div>

      {/* Members Section */}
      <div className="flex justify-center items-center mt-4 gap-4">
        <p className="font-semibold text-sm text-[#192028]">Members:</p>
        <div className="flex items-center -space-x-3">
          {team.members.slice(0, 3).map((member, index) => (
            <div key={index} className="w-6 h-6 rounded-full flex justify-center items-center bg-gray-300 text-white">
              {/* {member[0]} */}
            </div>
          ))}
          {team.members.length > 3 && (
            <div
              className="w-6 h-6 rounded-full flex justify-center items-center text-[#192028]"
              style={{ backgroundColor: '#E8EAF6' }}
            >
              +{team.members.length - 3}
            </div>
          )}
        </div>
      </div>

      {/* Footer with Date and Department */}
      <div className="flex justify-between text-sm text-[#192028] mt-4">
        <p>Date: <span className="text-[#59616E]">{new Date(team.createdAt).toLocaleDateString()}</span></p>
        <p>Department: <span className="text-[#59616E]">N/A</span></p>
      </div>

      {/* Leave Team Modal */}
      <LeaveTeamModal
        visible={isLeaveModalVisible}
        onCancel={() => setIsLeaveModalVisible(false)}
        onConfirm={() => {
          setIsLeaveModalVisible(false);
          if (onLeaveTeam) {
            onLeaveTeam(team._id);
          }
        }}
        teamId={team._id}
        teamName={team.name}
      />

      {/* Edit and Delete modals */}
      {/* <EditTeamModal visible={isEditModalVisible} onClose={handleCloseEditModal} team={team} teamId={team._id} /> */}
      <EditTeamModal
  visible={isEditModalVisible}
  onClose={handleCloseEditModal}
  teamId={team._id}
  teamName={team.name}
  teamDescription={team.description}
  teamMembers={team.members}
/>

      <DeleteTeamModal
        visible={isDeleteModalVisible}
        onClose={handleCloseDeleteModal}
        onDeleteSuccess={handleDeleteSuccess}
        teamId={team._id}
      />
    </div>
  );
};

export default TeamCard;
