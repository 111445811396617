import React, { useState } from "react";
import axios from "axios";
import CheckmarkIcon from "../../../assets/SVG/Success.svg";
import Brixlogo from '../../../assets/PNG/Brix-Logo.png';
import { useNavigate } from "react-router-dom";


const ForgotPassword = () => {
    const navigate = useNavigate();  
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerificationSent, setIsVerificationSent] = useState(false);

  const API_ENDPOINT =
    `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/auth/forgetPassword`;

  const handleSendVerificationLink = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setIsSubmitting(true);

    if (!email) {
      setError("Please provide a valid email.");
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await axios.post(API_ENDPOINT, { email });

      // Handle success response
      if (response.status === 200) {
        setSuccess(
          response.data?.data?.message ||
            "If this email exists on our records, you will receive an email."
        );
        setIsVerificationSent(true); // Hide the form and show the success message
      }
    } catch (error) {
      // Handle errors
      if (error.response) {
        setError(
          error.response?.data?.error?.message ||
            "An unexpected error occurred. Please try again."
        );
      } else {
        setError("Network error. Please check your internet connection.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center px-4 bg-transparent">
          <div className="absolute top-0 left-0 ml-[-130px]">
          <img
        src={Brixlogo}
        alt="Brix Logo"
        className="w-[116px] h-[44px] cursor-pointer"
        onClick={() => navigate("/auth/login")}
      />
      </div>

      <div className="relative w-full max-w-md mt-20">
        {isVerificationSent ? (
          // Success message with the success icon
          <div className="flex flex-col items-center">
            <img
              src={CheckmarkIcon}
              alt="Success"
              className="w-32 h-32 mb-4"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "default-success-icon.png"; // Replace with your fallback image
              }}
            />
            <p className="text-center text-gray-700">
              {success ||
                "We’ve sent a link to your email. Check your inbox to reset your password."}
            </p>
          </div>
        ) : (
          // The form is only shown when `isVerificationSent` is false
          <div className="ml-4">
            {error && (
              <div
                role="alert"
                aria-live="assertive"
                className="flex items-center p-4 mb-4 border border-red-300 bg-red-50 text-red-700"
              >
                <span className="ml-2">{error}</span>
              </div>
            )}

            <h2 className="text-2xl font-semibold mb-4">Forgot Password?</h2>
            <p className="text-gray-700 mb-6">
              Enter your email to receive a password reset link.
            </p>

            <form onSubmit={handleSendVerificationLink}>
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={isSubmitting}
                  className={`mt-1 block w-full px-3 py-2 border ${
                    isSubmitting ? "bg-gray-100" : "bg-white"
                  } border-gray-300 rounded-md shadow-sm`}
                />
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full h-10 text-white font-medium rounded-md"
                style={{
                  backgroundColor: isSubmitting ? "#CAD4DD" : "#FF6F61",
                  cursor: isSubmitting ? "not-allowed" : "pointer",
                }}
              >
                {isSubmitting ? "Sending..." : "Send Password Verification Link"}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
