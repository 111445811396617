import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import TeamCard from '../../../Services/UI/TeamCard'; // Replace with your TeamCard component path
import EmptyTeamCard from '../../../Services/UI/EmptyState/EmptyTeamCard'; // Replace with your EmptyTeamCard component path
import AddNewTeamMemberModal from '../../../Services/Modal/AddNewTeamMember'; // Modal for adding new team members
import { useAuth } from '../../../authcontext';

const ScrollTeam = ({ type, onTeamSelect }) => {
  const { userId } = useAuth(); // Get userId from authentication context
  const [teams, setTeams] = useState([]); // Store the list of teams
  const [page, setPage] = useState(2); // Start from page 2 after initial calls
  const [loading, setLoading] = useState(true); // Track loading state
  const [hasMore, setHasMore] = useState(true); // Determine if there's more data to fetch
  const [selectedTeam, setSelectedTeam] = useState(null); // State for the selected team
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state
  const [selectedCardId, setSelectedCardId] = useState(null); // State for the selected card
  const [selectedCardName, setSelectedCardName] = useState(''); // State for the selected card name
  const containerRef = useRef(null); // Reference for the scrollable container

  // Fetch initial teams from 1st and 2nd API calls sequentially
  const fetchInitialTeams = async () => {
    setLoading(true); // Set loading state to true before fetching
    try {
      const response1 = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?userId=${userId}&type=${type}`
      );
      const initialTeams = response1.data.data.teamsList;

      const response2 = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?userId=${userId}&type=${type}&page=1`
      );
      const pageOneTeams = response2.data.data.teamsList;

      const combinedTeams = [...initialTeams, ...pageOneTeams];

      setTeams(combinedTeams); // Set combined teams

      if (combinedTeams.length === 0) {
        setHasMore(false); // If no teams, no more data
      }

      // Set the first team as the default selected card
      if (initialTeams.length > 0) {
        setSelectedCardId(initialTeams[0]._id);
        setSelectedCardName(initialTeams[0].name);
        onTeamSelect(initialTeams[0].members); // Pass the members of the first team to the parent component
      }
    } catch (error) {
      console.error('Error fetching initial teams data:', error);
      setHasMore(false); // Stop fetching more if there's an error
    } finally {
      setLoading(false); // Set loading to false after fetch
    }
  };

  const fetchTeamsByPage = async (pageNum) => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?userId=${userId}&type=${type}&page=${pageNum}`
      );
      const fetchedTeams = response.data.data.teamsList;

      if (fetchedTeams.length === 0) {
        setHasMore(false);
      } else {
        setTeams((prevTeams) => [...prevTeams, ...fetchedTeams]);
      }
    } catch (error) {
      console.error('Error fetching paginated teams data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialTeams(); // Fetch teams on component mount
  }, [userId, type]);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;

      if (container) {
        if (
          container.scrollWidth - container.scrollLeft <=
          container.clientWidth + 50 &&
          hasMore &&
          !loading
        ) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasMore, loading]);

  useEffect(() => {
    if (page > 2) {
      fetchTeamsByPage(page);
    }
  }, [page]);

  const handleDeleteTeam = (team) => {
    console.log(`Team ${team.name} deleted!`);
  };

  const handleAddTeamMember = (team) => {
    setSelectedTeam(team); // Set the selected team for the modal
    setSelectedCardId(team._id); // Ensure the correct card ID is set for the modal
    setSelectedCardName(team.name); // Ensure the correct card name is set for the modal
    setIsModalVisible(true); // Open the modal
  };

  const handleCardClick = (teamId, teamName, memberIds) => {
    setSelectedCardId(teamId); // Update the selected card when clicked
    setSelectedCardName(teamName); // Update the selected card name
    setSelectedTeam({ _id: teamId, name: teamName }); // Store the selected team details
    onTeamSelect(memberIds); // Pass memberIds to the parent component
  };

  const LoadingPlaceholder = () => {
    return (
      <div
        style={{
          width: '372px',
          height: '210px',
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px',
          boxSizing: 'border-box',
          gap: '8px',
          flexShrink: 0,
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{
            width: '80%',
            height: '20px',
            background: '#E5E7EB',
            borderRadius: '4px',
          }}
        ></div>
        <div
          style={{
            width: '100%',
            height: '12px',
            background: '#E5E7EB',
            borderRadius: '4px',
          }}
        ></div>
      </div>
    );
  };

  return (
    <>
      <div
        ref={containerRef}
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
          overflowX: 'auto',
          padding: '16px',
          boxSizing: 'border-box',
          width: '100%',
          maxWidth: '100%',
          whiteSpace: 'nowrap',
          scrollbarWidth: 'thin',
          scrollSnapType: 'x mandatory',
        }}
      >
        {!loading && teams.length === 0 ? (
          <EmptyTeamCard type={type} />
        ) : (
          teams.map((team) => (
            <div
              key={team._id}
              style={{
                scrollSnapAlign: 'start',
                flex: '0 0 auto',
                border:
                  selectedCardId === team._id
                    ? '1px solid var(--Teal-Light-Primary-Hover_Click, #4ED2BF)'
                    : 'none',
                borderRadius: selectedCardId === team._id ? '8px' : 'none',
              }}
              onClick={() =>
                handleCardClick(team._id, team.name, team.members)
              }
            >
              <TeamCard
                key={team._id}
                team={team}
                onDeleteTeam={() => handleDeleteTeam(team)}
                onAddTeamMember={() => handleAddTeamMember(team)}
              />
            </div>
          ))
        )}

        {loading &&
          Array(4)
            .fill()
            .map((_, index) => <LoadingPlaceholder key={index} />)}

        {selectedTeam && (
          <AddNewTeamMemberModal
            visible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            teamId={selectedCardId}
            teamName={selectedCardName}
          />
        )}
      </div>
    </>
  );
};

export default ScrollTeam;
