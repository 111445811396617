import React, { useState } from "react";
import { Modal, Button, Input, Form, Select, Avatar, message, Spin } from "antd";
import { EditOutlined, DownOutlined } from "@ant-design/icons";
import { useAuth } from "../../authcontext";
import TeamSuccessSvg from "../../../assets/SVG/Success.svg";
import TeamErrorSvg from "../../../assets/SVG/Error.svg";
import useDebounceFetch from "../../Services/Utils/Debouncing";
import axios from "axios";

const { Option } = Select;
const { TextArea } = Input;

const AddTeamModal = ({ isModalVisible, handleCancel, fetchTeams }) => {
  const { userId } = useAuth();
  const [status, setStatus] = useState("idle");
  const [form] = Form.useForm();
  const [descriptionCount, setDescriptionCount] = useState(0);
  const [members, setMembers] = useState([]);
  const [coManagers, setCoManagers] = useState([]);
  const [department, setDepartment] = useState("");
  const [teamName, setTeamName] = useState("Create New Team");
  const [isEditingName, setIsEditingName] = useState(false);
  const [term, setTerm] = useState("");
  const [page, setPage] = useState(0);

  const { results: availableUsers, loading, fetchMore, moreToCome } = useDebounceFetch(term, page, setPage);

  const handleDescriptionChange = (e) => {
    setDescriptionCount(e.target.value.length);
  };

  const handleAddMember = (value) => {
    setMembers(value);
  };

  const handleAddCoManager = (value) => {
    setCoManagers(value);
  };

  const handleDepartmentChange = (value) => {
    setDepartment(value);
  };


  const handleSubmit = async (values) => {
    const payload = {
      name: teamName,
      description: values.description,
      departments: department,
      managerId: userId,
      members: members || [],
      comanagerId: coManagers || [],
    };
    try {
      const response =  await axios.post(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setStatus("success");
       } catch (error) {
          console.error("Error creating team:", error);
          message.error("Failed to create the team. Please try again.");
          setStatus("error");
        } finally {
          message.destroy();
        }
      };


  const renderForm = () => (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          marginBottom: "20px",
        }}
      >
        {isEditingName ? (
          <Input
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
            onBlur={() => setIsEditingName(false)}
            style={{
              width: "300px",
              fontSize: "24px",
              fontWeight: "bold",
              textAlign: "center",
            }}
            autoFocus
          />
        ) : (
          <h2
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              color: "#59616E",
              textAlign: "center",
              margin: 0,
            }}
          >
            {teamName}
          </h2>
        )}
        <EditOutlined
          onClick={() => setIsEditingName(true)}
          style={{ cursor: "pointer" }}
        />
      </div>

      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="description"
          label={
            <span>
              Description <span style={{ color: "#A0AEC0" }}>(Optional)</span>
            </span>
          }
          extra={<span style={{ float: "right" }}>{`${descriptionCount}/100`}</span>}
        >
          <TextArea
            placeholder="Enter description"
            maxLength={100}
            onChange={handleDescriptionChange}
            autoSize={{ minRows: 2, maxRows: 4 }}
            style={{
              borderRadius: "8px",
              border: "1px solid #D9DEE7",
            }}
          />
        </Form.Item>

        <Form.Item
          name="members"
          label="Add Members"
          extra={<span>*Team Members will be notified via email</span>}
        >
          <Select
            mode="multiple"
            placeholder="Search and select members"
            optionLabelProp="label"
            onChange={handleAddMember}
            value={members}
            onSearch={setTerm}
            notFoundContent={loading ? <Spin size="small" /> : "No users found"}
            onPopupScroll={(e) => {
              if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight && moreToCome) {
                fetchMore();
              }
            }}
            style={{ border: "1px solid #D9DEE7", borderRadius: "8px" }}
          >
            {availableUsers.map((user) => (
              <Option key={user._id} value={user._id} label={user.name}>
                <Avatar
                  src={`https://i.pravatar.cc/40?img=${Math.floor(
                    Math.random() * 10
                  )}`}
                  style={{ marginRight: "8px" }}
                />
                {user.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <div style={{ display: "flex", gap: "24px" }}>
          <Form.Item name="comanagerId" label="Co-Manager" style={{ flex: 1 }}>
            <Select
              mode="multiple"
              placeholder="Search and select Co-Managers"
              onChange={handleAddCoManager}
              value={coManagers}
              onSearch={setTerm}
              notFoundContent={loading ? <Spin size="small" /> : "No users found"}
              onPopupScroll={(e) => {
                if (e.target.scrollTop + e.target.offsetHeight === e.target.scrollHeight && moreToCome) {
                  fetchMore();
                }
              }}
              style={{ border: "1px solid #D9DEE7", borderRadius: "8px" }}
            >
              {availableUsers.map((user) => (
                <Option key={user._id} value={user._id} label={user.name}>
                  <Avatar
                    src={`https://i.pravatar.cc/40?img=${Math.floor(
                      Math.random() * 10
                    )}`}
                    style={{ marginRight: "8px" }}
                  />
                  {user.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="department" label="Department" style={{ flex: 1 }}>
            <Select
              placeholder="Select Department"
              onChange={handleDepartmentChange}
              value={department}
              suffixIcon={<DownOutlined />}
              style={{ border: "1px solid #D9DEE7", borderRadius: "8px" }}
            >
              <Option value="design">Design</Option>
              <Option value="engineering">Engineering</Option>
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "24px",
            marginTop: "20px",
          }}
        >
          <Button
            key="back"
            onClick={handleCancel}
            style={{
              backgroundColor: "#E1E5EC",
              color: "#59616E",
              borderRadius: "8px",
              border: "1px solid #E1E5EC",
            }}
          >
            Close
          </Button>
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={status === "loading"}
            style={{
              backgroundColor: "#41EAD4",
              color: "#192028",
              borderRadius: "8px",
              border: "1px solid #41EAD4",
            }}
          >
            Create
          </Button>
        </div>
      </Form>
    </>
  );

  const renderSuccess = () => (
    <div
      style={{
        display: "flex",
        maxWidth: "539px",
        maxHeight: "396px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "24px",
      }}
    >
      <img src={TeamSuccessSvg} alt="Success" />
      <h2 style={{ color: "#192028", fontSize: "20px", fontWeight: "600" }}>
        Team Created Successfully
      </h2>
      <Button
        type="primary"
        style={{
          backgroundColor: "#41EAD4",
          color: "#192028",
          borderRadius: "8px",
          padding: "8px 24px",
          border: "none",
        }}
        onClick={handleCancel}
      >
        Go to Teams
      </Button>
    </div>
  );

  const renderError = () => (
    <div
      style={{
        display: "flex",
        maxWidth: "539px",
        maxHeight: "396px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "24px",
      }}
    >
      <img src={TeamErrorSvg} alt="Error" />
      <p
        style={{
          color: "#A6B6C6",
          fontSize: "12px",
          lineHeight: "28px",
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        An error occurred while creating the team. <br />
        Please check the following and try again.
      </p>
      <Button
        type="primary"
        style={{
          backgroundColor: "#41EAD4",
          color: "#192028",
          borderRadius: "8px",
          padding: "8px 15px",
          border: "none",
        }}
        onClick={() => setStatus("idle")}
      >
        Try Again
      </Button>
    </div>
  );

  return (
    <Modal
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      centered
      width={status === "idle" ? 900 : 539}
      style={{
        minHeight: status === "idle" ? "631px" : "396px",
        borderRadius: "8px",
        padding: "90px",
      }}
      className="custom-modal"
    >
      {status === "idle" && renderForm()}
      {status === "success" && renderSuccess()}
      {status === "error" && renderError()}
    </Modal>
  );
};

export default AddTeamModal;
