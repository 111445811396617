import React, { useEffect, useState } from 'react';
import { UserAddOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useAuth } from '../../authcontext';
import { Spin } from 'antd';

const AddNewTeamMemberModal = ({ visible, onClose, teamId, teamName }) => {
  const [availableUsers, setAvailableUsers] = useState([]);
  const [status, setStatus] = useState('idle'); // idle, success, error
  const [isAdding, setIsAdding] = useState(false); // State to manage spinner
  const { userRole } = useAuth();

  console.log(teamId);

  useEffect(() => {
    const fetchUsers = async () => {
      let endpoint = null;
      try {
        if (userRole === 'ADMIN') {
          endpoint = `${process.env.REACT_APP_RBAC_SERVICE_URL}/admin/users`;
        } else if (userRole === 'SUPER ADMIN') {
          endpoint = `${process.env.REACT_APP_RBAC_SERVICE_URL}/superadmin/users`;
        }

        if (endpoint) {
          const response = await axios.get(endpoint);
          if (Array.isArray(response.data.users)) {
            const filteredUsers = response.data.users.filter(
              (user) => user.role !== 'SUPER ADMIN'
            );
            setAvailableUsers(filteredUsers);
            setStatus('success');
          }
        }
      } catch (error) {
        console.error('Error fetching users:', error);
        setStatus('error');
      }
    };

    if (visible) {
      fetchUsers();
    }
  }, [userRole, visible]);

  const handleAddMember = async () => {
    setIsAdding(true); // Show spinner
    try {
      // Simulate adding a member (replace with actual API call)
      await new Promise((resolve) => setTimeout(resolve, 2000));
      console.log('Member added successfully');
      // Handle success (e.g., show notification or close modal)
    } catch (error) {
      console.error('Error adding member:', error);
    } finally {
      setIsAdding(false); // Hide spinner after response
    }
  };

  if (!visible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative bg-white rounded-lg w-[800px] max-h-[424px] p-12 shadow-lg ">
        {/* Close Icon */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          <CloseOutlined className="text-xl" />
        </button>

        {/* Modal Title */}
        <div className="text-2xl font-semibold flex items-center justify-center gap-2 mb-6 text-gray-700">
          Add New Team Member to {teamName} <UserAddOutlined className="text-[#59616E]" />
        </div>

        {/* Status Message */}
        {status === 'error' && (
          <div className="text-red-500 mb-4">Failed to load users.</div>
        )}

        {/* Form Inputs */}
        <div className="flex justify-between gap-6">
          {/* User Name Field */}
          <div className="flex flex-col gap-2 w-1/2">
            <label className="text-sm font-medium text-gray-600 mb-2 block">
              Select User
            </label>
            <select
              className="w-full border border-gray-300 rounded-md p-3 text-gray-700 outline-none focus:ring-2 focus:ring-teal-400"
              disabled={status === 'loading'}
            >
              {status === 'idle' && <option>Loading users...</option>}
              {status === 'error' && <option>Error loading users</option>}
              {status === 'success' && availableUsers.length > 0 ? (
                availableUsers.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.name} ({user.email})
                  </option>
                ))
              ) : (
                <option>No users available</option>
              )}
            </select>
          </div>

          {/* Team Field */}
         
          {/* Team Field */}
          <div className="flex flex-col gap-2 w-1/2">
            <label className="text-sm font-medium text-gray-600">Team</label>
            <div className="w-full border border-gray-300 rounded-md p-3 mt-2 text-gray-700 outline-none focus:ring-2 focus:ring-teal-400">
              <span className="text-gray-700 text-sm ml-3">Team xyz</span>
            </div>
          </div>
        </div>

        {/* Co-Manager Field */}
        <div className="mt-4 flex items-center gap-2">
          <input type="checkbox" className="w-4 h-4 border-gray-300 rounded" />
          <label className="text-sm font-medium text-gray-600">Co-Manager</label>
        </div>

        {/* Modal Buttons */}
        <div className="flex justify-end gap-4 mt-8">
          <button
            onClick={onClose}
            className="bg-gray-200 text-[#192028] px-6 py-2 rounded-lg transition"
            style={{ minWidth: '90px', minHeight: '40px' }}

          >
            Close
          </button>
          <button
            onClick={handleAddMember}
            className="bg-[#41EAD4] text-[#192028] px-6 py-2 rounded-lg font-normal transition  flex items-center justify-center"
            disabled={isAdding} // Disable button while loading
            style={{ minWidth: '90px', minHeight: '40px' }}
          >
            {isAdding ? (
              <Spin
                size="small"
                style={{
                  color: '#192028',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
            ) : (
              'Add Member'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddNewTeamMemberModal;