import React from 'react';
import { Modal } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';

const MakeCoManagerModal = ({ visible, onClose }) => {
  return (
    <Modal
      title={null} // Remove the default title
      visible={visible} // Control modal visibility
      onCancel={onClose}
      footer={null} // Remove the default footer
      centered
      bodyStyle={{
        padding: '18px',
        textAlign: 'center',
      }}
      className="rounded-lg"
    >
      {/* Modal Content */}
      <div className="flex flex-col items-center justify-center mb-6">
        <div className="text-lg font-semibold flex items-center justify-center gap-2 mb-2">
          Make Co-Manager
          <UserAddOutlined />
        </div>
      </div>

      {/* Modal Description */}
      <div className="text-gray-500 mb-6">
      Are you sure you want to make Saurva Co-manager? <br></br>

      You're granting him full control over the team's membership. This means he can add or remove members as needed.
      </div>

      {/* Modal Buttons */}
      <div className="flex justify-end gap-4">
        <button
          onClick={onClose} // Close modal when clicking the "Close" button
          className="bg-gray-200 text-[#1B2028] rounded-lg py-2 px-6 hover:bg-gray-300 focus:outline-none"
        >
          Close
        </button>
        <button
          onClick={() => console.log('Member added')} // Perform some action (here it's logging to the console)
          className="bg-teal-300 text-[#192028] rounded-lg py-2 px-6 hover:bg-teal-400 focus:outline-none"
        >
          Create
        </button>
      </div>
    </Modal>
  );
};

export default MakeCoManagerModal;