import React, { useState, useEffect, useRef } from "react";
import {
  PlusOutlined,
  FlagOutlined,
  ClockCircleOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { message } from "antd";
import AddTaskModal from "../../Services/Modal/AddTaskModal";
import EditTaskModal from "../../Services/EditModal/EditTaskModal";
import DeleteTaskModal from "../../Services/DeleteModal/DeleteTaskModal";
import EditReminderModal from "../../Services/EditModal/EditReminderModal";
import moment from "moment";
import axios from "axios";
import EmptyStateSvg from "../../../assets/SVG/Ongoing.svg";

// Skeleton Loader for loading state
const LoadingPlaceholder = () => (
  <div className="flex justify-between items-center p-4 mb-4 bg-[#F5FFFD] shadow-sm border border-gray-200 rounded-lg relative animate-pulse">
    <div className="absolute left-0 top-0 bottom-0 w-1 bg-gray-300 rounded-l-lg" />
    <div className="flex items-center ml-4">
      <div className="w-5 h-5 bg-gray-300 rounded-full mr-3 flex-shrink-0" />
      <div className="flex flex-col gap-2">
        <div className="h-4 bg-gray-300 rounded w-32 mb-2"></div>
        <div className="h-3 bg-gray-300 rounded w-20"></div>
      </div>
    </div>
    <div className="flex items-center space-x-16 relative">
      <div className="h-4 bg-gray-300 rounded w-20"></div>
      <div className="h-4 bg-gray-300 rounded w-10"></div>
      <div className="h-4 bg-gray-300 rounded w-5"></div>
    </div>
  </div>
);

const Ongoing = ({
  tasks,
  setTasks,
  fetchNextPage,
  triggerCompletedRefresh,
  onTaskCreated,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editTaskModalVisible, setEditTaskModalVisible] = useState(false);
  const [deleteTaskModalVisible, setDeleteTaskModalVisible] = useState(false);
  const [calendarVisible, setCalendarVisible] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedTime, setSelectedTime] = useState(moment());
  const [clickedTasks, setClickedTasks] = useState({});
  const [taskToEdit, setTaskToEdit] = useState(null);
  
  const [taskToDelete, setTaskToDelete] = useState(null);
  const containerRef = useRef(null);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);


  const dropdownRef = useRef(null);

  // Close the dropdown when clicking outside
  useEffect(() => {
    // Close dropdown on outside click
    const handleOutsideClick = (event) => {
      if (!event.target.closest(`#dropdowns`)) {
        setDropdownVisible(false);
      }
    };

    if (dropdownVisible) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [dropdownVisible]);


  const handleAddTaskClick = () => setIsModalVisible(true);

  const handleModalClose = () => setIsModalVisible(false);

  const handleEditTask = (task) => {
    setTaskToEdit(task);
    setEditTaskModalVisible(true);
    setDropdownVisible(null); // Close the dropdown when Edit modal opens
  };

  const handleEditModalClose = () => setEditTaskModalVisible(false);

  const handleDeleteTask = (task) => {
    setTaskToDelete(task);
    setDeleteTaskModalVisible(true);
    setDropdownVisible(null); // Close the dropdown when Delete modal opens
  };
  const handleDeleteModalClose = () => setDeleteTaskModalVisible(false);

  const confirmDeleteTask = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks/${taskToDelete._id}`
      );
      if (response.status === 200) {
        message.success(`Task deleted: ${taskToDelete.name}`);
        setTasks((prevTasks) =>
          prevTasks.filter((task) => task._id !== taskToDelete._id)
        );
        setDeleteTaskModalVisible(false);
      }
    } catch (error) {
      message.error("Failed to delete task");
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollTop + clientHeight >= scrollHeight && hasMore && !loading) {
        fetchNextPage(page + 1);
        setPage(page + 1);
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [page, hasMore, loading]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  const handleClick = (taskId) => {
    setClickedTasks((prevState) => ({
      ...prevState,
      [taskId]: !prevState[taskId],
    }));
    updateTaskStatus(taskId);
  };

  const updateTaskStatus = async (taskId) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks/${taskId}/`,
        { markDone: true }
      );

      if (response.status === 200) {
        message.success("Task marked as completed!");
        setTasks((prevTasks) =>
          prevTasks.filter((task) => task._id !== taskId)
        );
        triggerCompletedRefresh();
      }
    } catch (error) {
      message.error("Failed to mark task as completed");
    }
  };

  const handleClockClick = (taskId) => {
    setCalendarVisible(calendarVisible === taskId ? null : taskId);
  };

  const handleDropdownToggle = (taskId) => {
    setDropdownVisible(dropdownVisible === taskId ? null : taskId);
  };

  const customDropdown = (task) => (
    <div
      className="absolute bg-white shadow-lg rounded-lg p-2 z-50"
      style={{
        top: "100%",
        left: "40%",
        transform: "translateX(-90%)",
        whiteSpace: "nowrap",
        overflow: "visible",
      }}
    >
      <div
        className="p-2 cursor-pointer hover:bg-[#D1F8F0] flex items-center"
        onClick={() => handleEditTask(task)}
      >
        <EditOutlined className="mr-2 text-gray-600" />
        <span className="text-gray-700">Edit Task</span>
      </div>

      <div
        className="p-2 cursor-pointer hover:bg-[#D1F8F0] flex items-center"
        onClick={() => handleDeleteTask(task)}
      >
        <DeleteOutlined className="mr-2 text-gray-600" />
        <span className="text-gray-700">Delete Task</span>
      </div>
    </div>
  );

  const getFlagColor = (priority) => {
    switch (priority) {
      case "high":
        return "text-red-600";
      case "medium":
        return "text-orange-500";
      case "low":
        return "text-yellow-400";
      default:
        return "text-gray-500";
    }
  };

  const getOverdueText = (task) => {
    const currentDate = moment();
    const dueDate = moment(task.dueDate);
    const daysOverdue = currentDate.diff(dueDate, "days");

    if (daysOverdue <= 0) {
      return null;
    }

    return `Overdue by ${daysOverdue} days`;
  };

  return (
    <div className="relative bg-white shadow-md rounded-lg p-4 max-w-full h-full">
      <button
        className="relative flex items-center justify-start px-4 py-2 mb-4 w-full bg-white rounded-lg shadow-sm gap-2"
        onClick={handleAddTaskClick}
      >
        <div className="absolute left-0 top-0 bottom-0 w-1 bg-red-600 rounded-l-lg" />
        <div className="flex items-center justify-center border border-red-600 rounded-md p-1">
          <PlusOutlined className="text-red-600 text-sm" />
        </div>
        <span className="text-gray-600 text-sm">Add Task</span>
        {/* {isModalVisible && (
          <AddTaskModal
            isVisible={isModalVisible}
            handleCancel={handleModalClose}
            onTaskCreated={onTaskCreated} // Pass to AddTaskModal
          />
        )} */}
      </button>

      <div
        ref={containerRef}
        className={tasks.length > 0 ? "max-h-[300px] overflow-y-auto pr-2" : ""}
      >
        {loading ? (
          Array(5)
            .fill()
            .map((_, index) => <LoadingPlaceholder key={index} />)
        ) : tasks.length === 0 ? (
          <div className="flex flex-col items-center justify-center">
            <img src={EmptyStateSvg} alt="No Active Tasks" className="mb-4" />
            <p className="text-lg font-semibold text-gray-700">
              Task List Empty
            </p>
            <p className="text-sm text-gray-500">
              You don’t have any active tasks right now.
            </p>
          </div>
        ) : (
          tasks.map((task,index) => (
            <div
              key={index}
              className="relative flex justify-between items-center p-1 mb-1 bg-[#F5FFFD] shadow-sm border border-gray-200 rounded-lg"
            >
              <div className="absolute left-0 top-0 bottom-0 w-1 bg-teal-200 rounded-l-lg" />
              <div className="flex items-center">
                <div className="flex flex-col ml-2">
                  <div className="flex items-center mb-0.5">
                    {((task.user_id === task.assigner_id) || (task.user_id === task.assignee_id)) ? (
                      <div
                        className={`w-4 h-4 border ${clickedTasks[task._id]
                            ? "bg-teal-300 border-teal-300"
                            : "bg-white border-teal-200"
                          } rounded-full mr-1 flex-shrink-0 flex items-center justify-center cursor-pointer`}
                        onClick={() => handleClick(task._id)}
                      >
                        {clickedTasks[task._id] && (
                          <CheckOutlined className="text-white text-xs" />
                        )}
                      </div>
                    ) : (
                      <div
                        className="w-4 h-4 rounded-full mr-1 flex-shrink-0"
                        style={{ visibility: "hidden" }}
                      ></div>
                    )}
                    {/* <div
                      className={`w-4 h-4 border ${clickedTasks[task._id]
                          ? "bg-teal-300 border-teal-300"
                          : "bg-white border-teal-200"
                        } rounded-full mr-1 flex-shrink-0 flex items-center justify-center cursor-pointer`}
                      onClick={() => handleClick(task._id)}
                    >
                      {clickedTasks[task._id] && (
                        <CheckOutlined className="text-white text-xs" />
                      )}
                    </div> */}
                    <h3 className="text-sm font-semibold text-gray-900 mr-1">
                      {task.name.length > 20
                        ? `${task.name.substring(0, 20)}...`
                        : task.name}
                    </h3>

                    {getOverdueText(task) && (
                      <span className="flex items-center justify-center h-4 px-2 text-xs text-red-600 border border-red-600 bg-white rounded-full">
                        {getOverdueText(task)}
                      </span>
                    )}
                  </div>
                  <p className="text-xs text-gray-600 mb-0.5 ml-6">
                    {task.description}
                  </p>
                  <p className="text-xs text-gray-500 mb-0.5 ml-6">
                    Due on {moment(task.dueDate).format("DD/MM/YYYY")}
                  </p>
                </div>
              </div>

              <div className="flex items-center space-x-16 relative">
                <div className="flex space-x-32">
                  <div className="px-3 py-1 bg-white border border-gray-300 rounded-full text-sm text-gray-700">
                    {task.assigner}{" "}
                  </div>
                  <div className="px-3 py-1 bg-white border border-gray-300 rounded-full text-sm text-gray-700">
                    {task.assignee}
                  </div>
                  <div className="px-3 py-1 bg-white border border-gray-300 rounded-full text-sm text-gray-700">
                    {task.team}
                  </div>
                </div>
              </div>

              <div className="flex items-center space-x-16 relative">
                <FlagOutlined className={getFlagColor(task.priority)} />


                {(task.user_id === task.assignee_id) ? (

                  <div className="w-5 h-5 flex items-center justify-center">
                    {task.assigner === task.assignee && (
                      <ClockCircleOutlined
                        className="text-gray-500 cursor-pointer"
                        onClick={() => handleClockClick(task._id)}
                      />
                    )}
                  </div>
                ) : (
                  <div className="w-5 h-5 flex items-center justify-center">
                  </div>
                )}

                <div className="relative" ref={dropdownRef}>
                {(task.user_id === task.assigner_id) && (

                  <MoreOutlined
                    className="text-gray-500 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropdownVisible(
                        dropdownVisible === task._id ? null : task._id
                      );
                    }}
                  />
                  )}
                  {dropdownVisible === task._id && customDropdown(task)}
                </div>

                {calendarVisible === task._id && (
                  <EditReminderModal
                    isVisible={calendarVisible === task._id}
                    onClose={() => setCalendarVisible(null)}
                    task={task}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    selectedTime={selectedTime}
                    setSelectedTime={setSelectedTime}
                    taskId={task._id}
                  />
                )}
              </div>
            </div>
          ))
        )}
      </div>

      {isModalVisible && (
        <AddTaskModal
          isVisible={isModalVisible}
          handleCancel={handleModalClose}
          onTaskCreated={onTaskCreated} // Pass to AddTaskModal
        />
      )}
      {editTaskModalVisible && (
        <EditTaskModal
          visible={editTaskModalVisible}
          onClose={handleEditModalClose}
          task={taskToEdit}
          onTaskUpdated={(updatedTask) => {
            setTasks((prevTasks) =>
              prevTasks.map((task) =>
                task._id === updatedTask._id ? updatedTask : task
              )
            );
          }}
          onTaskCreated={onTaskCreated} // Pass to AddTaskModal

        />
      )}
      {deleteTaskModalVisible && (
        <DeleteTaskModal
          isVisible={deleteTaskModalVisible}
          onClose={handleDeleteModalClose}
          onDelete={confirmDeleteTask}
          task={taskToDelete}
        />
      )}
    </div>
  );
};

export default Ongoing;
