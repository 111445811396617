import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import TeamsHeader from '../Components/Teams/TeamHeader';
import Managed from '../Components/Teams/Managed';
import Joined from '../Components/Teams/Joined';

const Teams = () => {
  const [viewAll, setViewAll] = useState(false); // Control "View All" state
  const [activeTab, setActiveTab] = useState('managed'); // State to handle tab selection

  // Handle tab change for switching between Managed and Joined
  const handleTabChange = (key) => {
    setActiveTab(key);
    setViewAll(false); // Reset viewAll state when switching tabs
  };

  return (
    <div>
      {/* Teams Header */}
      <TeamsHeader setViewAll={setViewAll} onTabChange={handleTabChange} />
      
      {/* Conditionally render Managed or Joined based on activeTab */}
      {activeTab === 'managed' ? (
        <Managed viewAll={viewAll} type="managed" /> 
      ) : (
        <Joined viewAll={viewAll} type="joined" /> 
      )}
      
      {/* Outlet for routing if needed */}
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default Teams;
