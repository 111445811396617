  // `${process.env.REACT_APP_DOC_SERVICE_URL}/folder?userId=${userId}&filterCriteria=shared&${queryParams.toString()}`






  import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { message } from 'antd';
import { useAuth } from '../../authcontext';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import FolderCard, { FolderCardSkeleton } from '../../Services/UI/FolderCard'; // Import FolderCard and skeleton
import AddFolderModal from '../../Services/Modal/AddFolderModal';
import FolderGridLoading from '../../Services/UI/LoadingState/FolderGridLoading'; // Import FolderGridLoading for loading state
import AllFoldersEmpty from '../../Services/UI/EmptyState/AllFoldersEmpty'; // Import AllFoldersEmpty for empty state

const FolderGrid = ({ sortOrder, currentSortOption, filter }) => {
  const [folders, setFolders] = useState([]);
  const { userId } = useAuth();
  const [loading, setLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef(); // Ref for observing last element
  const navigate = useNavigate(); // Initialize navigate for routing

  // Function to handle folder click event and navigate
  const handleFolderClick = (folderId) => {
    navigate(`/documents/folders/${folderId}`); // Navigate to the folder's path
  };

  const fetchFilteredFolders = async (currentPage = 0, reset = false) => {
    try {
      const queryParams = new URLSearchParams();
      if (filter) {
        queryParams.append('filterCriteria', filter);
      }
      if (sortOrder && currentSortOption) {
        queryParams.append('sortOrder', sortOrder);
        queryParams.append('sortBy', currentSortOption);
      }
      if (currentPage > 0) {
        queryParams.append('page', currentPage);
      }

      const response = await axios.get(
       `${process.env.REACT_APP_DOC_SERVICE_URL}/folder?userId=${userId}&filterCriteria=shared&${queryParams.toString()}`
      );
      const newFolders = response.data.data.folders || [];
      const moreToCome = response.data.data.moreToCome; // Get the moreToCome field

      if (reset) {
        setFolders(newFolders); // Reset folder list for first load or reset
      } else {
        setFolders((prevFolders) => [...prevFolders, ...newFolders]); // Append more folders for infinite scroll
      }

      setHasMore(moreToCome); // Use moreToCome to determine if more data is available
      setLoading(false);
      setIsLoadingMore(false);
    } catch (error) {
      console.error('Error fetching folders:', error);
      message.error('Failed to load folders. Please try again.');
      setLoading(false);
      setIsLoadingMore(false);
    }
  };

  // Fetch folders on mount and when sort/filter changes
  useEffect(() => {
    if (userId) {
      setLoading(true);
      setPage(0); // Reset pagination on filter/sort change
      fetchFilteredFolders(0, true); // Fetch with reset and omit page parameter in the first call
    }
  }, [userId, filter, sortOrder, currentSortOption]);

  // Infinite scroll logic (trigger when scrolled to the last folder)
  useEffect(() => {
    if (page > 0) {
      fetchFilteredFolders(page);
    }
  }, [page]);

  // Ref logic for last folder element
  const lastFolderElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1); // Increase page number for infinite scroll
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoadingMore, hasMore]
  );

  return (
    <div className="max-w-full min-h-[488px]">
      {/* Loading state */}
      {loading && <FolderGridLoading />} 

      {/* Folder Grid */}
      {!loading && folders.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
          {folders.map((folder, index) => (
            <div
              key={folder._id}
              ref={folders.length === index + 1 ? lastFolderElementRef : null} // Attach ref only to the last folder
            >
              <FolderCard folder={folder} handleFolderClick={handleFolderClick} /> 
              <AddFolderModal folder={folder} />
            </div>
          ))}

          {/* Loading skeleton for infinite scroll */}
          {isLoadingMore && <FolderCardSkeleton />}
        </div>
      )}

      {/* Empty State */}
      {!loading && folders.length === 0 && <AllFoldersEmpty />}
    </div>
  );
};

export default FolderGrid;
