// import React, { useState, useEffect } from 'react';
// import { Avatar, Switch, Button, message, Dropdown } from 'antd';
// import { PlusOutlined, FilterOutlined, UserOutlined } from '@ant-design/icons';
// import AddNewUserModal from '../../Services/Modal/AddNewUserModal';

// const SuperAdminPage = () => {
//   const [isModalVisible, setModalVisible] = useState(false);
//   const [adminChecked, setAdminChecked] = useState({});
//   const [pendingRequests, setPendingRequests] = useState([]);
//   const [users, setUsers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [filterDropdownVisible, setFilterDropdownVisible] = useState(false);
//   const [initialLoading, setInitialLoading] = useState(true);

//   const openModal = () => {
//     setModalVisible(true);
//   };

//   const closeModal = () => {
//     setModalVisible(false);
//   };

//   const handleAdminToggle = async (checked, index, userId) => {
//     setAdminChecked((prevState) => ({
//       ...prevState,
//       [index]: checked,
//     }));

//     try {
//       const response = await fetch('https://auth-comm-service.onrender.com/api/v1/superadmin/adminStatus', {
//         method: 'PATCH',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
//         },
//         body: JSON.stringify({
//           userId: userId,
//           changeTo: checked ? 'admin' : 'user',
//         }),
//       });
//       const data = await response.json();

//       if (response.ok) {
//         message.success(`User successfully ${checked ? 'promoted to' : 'demoted from'} admin`);
//         fetchUsers(); // Refresh user list to reflect changes
//       } else {
//         message.error(data.message || 'Failed to update admin status');
//       }
//     } catch (error) {
//       console.error('Error updating admin status:', error);
//       message.error('Error updating admin status');
//     }
//   };

//   const fetchPendingRequests = async () => {
//     try {
//       const response = await fetch('https://auth-comm-service.onrender.com/api/v1/admin/users?pending=true', {
//         method: 'GET',
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
//         },
//       });
//       const data = await response.json();
//       setPendingRequests(data.data.users || []);
//     } catch (error) {
//       console.error('Error fetching pending requests:', error);
//     }
//   };

//   const fetchUsers = async (filter = '') => {
//     setLoading(true);
//     try {
//       const response = await fetch(`https://auth-comm-service.onrender.com/api/v1/admin/users${filter}`, {
//         method: 'GET',
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
//         },
//       });
//       const data = await response.json();
//       setUsers(data.data.users || []);
//     } catch (error) {
//       console.error('Error fetching users:', error);
//     } finally {
//       setLoading(false);
//       setInitialLoading(false); // End initial loading
//     }
//   };

//   const hasLinkExpired = (updatedAt) => {
//     const expirationDate = new Date(updatedAt);
//     expirationDate.setMinutes(expirationDate.getMinutes() + 15);
//     return expirationDate < new Date();
//   };

//   const regenerateLink = async (user) => {
//     try {
//       const response = await fetch('https://auth-comm-service.onrender.com/api/v1/admin/resendGenPasswordMail', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
//         },
//         body: JSON.stringify({
//           id: user._id,
//           email: user.email,
//           name: user.name,
//         }),
//       });
//       const data = await response.json();
//       if (response.ok) {
//         message.success('Link regenerated successfully');
//         fetchPendingRequests(); // Refresh the list
//       } else {
//         message.error(data.message || 'Failed to regenerate link');
//       }
//     } catch (error) {
//       console.error('Error regenerating link:', error);
//       message.error('Error regenerating link');
//     }
//   };

//   const removeUser = async (userId) => {
//     try {
//       const response = await fetch(`https://auth-comm-service.onrender.com/api/v1/superadmin/resignationStatus`, {
//         method: 'PATCH',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
//         },
//         body: JSON.stringify({ userId }), // Pass userId in the body
//       });
//       if (response.ok) {
//         message.success('User removed successfully');
//         fetchUsers(); // Refresh user list to reflect changes
//       } else {
//         message.error('Failed to remove user');
//       }
//     } catch (error) {
//       console.error('Error removing user:', error);
//       message.error('Error removing user');
//     }
//   };
  
//   useEffect(() => {
//     fetchPendingRequests();
//     fetchUsers();
//   }, []);

//   const filterMenu = (
//     <div className="flex flex-col w-[152px] p-1.5 rounded-lg bg-white shadow-lg">
//       <div
//         className="flex items-center gap-2 p-2 cursor-pointer hover:bg-[#D1F8F0] text-gray-900 rounded-md"
//         onClick={() => {
//           fetchUsers('?isAdmin=true');
//           setFilterDropdownVisible(false);
//         }}
//       >
//         <UserOutlined className="text-[#192028]" />
//         Admin
//       </div>
//       <div
//         className="flex items-center gap-2 p-2 cursor-pointer hover:bg-[#D1F8F0] text-gray-500 rounded-md"
//         onClick={() => {
//           fetchUsers('?isResigned=true');
//           setFilterDropdownVisible(false);
//         }}
//       >
//         <UserOutlined className="text-[#768090]" />
//         Resigned
//       </div>
//     </div>
//   );

//   return (
//     <div className="flex flex-col space-y-8 min-h-screen">
//       {initialLoading ? (
//         <div className="flex flex-col space-y-8">
//           {/* Loading State */}
//           {/* Add loading skeleton components here */}
//         </div>
//       ) : (
//         <>
//           {/* Pending Requests Table */}
//           <div className="flex flex-col max-w-[1430px] p-3 rounded-lg bg-white shadow-sm overflow-auto">
//             <div className="flex justify-between items-center mb-4">
//               <h2 className="text-lg font-semibold">Pending Requests</h2>
//               <button
//                 className="flex items-center px-4 py-2 text-red-600 border border-red-200 rounded-lg"
//                 onClick={openModal}
//               >
//                 <PlusOutlined className="mr-2" />
//                 Create New User
//               </button>
//             </div>

//             {loading ? (
//               <div className="space-y-2">
//                 {/* Loading skeleton rows */}
//               </div>
//             ) : (
//               <table className="table-auto w-full border-separate" style={{ borderSpacing: '0 10px' }}>
//                 <thead className="text-left text-sm font-semibold text-gray-700 bg-gray-50">
//                   <tr>
//                     <th className="px-4 py-2">Username</th>
//                     <th className="px-4 py-2">Request At</th>
//                     <th className="px-4 py-2">Status</th>
//                     <th className="px-4 py-2">Created By</th>
//                     <th className="px-4 py-2">Link Expires At</th>
//                     <th className="px-4 py-2">Action</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {pendingRequests.map((user) => (
//                     <tr key={user._id} className="bg-white shadow-sm rounded-lg">
//                       <td className="px-4 py-2">
//                         <div className="flex items-center gap-2">
//                           <Avatar src="https://i.pravatar.cc/30?img=64" alt="User Avatar" size={30} />
//                           <span>{`${user.name} ${user.lastName}`}</span>
//                         </div>
//                       </td>
//                       <td className="px-4 py-2">{new Date(user.updatedAt).toLocaleString()}</td>
//                       <td className="px-4 py-2">
//                         {hasLinkExpired(user.updatedAt) ? (
//                           <span className="px-2 py-1 text-gray-500 bg-gray-100 border border-gray-300 rounded-md">
//                             Expired
//                           </span>
//                         ) : (
//                           <span className="px-2 py-1 border border-yellow-500 text-yellow-500 rounded-md bg-yellow-100">
//                             Pending
//                           </span>
//                         )}
//                       </td>
//                       <td className="px-4 py-2">{user.createdBy?.name || 'N/A'}</td>
//                       <td className="px-4 py-2">
//                         {hasLinkExpired(user.updatedAt)
//                           ? 'N/A'
//                           : new Date(new Date(user.updatedAt).getTime() + 15 * 60000).toLocaleString()}
//                       </td>
//                       <td className="px-4 py-2">
//                         {hasLinkExpired(user.updatedAt) ? (
//                           <button className="px-4 py-1 rounded-lg bg-teal-500 text-white" onClick={() => regenerateLink(user)}>
//                             Regenerate Link
//                           </button>
//                         ) : (
//                           <button className="px-4 py-1 rounded-lg bg-gray-300 text-gray-500" disabled>
//                             Regenerate Link
//                           </button>
//                         )}
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             )}
//           </div>

//           {/* Users Table */}
//           <div className="flex flex-col max-w-[1430px] p-3 rounded-lg bg-white shadow-sm overflow-auto">
//             <div className="flex justify-between items-center mb-4">
//               <h2 className="text-lg font-semibold">Users</h2>
//               <Dropdown
//                 overlay={filterMenu}
//                 trigger={['click']}
//                 visible={filterDropdownVisible}
//                 onVisibleChange={(flag) => setFilterDropdownVisible(flag)}
//               >
//                 <Button
//                   className="flex items-center justify-between border rounded-md"
//                   style={{
//                     backgroundColor: '#FFF',
//                     border: '1px solid #D9D9D9',
//                     borderRadius: '8px',
//                     height: '40px',
//                     maxWidth: '150px',
//                     padding: '0',
//                     boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.05)',
//                   }}
//                 >
//                   <span
//                     style={{
//                       flexGrow: 1,
//                       textAlign: 'left',
//                       paddingLeft: '16px',
//                       color: '#192028',
//                     }}
//                   >
//                     All 
//                   </span>
//                   <span
//                     style={{
//                       display: 'flex',
//                       justifyContent: 'center',
//                       alignItems: 'center',
//                       border: '1px solid #41EAD4',
//                       height: '38px',
//                       width: '38px',
//                       marginRight: '-1px',
//                       borderRadius: '4px',
//                       backgroundColor: '#FFF',
//                     }}
//                   >
//                     <FilterOutlined
//                       style={{
//                         color: '#41EAD4',
//                         fontSize: '16px',
//                       }}
//                     />
//                   </span>
//                 </Button>
//               </Dropdown>
//             </div>

//             {loading ? (
//               <div className="space-y-2">
//                 {/* Loading skeleton rows */}
//               </div>
//             ) : (
//               <table className="table-auto w-full border-separate" style={{ borderSpacing: '0 10px' }}>
//                 <thead className="text-left text-sm font-semibold text-gray-700 bg-gray-50">
//                   <tr>
//                     <th className="px-4 py-2">Name</th>
//                     <th className="px-4 py-2">Designation</th>
//                     <th className="px-4 py-2">Joined Date</th>
//                     <th className="px-4 py-2">Email</th>
//                     <th className="px-4 py-2">Make Admin</th>
//                     <th className="px-4 py-2">Action</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {users.map((user, index) => (
//                     <tr key={user._id} className={`bg-white shadow-sm rounded-lg ${user.resigned ? 'backdrop-blur-sm opacity-70' : ''}`}>
//                       <td className="px-4 py-2 flex items-center gap-2">
//                         <Avatar style={{ backgroundColor: user.resigned ? '#FFFFFF' : undefined }} src="https://i.pravatar.cc/30?img=64" alt="User Avatar" size={30} />
//                         <span className={`${user.resigned ? 'text-gray-800' : ''}`}>
//                           {user.resigned ? 'Resigned User' : `${user.name} ${user.lastName}`}
//                         </span>
//                       </td>
//                       <td className="px-4 py-2">{user.resigned ? 'Senior Engineer' : user.type === 'admin' ? 'Administrator' : 'Regular User'}</td>
//                       <td className="px-4 py-2">{user.resigned ? '11/08/2028' : new Date(user.createdAt).toLocaleDateString()}</td>
//                       <td className="px-4 py-2">{user.resigned ? `resigned_user${index + 1}@gmail.com` : user.email}</td>
//                       <td className="px-4 py-2">
//                         {!user.resigned && (
//                           <Switch
//                             checked={adminChecked[index] || user.type === 'admin'}
//                             onChange={(checked) => handleAdminToggle(checked, index, user._id)}
//                             style={{
//                               backgroundColor: user.type === 'admin' ? '#41EAD4' : '#59616E',
//                               transition: 'background-color 0.3s', 
//                             }}
//                             className="w-[56px] h-[30px] rounded-full"
//                           />
//                         )}
//                         {user.resigned && <span>N/A</span>}
//                       </td>
//                       <td className="px-4 py-2">
//                         <button
//                           className={`px-4 py-1 rounded-lg ${user.resigned ? 'bg-[#D9DEE7] text-[#59616E]' : 'bg-red-500 text-white'}`}
//                           onClick={() => user.resigned ? null : removeUser(user._id)}
//                           disabled={user.resigned}
//                         >
//                           Remove User
//                         </button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             )}
//           </div>

//           {/* Add New User Modal */}
//           <AddNewUserModal isVisible={isModalVisible} onClose={closeModal} isSuperAdmin={true} />
//         </>
//       )}
//     </div>
//   );
// };

// export default SuperAdminPage;





























import React, { useState } from 'react';
import { message } from 'antd';
import PendingSuperAdmin from '../../Services/SettingsTable/PendingSuperAdmin';
import UsersSuperAdmin from '../../Services/SettingsTable/UsersSuperAdmin';

const AdminPage = () => {
  const [pendingMoreToCome, setPendingMoreToCome] = useState(true);
  const [usersMoreToCome, setUsersMoreToCome] = useState(true);

  // const fetchPendingRequests = async (page) => {
  //   if (!pendingMoreToCome) return []; // Stop API calls if moreToCome is false

  //   try {
  //     const url =
  //       page === 0
  //         ? 'https://auth-comm-service.onrender.com/api/v1/admin/users?pending=true'
  //         : `https://auth-comm-service.onrender.com/api/v1/admin/users?pending=true&page=${page}`;
  //     const response = await fetch(url, {
  //       method: 'GET',
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
  //       },
  //     });
  //     const data = await response.json();
  //     if (response.ok) {
  //       // Update moreToCome state
  //       if (!data.data.moreToCome) {
  //         setPendingMoreToCome(false);
  //       }
  //       return data.data.users || [];
  //     } else {
  //       message.error(data.message || 'Failed to fetch pending requests');
  //       return [];
  //     }
  //   } catch (error) {
  //     console.error('Error fetching pending requests:', error);
  //     message.error('Error fetching pending requests');
  //     return [];
  //   }
  // };



  const fetchPendingRequests = async (page) => {
    if (!pendingMoreToCome) return []; // Stop API calls if moreToCome is false
  
    try {
      const url =
        page === 0
          ? `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/admin/users?pending=true`
          : `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/admin/users?pending=true&page=${page}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        if (!data.data.moreToCome) {
          setPendingMoreToCome(false);
        }
        return data.data.users || [];
      } else {
        message.error(data.message || 'Failed to fetch pending requests');
        return [];
      }
    } catch (error) {
      console.error('Error fetching pending requests:', error);
      message.error('Error fetching pending requests');
      return [];
    }
  };
  

  const fetchUsersApi = async (page) => {
    if (!usersMoreToCome) return []; // Stop API calls if moreToCome is false

    try {
      const url =
        page === 0
          ? `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/admin/users`
          : `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/admin/users?page=${page}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        // Update moreToCome state
        if (!data.data.moreToCome) {
          setUsersMoreToCome(false);
        }
        return data.data.users || [];
      } else {
        message.error(data.message || 'Failed to fetch users');
        return [];
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      message.error('Error fetching users');
      return [];
    }
  };

  return (
    <div className="flex flex-col space-y-8 min-h-screen p-6 bg-gray-100">
      {/* Pending Requests Section */}
      <PendingSuperAdmin fetchPendingRequests={fetchPendingRequests} />

      {/* Users Section */}
      <UsersSuperAdmin fetchUsersApi={fetchUsersApi} />
    </div>
  );
};

export default AdminPage;
