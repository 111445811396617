import React from 'react';
import SettingsHeader from '../Components/Settings/SettingsHeader';
import GeneralSettings from '../Components/Settings/General'; // General Settings component
import Audit from '../Components/Settings/Audit'

const Settings = () => {
  return (
    <div>
      {/* Settings Header */}
      <SettingsHeader />

      {/* General Settings Form */}
      <div className="mt-6">
        {/* <GeneralSettings /> */}
      </div>

      {/* Audit Trail Section */}
      <div className="mt-10">
        {/* <Audit /> */}
      </div>
    </div>
  );
};

export default Settings;