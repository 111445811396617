import React, { useState, useEffect } from 'react';
import { useAuth } from "../../authcontext";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Checkbox,
  IconButton,
  Select,
  MenuItem,
  TextField,
  makeStyles,
} from '@material-ui/core';
import Pagination from '@atlaskit/pagination';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles((theme) => ({
  table: {
    fontSize: '0.6rem', // smaller font size for the entire table
  },
  tableRow: {
    height: '10px', // reduced row height
  },
  tableCell: {
    padding: '1px', // smaller padding
  },
  checkbox: {
    transform: 'scale(0.6)', // smaller checkbox
  },
  select: {
    fontSize: '0.6rem', // smaller font size for select
  },
  textField: {
    fontSize: '0.6rem', // smaller font size for text field
  },
}));

const ProjectTable = ({ projects, selectedProject, handleCheckboxClick, handleProjectUpdate }) => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState(1);
  const [ownerFilter, setOwnerFilter] = useState('me');
  const [statusFilter, setStatusFilter] = useState('');
  const [priorityFilter, setPriorityFilter] = useState('');
  const rowsPerPage = 10;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleProjectChange = (index, key, value) => {
    const newProjects = [...projects];
    newProjects[index][key] = value;
    handleProjectUpdate(newProjects);
  };

  const paginatedProjects = projects.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  return (
    <>
      {/* <div>
      
        <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)} className={classes.select}>
          <MenuItem value="name">Project Name</MenuItem>
          <MenuItem value="startDate">Start Date</MenuItem>
          <MenuItem value="deadline">Deadline</MenuItem>
        </Select>
        <Select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} className={classes.select}>
          <MenuItem value={1}>Ascending</MenuItem>
          <MenuItem value={-1}>Descending</MenuItem>
        </Select>
      </div> */}
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell padding="checkbox" className={classes.tableCell}>
                <Checkbox
                  className={classes.checkbox}
                  indeterminate={
                    selectedProject !== null &&
                    selectedProject.length < projects.length
                  }
                  checked={projects.length > 0 && selectedProject !== null}
                  onChange={(event) =>
                    handleCheckboxClick(
                      event,
                      projects.map((project) => project.projectName)
                    )
                  }
                />
              </TableCell>
              <TableCell className={classes.tableCell}>Project Name</TableCell>
              <TableCell className={classes.tableCell}>Owner</TableCell>
              <TableCell className={classes.tableCell}>Team</TableCell>
              <TableCell className={classes.tableCell}>Status</TableCell>
              <TableCell className={classes.tableCell}>Progress</TableCell>
              <TableCell className={classes.tableCell}>Start Date</TableCell>
              <TableCell className={classes.tableCell}>Deadline</TableCell>
              <TableCell className={classes.tableCell}>Priority</TableCell>
              <TableCell className={classes.tableCell}>Project URL</TableCell>
              <TableCell className={classes.tableCell}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedProjects.map((project, index) => (
              <TableRow
                key={index}
                hover
                role="checkbox"
                className={classes.tableRow}
              >
                <TableCell padding="checkbox" className={classes.tableCell}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={selectedProject === project.projectName}
                    onChange={(event) =>
                      handleCheckboxClick(event, project.projectName)
                    }
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {project.name}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {project.owner}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {project.team}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Select
                    value={project.status}
                    onChange={(e) =>
                      handleProjectChange(index, 'status', e.target.value)
                    }
                    className={classes.select}
                    style={{ minWidth: 120 }}
                  >
                    <MenuItem value="on track">
                      <Chip
                        label="On Track"
                        style={{ backgroundColor: 'darkblue', color: 'white' }}
                      />
                    </MenuItem>
                    <MenuItem value="at risk">
                      <Chip
                        label="At Risk"
                        style={{ backgroundColor: 'red', color: 'white' }}
                      />
                    </MenuItem>
                    <MenuItem value="not started">
                      <Chip
                        label="Not Started"
                        style={{ backgroundColor: 'grey', color: 'white' }}
                      />
                    </MenuItem>
                  </Select>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <div className="relative w-full h-4 bg-gray-200 rounded-full">
                    <div
                      className="absolute left-0 top-0 h-full bg-green-500 rounded-full"
                      style={{ width: '66%' }}
                    />
                    <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white">
                      66%
                    </span>
                  </div>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {project.startDate}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {project.deadline}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Select
                    value={project.priority}
                    onChange={(e) =>
                      handleProjectChange(index, 'priority', e.target.value)
                    }
                    className={classes.select}
                    style={{ minWidth: 120 }}
                  >
                    <MenuItem value="low">
                      <Chip
                        label="Low"
                        style={{ backgroundColor: 'yellow', color: 'black' }}
                      />
                    </MenuItem>
                    <MenuItem value="medium">
                      <Chip
                        label="Medium"
                        style={{ backgroundColor: 'orange', color: 'white' }}
                      />
                    </MenuItem>
                    <MenuItem value="high">
                      <Chip
                        label="High"
                        style={{ backgroundColor: 'red', color: 'white' }}
                      />
                    </MenuItem>
                  </Select>
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <TextField
                    value={project.url}
                    onChange={(e) =>
                      handleProjectChange(index, 'url', e.target.value)
                    }
                    variant="outlined"
                    size="small"
                    className={classes.textField}
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <IconButton size="small">
                    <MoreHorizIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {projects.length > rowsPerPage && (
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}
        >
          <Pagination
            nextLabel="Next"
            label="Page"
            pageLabel="Page"
            pages={[...Array(Math.ceil(projects.length / rowsPerPage)).keys()]
              .map((x) => x + 1)
              .map((page) => (
                <div
                  key={page}
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </div>
              ))}
            previousLabel="Previous"
          />
        </div>
      )}
    </>
  );
};

export default ProjectTable;
