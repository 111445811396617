import React, { useState, useEffect } from 'react';
import { FlagOutlined, PlusOutlined, BellOutlined, MoreOutlined, CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { message, Dropdown, Menu, Skeleton } from 'antd';
import axios from 'axios';
import { useAuth } from '../../authcontext';
import TodoSvg from '../../../assets/SVG/ToDo.svg';
import AddTaskModal from '../../Services/Modal/AddTaskModal';
import axiosDocInstance from '../../ApiServices/AxiosDocInstance';

const ToDoList = () => {
  const { userId } = useAuth();
  const [tasks, setTasks] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTasks = async () => {
      setIsLoading(true);
      try {
        const response = await axiosDocInstance.get(`${process.env.REACT_APP_DOC_SERVICE_URL}/tasks?forDashboard=true`);
        const allTasks = response.data?.data?.tasks || [];
        
        // Introduce an artificial delay to ensure loading state is visible
        setTimeout(() => {
          setTasks(allTasks);
          setIsLoading(false);
        }, 500); // 500ms delay for loading state to show
      } catch (error) {
        console.error('Error fetching tasks:', error);
        message.error('Failed to fetch tasks.');
        setIsLoading(false);
      }
    };

    fetchTasks();
  }, [userId]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleTaskCreated = (newTask) => {
    setTasks((prevTasks) => [newTask, ...prevTasks]);
    setIsModalVisible(false);
  };

  const toggleTaskCompletion = (taskId) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task._id === taskId ? { ...task, completed: !task.completed } : task
      )
    );
  };

  const handleEdit = (taskId) => {
    console.log('Edit task with id:', taskId);
  };

  const handleDelete = (taskId) => {
    console.log('Delete task with id:', taskId);
  };

  const getMenu = (taskId) => (
    <Menu
      style={{
        display: 'flex',
        width: '199px',
        padding: '4px',
        flexDirection: 'column',
        background: '#FFF',
        boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
        borderRadius: '8px',
      }}
    >
      <Menu.Item
        key="1"
        onClick={() => handleEdit(taskId)}
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#D1F8F0',
          borderRadius: '4px',
          color: '#192028',
          margin: '4px 0',
        }}
      >
        <EditOutlined style={{ marginRight: '8px', color: '#192028', width: '16px', height: '16px' }} />
        Modify Task
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => handleDelete(taskId)}
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#FFF',
          borderRadius: '4px',
          color: '#192028',
          margin: '4px 0',
        }}
      >
        <DeleteOutlined style={{ marginRight: '8px', color: '#798897', width: '16px', height: '16px' }} />
        Delete Task
      </Menu.Item>
    </Menu>
  );

  if (isLoading) {
    // Loading state skeleton layout as per the provided PNG image
    return (
      <div className="p-4 border border-gray-200 rounded-lg shadow-sm bg-white" style={{ maxWidth: '440px', minHeight: '196px' }}>
        <div className="flex justify-between items-center mb-4">
          <Skeleton.Button active style={{ width: '150px', height: '20px' }} />
          <Skeleton.Button active style={{ width: '80px', height: '20px' }} />
        </div>
        {[1, 2].map((_, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '12px' }}>
            <Skeleton.Avatar active shape="square" size="large" />
            <div style={{ flex: 1 }}>
              <Skeleton.Button active style={{ width: '80%', height: '12px', marginBottom: '4px' }} />
              <Skeleton.Button active style={{ width: '60%', height: '12px' }} />
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <Skeleton.Button active style={{ width: '40px', height: '20px' }} />
              <Skeleton.Button active style={{ width: '40px', height: '20px' }} />
              <Skeleton.Button active shape="circle" style={{ width: '20px', height: '20px' }} />
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="p-4 border border-gray-200 rounded-lg shadow-sm bg-white" style={{ maxWidth: '440px', minHeight: '196px' }}>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-gray-900">To Do List</h2>
        {!isLoading && tasks.length > 0 && (
          <a href="#" className="text-teal-500 underline flex items-center space-x-1">
            <FlagOutlined className="text-gray-500" />
            <span>View All</span>
          </a>
        )}
      </div>

      {!isLoading && tasks.length > 0 ? (
        <div className="space-y-2" style={{ flexGrow: 1, overflowY: 'auto' }}>
          {tasks.map((task) => (
            <div
              key={task._id}
              className="flex items-center justify-between p-2 rounded-lg shadow-sm"
              style={{
                backgroundColor: '#F0F9F9',
                borderRadius: '8px',
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
                borderLeft: '4px solid #00BFA5',
              }}
            >
              <div className="flex items-center space-x-2 flex-1">
                <button
                  className="w-5 h-5 rounded-full border-2 border-[#00BFA5] cursor-pointer flex items-center justify-center"
                  style={{ backgroundColor: task.completed ? '#00BFA5' : 'transparent' }}
                  onClick={() => toggleTaskCompletion(task._id)}
                >
                  {task.completed && <CheckOutlined style={{ color: '#fff', fontSize: '12px' }} />}
                </button>
                <h3 className="text-sm font-semibold text-gray-900">{task.name}</h3>
                <span className="text-xs text-red-500 border border-red-500 rounded-full px-2 py-0.5 ml-4" style={{ whiteSpace: 'nowrap', fontSize: '10px' }}>
                  {new Date(task.dueDate).toLocaleString()}
                </span>
              </div>

              <div className="flex items-center space-x-4">
                <FlagOutlined className="text-blue-500" />
                <BellOutlined className="text-gray-500" />
                <Dropdown overlay={getMenu(task._id)} trigger={['click']}>
                  <MoreOutlined className="text-gray-500 cursor-pointer" />
                </Dropdown>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center" style={{ minHeight: '196px' }}>
          <img src={TodoSvg} alt="todo svg" style={{ width: '100px' }} />
          <h3 style={{ fontSize: '16px', fontWeight: 600, color: '#192028' }}>You're All Caught Up!</h3>
          <p style={{ fontSize: '12px', color: '#59616E' }}>Create your first task to keep the momentum going.</p>
        </div>
      )}

      <div className="mt-4 flex items-center text-teal-500 cursor-pointer" onClick={showModal}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '24px',
            height: '24px',
            borderRadius: '4px',
            border: '1px solid #00BFA5',
            backgroundColor: '#FFFFFF',
            marginRight: '8px',
          }}
        >
          <PlusOutlined style={{ color: '#00BFA5' }} />
        </div>
        <span className="ml-2 text-sm" style={{ color: '#59616E' }}>Add Task</span>
      </div>

      <AddTaskModal
        isVisible={isModalVisible}
        handleCancel={handleCancel}
        userId={userId}
        onTaskCreated={handleTaskCreated}
      />
    </div>
  );
};

export default ToDoList;
