import React, { useState, useEffect } from 'react';
 
const IPAddressDisplay = () => {
  const [ipAddress, setIPAddress] = useState('');

  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setIPAddress(data.ip);
        
        // Post IP address to the specified API endpoint
        await fetch('${RbacServiceUrl}/user/ip', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ ip: data.ip })
        });
      } catch (error) {
        console.error('Error fetching or posting IP address:', error);
      }
    };

    // Call the function to fetch IP address when component mounts
    fetchIPAddress();

    // Clean-up function
    return () => {
      // Any clean-up code if necessary
    };
  }, []); // Empty dependency array ensures effect is only run once

  return (
    <div>
      <h1>Your IP Address is:</h1>
      <p>{ipAddress}</p>
    </div>
  );
};

export default IPAddressDisplay;
