import React, { useState } from 'react';
import FolderHeader from '../Components/Folders/FolderHeader';
import FolderGrid from '../Components/Folders/Folders';
import Shared from '../Components/Folders/Shared';
import Pinned from '../Components/Folders/Pinned';
import Default from '../Components/Folders/Default';
import DocHeader from '../Components/Folders/DocHeader';

const Folders = () => {
  const [selectedFolderId, setSelectedFolderId] = useState(null); // Track the selected folder
  const [selectedOption, setSelectedOption] = useState('allFolders'); // Track the selected option (e.g., allFolders, sharedWithMe, pinnedDocument)
  const [sortOrder, setSortOrder] = useState(null); // State to manage sorting order (ascending/descending)
  const [filter, setFilter] = useState(null); // State to manage filtering
  const [currentSortOption, setCurrentSortOption] = useState('createdAt'); // Track the current selected sort option

  // Function to handle changes in sorting order and option
  const handleSortOrderChange = (order, option) => {
    setSortOrder(order); // Set the sort order (ascending/descending)
    setCurrentSortOption(option); // Set the current sort option (e.g., name, createdAt, etc.)
  };

  // Define component mapping based on selected folder type
  const componentMap = {
    allFolders: FolderGrid,
    sharedWithMe: Shared,
    pinnedDocument: Pinned,
    default: Default,
  };

  // Define sorting options based on the selected folder type
  const sortOptionsMap = {
    allFolders: [
      { label: 'Name', value: 'name' },
      { label: 'Created at', value: 'createdAt' },
      { label: 'Modified on', value: 'modifiedAt' },
      { label: 'Number of Documents', value: 'items' }
    ],
    sharedWithMe: [
      { label: 'Name', value: 'name' },
      { label: 'Created at', value: 'createdAt' },
      { label: 'Modified on', value: 'modifiedAt' },
      { label: 'Number of Documents', value: 'items' }
    ],
    pinnedDocument: [
      { label: 'Name', value: 'name' },
      { label: 'Created at', value: 'createdAt' },
      { label: 'Modified on', value: 'modifiedAt' },
      { label: 'Number of Documents', value: 'items' }
    ],
    default: [
      { label: 'Name', value: 'name' },
      { label: 'Created at', value: 'createdAt' },
      { label: 'Modified on', value: 'modifiedAt' },
      { label: 'Number of Documents', value: 'items' }
    ]
  };

  // Define filtering options based on the selected folder type
  const filterOptionsMap = {
    allFolders: [
      { label: 'Self Uploaded', value: 'self' },
      { label: 'Org-wide', value: 'org' },
      { label: 'Team', value: 'team' }
    ],
    sharedWithMe: [
      { label: 'Org-wide', value: 'org' },
      { label: 'Team', value: 'team' }
    ],
    pinnedDocument: [
      { label: 'Self Uploaded', value: 'self' },
      { label: 'Org-wide', value: 'org' },
      { label: 'Team', value: 'team' }
    ],
    default: [
      { label: 'Self Uploaded', value: 'self' },
      { label: 'Org-wide', value: 'org' },
      { label: 'Team', value: 'team' }
    ]
  };

  // Render the appropriate component based on the selected view using mapping
  const renderSelectedComponent = () => {
    const SelectedComponent = componentMap[selectedOption] || componentMap.default;

    return (
      <SelectedComponent
        selectedFolderId={selectedFolderId}
        setSelectedFolderId={setSelectedFolderId}
        sortOrder={sortOrder}
        currentSortOption={currentSortOption} // Pass the current sort option
        filter={filter}
      />
    );
  };

  return (
    <div>
      {/* FolderHeader handles sorting and filtering options */}
      <FolderHeader
        setSelectedOption={setSelectedOption} // Update the selected folder view (allFolders, sharedWithMe, pinnedDocument)
        setSortOrder={handleSortOrderChange} // Handle sort order change (ascending/descending)
        setFilter={setFilter} // Set the current filter options
        filterOptions={filterOptionsMap[selectedOption] || filterOptionsMap.default} // Pass the correct filter options based on the selected view
        sortOptions={sortOptionsMap[selectedOption] || sortOptionsMap.default} // Pass the correct sort options based on the selected view
        currentSortOption={currentSortOption} // Pass the current sort option to the header
        selectedOption={selectedOption} // Track the selected option
      />
      {/* Render the appropriate component based on the selected folder view */}
      {renderSelectedComponent()}
    </div>
  );
};

export default Folders;
