
import React from 'react';
import Navbar from './navbar';
import { useAuth } from '../authcontext';

function Header() {
  const { userRole, authenticated, logout } = useAuth();

  return (
    <>
      <Navbar userRole={userRole} authenticated={authenticated} handleLogout={logout} />
    </>
  );
}

export default Header;

