// import React, { useState } from 'react';
// import { useAuth } from '../../authcontext';
// import { useNavigate } from 'react-router-dom';
// import { EyeOutlined, EyeInvisibleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
// import Brixlogo from '../../../assets/PNG/Brix-Logo.png';
// import CheckmarkIcon from '../../../assets/SVG/Success.svg';

// const LoginForm = () => {
//   const { login, sendPasswordResetLink, isSubmitting } = useAuth();
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
//   const [isForgotPassword, setIsForgotPassword] = useState(false);
//   const [isVerificationSent, setIsVerificationSent] = useState(false);
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setError('');
//     setSuccess('');
//     try {
//       const response = await login(email, password);
//       if (response?.success) {
//         navigate('/app/dashboard');
//       } else {
//         setError(response?.message || 'An unexpected error occurred.');
//       }
//     } catch (err) {
//       setError('An error occurred while logging in. Please try again.');
//     }
//   };

//   const handleForgotPassword = () => setIsForgotPassword(true);

//   const handleSendVerificationLink = async (e) => {
//     e.preventDefault();
//     setError('');
//     setSuccess(''); 

//     if (!email) {
//       setError('Please provide a valid email.');
//       return;
//     }

//     // Show Checkmark icon and maintain state
//     setIsVerificationSent(true);

//     try {
//       const response = await sendPasswordResetLink(email);
//       if (response?.success) {
//         setSuccess(response?.message || 'Verification link sent successfully.');
//         setIsVerificationSent(true);

//         // Automatically redirect after an increased delay (10 seconds)
//         setTimeout(() => {
//           setIsForgotPassword(false); 
//           navigate('/auth/login'); // Redirect to Login Page
//         }, 45000); // 10 seconds delay
//       } else {
//         setIsVerificationSent(false); // Revert only if the API fails
//         setError(response?.message || 'Failed to send verification link.');
//       }
//     } catch (err) {
//       setIsVerificationSent(false); // Revert only in case of error
//       setError('An error occurred while sending the verification link. Please try again.');
//     }
//   };

//   return (
//     <div className="relative min-h-screen flex flex-col items-center justify-center px-4 bg-transparent">
//       <div className="absolute top-0 left-0 ml-[-130px]">
//         <img src={Brixlogo} alt="Brix Logo" className="w-[116px] h-[44px]" />
//       </div>

//       <div className="relative w-full max-w-md mt-20">
//         {!isForgotPassword && !isVerificationSent ? (
//           <div className="ml-4">
//             {error && (
//               <div className="flex items-center p-4 mb-4 border border-red-300 bg-red-50 text-red-700">
//                 <ExclamationCircleOutlined style={{ color: '#FF412E', fontSize: '18px' }} />
//                 <span className="ml-2">{error}</span>
//               </div>
//             )}

//             {success && (
//               <div className="flex flex-col items-center justify-center ml-4">
//                 <img src={CheckmarkIcon} alt="Success" style={{ width: '132px', height: '132px', marginBottom: '24px' }} />
//                 <span className="ml-2">{success}</span>
//               </div>
//             )}

//             <h1 className="text-3xl font-bold mb-2">Welcome to BRIX</h1>
//             <p className="text-gray-700 mb-6">Log in with your credentials to manage your account</p>

//             <form onSubmit={handleSubmit}>
//               <div className="mb-4">
//                 <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
//                 <input
//                   type="email"
//                   id="email"
//                   placeholder="Email"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   required
//                   className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
//                 />
//               </div>

//               <div className="mb-6 relative">
//                 <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
//                 <input
//                   type={showPassword ? 'text' : 'password'}
//                   id="password"
//                   placeholder="Password"
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                   required
//                   className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
//                 />
//                 {showPassword ? (
//                   <EyeOutlined
//                     className="absolute right-3 top-10 cursor-pointer"
//                     onClick={() => setShowPassword(false)}
//                   />
//                 ) : (
//                   <EyeInvisibleOutlined
//                     className="absolute right-3 top-10 cursor-pointer"
//                     onClick={() => setShowPassword(true)}
//                   />
//                 )}
//               </div>

//               <div className="flex items-center justify-between mb-6">
//                 <button
//                   type="button"
//                   onClick={handleForgotPassword}
//                   className="text-sm font-normal text-[#1B2028] underline ml-auto cursor-pointer"
//                 >
//                   Forgot Password?
//                 </button>
//               </div>

//               <button
//                 type="submit"
//                 disabled={isSubmitting}
//                 className="w-full h-10 text-white font-medium rounded-md"
//                 style={{
//                   backgroundColor: isSubmitting ? '#CAD4DD' : '#FF6F61',
//                   cursor: isSubmitting ? 'not-allowed' : 'pointer',
//                 }}
//               >
//                 {isSubmitting ? 'Logging in...' : 'Log In'}
//               </button>
//             </form>
//           </div>
//         ) : isForgotPassword && !isVerificationSent ? (
//           <div className="ml-4">
//             <h2 className="text-2xl font-semibold mb-4">Forgot Password?</h2>
//             <p className="text-gray-700 mb-6">
//               Enter your email to receive a password reset link.
//             </p>

//             <form onSubmit={handleSendVerificationLink}>
//               <div className="mb-6">
//                 <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
//                 <input
//                   type="email"
//                   id="email"
//                   placeholder="Email"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   required
//                   className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
//                 />
//               </div>

//               <button
//                 type="submit"
//                 disabled={!email || isSubmitting}
//                 className="w-full h-10 text-white font-medium rounded-md"
//                 style={{
//                   backgroundColor: email ? '#FF6F61' : '#CAD4DD',
//                   cursor: email ? 'pointer' : 'not-allowed',
//                 }}
//               >
//                 {isSubmitting ? 'Sending...' : 'Send Verification Link'}
//               </button>
//             </form>
//           </div>
//         ) : (
//           <div className="flex flex-col items-center">
//             <img src={CheckmarkIcon} alt="Success" className="w-32 h-32 mb-4" />
//             <p className="text-center text-gray-700">
//               {success || 'We’ve sent a link to your email. Check your inbox to reset your password.'}
//             </p>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default LoginForm;











import React, { useState } from 'react';
import { useAuth } from '../../authcontext';
import { useNavigate, Link } from 'react-router-dom';
import { EyeOutlined, EyeInvisibleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Brixlogo from '../../../assets/PNG/Brix-Logo.png';

const LoginForm = () => {
  const { login, isSubmitting } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await login(email, password);
      if (response?.success) {
        navigate('/app/dashboard');
      } else {
        setError(response?.message || 'An unexpected error occurred.');
      }
    } catch (err) {
      setError('An error occurred while logging in. Please try again.');
    }
  };

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center px-4 bg-transparent">
      <div className="absolute top-0 left-0 ml-[-130px]">
      <img
        src={Brixlogo}
        alt="Brix Logo"
        className="w-[116px] h-[44px] cursor-pointer"
        onClick={() => navigate("/auth/login")}
      />
      </div>

      <div className="relative w-full max-w-md mt-20">
        <div className="ml-4">
          {error && (
            <div className="flex items-center p-4 mb-4 border border-red-300 bg-red-50 text-red-700">
              <ExclamationCircleOutlined style={{ color: '#FF412E', fontSize: '18px' }} />
              <span className="ml-2">{error}</span>
            </div>
          )}

          <h1 className="text-3xl font-bold mb-2">Welcome to BRIX</h1>
          <p className="text-gray-700 mb-6">Log in with your credentials to manage your account</p>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              />
            </div>

            <div className="mb-6 relative">
  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
    Password
  </label>
  <input
    type={showPassword ? 'text' : 'password'}
    id="password"
    placeholder="Password"
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    required
    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm pr-10"
    // Added 'pr-10' to create space for the icon
  />
  {showPassword ? (
    <EyeOutlined
      className="absolute right-3 top-10 cursor-pointer"
      onClick={() => setShowPassword(false)}
    />
  ) : (
    <EyeInvisibleOutlined
      className="absolute right-3 top-10 cursor-pointer"
      onClick={() => setShowPassword(true)}
    />
  )}
</div>


            <div className="flex items-center justify-between mb-6">
              <Link
                to="/forgot-password"
                className="text-sm font-normal text-[#1B2028] underline ml-auto cursor-pointer"
              >
                Forgot Password?
              </Link>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full h-10 text-white font-medium rounded-md"
              style={{
                backgroundColor: isSubmitting ? '#CAD4DD' : '#FF6F61',
                cursor: isSubmitting ? 'not-allowed' : 'pointer',
              }}
            >
              {isSubmitting ? 'Logging in...' : 'Log In'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;