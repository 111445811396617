// import React from 'react';
// import { Col, Row } from 'antd';
// import RecentlyModified from '../Components/LandingPage/RecentlyModified';
// import PinnedDocuments from '../Components/LandingPage/PinnedDocument';
// import ToDoList from '../Components/LandingPage/TodoList';
// import MyTeams from '../Components/LandingPage/MyTeams';
// import QuickActions from '../Components/LandingPage/QuickActions';
// import Folders from '../Components/LandingPage/Folders';

// const Dashboard = () => {
//   return (
//     <div style={{ padding: '20px' }}>
//       <Row gutter={[16, 16]}>
//         <Col span={16}>
//           <Row gutter={[16, 16]}>
//             <Col span={24}>
//               <h2>Welcome Back, Xyz</h2>
//             </Col>
//             <Col span={24}>
//               <QuickActions />
//             </Col>
//             <Col span={24}>
//               <Folders />
//             </Col>
//           </Row>
//         </Col>

//         <Col span={8}>
//           <Row gutter={[16, 16]}>
//             <Col span={24}>
//               <PinnedDocuments />
//             </Col>
//             <Col span={24}>
//               <ToDoList />
//             </Col>
//             <Col span={24}>
//               <MyTeams />
//             </Col>
//           </Row>
//         </Col>
//       </Row>

//       <Row gutter={[16, 16]}>
//         <Col span={24}>
//           <RecentlyModified />
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default Dashboard;




//home.jsx//

// import React from 'react';
// import { Col, Row } from 'antd';
// import RecentlyModified from '../Components/LandingPage/RecentlyModified';
// import PinnedDocuments from '../Components/LandingPage/PinnedDocument';
// import ToDoList from '../Components/LandingPage/TodoList';
// import MyTeams from '../Components/LandingPage/MyTeams';
// import QuickActions from '../Components/LandingPage/QuickActions';
// import Folders from '../Components/LandingPage/Folders';

// const Dashboard = () => {
//   return (
//     <div style={{ padding: '20px' }}>
//       <Row gutter={[16, 16]}>
//         <Col span={16}>
//           <Row gutter={[16, 16]}>
//             <Col span={24}>
//               <h2>Welcome Back, Xyz</h2>
//             </Col>
//             <Col span={24}>
//               <QuickActions />
//             </Col>
//             <Col span={24}>
//               <Folders />
//             </Col>
//           </Row>
//         </Col>

//         <Col span={8}>
//           <Row gutter={[16, 16]}>
//             <Col span={24}>
//               <PinnedDocuments />
//             </Col>
//             <Col span={24}>
//               <ToDoList />
//             </Col>
//             <Col span={24}>
//               <MyTeams />
//             </Col>
//           </Row>
//         </Col>
//       </Row>

//       <Row gutter={[16, 16]}>
//         <Col span={24}>
//           <RecentlyModified />
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default Dashboard;




// //home.jsx//


// import React, { useState } from 'react';
// import { Col, Row, Button } from 'antd';
// import RecentlyModified from '../Components/LandingPage/RecentlyModified';
// import PinnedDocuments from '../Components/LandingPage/PinnedDocument';
// import ToDoList from '../Components/LandingPage/TodoList';
// import MyTeams from '../Components/LandingPage/MyTeams';
// import QuickActions from '../Components/LandingPage/QuickActions';
// import Folders from '../Components/LandingPage/Folders';
// // import Buttons from '../Components/LandingPage/Buttons'; 

// const Dashboard = () => {
//   const [isEmptyState, setIsEmptyState] = useState(false);

//   return (
//     <div style={{ padding: '20px' }}>


//       <Row gutter={[16, 16]}>
//         {/* Full-width for QuickActions on mobile, 2/3 on larger screens */}
//         <Col xs={24} md={16}>
//           <Row gutter={[16, 16]}>
//             <Col xs={24}>
//               <div style={{ marginBottom: '20px' }}>
//                 <QuickActions />
//               </div>
//             </Col>
//             <Col xs={24}>
//               <Folders isEmptyState={isEmptyState} />
//             </Col>
//           </Row>
//         </Col>

//         {/* Full-width for right column components on mobile, 1/3 on larger screens */}
//         <Col xs={24} md={8}>
//           <Row gutter={[16, 16]}>
//             <Col xs={24}>
//               {/* <Buttons />  */}
//             </Col>
//             <Col xs={24}>
//               <PinnedDocuments isEmptyState={isEmptyState} />
//             </Col>
//             <Col xs={24}>
//               <ToDoList isEmptyState={isEmptyState} />
//             </Col>
//             <Col xs={24}>
//               <MyTeams isEmptyState={isEmptyState} />
//             </Col>
//           </Row>
//         </Col>
//       </Row>

//       {/* RecentlyModified component spans full width */}
//       <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
//         <Col xs={24}>
//           <RecentlyModified />
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default Dashboard;







import React, { useState } from 'react';
import { Col, Row, Button } from 'antd';
import RecentlyModified from '../Components/LandingPage/RecentlyModified';
import PinnedDocuments from '../Components/LandingPage/PinnedDocument';
import ToDoList from '../Components/LandingPage/TodoList';
import MyTeams from '../Components/LandingPage/MyTeams';
import QuickActions from '../Components/LandingPage/QuickActions';
import Folders from '../Components/LandingPage/Folders';

const Dashboard = () => {
  const [isEmptyState, setIsEmptyState] = useState(false);

  return (
    <div style={{ padding: '20px' }}>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={16}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <div style={{ marginBottom: '20px' }}>
                <QuickActions />
              </div>
            </Col>
            <Col xs={24}>
              <Folders isEmptyState={isEmptyState} />
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={8}>
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              {/* Placeholder to maintain space for the removed Buttons component */}
              <div style={{ height: '78px' }}></div>
            </Col>
            <Col xs={24}>
              <PinnedDocuments isEmptyState={isEmptyState} />
            </Col>
            <Col xs={24}>
              <ToDoList isEmptyState={isEmptyState} />
            </Col>
            <Col xs={24}>
              <MyTeams isEmptyState={isEmptyState} />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
        <Col xs={24}>
          <RecentlyModified />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;




