import React from 'react'
import ForgotPassword from '../layouts/ForgotPassword'

const forgotPassword = () => {
  return (
    <div>
     <ForgotPassword/>
    </div>
  )
}

export default forgotPassword