
import React from "react";
import Reset from "../layouts/Reset";

const reset = () => {
  return (
    <div>
      <Reset /> 
    </div>
  );
};

export default reset;



