import React, { useState, useEffect, useRef } from "react";
import { Button, Input, message } from "antd";
import { EyeInvisibleOutlined, EyeOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import LockIcon from "../../../../assets/SVG/Vault.svg";
import ForgotPin from "./ForgotPin";
import ChangePin from "./ChangePin";
import axios from "axios";
import VaultDoc from "../../../Screens/VaultDoc"; // Ensure the path is correct
import { Spin } from "antd"; 


const VaultAccess = () => {
  const [pin, setPin] = useState(["", "", "", ""]);
  const [showForgotPin, setShowForgotPin] = useState(false);
  const [showChangePin, setShowChangePin] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [localAttempts, setLocalAttempts] = useState(0); // Track session attempts
  const [isVaultUnlocked, setIsVaultUnlocked] = useState(false); // Track vault unlock status
  const [isLoadingStatus, setIsLoadingStatus] = useState(true); // Loading state for vault/status API
  const [isUnlocking, setIsUnlocking] = useState(false);

  const inputRefs = useRef([]);
  const clearPinInputs = () => {
    setPin(["", "", "", ""]); // Clear the PIN state
    inputRefs.current[0]?.focus(); // Focus back on the first input box
  };


  useEffect(() => {
    const checkVaultStatus = async () => {
      const sessionToken = localStorage.getItem("sessionToken");
      const vaultToken = localStorage.getItem("vaultToken");

      if (!sessionToken || !vaultToken) {
        setIsLoadingStatus(false);
        return; // Missing tokens, proceed to regular flow
      }

      try {
        setIsLoadingStatus(true); // Show loading state
        const response = await axios.get(
          `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/vault/status`,
          {
            headers: {
              Authorization: `Bearer ${sessionToken}`,
              vaultToken: vaultToken,
            },
          }
        );

        if (response.status === 200) {
          setIsVaultUnlocked(true); // Directly unlock the vault if the response is positive
        }
      } catch (error) {
        console.error("Error checking vault status:", error);
      } finally {
        setIsLoadingStatus(false); // Hide loading state
      }
    };

    checkVaultStatus();
  }, []);

  const handlePinChange = (index, value) => {
    if (/^\d?$/.test(value)) {
      const newPin = [...pin];
      newPin[index] = value;
      setPin(newPin);

      if (value && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleUnlockVault = async () => {
    const enteredPin = parseInt(pin.join(""), 10); // Combine PIN and parse it as a number

    setIsUnlocking(true); // Start spinner
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/vault/unlock`,
        {
          pin: enteredPin, // Pass pin as a number
        }
      );

      if (response.status === 200 && response.data?.data?.token) {
        const { token } = response.data.data;
        localStorage.setItem("vaultToken", token);
        message.success("Vault unlocked successfully!");
        setLocalAttempts(0); // Reset attempts
        setErrorMessage(""); // Clear error message
        setIsVaultUnlocked(true); // Unlock vault
      }
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (
          status === 403 &&
          data?.error?.message === "vault is locked , contact superadmin"
        ) {
          setErrorMessage("Max Unlock Attempts Exceeded");
          message.error("Vault is locked. Contact your super-admin.");
        } else if (
          status === 401 &&
          data?.error?.message === "access denied, incorrect pin"
        ) {
          if (localAttempts + 1 >= 3) {
            setErrorMessage("Max Unlock Attempts Exceeded");
          } else {
            setLocalAttempts(localAttempts + 1);
            message.error("Incorrect PIN. Please try again.");
          }
        } else {
          message.error(data?.error?.message || "An unexpected error occurred.");
        }
      } else {
        message.error("Network error. Please try again.");
      }
      clearPinInputs(); // Clear the PIN input boxes on error

    } finally {
      setIsUnlocking(false); // Stop spinner
    }
  };

  const handleForgotPinClick = () => {
    setShowForgotPin(true);
  };

  const handleChangePinClick = () => {
    setShowChangePin(true);
  };

  const handleBackToVaultAccess = () => {
    setShowForgotPin(false);
    setShowChangePin(false);
  };

  if (isLoadingStatus) {
    // Loading state specifically for vault/status API
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <div className="w-full max-w-6xl  rounded-lg p-6">
          {/* Header */}
          <div className="h-8 bg-gray-300 rounded w-1/4 mb-6"></div>

          {/* Table Header */}
          <div className="grid grid-cols-12 gap-4 mb-4">
            <div className="col-span-2 h-6 bg-gray-300 rounded"></div>
            <div className="col-span-3 h-6 bg-gray-300 rounded"></div>
            <div className="col-span-2 h-6 bg-gray-300 rounded"></div>
            <div className="col-span-2 h-6 bg-gray-300 rounded"></div>
            <div className="col-span-3 h-6 bg-gray-300 rounded"></div>
          </div>

          {/* Rows */}
          <div className="space-y-4">
            {[...Array(6)].map((_, rowIndex) => (
              <div
                key={rowIndex}
                className="grid grid-cols-12 gap-4 bg-gray-50 py-3 px-4 rounded shadow-sm"
              >
                <div className="col-span-2 h-6 bg-gray-300 rounded"></div>
                <div className="col-span-3 h-6 bg-gray-300 rounded"></div>
                <div className="col-span-2 h-6 bg-gray-300 rounded"></div>
                <div className="col-span-2 h-6 bg-gray-300 rounded"></div>
                <div className="col-span-3 h-6 bg-gray-300 rounded"></div>
              </div>
            ))}
          </div>

          {/* Footer */}
          <div className="h-8 bg-gray-300 rounded w-1/4 mt-6"></div>
        </div>
      </div>
    );
  }

  if (showForgotPin) {
    return <ForgotPin onBack={handleBackToVaultAccess} />;
  }

  if (showChangePin) {
    return <ChangePin onBack={handleBackToVaultAccess} />;
  }

  if (isVaultUnlocked) {
    return <VaultDoc />;
  }

  return (
    <div className="flex justify-center items-center w-full h-full p-12 relative">
      {localAttempts >= 3 && errorMessage === "Max Unlock Attempts Exceeded" && (
        <div
          className="absolute top-4 inline-flex items-center justify-center gap-3 p-2.5 bg-[#FAF0EF] border border-[#FF412E] rounded-md shadow-md"
        >
          <ExclamationCircleOutlined className="text-[#FF412E] text-lg" />
          <span className="text-[#FF412E] font-semibold">
            Max Unlock Attempts Exceeded
          </span>
        </div>
      )}

      {errorMessage === "Max Unlock Attempts Exceeded" ? (
        <div className="flex flex-col items-center p-8 bg-white rounded-lg shadow-md w-[428px] max-h-[508px] mt-20">
          <h2 className="text-2xl font-semibold text-[#59616E]">Vault Access</h2>
          <p className="text-center text-[#798897] font-sans text-sm font-normal leading-5 my-4">
            Your vault access has been locked,
            <br />
            due to 3 failed attempts.
          </p>
          <p className="text-center text-[#798897] font-sans text-sm font-normal leading-5 mb-8">
            Talk to your super-admin to reset it again.
          </p>
          <div className="w-full flex justify-end">
            <Button
              onClick={() => {
                setLocalAttempts(0);
                setErrorMessage("");
              }}
              className="flex flex-col justify-center items-center gap-2 h-10 px-4 rounded-lg text-[#192028]"
              style={{
                backgroundColor: "#C4CBD8",
                border: "none",
                color: "#192028",
                cursor: "pointer",
                transition: "none",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#C4CBD8")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#C4CBD8")}
            >
              Close
            </Button>




          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center p-8 bg-white rounded-lg shadow-md w-[428px] h-[508px]">
          <h2 className="text-2xl font-semibold text-[#59616E]">Vault Access</h2>
          <div className="my-4">
            <img src={LockIcon} alt="Lock Icon" />
          </div>
          <p className="text-center text-[#798897] mb-4">
            Please enter the pin to get access to documents in the vault.
          </p>

          <div className="w-full flex items-center justify-between mb-4">
            <label
              htmlFor="pin"
              className="text-lg font-semibold text-left text-[#59616E]"
            >
              Enter your PIN
            </label>
            <Button
              icon={showPin ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              className="border-none"
              onClick={() => setShowPin(!showPin)}
              style={{
                color: "#59616E",
                background: "none",
                boxShadow: "none",
                border: "none",
              }}
              onMouseDown={(e) => e.preventDefault()}
            />
          </div>

          <div className="w-full flex gap-1 justify-between mb-6">
            {pin.map((digit, index) => (
              <Input
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
                maxLength={1}
                type={digit ? (showPin ? "text" : "password") : "text"}
                value={digit}
                autoComplete="off"
                onChange={(e) => handlePinChange(index, e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Backspace" && !pin[index]) {
                    if (index > 0) inputRefs.current[index - 1].focus();
                  }
                }}
                className="w-12 h-12 text-center border border-gray-300 rounded focus:outline-none focus:ring-0"
                style={{
                  boxShadow: "none",
                  borderColor: "#C4CBD8",
                  transition: "border-color 0.2s ease-in-out",
                }}
              />
            ))}
          </div>

          <div className="w-full flex justify-between text-[#00A2FF] mb-6">
            <button className="text-[#00A2FF]" onClick={handleChangePinClick}>
              Change Pin
            </button>
            <button className="text-[#00A2FF]" onClick={handleForgotPinClick}>
              Forgot Pin?
            </button>
          </div>

          <div className="flex w-full justify-end items-center gap-3 mt-4 pr-2">
            <button
              className="flex items-center justify-center px-6 py-2 rounded-md bg-[#C4CBD8] text-[#1B2028]"
              onClick={() => window.history.back()}
              style={{
                minWidth: "120px", 
                minHeight: "40px",
              }}
            >
              Close
            </button>
            <button
              className={`flex items-center justify-center px-6 py-2 rounded-md bg-[#41EAD4] text-[#192028] ${isUnlocking ? "cursor-not-allowed opacity-50" : ""
                }`}
              onClick={handleUnlockVault}
              disabled={isUnlocking} 
              style={{
                minWidth: "120px", 
                minHeight: "40px", 
              }}
            >
              {isUnlocking ? <Spin size="small" /> : "Unlock Vault"} 
            </button>

          </div>
        </div>
      )}
    </div>
  );
};

export default VaultAccess;