// import React, { useState, useEffect } from 'react';
// import { Table, Button, Avatar, message, Skeleton } from 'antd';
// import axios from 'axios';
// import PdfIcon from '../../../assets/SVG/Pdf.svg';
// import HtmlIcon from '../../../assets/SVG/Html.svg';
// import JpgIcon from '../../../assets/SVG/JPG.svg';
// import ZipIcon from '../../../assets/SVG/zip.svg';
// import DocxIcon from '../../../assets/SVG/Docs.svg';
// import RestoreTeamModal from '../../Services/EditModal/RestoreTeamModal';
// import RestoreDocumentModal from '../../Services/EditModal/RestoreDocumentModal';
// import { useAuth } from '../../authcontext';

// const Audit = () => {
//   const [isLoading, setIsLoading] = useState(true); // Loading state
//   const [isTeamModalVisible, setTeamModalVisible] = useState(false);
//   const [isDocumentModalVisible, setDocumentModalVisible] = useState(false);
//   const [selectedTeam, setSelectedTeam] = useState(null);
//   const [selectedDocument, setSelectedDocument] = useState(null);
//   const [vaultData, setVaultData] = useState([]);
//   const { userEmail, userId, userName } = useAuth();

//   useEffect(() => {
//     const fetchVaultData = async () => {
//       try {
//         const token = localStorage.getItem('sessionToken');
//         const response = await axios.get('https://auth-comm-service.onrender.com/api/v1/admin/users?resetPinRequest=true', {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         const fetchedData = response.data.data.users.map(user => ({
//           key: user._id,
//           id: user._id,
//           email: user.email,
//           name: user.name,
//           username: `${user.name} ${user.lastName}`,
//           requestedAt: new Date(user.createdAt).toLocaleString(),
//           status: user.pinResetRequest ? 'Pending' : 'Completed',
//           linkIssuedAt: new Date(user.updatedAt).toLocaleString(),
//           linkExpiresAt: new Date(new Date(user.updatedAt).getTime() + 86400000).toLocaleString(),
//           action: user.pinResetRequest ? 'Regenerate Link' : 'N/A',
//         }));
//         setVaultData(fetchedData);
//       } catch (error) {
//         console.error('Error fetching Vault data:', error);
//       } finally {
//         setIsLoading(false); // Stop loading after data is fetched
//       }
//     };
//     fetchVaultData();
//   }, []);

//   // Define missing functions and variables
//   const getStatusClass = (status) => {
//     switch (status) {
//       case 'Pending':
//         return 'border-[#FABF24] text-[#FABF24] rounded-full';
//       case 'Completed':
//         return 'border-[#00C0A1] text-[#00C0A1] rounded-full';
//       case 'Expired':
//         return 'border-[#798897] text-[#798897] rounded-full';
//       default:
//         return '';
//     }
//   };

//   const handleRegenerateLink = async (record) => {
//     try {
//       const token = localStorage.getItem('sessionToken');
//       await axios.post(
//         'https://auth-comm-service.onrender.com/api/v1/superadmin/sendPinResetRequest',
//         {
//           id: record.id,
//           email: record.email,
//           name: record.name,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       message.success('Link regenerated successfully!');
//     } catch (error) {
//       console.error('Error regenerating link:', error);
//       message.error('Failed to regenerate link.');
//     }
//   };

//   const renderDocumentIcon = (type) => {
//     switch (type) {
//       case 'PDF':
//         return <img src={PdfIcon} alt="PDF" className="w-6 h-6 mr-3" />;
//       case 'HTML':
//         return <img src={HtmlIcon} alt="HTML" className="w-6 h-6 mr-3" />;
//       case 'JPG':
//         return <img src={JpgIcon} alt="JPG" className="w-6 h-6 mr-3" />;
//       case 'ZIP':
//         return <img src={ZipIcon} alt="ZIP" className="w-6 h-6 mr-3" />;
//       case 'DOCX':
//         return <img src={DocxIcon} alt="DOCX" className="w-6 h-6 mr-3" />;
//       default:
//         return null;
//     }
//   };

//   const handleRestoreDocumentClick = (record) => {
//     setSelectedDocument(record);
//     setDocumentModalVisible(true);
//   };

//   const handleDocumentModalClose = () => {
//     setDocumentModalVisible(false);
//     setSelectedDocument(null);
//   };

//   const handleRestoreDocument = () => {
//     console.log('Restoring document:', selectedDocument?.documentName);
//     setDocumentModalVisible(false);
//   };

//   const handleRestoreTeamClick = (record) => {
//     setSelectedTeam(record);
//     setTeamModalVisible(true);
//   };

//   const handleTeamModalClose = () => {
//     setTeamModalVisible(false);
//     setSelectedTeam(null);
//   };

//   const handleRestoreTeam = () => {
//     console.log('Restoring team:', selectedTeam?.teamName);
//     setTeamModalVisible(false);
//   };

//   const teamData = [
//     {
//       key: '1',
//       teamName: 'Team Name',
//       deletedAt: '11/08/2028, 2:20 PM',
//       deletedBy: 'Person',
//       action: 'Team Restored'
//     },
//     {
//       key: '2',
//       teamName: 'Team Name',
//       deletedAt: '11/08/2028, 2:20 PM',
//       deletedBy: 'Person',
//       action: 'Restore Team'
//     },
//     {
//       key: '3',
//       teamName: 'Team Name',
//       deletedAt: '11/08/2028, 2:20 PM',
//       deletedBy: 'Person',
//       action: 'Restore Team'
//     }
//   ];

//   const deletedData = [
//     {
//       key: '1',
//       documentName: 'Content',
//       type: 'PDF',
//       deletedAt: '11/08/2028, 2:20 PM',
//       deletedBy: 'Person',
//       teams: 'Team Name',
//       size: '10 MB',
//       action: 'Restore'
//     },
//     {
//       key: '2',
//       documentName: 'Content',
//       type: 'HTML',
//       deletedAt: '11/08/2028, 2:20 PM',
//       deletedBy: 'Person',
//       teams: 'Team XYZ Production.. +2',
//       size: '20.1 MB',
//       action: 'Restore'
//     }
//   ];

//   const skeletonCard = (
//     <div className="flex flex-col justify-center items-center bg-white border border-[#99EDDE] rounded-md p-6 w-1/4">
//       <Skeleton.Input active style={{ width: 80, height: 30 }} />
//       <Skeleton.Input active style={{ width: 120, height: 20, marginTop: 10 }} />
//     </div>
//   );

//   const vaultColumns = [
//     {
//       title: 'Username',
//       dataIndex: 'username',
//       key: 'username',
//       render: (username) => (
//         <div className="flex items-center gap-3">
//           <Avatar src="https://randomuser.me/api/portraits/women/1.jpg" />
//           <span>{username}</span>
//         </div>
//       ),
//     },
//     { title: 'Requested At', dataIndex: 'requestedAt', key: 'requestedAt' },
//     {
//       title: 'Status',
//       dataIndex: 'status',
//       key: 'status',
//       render: (text) => (
//         <span className={`px-3 py-1 rounded-full ${getStatusClass(text)} border`}>
//           {text}
//         </span>
//       ),
//     },
//     { title: 'Link Issued At', dataIndex: 'linkIssuedAt', key: 'linkIssuedAt' },
//     { title: 'Link Expires At', dataIndex: 'linkExpiresAt', key: 'linkExpiresAt' },
//     {
//       title: 'Action',
//       dataIndex: 'action',
//       key: 'action',
//       render: (text, record) => (
//         <Button
//           type="primary"
//           className={`px-4 py-1 rounded-lg ${
//             text === 'Regenerate Link' && record.status === 'Pending'
//               ? 'bg-teal-500 text-white'
//               : 'bg-gray-300 text-gray-500'
//           }`}
//           disabled={!(text === 'Regenerate Link' && record.status === 'Pending')}
//           onClick={() => handleRegenerateLink(record)}
//         >
//           {text}
//         </Button>
//       ),
//     },
//   ];

//   const deletedColumns = [
//     {
//       title: 'Document Name',
//       dataIndex: 'documentName',
//       key: 'documentName',
//       render: (text, record) => (
//         <div className="flex items-center">
//           {renderDocumentIcon(record.type)}
//           <div>
//             <div className="font-semibold text-[#192028]">{text}</div>
//             <div className="text-sm text-[#59616E]">File Location</div>
//           </div>
//         </div>
//       )
//     },
//     { title: 'Type', dataIndex: 'type', key: 'type' },
//     { title: 'Deleted At', dataIndex: 'deletedAt', key: 'deletedAt' },
//     {
//       title: 'Deleted By',
//       dataIndex: 'deletedBy',
//       key: 'deletedBy',
//       render: (text) => (
//         <div className="flex items-center">
//           <Avatar src="https://randomuser.me/api/portraits/women/1.jpg" />
//           <span className="ml-2">{text}</span>
//         </div>
//       )
//     },
//     { title: 'Teams', dataIndex: 'teams', key: 'teams' },
//     { title: 'Size', dataIndex: 'size', key: 'size' },
//     {
//       title: 'Action',
//       dataIndex: 'action',
//       key: 'action',
//       render: (text, record) => (
//         <Button
//           type="primary"
//           className={`${
//             text === 'Restore' ? 'bg-[#41EAD4] text-[#192028]' : 'bg-gray-200 text-gray-600'
//           }`}
//           onClick={() => handleRestoreDocumentClick(record)}
//         >
//           {text}
//         </Button>
//       )
//     }
//   ];

//   const teamColumns = [
//     { title: 'Team Name', dataIndex: 'teamName', key: 'teamName' },
//     { title: 'Deleted At', dataIndex: 'deletedAt', key: 'deletedAt' },
//     {
//       title: 'Deleted By',
//       dataIndex: 'deletedBy',
//       key: 'deletedBy',
//       render: (text) => (
//         <div className="flex items-center">
//           <Avatar src="https://randomuser.me/api/portraits/women/1.jpg" />
//           <span className="ml-2">{text}</span>
//         </div>
//       )
//     },
//     {
//       title: 'Action',
//       dataIndex: 'action',
//       key: 'action',
//       render: (text, record) => (
//         <Button
//           type="primary"
//           className="flex justify-center items-center gap-1 rounded-sm"
//           style={{
//             padding: '0px 8px',
//             background: text === 'Team Restored' ? '#f5f7fa' : '#41EAD4',
//             color: text === 'Team Restored' ? '#798897' : '#192028',
//             borderRadius: '4px',
//             boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.05)',
//             border: text === 'Team Restored' ? '' : 'none',
//           }}
//           disabled={text === 'Team Restored'}
//           onClick={() => handleRestoreTeamClick(record)}
//         >
//           {record.action}
//         </Button>
//       )
//     }
//   ];

//   return (
//     <div className="p-2">
//       {/* Stats Cards */}
//       <div className="flex items-center gap-5" style={{ maxWidth: '1430px', gap: '40px' }}>
//         {isLoading ? (
//           <>
//             {skeletonCard}
//             {skeletonCard}
//             {skeletonCard}
//             {skeletonCard}
//           </>
//         ) : (
//           <>
//             <div className="flex flex-col justify-center items-center bg-white border border-[#99EDDE] rounded-md p-6 w-1/4">
//               <h3 className="text-3xl font-semibold text-[#1B2028]">12,345</h3>
//               <p className="font-sfpro text-[#59616E] text-center" style={{ fontSize: '16px', fontWeight: '600', lineHeight: '24px' }}>
//                 Total Users
//               </p>
//             </div>
//             <div className="flex flex-col justify-center items-center bg-white border border-[#99EDDE] rounded-md p-6 w-1/4">
//               <h3 className="text-3xl font-semibold text-[#1B2028]">345</h3>
//               <p className="font-sfpro text-[#59616E] text-center" style={{ fontSize: '16px', fontWeight: '600', lineHeight: '24px' }}>
//                 Admins
//               </p>
//             </div>
//             <div className="flex flex-col justify-center items-center bg-white border border-[#99EDDE] rounded-md p-6 w-1/4">
//               <h3 className="text-3xl font-semibold text-[#1B2028]">45</h3>
//               <p className="font-sfpro text-[#59616E] text-center" style={{ fontSize: '16px', fontWeight: '600', lineHeight: '24px' }}>
//                 Pending Requests
//               </p>
//             </div>
//             <div className="flex flex-col justify-center items-center bg-white border border-[#99EDDE] rounded-md p-6 w-1/4">
//               <h3 className="text-3xl font-semibold text-[#1B2028]">6,008</h3>
//               <p className="font-sfpro text-[#59616E] text-center" style={{ fontSize: '16px', fontWeight: '600', lineHeight: '24px' }}>
//                 Terminated Users
//               </p>
//             </div>
//           </>
//         )}
//       </div>

//       <div className="p-2">
//       {/* Vault Pin Reset Section with refined skeleton loading state */}
//       <div className="flex flex-col items-start p-4 gap-4 rounded-lg shadow-sm mt-8" style={{ maxWidth: '1430px', background: '#FFF' }}>
//         <h2 className="text-2xl font-semibold text-[#192028]">Vault - Pin Reset</h2>
//         {isLoading ? (
//           <div className="w-full">
//             <Skeleton.Input active style={{ width: '100%', height: 40, marginBottom: 8 }} />
//             <div className="flex flex-col gap-2">
//               {Array.from({ length: 6 }).map((_, index) => (
//                 <div key={index} className="flex items-center justify-between gap-3 py-2">
//                   <Skeleton.Avatar active size="large" />
//                   <Skeleton.Input active style={{ width: '20%', height: 20 }} />
//                   <Skeleton.Input active style={{ width: '15%', height: 20 }} />
//                   <Skeleton.Input active style={{ width: '15%', height: 20 }} />
//                   <Skeleton.Input active style={{ width: '15%', height: 20 }} />
//                   <Skeleton.Button active style={{ width: '80px', height: 30 }} />
//                 </div>
//               ))}
//             </div>
//           </div>
//         ) : (
//           <Table columns={vaultColumns} dataSource={vaultData} pagination={false} className="w-full bg-[#FCF9F9] rounded-lg" />

//           )}
//         </div>

//         {/* Deleted Team Documents Section */}
//         <div className="p-2">
//           <div className="flex flex-col items-start p-4 gap-4 rounded-lg shadow-sm mt-8" style={{ maxWidth: '1430px', background: '#FFF' }}>
//             <h2 className="text-2xl font-semibold text-[#192028]">Deleted Team Documents</h2>
//             {isLoading ? (
//               <div className="w-full">
//                 <Skeleton.Input active style={{ width: '100%', height: 40, marginBottom: 8 }} />
//                 <div className="flex flex-col gap-2">
//                   {Array.from({ length: 6 }).map((_, index) => (
//                     <div key={index} className="flex items-center justify-between gap-3 py-2">
//                       <Skeleton.Avatar active size="large" />
//                       <div className="flex-1 flex flex-col gap-1">
//                         <Skeleton.Input active style={{ width: '60%', height: 20 }} />
//                         <Skeleton.Input active style={{ width: '40%', height: 20 }} />
//                       </div>
//                       <Skeleton.Input active style={{ width: '15%', height: 20 }} />
//                       <Skeleton.Avatar active size="small" />
//                       <Skeleton.Input active style={{ width: '10%', height: 20 }} />
//                       <Skeleton.Input active style={{ width: '10%', height: 20 }} />
//                       <Skeleton.Button active style={{ width: '80px', height: 30 }} />
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             ) : (
//               <Table columns={deletedColumns} dataSource={deletedData} pagination={false} className="w-full bg-[#FCF9F9] rounded-lg" />
//             )}
//           </div>
//         </div>

//         {/* Team Deletion Log Section with loading skeleton */}
//         <div className="flex flex-col items-start p-4 gap-4 rounded-lg shadow-sm mt-8" style={{ maxWidth: '1430px', background: '#FFF' }}>
//           <h2 className="text-2xl font-semibold text-[#192028]">Team Deletion Log</h2>
//           {isLoading ? (
//             <div className="w-full">
//               <Skeleton.Input active style={{ width: '100%', height: 40, marginBottom: 8 }} />
//               <div className="flex flex-col gap-2">
//                 {Array.from({ length: 6 }).map((_, index) => (
//                   <div key={index} className="flex items-center justify-between gap-3 py-2">
//                     <Skeleton.Input active style={{ width: '20%', height: 20 }} />
//                     <Skeleton.Input active style={{ width: '15%', height: 20 }} />
//                     <div className="flex items-center gap-2">
//                       <Skeleton.Avatar active size="small" />
//                       <Skeleton.Input active style={{ width: '60%', height: 20 }} />
//                     </div>
//                     <Skeleton.Button active style={{ width: '80px', height: 30 }} />
//                   </div>
//                 ))}
//               </div>
//             </div>
//           ) : (
//             <Table columns={teamColumns} dataSource={teamData} pagination={false} className="w-full bg-[#FCF9F9] rounded-lg" />
//           )}
//         </div>
//       </div>

//       {/* Restore Team Modal */}
//       <RestoreTeamModal visible={isTeamModalVisible} onClose={handleTeamModalClose} onRestore={handleRestoreTeam} />

//       {/* Restore Document Modal */}
//       <RestoreDocumentModal visible={isDocumentModalVisible} onClose={handleDocumentModalClose} onRestore={handleRestoreDocument} />
//     </div>
//   );
// };

// export default Audit;















import React, { useState, useEffect } from 'react';
import VaultPinReset from '../../Services/SettingsTable/VaultPinReset';
import DeletedTeamDocuments from '../../Services/SettingsTable/DeletedTeamDocuments';
import TeamDeletionLog from '../../Services/SettingsTable/TeamDeletionLog';
import { Skeleton } from 'antd';

const Audit = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [deletedData, setDeletedData] = useState([]);
  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    // Simulate API calls for deleted documents and team data
    const fetchDeletedDocuments = async () => {
      try {
        // Replace with your API call
        const data = [
          {
            key: '1',
            documentName: 'Content',
            type: 'PDF',
            deletedAt: '11/08/2028, 2:20 PM',
            deletedBy: 'Person',
            teams: 'Team Name',
            size: '10 MB',
            action: 'Restore',
          },
          {
            key: '2',
            documentName: 'Content',
            type: 'HTML',
            deletedAt: '11/08/2028, 2:20 PM',
            deletedBy: 'Person',
            teams: 'Team XYZ Production.. +2',
            size: '20.1 MB',
            action: 'Restore',
          },
        ];
        setDeletedData(data);
      } catch (error) {
        console.error('Error fetching deleted documents:', error);
      }
    };

    const fetchTeamData = async () => {
      try {
        // Replace with your API call
        const data = [
          {
            key: '1',
            teamName: 'Team Name',
            deletedAt: '11/08/2028, 2:20 PM',
            deletedBy: 'Person',
            action: 'Restore Team',
          },
          {
            key: '2',
            teamName: 'Another Team',
            deletedAt: '11/08/2028, 2:30 PM',
            deletedBy: 'Admin',
            action: 'Restore Team',
          },
        ];
        setTeamData(data);
      } catch (error) {
        console.error('Error fetching team data:', error);
      }
    };

    const fetchAllData = async () => {
      await Promise.all([fetchDeletedDocuments(), fetchTeamData()]);
      setIsLoading(false);
    };

    fetchAllData();
  }, []);

  return (
    <div className="p-4">
      {/* Vault Pin Reset Section */}
      <div className="mb-8">
        <VaultPinReset />
      </div>

      {/* Deleted Team Documents Section */}
      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Deleted Team Documents</h2>
        {isLoading ? (
          <div className="w-full">
            <Skeleton active />
          </div>
        ) : (
          <DeletedTeamDocuments isLoading={isLoading} deletedData={deletedData} />
        )}
      </div>

      {/* Team Deletion Log Section */}
      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Team Deletion Log</h2>
        {isLoading ? (
          <div className="w-full">
            <Skeleton active />
          </div>
        ) : (
          <TeamDeletionLog isLoading={isLoading} teamData={teamData} />
        )}
      </div>
    </div>
  );
};

export default Audit;
