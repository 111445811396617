// components/FolderEmptyState.jsx

import React from "react";
import FolderDcoSvg from "../../../../assets/SVG/FolderDoc.svg"; // Update this path to the location of your SVG

const FolderEmptyState = () => {
  return (
    <div className="flex justify-center items-center flex-col mt-4">
      <img src={FolderDcoSvg} alt="No documents found" className="w-72 h-72" />
      {/* <p className="text-gray-500 mt-4">No documents found.</p>
      <p className="text-gray-400">Upload your first document to get started!</p> */}
    </div>
  );
};

export default FolderEmptyState;
