import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import BrixLogo from '../../../assets/PNG/Brix-Logo.png';
import CheckmarkIcon from '../../../assets/SVG/Success.svg';
import TokenExpiredIcon from '../../../assets/SVG/expiredToken.svg';
import ErrorIcon from '../../../assets/SVG/ErrorLoad.svg'; 

const GenerateForm = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isPasswordGeneratedSuccess, setIsPasswordGeneratedSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const token = searchParams.get('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      setErrorMessage('Invalid or missing token.');
    }
  }, [token]);

  // Automatically redirect to login page if there is an error
  useEffect(() => {
    if (errorMessage) {
      const redirectTimer = setTimeout(() => {
        navigate('/auth/login'); 
      }, 3000); // Redirect after 3 seconds

      return () => clearTimeout(redirectTimer); 
    }
  }, [errorMessage, navigate]);

  const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return minLength && hasUppercase && hasLowercase && hasSpecialChar;
  };

  useEffect(() => {
    const isPasswordValid = validatePassword(password);
    const arePasswordsMatching = password && confirmPassword && password === confirmPassword;
    setIsButtonEnabled(isPasswordValid && arePasswordsMatching);
  }, [password, confirmPassword]);

  const handleGenerate = async () => {
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    setErrorMessage('');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/auth/generatePassword`,
        {
          token,
          password,
        }
      );

      if (response.status === 200) {
        setIsPasswordGeneratedSuccess(true);
        setTimeout(() => {
          navigate('/auth/login');
        }, 3000); 
      }
    } catch (error) {
      const status = error.response?.status;
      const backendError = error.response?.data?.error?.message || 'An unexpected error occurred.';

      switch (status) {
        case 401:
          if (backendError === "your token(link) has expired , please contact superadmin") {
            setErrorMessage("Your token has expired. Please contact the superadmin for assistance.");
          } else {
            setErrorMessage("Unauthorized access.");
          }
          break;
        case 403:
          setErrorMessage("You do not have permission to perform this action.");
          break;
        case 404:
          setErrorMessage("The requested resource was not found.");
          break;
        case 422:
          setErrorMessage("Invalid input. Please check your data.");
          break;
        case 500:
          setErrorMessage("Internal Server Error. Please try again later.");
          break;
        default:
          setErrorMessage(backendError);
          break;
      }
    }
  };

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center px-4">
      <div className="absolute top-0 left-0 ml-[-150px]">
      <img
        src={BrixLogo}
        alt="Brix Logo"
        className="w-[116px] h-[44px] cursor-pointer"
        onClick={() => navigate("/auth/login")}
      />
      </div>

      {/* Password generation success */}
      {isPasswordGeneratedSuccess && (
        <div className="flex flex-col items-center justify-center w-full">
          <div className="p-8 mb-4">
            <img src={CheckmarkIcon} alt="Checkmark Icon" className="h-[132px] w-[132px]" />
          </div>
          <h2 className="text-[16px] font-semibold text-[#007768] leading-[24px] text-center mb-2">
            Password Generated Successfully
          </h2>
          <p className="text-[16px] font-normal text-[#007768] leading-[24px] text-center">
            You are now being redirected to the login page.
          </p>
        </div>
      )}

      {/* Error handling section */}
      {!isPasswordGeneratedSuccess && errorMessage && (
        <div className="flex flex-col items-center justify-center w-full text-center">
          {errorMessage.includes('expired') && (
            <img
              src={TokenExpiredIcon}
              alt="Token Expired Icon"
              className="h-[132px] w-[132px] mb-4"
            />
          )}
          {!errorMessage.includes('expired') && (
            <img
              src={ErrorIcon}
              alt="Error Icon"
              className="h-[132px] w-[132px] mb-4"
            />
          )}
          <p className="text-[14px] font-normal text-[#FF412E] leading-[20px] mb-2">{errorMessage}</p>
          {/* <p className="text-[12px] font-normal text-gray-500">
            Redirecting to the login page...
          </p> */}
        </div>
      )}

      {/* Password generation form */}
      {!isPasswordGeneratedSuccess && !errorMessage && (
        <div className="w-full max-w-md">
          <h2 className="text-[30px] font-semibold text-[#121212] mb-2 leading-[38px] font-sf-pro">
            Generate Password
          </h2>
          <p className="text-[16px] font-normal text-[#121212] mb-6 leading-[24px]">
            Please set up a password for your BRIX account.
          </p>

          <div className="flex flex-col items-start gap-1 mb-4 w-full">
            <label htmlFor="password" className="text-[16px] text-[#1B2028] font-normal leading-[24px]">
              Enter Password
            </label>
            <div className="relative w-full">
              <input
                id="password"
                type={passwordVisible ? 'text' : 'password'}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#768090] w-6 h-6 cursor-pointer"
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              </span>
            </div>
          </div>

          <div className="flex flex-col items-start gap-1 mb-4 w-full">
            <label htmlFor="confirmPassword" className="text-[16px] text-[#1B2028] font-normal leading-[24px]">
              Confirm Password
            </label>
            <div className="relative w-full">
              <input
                id="confirmPassword"
                type={confirmPasswordVisible ? 'text' : 'password'}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <span
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#768090] w-6 h-6 cursor-pointer"
                onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
              >
                {confirmPasswordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              </span>
            </div>
          </div>

          {errorMessage && (
            <p className="text-[12px] font-normal text-[#FF412E] leading-[20px] mb-4">
              {errorMessage}
            </p>
          )}

          <button
            onClick={handleGenerate}
            disabled={!isButtonEnabled}
            className={`flex h-[40px] p-2 justify-center items-center gap-2 w-full rounded font-medium transition duration-300 ${
              isButtonEnabled
                ? 'bg-[#FF6F61] text-white hover:bg-[#ff5a4d]'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            Generate
          </button>
        </div>
      )}
    </div>
  );
};

export default GenerateForm;