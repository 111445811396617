import React, { useState } from 'react';
import { Modal, Button, Radio, message } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

const DeleteVersionModal = ({ isModalVisible, handleCancel, versions, onDelete }) => {
  const [selectedVersion, setSelectedVersion] = useState(null);

  const handleVersionSelect = (e) => {
    setSelectedVersion(e.target.value);
  };

  const handleDelete = () => {
    if (selectedVersion) {
      onDelete(selectedVersion);
      message.success(`Version ${selectedVersion} deleted successfully`);
    } else {
      message.error("Please select a version to delete");
    }
  };

  return (
    <Modal
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      centered
      className="rounded-lg shadow-lg"
      width={780}
      minheight={348}
    >
      <div className="text-center">
        <CloseCircleOutlined style={{ fontSize: '48px', color: '#FF412E' }} />
        <h2 style={{ color: '#59616E', fontWeight: '600', fontSize: '24px', marginTop: '16px' }}>
          Delete Version
        </h2>
        <p style={{ color: '#768090', fontSize: '16px', marginBottom: '24px' }}>
          Select a version to delete. This action cannot be undone.
        </p>

        {/* Radio Buttons for Versions */}
        <Radio.Group onChange={handleVersionSelect}>
          {versions && versions.map((version) => (
            <Radio key={version.versionId} value={version.versionId}>
              Version {version.versionId} - Updated on {new Date(version.createdAt).toLocaleDateString()}
            </Radio>
          ))}
        </Radio.Group>

        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '16px', marginTop: '24px' }}>
          <Button
            onClick={handleCancel}
            style={{ backgroundColor: '#C4CBD8', color: '#1B2028', borderRadius: '6px', padding: '8px 24px' }}
          >
            Close
          </Button>
          <Button
            type="primary"
            danger
            onClick={handleDelete}
            style={{ backgroundColor: '#FF412E', borderRadius: '6px', padding: '8px 24px' }}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteVersionModal;
