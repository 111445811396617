// import React, { useState, useEffect, useRef } from 'react';
// import { Avatar, message, Spin } from 'antd';
// import AddNewUserModal from '../Modal/AddNewUserModal';

// const Vaultpinreset = () => {
//   const [pendingRequests, setPendingRequests] = useState([]);
//   const [page, setPage] = useState(0);
//   const [loading, setLoading] = useState(false);
//   const [showSkeleton, setShowSkeleton] = useState(true);
//   const [hasMore, setHasMore] = useState(true);
//   const tableRef = useRef(null);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [loadingUserId, setLoadingUserId] = useState(null);

//   const isInitialFetch = useRef(false);

//   const fetchPendingRequests = async (currentPage) => {
//     try {
//       const token = localStorage.getItem('sessionToken');
//       const url =
//         currentPage === 0
//           ? `https://auth-comm-service.onrender.com/api/v1/admin/users?resetPinRequest=true`
//           : `https://auth-comm-service.onrender.com/api/v1/admin/users?resetPinRequest=true&page=${currentPage}`;

//       const response = await fetch(url, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       if (!response.ok) {
//         throw new Error('Failed to fetch pending requests');
//       }

//       const data = await response.json();
//       return data.data.users;
//     } catch (error) {
//       console.error(error);
//       message.error('Error fetching pending requests');
//       return [];
//     }
//   };

//   const loadMorePendingRequests = async () => {
//     if (!hasMore || loading) return;

//     setLoading(true);
//     try {
//       const newRequests = await fetchPendingRequests(page);
//       if (newRequests.length === 0) {
//         setHasMore(false);
//       } else {
//         setPendingRequests((prev) => [...prev, ...newRequests]);
//         setPage((prevPage) => prevPage + 1);
//       }
//     } catch (error) {
//       console.error('Error fetching pending requests:', error);
//     }
//     setLoading(false);
//     setShowSkeleton(false);
//   };

//   const regenerateLink = async (user, actionType) => {
//     setLoadingUserId(user._id);
//     try {
//       const token = localStorage.getItem('sessionToken');

//       const response = await fetch(
//         'https://auth-comm-service.onrender.com/api/v1/superadmin/sendPinResetRequest',
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`,
//           },
//           body: JSON.stringify({
//             id: user._id,
//             email: user.email,
//             name: user.name,
//           }),
//         }
//       );

//       if (!response.ok) {
//         throw new Error('Failed to process request');
//       }

//       message.success(`${actionType} link successfully processed`);

//       // Refresh pending requests
//       const refreshedRequests = await fetchPendingRequests(0);
//       setPendingRequests(refreshedRequests);
//       setPage(1);
//       setHasMore(refreshedRequests.length > 0);
//       setShowSkeleton(false);
//     } catch (error) {
//       console.error('Error processing link:', error);
//       message.error(`Error processing link`);
//     } finally {
//       setLoadingUserId(null);
//     }
//   };

//   const getStatus = (user) => {
//     const currentTime = new Date();
//     const issuedAt = user.pinResetLinkIssuedAt
//       ? new Date(user.pinResetLinkIssuedAt)
//       : null;

//     if (!issuedAt) return 'not-initiated';

//     const expirationTime = new Date(issuedAt.getTime() + 15 * 60 * 1000);
//     if (currentTime > expirationTime) return 'expired';
//     return 'pending';
//   };

//   const calculateExpirationTime = (issuedAt) => {
//     if (!issuedAt) return 'N/A';
//     const expirationDate = new Date(new Date(issuedAt).getTime() + 15 * 60 * 1000);
//     return expirationDate.toLocaleString();
//   };

//   useEffect(() => {
//     if (!isInitialFetch.current) {
//       isInitialFetch.current = true;
//       loadMorePendingRequests();
//     }
//   }, []);

//   const handleScroll = () => {
//     if (!tableRef.current) return;
//     const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
//     if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
//       loadMorePendingRequests();
//     }
//   };

//   return (
//     <div className="flex flex-col max-w-[1430px] p-3 rounded-lg bg-white shadow-sm overflow-hidden">
//       <h2 className="text-lg font-semibold mb-4">Vault Pin Requests</h2>
//       <div
//         className="overflow-auto"
//         style={{ maxHeight: '552px' }}
//         onScroll={handleScroll}
//         ref={tableRef}
//       >
//         <table className="table-auto w-full border-separate" style={{ borderSpacing: '0 10px' }}>
//           <thead className="text-left text-sm font-semibold text-gray-700 bg-gray-50">
//             <tr>
//               <th className="px-4 py-2">Username</th>
//               <th className="px-4 py-2">Request At</th>
//               <th className="px-4 py-2">Status</th>
//               <th className="px-4 py-2">Link Expires At</th>
//               <th className="px-4 py-2">Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {showSkeleton
//               ? Array.from({ length: 5 }).map((_, index) => (
//                   <tr key={index} className="bg-white shadow-sm rounded-lg">
//                     <td className="px-4 py-2">
//                       <div className="flex items-center gap-2">
//                         <div className="w-8 h-8 bg-gray-300 rounded-full"></div>
//                         <div className="w-24 h-4 bg-gray-300 rounded"></div>
//                       </div>
//                     </td>
//                     <td className="px-4 py-2">
//                       <div className="w-20 h-4 bg-gray-300 rounded"></div>
//                     </td>
//                     <td className="px-4 py-2">
//                       <div className="w-20 h-4 bg-gray-300 rounded"></div>
//                     </td>
//                     <td className="px-4 py-2">
//                       <div className="w-32 h-4 bg-gray-300 rounded"></div>
//                     </td>
//                     <td className="px-4 py-2">
//                       <div className="w-24 h-4 bg-gray-300 rounded"></div>
//                     </td>
//                   </tr>
//                 ))
//               : pendingRequests.map((user) => {
//                   const status = getStatus(user);
//                   return (
//                     <tr key={user._id} className="bg-white shadow-sm rounded-lg">
//                       <td className="px-4 py-2">
//                         <div className="flex items-center gap-2">
//                           <Avatar src="https://i.pravatar.cc/30?img=64" alt="User Avatar" size={30} />
//                           <span>{`${user.name} ${user.lastName}`}</span>
//                         </div>
//                       </td>
//                       <td className="px-4 py-2">
//                         {user.lastPinResetRequestAt
//                           ? new Date(user.lastPinResetRequestAt).toLocaleString()
//                           : 'N/A'}
//                       </td>
//                       <td className="px-4 py-2">
//                         <span
//                           className={`px-2 py-1 text-sm font-semibold rounded-md ${
//                             status === 'expired'
//                               ? 'text-gray-500 bg-gray-100 border border-gray-300'
//                               : status === 'pending'
//                               ? 'text-yellow-500 bg-yellow-100 border border-yellow-500'
//                               : 'text-orange-500 bg-orange-100 border border-orange-500'
//                           }`}
//                         >
//                           {status}
//                         </span>
//                       </td>
//                       <td className="px-4 py-2">
//                         {status === 'not-initiated'
//                           ? 'N/A'
//                           : calculateExpirationTime(user.pinResetLinkIssuedAt)}
//                       </td>
//                       <td className="px-4 py-2">
//                         <button
//                           className={`px-4 py-1 w-full rounded-lg flex items-center justify-center ${
//                             loadingUserId === user._id
//                               ? 'bg-gray-200 text-gray-500'
//                               : status === 'pending'
//                               ? 'bg-gray-200 text-gray-500'
//                               : 'bg-[#41EAD4] text-[#192028]'
//                           }`}
//                           onClick={() =>
//                             regenerateLink(user, status === 'not-initiated' ? 'Generate' : 'Regenerate')
//                           }
//                           disabled={status === 'pending' || loadingUserId === user._id}
//                         >
//                           {loadingUserId === user._id ? <Spin size="small" /> : status === 'not-initiated' ? 'Generate Link' : 'Regenerate Link'}
//                         </button>
//                       </td>
//                     </tr>
//                   );
//                 })}
//           </tbody>
//         </table>
//       </div>
//       <AddNewUserModal
//         isVisible={isModalVisible}
//         onClose={() => setIsModalVisible(false)}
//         isSuperAdmin={true}
//       />
//     </div>
//   );
// };

// export default Vaultpinreset;









import React, { useState, useEffect, useRef } from 'react';
import { Avatar, message, Spin } from 'antd';
import AddNewUserModal from '../Modal/AddNewUserModal';

const Vaultpinreset = () => {
  const [pendingRequests, setPendingRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const tableRef = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingUserId, setLoadingUserId] = useState(null);

  const isInitialFetch = useRef(false);

  const fetchPendingRequests = async (currentPage) => {
    try {
      const token = localStorage.getItem('sessionToken');
      const url =
        currentPage === 0
          ? `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/admin/users?resetPinRequest=true`
          : `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/admin/users?resetPinRequest=true&page=${currentPage}`;

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch pending requests');
      }

      const data = await response.json();
      return data.data.users;
    } catch (error) {
      console.error(error);
      message.error('Error fetching pending requests');
      return [];
    }
  };

  const loadMorePendingRequests = async () => {
    if (!hasMore || loading) return;

    setLoading(true);
    try {
      const newRequests = await fetchPendingRequests(page);
      if (newRequests.length === 0) {
        setHasMore(false);
      } else {
        setPendingRequests((prev) => [...prev, ...newRequests]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error('Error fetching pending requests:', error);
    }
    setLoading(false);
    setShowSkeleton(false);
  };

  const regenerateLink = async (user, actionType) => {
    setLoadingUserId(user._id);
    try {
      const token = localStorage.getItem('sessionToken');

      const response = await fetch(
        `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/superadmin/sendPinResetRequest`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            id: user._id,
            email: user.email,
            name: user.name,
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to process request');
      }

      message.success(`${actionType} link successfully processed`);

      // Refresh pending requests
      const refreshedRequests = await fetchPendingRequests(0);
      setPendingRequests(refreshedRequests);
      setPage(1);
      setHasMore(refreshedRequests.length > 0);
      setShowSkeleton(false);
    } catch (error) {
      console.error('Error processing link:', error);
      message.error(`Error processing link`);
    } finally {
      setLoadingUserId(null);
    }
  };

  const getStatus = (user) => {
    const currentTime = new Date();
    const issuedAt = user.pinResetLinkIssuedAt
      ? new Date(user.pinResetLinkIssuedAt)
      : null;

    if (!issuedAt) return 'not-initiated';

    const expirationTime = new Date(issuedAt.getTime() + 15 * 60 * 1000);
    if (currentTime > expirationTime) return 'expired';
    return 'pending';
  };

  const calculateExpirationTime = (user) => {
    const status = getStatus(user);
    if (status === 'expired' || status === 'not-initiated') return 'N/A';

    const issuedAt = user.pinResetLinkIssuedAt
      ? new Date(user.pinResetLinkIssuedAt)
      : null;

    if (!issuedAt) return 'N/A';
    const expirationDate = new Date(issuedAt.getTime() + 15 * 60 * 1000);
    return expirationDate.toLocaleString();
  };

  useEffect(() => {
    if (!isInitialFetch.current) {
      isInitialFetch.current = true;
      loadMorePendingRequests();
    }
  }, []);

  const handleScroll = () => {
    if (!tableRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
      loadMorePendingRequests();
    }
  };

  return (
    <div className="flex flex-col max-w-[1430px] p-3 rounded-lg bg-white shadow-sm overflow-hidden">
      <h2 className="text-lg font-semibold mb-4">Vault Pin Requests</h2>
      <div
        className="overflow-auto"
        style={{ maxHeight: '552px' }}
        onScroll={handleScroll}
        ref={tableRef}
      >
        <table className="table-auto w-full border-separate" style={{ borderSpacing: '0 10px' }}>
          <thead className="text-left text-sm font-semibold text-gray-700 bg-gray-50">
            <tr>
              <th className="px-4 py-2">Username</th>
              <th className="px-4 py-2">Request At</th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2">Link Expires At</th>
              <th className="px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {showSkeleton
              ? Array.from({ length: 5 }).map((_, index) => (
                  <tr key={index} className="bg-white shadow-sm rounded-lg">
                    <td className="px-4 py-2">
                      <div className="flex items-center gap-2">
                        <div className="w-8 h-8 bg-gray-300 rounded-full"></div>
                        <div className="w-24 h-4 bg-gray-300 rounded"></div>
                      </div>
                    </td>
                    <td className="px-4 py-2">
                      <div className="w-20 h-4 bg-gray-300 rounded"></div>
                    </td>
                    <td className="px-4 py-2">
                      <div className="w-20 h-4 bg-gray-300 rounded"></div>
                    </td>
                    <td className="px-4 py-2">
                      <div className="w-32 h-4 bg-gray-300 rounded"></div>
                    </td>
                    <td className="px-4 py-2">
                      <div className="w-24 h-4 bg-gray-300 rounded"></div>
                    </td>
                  </tr>
                ))
              : pendingRequests.map((user) => {
                  const status = getStatus(user);
                  return (
                    <tr key={user._id} className="bg-white shadow-sm rounded-lg">
                      <td className="px-4 py-2">
                        <div className="flex items-center gap-2">
                          <Avatar src="https://i.pravatar.cc/30?img=64" alt="User Avatar" size={30} />
                          <span>{`${user.name} ${user.lastName}`}</span>
                        </div>
                      </td>
                      <td className="px-4 py-2">
                        {user.lastPinResetRequestAt
                          ? new Date(user.lastPinResetRequestAt).toLocaleString()
                          : 'N/A'}
                      </td>
                      <td className="px-4 py-2">
                        <span
                          className={`px-2 py-1 text-sm font-semibold rounded-md ${
                            status === 'expired'
                              ? 'text-gray-500 bg-gray-100 border border-gray-300'
                              : status === 'pending'
                              ? 'text-yellow-500 bg-yellow-100 border border-yellow-500'
                              : 'text-orange-500 bg-orange-100 border border-orange-500'
                          }`}
                        >
                          {status}
                        </span>
                      </td>
                      <td className="px-4 py-2">
                        {calculateExpirationTime(user)}
                      </td>
                      <td className="px-4 py-2">
                        <button
                          className={`px-4 py-1 w-full rounded-lg flex items-center justify-center ${
                            loadingUserId === user._id
                              ? 'bg-gray-200 text-gray-500'
                              : status === 'pending'
                              ? 'bg-gray-200 text-gray-500'
                              : 'bg-[#41EAD4] text-[#192028]'
                          }`}
                          onClick={() =>
                            regenerateLink(user, status === 'not-initiated' ? 'Generate' : 'Regenerate')
                          }
                          disabled={status === 'pending' || loadingUserId === user._id}
                        >
                          {loadingUserId === user._id ? <Spin size="small" /> : status === 'not-initiated' ? 'Generate Link' : 'Regenerate Link'}
                        </button>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
      <AddNewUserModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        isSuperAdmin={true}
      />
    </div>
  );
};

export default Vaultpinreset;
