import React, { useState, useEffect } from 'react';
import { Button, Avatar, Input, Dropdown, Menu } from 'antd';
import { FormOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useAuth } from '../../authcontext'; // Assuming you're using AuthContext to get user role

const AccessControl = ({ docId }) => {
  const { userRole } = useAuth(); // Access user role from AuthContext
  const [availableUsers, setAvailableUsers] = useState([]); // Stores the list of available users fetched from RBAC service
  const [searchTerm, setSearchTerm] = useState(''); // Stores the search input value
  const [filteredUsers, setFilteredUsers] = useState([]); // Stores the filtered list of users based on search
  const [selectedUsers, setSelectedUsers] = useState([]); // Stores the selected users from dropdown

  // Fetch the list of users from the RBAC service based on user role
  useEffect(() => {
    const fetchUsers = async () => {
      let endpoint = null;
      try {
        // Define endpoint based on the userRole
        if (userRole === 'ADMIN') {
          endpoint = `${process.env.REACT_APP_RBAC_SERVICE_URL}/admin/users`;
        } else if (userRole === 'SUPER ADMIN') {
          endpoint = `${process.env.REACT_APP_RBAC_SERVICE_URL}/superadmin/users`;
        }

        if (endpoint) {
          const response = await axios.get(endpoint);
          if (Array.isArray(response.data.users)) {
            // Filter out users with the role 'SUPER ADMIN'
            const filteredUsers = response.data.users.filter(
              (user) => user.role !== 'SUPER ADMIN'
            );
            setAvailableUsers(filteredUsers);
            setFilteredUsers(filteredUsers); // Initialize filtered users
          }
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [userRole]);

  // Handle search functionality
  useEffect(() => {
    if (searchTerm) {
      const filtered = availableUsers.filter((user) =>
        (user.name && user.name.toLowerCase().includes(searchTerm.toLowerCase())) || 
        (user.email && user.email.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(availableUsers); // Reset to full list if no search term
    }
  }, [searchTerm, availableUsers]);

  // Handle selecting a user from dropdown
  const handleMenuClick = (user) => {
    if (!selectedUsers.find((selectedUser) => selectedUser.id === user.id)) {
      setSelectedUsers([...selectedUsers, user]); // Add selected user to the list
    }
    setSearchTerm(''); // Clear search input after selecting
  };

  // Remove a user from the selected list
  const handleRemoveUser = (userId) => {
    setSelectedUsers(selectedUsers.filter((user) => user.id !== userId));
  };

  // Handle the share functionality by sending selected users to the backend
  const handleShare = async () => {
    if (selectedUsers.length > 0) {
      const permissions = selectedUsers.map((user) => ({
        entityId: user.id,
        entityType: 'User', // Assuming you're sharing with users; adjust this if needed
        permissionType: 'view',
      }));

      try {
        await axios.patch(`${process.env.REACT_APP_DOC_SERVICE_URL}/share/${docId}`, {
          permissions,
        });
        console.log('Document shared successfully!');
        setSelectedUsers([]); // Clear selected users after sharing
      } catch (error) {
        console.error('Error sharing document:', error);
      }
    }
  };

  // Dropdown menu with user recommendations
  const menu = (
    <Menu>
      {filteredUsers && filteredUsers.length > 0 ? (
        filteredUsers.map((user) => (
          <Menu.Item key={user.id} onClick={() => handleMenuClick(user)}>
            {user.name ? `${user.name} (${user.email})` : user.email}
          </Menu.Item>
        ))
      ) : (
        <Menu.Item disabled>No users found</Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      {/* Section Header */}
      <h3 className="text-[#768090] font-semibold mb-2" style={{ marginBottom: '4px', marginTop: '0px' }}>
        Who has access
      </h3>

      {/* Search Bar and Share Button */}
      <div className="flex items-center gap-2 mb-4">
        {/* Dropdown Input */}
        <Dropdown overlay={menu} trigger={['click']} visible={!!searchTerm && filteredUsers.length > 0}>
          <Input
            type="text"
            placeholder="Share By Name or Team"
            className="flex-grow p-2 border border-[#99EDDE] rounded-lg focus:outline-none"
            style={{ fontSize: '14px', color: '#99EDDE' }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm on input change
          />
        </Dropdown>
        <Button
          className="bg-[#41EAD4] text-[#192028] rounded-lg"
          style={{ fontSize: '14px', padding: '4px 12px', fontWeight: '500' }}
          onClick={handleShare} // Handle share on button click
        >
          Share
        </Button>
      </div>

      {/* Display selected users */}
      <div className="mt-2">
        {selectedUsers.map((user) => (
          <div className="flex items-center justify-between gap-2 mb-2" key={user.id}>
            <div className="flex items-center gap-2">
              <Avatar style={{ backgroundColor: '#007768' }}>
                {user.name ? user.name[0].toUpperCase() : 'A'}
              </Avatar>
              <span className="text-[#1B2028]">{user.name || user.email}</span>
            </div>
            <Button
              type="link"
              style={{ color: '#FF6F61' }}
              onClick={() => handleRemoveUser(user.id)}
            >
              Remove
            </Button>
          </div>
        ))}
      </div>
    </>
  );
};

export default AccessControl;
