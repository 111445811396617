import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import Header from '../layouts/header';
import { useAuth } from '../authcontext';
import '../style.css'

const ManageUsers = () => {
  const { userRole, authenticated } = useAuth();
  const [users, setUsers] = useState([]);
  const [flashMessage, setFlashMessage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let endpoint;
        if (userRole === 'ADMIN') {
          endpoint = `${process.env.REACT_APP_RBAC_SERVICE_URL}/admin/users`;
        } else if (userRole === 'SUPER ADMIN') {
          endpoint = `${process.env.REACT_APP_RBAC_SERVICE_URL}/superadmin/users`;
        }
        if (endpoint) {
          const response = await axios.get(endpoint);
          if (Array.isArray(response.data.users)) {
            const filteredUsers = response.data.users.filter(user => user.role !== 'SUPER ADMIN');
            setUsers(filteredUsers);
          } else {
            console.error('Invalid data format:', response.data);
          }
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchData();
  }, [userRole]);

  const updateUserRole = async (id, role) => {
    try {
      let updateEndpoint;
      if (userRole === 'ADMIN') {
        updateEndpoint = `${process.env.REACT_APP_RBAC_SERVICE_URL}/admin/update-role`;
        if (role === 'ADMIN') {
          setFlashMessage('You are not authorized to perform this action.');
          return;
        }
      } else if (userRole === 'SUPER ADMIN') {
        updateEndpoint = `${process.env.REACT_APP_RBAC_SERVICE_URL}/superadmin/update-role`;
      }
      if (updateEndpoint) {
        await axios.post(updateEndpoint, {
          id,
          role,
        });
        setFlashMessage(`User role updated to ${role}`);
      }
    } catch (error) {
      console.error('Failed to update user role:', error.message);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setFlashMessage(null);
    }, 5000);

    return () => clearTimeout(timer);
  }, [flashMessage]);

  return (
    <div>
      {authenticated ? (
        <React.Fragment>
          {/* <Header /> */}
          <h1 className="table">Manage Users</h1>
          {flashMessage && <div className="flash-message" style={{ color: 'red' }}>{flashMessage}</div>}
          <table className="table">
            <thead>
              <tr>
                <th>id</th>
                <th>email</th>
                <th>role</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>
                    <a href={`${process.env.REACT_APP_RBAC_SERVICE_URL}/${userRole}/user/${user.id}`}>{user.email}</a>
                  </td>
                  <td>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        updateUserRole(user.id, e.target.role.value);
                      }}
                      className="manage-user-form"
                    >
                      <input type="hidden" name="id" value={user.id} />
                      <select name="role" id="role" defaultValue={user.role}>
                        <option value="ADMIN">Admin</option>
                        <option value="MODERATOR">Moderator</option>
                        <option value="CLIENT">Client</option>
                      </select>
                      <input type="submit" value="update" />
                    </form>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </React.Fragment>
      ) : (
        <Navigate to="/" replace />
      )}
    </div>
  );
};

export default ManageUsers;
