import React from 'react';
import { Table, Avatar, Skeleton } from 'antd';
import PdfIcon from '../../../assets/SVG/Pdf.svg'; // Replace with actual paths

const DeletedTeamDocuments = ({ isLoading, deletedData }) => {
  const renderDocumentIcon = (type) => {
    const icons = { PDF: PdfIcon }; // Add more icons here
    return <img src={icons[type]} alt={type} className="w-6 h-6" />;
  };

  const columns = [
    { title: 'Document Name', dataIndex: 'documentName', key: 'documentName', render: renderDocumentIcon },
    { title: 'Type', dataIndex: 'type', key: 'type' },
    { title: 'Deleted At', dataIndex: 'deletedAt', key: 'deletedAt' },
  ];

  return isLoading ? <Skeleton active /> : <Table columns={columns} />;
};

export default DeletedTeamDocuments;
