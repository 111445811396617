import React, { useEffect, useState } from 'react';
import { ArrowRightOutlined, FlagOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';

const ProfileDetail = ({ profile = {}, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsVisible(true), 10); 
  }, []);

  return (
    <div
      className={`fixed top-16 right-0 h-[calc(100vh-64px)] transform transition-transform duration-500 ${
        isVisible ? 'translate-x-0' : 'translate-x-full'
      } flex flex-col items-center max-w-[480px] bg-[#F5FFFD] p-5 gap-6 shadow-lg overflow-y-auto`} 
      style={{ height: 'calc(100vh - 64px)' }} 
    >
      {/* Close and More Options */}
      <div className="flex justify-between items-center w-full mb-4">
        {/* Left aligned heading */}
        <h1 className="text-2xl font-semibold text-black">User Profile</h1>

        {/* Right aligned arrow icon */}
        <button onClick={onClose} className="text-lg text-black">
          <ArrowRightOutlined />
        </button>
      </div>

      {/* Entire Vertical Card for All Sections */}
      <div className="w-full bg-white p-6 rounded-lg shadow-md flex flex-col gap-8">

        {/* Profile Section */}
        <div className="flex flex-col items-center text-center">
          <div
            className="rounded-full w-[120px] h-[120px] bg-cover bg-center border-4 border-teal-400 mb-4"
            style={{ backgroundImage: `url(${profile?.avatar || 'https://via.placeholder.com/150'})` }} // Fallback to placeholder image
          />
          <h2 className="text-xl font-semibold text-[#192028]">{profile?.name || 'N/A'}</h2>
          <p className="text-gray-500">Department: {profile?.department || 'N/A'}</p>

          {/* Contact Information */}
          <div className="flex justify-center items-center space-x-4 text-sm text-gray-700 mt-4">
            <div className="flex items-center gap-1">
              <MailOutlined className="text-[#4ED2BF]" />
              <span>{profile?.email || 'No Email'}</span>
            </div>
            <div className="flex items-center gap-1">
              <PhoneOutlined className="text-[#4ED2BF]" />
              <span>{profile?.phone || 'No Phone'}</span>
            </div>
          </div>
        </div>

        {/* Horizontal line below contact information */}
        <hr className="w-full border-t border-gray-300 mt-4" />

        {/* Common Teams Section */}
        <div className="w-full">
          <h3 className="text-lg font-semibold text-[#192028]">Common Teams</h3>

          {/* Team List */}
          {['Team Name', 'Team Name', 'Team Name'].map((team, index) => (
            <div key={index}>
              <div className="flex justify-between items-center w-full py-4">
                <div>
                  <p className="text-sm font-semibold text-[#192028]">{team}</p>
                  <p className="text-xs text-gray-500">Created by Sunil.S.N</p>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex -space-x-1">
                    <img
                      className="w-6 h-6 rounded-full"
                      src="https://randomuser.me/api/portraits/women/1.jpg"
                      alt="Avatar"
                    />
                    <span className="bg-[#EA4157] text-white rounded-full w-6 h-6 flex items-center justify-center">A</span>
                    <span className="bg-[#EEFBD9] text-[#ABEA41] rounded-full w-6 h-6 flex items-center justify-center">A</span>
                    <span className="bg-gray-200 text-[#192028] rounded-full w-6 h-6 flex items-center justify-center">+15</span>
                  </div>
                </div>
              </div>

              {/* Line Divider */}
              {index < 2 && <hr className="border-t border-gray-200 w-full" />}
            </div>
          ))}
        </div>

        {/* Tasks Section */}
        <div className="w-full">
          <h3 className="text-lg font-semibold text-[#192028]">Tasks</h3>
          {/* Task List */}
          {Array(3)
            .fill('Task')
            .map((task, index) => (
              <div
                key={index}
                className="flex justify-between items-center w-full p-4 bg-[#F5FFFD] rounded-lg shadow-sm mt-4 border border-gray-200"
                style={{ borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px', borderLeft: '4px solid #7DE2D1' }}
              >
                <div className="flex flex-col">
                  {/* Task Name and Due Date on Same Line */}
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-semibold text-[#192028]">Task</p>
                    <span className="text-xs text-red-600 border border-red-600 px-2 py-1 rounded-full inline-block">
                      Due In 6 hours
                    </span>
                  </div>

                  {/* Task Details Below */}
                  <p className="text-xs text-gray-500 mt-1">Task Detail's</p>
                </div>

                {/* Icon */}
                <div className="flex items-center justify-center w-8 h-8 bg-white border border-gray-300 rounded-full shadow-lg">
                  <FlagOutlined className="text-[#00A2FF]" />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProfileDetail;