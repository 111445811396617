import React, { useState, useEffect, useCallback, useRef } from "react";
import { Modal, List, message, Spin } from "antd";
import axios from "axios";
import { useAuth } from "../../authcontext";

const FolderSelectionModal = ({ isVisible, handleCancel, onFolderSelect }) => {
  const { userId } = useAuth();
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(0); // Page state for pagination
  const [hasMore, setHasMore] = useState(true); // Flag to check if more folders are available
  const observer = useRef(); // Ref for observing last element in the list

  // Fetch folders with pagination
  const fetchFolders = async (currentPage = 0, reset = false) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/folder/?userId=${userId}&page=${currentPage}`
      );
      const newFolders = response.data.data.folders || [];

      if (reset) {
        setFolders(newFolders); // Reset folder list for first load or reset
      } else {
        setFolders((prevFolders) => [...prevFolders, ...newFolders]); // Append new folders for infinite scroll
      }

      setHasMore(newFolders.length > 0); // Check if there are more folders
      setLoading(false);
      setIsLoadingMore(false);
    } catch (error) {
      message.error("Failed to load folders");
      setLoading(false);
      setIsLoadingMore(false);
    }
  };

  // Fetch folders on modal open
  useEffect(() => {
    if (isVisible) {
      setLoading(true);
      setPage(0); // Reset the page number
      fetchFolders(0, true); // Initial fetch
    }
  }, [isVisible, userId]);

  // Fetch more folders on scroll
  useEffect(() => {
    if (page > 0) {
      fetchFolders(page);
    }
  }, [page]);

  // Infinite scroll logic: Ref logic for the last folder element
  const lastFolderElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setIsLoadingMore(true);
          setPage((prevPage) => prevPage + 1); // Increase the page number to fetch more
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoadingMore, hasMore]
  );

  return (
    <Modal
      title="Select a Folder"
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
    >
      {loading ? (
        <Spin tip="Loading folders..." />
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={folders}
          renderItem={(folder, index) => (
            <List.Item
              key={folder._id}
              onClick={() => onFolderSelect(folder._id)}
              ref={folders.length === index + 1 ? lastFolderElementRef : null} // Attach ref to the last folder
              style={{ cursor: "pointer" }}
            >
              <List.Item.Meta
                title={folder.name}
                description={`Contains ${folder.items} files`}
              />
            </List.Item>
          )}
        />
      )}

      {/* Show loading spinner when fetching more data */}
      {isLoadingMore && <Spin tip="Loading more folders..." />}
    </Modal>
  );
};

export default FolderSelectionModal;
