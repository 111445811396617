// import React, { useEffect, useState, useRef } from "react";
// import Ongoing from "../Components/Todo/Ongoing";
// import Completed from "../Components/Todo/Completed";
// import { useAuth } from "../authcontext";
// import axios from "axios";
// import {
//   FilterOutlined,
//   DownOutlined,
//   FileOutlined,
//   TeamOutlined,
// } from "@ant-design/icons";
// import { message } from "antd";
// import axiosDocInstance from "../ApiServices/AxiosDocInstance";

// const Todo = () => {
//   const { userId, userRole } = useAuth(); // Fetch userId and role from auth context
//   const [userTeams, setUserTeams] = useState([]); 
//   const [ongoingTasks, setOngoingTasks] = useState([]); // Ongoing tasks
//   const [completedTasks, setCompletedTasks] = useState([]); // Completed tasks
//   const [ongoingPage, setOngoingPage] = useState(0); // Page tracking for ongoing tasks
//   const [completedPage, setCompletedPage] = useState(0); // Page tracking for completed tasks
//   const [moreOngoingToCome, setMoreOngoingToCome] = useState(true); // Track if more ongoing tasks exist
//   const [moreCompletedToCome, setMoreCompletedToCome] = useState(true); // Track if more completed tasks exist

//   const [assigner, setAssigner] = useState(""); // Filter by Assigner (userId)
//   const [assignee, setAssignee] = useState(""); // Filter by Assignee (userId)
//   const [team, setTeam] = useState(""); // Filter by Team
//   const [onlySelf, setOnlySelf] = useState(false); // Self-assigned filter

//   const [availableUsers, setAvailableUsers] = useState([]); // Store users for assigner/assignee
//   const [showDropdown, setShowDropdown] = useState(false); // Controls the dropdown visibility
//   const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 }); // Position of the dropdown
//   const filterButtonRef = useRef(null); // Ref for the filter button
//   const dropdownRef = useRef(null); // Ref for the dropdown menu
//   const [refreshOngoing, setRefreshOngoing] = useState(false);
//   const [refreshCompleted, setRefreshCompleted] = useState(false);


//   // Toggle the dropdown and set its position relative to the filter button
//   const toggleDropdown = () => {
//     if (!showDropdown && filterButtonRef.current) {
//       const buttonRect = filterButtonRef.current.getBoundingClientRect();
//       const dropdownWidth = 199;
//       setDropdownPosition({
//         top: buttonRect.bottom + window.scrollY,
//         left: buttonRect.right - dropdownWidth + window.scrollX,
//       });
//     }
//     setShowDropdown(!showDropdown);
//   };

//   // Close the dropdown when clicking outside of it
//   const handleClickOutside = (event) => {
//     if (
//       dropdownRef.current &&
//       !dropdownRef.current.contains(event.target) &&
//       !filterButtonRef.current.contains(event.target)
//     ) {
//       setShowDropdown(false);
//     }
//   };


//     // Fetch user's teams
//     const fetchUserTeams = async () => {
//       try {
//         const response = await axiosDocInstance.get(
//           `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/names`
//         );

//         setUserTeams(response.data.data.teams);
//         console.log(response);
//       } catch (error) {
//         console.error("Error fetching user teams:", error);
//         message.error("Failed to fetch user teams.");
//       }
//     };


//   // Effect to handle the dropdown's click outside
//   useEffect(() => {
//     if (showDropdown) {
//       document.addEventListener("mousedown", handleClickOutside);
//     } else {
//       document.removeEventListener("mousedown", handleClickOutside);
//     }
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [showDropdown]);

//   // Function to build query parameters based on selected filters
//   const buildQueryParams = () => {
//     const queryParams = {};
//     // if (userId) queryParams.userId = userId;
//     if (assigner) queryParams.assigner = assigner; // Pass assigner userId
//     if (assignee) queryParams.assignee = assignee; // Pass assignee userId
//     if (team) queryParams.team = team; // Team filter
//     if (onlySelf) queryParams.onlySelf = "true"; // Self-assigned filter
//     return new URLSearchParams(queryParams).toString();
//   };

//   // Function to fetch ongoing tasks
//   const fetchOngoingTasks = async (reset = false, page = 0) => {
//     try {
//       const queryParams = buildQueryParams();
//       const url =
//         page === 0
//           ? `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks?${queryParams}`
//           : `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks?${queryParams}&page=${page}`;

//       const response = await axiosDocInstance.get(url);

//       // Ensure we have an array of tasks
//       const tasksArray = response.data.data.tasks || [];
//       // If reset is true, replace tasks; otherwise, append
//       setOngoingTasks(reset ? tasksArray : (prev) => [...prev, ...tasksArray]);
//       setMoreOngoingToCome(response.data.data.moreToCome); // Update more ongoing tasks state
//     } catch (error) {
//       console.error("Error fetching ongoing tasks:", error);
//       message.error("Failed to fetch ongoing tasks.");
//     }
//   };

//   // Function to fetch completed tasks
//   const fetchCompletedTasks = async (reset = false, page = 0) => {
//     try {
//       const queryParams = buildQueryParams();
//       const url =
//         page === 0
//           ? `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks?${queryParams}&completed=true`
//           : `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks?${queryParams}&completed=true&page=${page}`;

//       const response = await axios.get(url);

//       // Ensure we have an array of tasks
//       const tasksArray = response.data.data.tasks || [];
//       // If reset is true, replace tasks; otherwise, append
//       setCompletedTasks(
//         reset ? tasksArray : (prev) => [...prev, ...tasksArray]
//       );
//       setMoreCompletedToCome(response.data.data.moreToCome); // Update more completed tasks state
//     } catch (error) {
//       console.error("Error fetching completed tasks:", error);
//       message.error("Failed to fetch completed tasks.");
//     }
//   };

//   // Fetch tasks when component mounts or refreshOngoing changes
//   useEffect(() => {
//     fetchOngoingTasks(true, 0);
//   }, [refreshOngoing]);

//   useEffect(() => {
//     fetchCompletedTasks(true, 0);
//   }, [refreshCompleted]);


//   useEffect(() => {
//     fetchUserTeams();
//   }, [userRole]);


// // Update dropdown options dynamically based on selection
// useEffect(() => {
//   const teamData = userTeams.find((t) => t._id === team);
//   if (teamData) {
//     const members = teamData.members.filter((m) => m.name); // Exclude null names
//     setAvailableUsers(members.map((m) => ({ id: m._id, name: m.name })));
//   } else if (onlySelf) {
//     setAvailableUsers([{ id: userId, name: "Self" }]); // Only "Self" option
//   } else {
//     setAvailableUsers([]);
//   }
// }, [team, onlySelf, userTeams]);

// // Reset dropdown states on self-assigned filter
// const handleSelfAssigned = () => {
//   setAssigner(""); // Reset assigner filter
//   setAssignee(userId); // Default to current user for assignee
//   setTeam(""); // Clear team selection
//   setOnlySelf(true); // Enable self-assigned mode
// };

// const handleTeamAssigned = () => {
//   setOnlySelf(false);
//   setTeam(""); // Clear any existing team selection
// };

//   // Fetch tasks when filters change
//   useEffect(() => {
//     // Reset tasks, pages, and fetch new data when filters change
//     setOngoingTasks([]);
//     setCompletedTasks([]);
//     setOngoingPage(0);
//     setCompletedPage(0);
//     fetchOngoingTasks(true, 0); // Fetch fresh ongoing tasks on filter change
//     fetchCompletedTasks(true, 0); // Fetch fresh completed tasks on filter change
//   }, [assigner, assignee, team, onlySelf, userId]);

//   // Load next page for ongoing tasks when user scrolls to bottom
//   const fetchNextOngoingPage = () => {
//     if (moreOngoingToCome) {
//       const nextPage = ongoingPage + 1;
//       setOngoingPage(nextPage);
//       fetchOngoingTasks(false, nextPage);
//     }
//   };

//   // Load next page for completed tasks when user scrolls to bottom
//   const fetchNextCompletedPage = () => {
//     if (moreCompletedToCome) {
//       const nextPage = completedPage + 1;
//       setCompletedPage(nextPage);
//       fetchCompletedTasks(false, nextPage);
//     }
//   };

//   // Handlers for Refresh
//   const triggerRefreshOngoing = () => {
//     setRefreshOngoing((prev) => !prev);
//   };

//   const triggerRefreshCompleted = () => {
//     setRefreshCompleted((prev) => !prev);
//   };

//   return (
//     <>
//       <div>
//         {/* Top section with To-Do List heading and Filter button */}
//         <div className="flex justify-between items-center mb-4 p-4">
//           <h1 className="text-[20px] font-semibold leading-[28px] text-[#192028]">
//             To-Do List
//           </h1>

//           {/* Filter Button */}
//           <button
//             ref={filterButtonRef}
//             className="relative z-10 flex items-center border border-[#D9DEE7] rounded-md text-[#1B2028] bg-white h-[40px] w-[150px] justify-center"
//             onClick={toggleDropdown}
//             aria-haspopup="true"
//             aria-expanded={showDropdown}
//           >
//             <span className="flex-grow text-center pl-4">Filter</span>
//             <span className="flex items-center justify-center border border-[#41EAD4] rounded-md h-[40px] w-[40px] bg-white">
//               <FilterOutlined className="text-[#41EAD4] text-[14px]" />
//             </span>
//           </button>
//         </div>

//         {/* Filters Section */}
//         <div className="flex items-center justify-between p-4 max-w-[1130px] max-h-[1124px]">
//           {/* Task Name Styled Box */}
//           <div className="flex items-center px-4 py-2 w-[250px] bg-transparent">
//             <span className="text-[#192028] text-[16px]">Task Name</span>
//           </div>

//           <div className="flex space-x-6 justify-center flex-grow">
//           <FilterSelect
//           label="Assigner"
//           value={assigner}
//           onChange={(e) => setAssigner(e.target.value)}
//           options={[
//             { id: "", name: "Self" },
//             ...availableUsers, // Dynamic options
//           ]}
//         />
//         <FilterSelect
//           label="Assignee"
//           value={assignee}
//           onChange={(e) => setAssignee(e.target.value)}
//           options={[
//             { id: userId, name: "Self" }, // Default to "Self"
//             ...availableUsers,
//           ]}
//         />
//         {!onlySelf && (
//           <FilterSelect
//             label="Team"
//             value={team}
//             onChange={(e) => setTeam(e.target.value)}
//             options={userTeams.map((t) => ({ id: t.id, name: t.name }))}
//           />
//         )}
//           </div>

//           {/* Empty space for proper centering */}
//           <div className="flex items-center px-4 py-2 w-[250px]" />
//         </div>

//         {/* Horizontal line */}
//         <hr className="border-t border-[#E0E0E0] mb-4" />

//         {/* Dropdown for Filter Options */}
//         {showDropdown && (
//           <div
//             ref={dropdownRef}
//             className="absolute z-50 flex flex-col p-1 rounded-lg bg-white shadow-lg w-[199px]"
//             style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
//           >
//             <DropdownItem
//               icon={FileOutlined}
//               label="Self Assigned"
//               onClick={handleSelfAssigned}
//             />
//             <DropdownItem
//               icon={TeamOutlined}
//               label="Team Assigned"
//               onClick={handleTeamAssigned}
//             />
//           </div>
//         )}
//       </div>

//       <div className="p-5">
//         <div className="flex flex-col">
//           {/* Ongoing tasks */}
//           <div className="mb-10 h-3/5">
//             <Ongoing
//               tasks={ongoingTasks.map((task) => ({
//                 _id: task._id,
//                 name: task.name,
//                 description: task.description,
//                 dueDate: task.dueDate,
//                 priority: task.priority,
//                 assigner: task.assigner?.name || "Unassigned",
//                 assignee: task.assignee?.name || "Unassigned",
//                 team: task.team?.name || "Unassigned",
//               }))}
//               setTasks={setOngoingTasks}
//               fetchNextPage={fetchNextOngoingPage}
//               ongoingRefresh={refreshOngoing}
//               triggerCompletedRefresh={triggerRefreshCompleted}
//             />
//           </div>

//           {/* Completed tasks */}
//           <div className="h-2/5">
//             <Completed
//               tasks={completedTasks.map((task) => ({
//                 _id: task._id,
//                 name: task.name,
//                 description: task.description,
//                 dueDate: task.dueDate,
//                 priority: task.priority,
//                 assigner: task.assigner?.name || "Unassigned",
//                 assignee: task.assignee?.name || "Unassigned",
//                 team: task.team?.name || "Unassigned",
//               }))}
//               setTasks={setCompletedTasks}
//               fetchNextPage={fetchNextCompletedPage}
//               completedrefresh={refreshCompleted}
//               triggerOngoingRefresh={triggerRefreshOngoing}
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// const parseTeams = (data) => {
//   return data.teams.map((team) => ({
//     id: team._id,
//     name: team.name,
//     members: team.members.filter((m) => m.name), // Exclude null names
//   }));
// };

// // Utility function to generate dropdown options
// const generateOptions = (list) =>
//   list.map((item) => (
//     <option key={item.id} value={item.id}>
//       {item.name}
//     </option>
//   ));

// // Reusable FilterSelect component
// const FilterSelect = ({ label, value, onChange, options }) => (
//   <div className="relative w-[150px]">
//     <select
//       className="w-full px-4 py-2 border rounded-md bg-white text-black appearance-none"
//       value={value}
//       onChange={onChange}
//     >
//       <option value="">{label}</option>
//       {options.map((option) => (
//         <option key={option.id} value={option.id}>
//           {option.name}
//         </option>
//       ))}
//     </select>
//     <DownOutlined className="absolute right-2 top-1/2 transform -translate-y-1/2" />
//   </div>
// );

// // Reusable DropdownItem component
// const DropdownItem = ({ icon: Icon, label, onClick }) => (
//   <div
//     className="flex items-center px-4 py-2 hover:bg-[#D1F8F0] text-[#1B2028] rounded-lg cursor-pointer"
//     onClick={onClick}
//   >
//     <Icon className="text-[#798897] mr-2" />
//     <span>{label}</span>
//   </div>
// );

// export default Todo;











import React, { useEffect, useState } from "react";
import Ongoing from "../Components/Todo/Ongoing";
import Completed from "../Components/Todo/Completed";
import TodoHeader from "../Components/Todo/TodoHeader"; // Import the extracted TodoHeader
import { useAuth } from "../authcontext";
import { message } from "antd";
import axiosDocInstance from "../ApiServices/AxiosDocInstance";
import AddTaskModal from "../Services/Modal/AddTaskModal";
import EditTaskModal from "../Services/EditModal/EditTaskModal";


const Todo = () => {
  const { userId, userRole } = useAuth(); // Fetch userId and role from auth context
  const [userTeams, setUserTeams] = useState([]);
  const [ongoingTasks, setOngoingTasks] = useState([]); // Ongoing tasks
  const [completedTasks, setCompletedTasks] = useState([]); // Completed tasks
  const [ongoingPage, setOngoingPage] = useState(0); // Page tracking for ongoing tasks
  const [completedPage, setCompletedPage] = useState(0); // Page tracking for completed tasks
  const [moreOngoingToCome, setMoreOngoingToCome] = useState(true); // Track if more ongoing tasks exist
  const [moreCompletedToCome, setMoreCompletedToCome] = useState(true); // Track if more completed tasks exist
  const [assigneeList, setAssigneeList] = useState([]);
  const [assignerList, setAssignerList] = useState([]);

  const [assigner, setAssigner] = useState(""); // Filter by Assigner (userId)
  const [assignee, setAssignee] = useState(userId || ""); 
  const [team, setTeam] = useState(""); // Filter by Team
  const [onlySelf, setOnlySelf] = useState(null); // Self-assigned filter

  const [availableUsers, setAvailableUsers] = useState([]); // Store users for assigner/assignee
  const [refreshOngoing, setRefreshOngoing] = useState(false);
  const [refreshCompleted, setRefreshCompleted] = useState(false);
  const [isAddTaskModalVisible, setAddTaskModalVisible] = useState(false);
  const [taskCreated, setTaskCreated] = useState(false);
  const [taskUpdated, setTaskUpdated] = useState(false);
  const [isEditTaskModalVisible, setEditTaskModalVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null); // Task to edit

  const [currentTeams, setCurrentTeams] = useState([]); // Store teams data
  const [page, setPage] = useState(0); // Current page
  const [moreToCome, setMoreToCome] = useState(true); // Track if more pages exist
  const [isLoading, setIsLoading] = useState(false); // Track loading state


  const [teamNamePage, setTeamNamePage] = useState(1);
  const [teamNameIsMore, setTeamNameIsMore] = useState(false);

  const handleTaskCreated = () => {
    console.log("Task created, toggling taskCreated state");
    setTaskCreated((prev) => !prev); // Toggle taskCreated to trigger refresh
  };

  const handleTaskUpdated = () => {
    setTaskUpdated((prev) => !prev);
  };

  console.log(userTeams);
  console.log({ assigneeList });

  const fetchUserTeams = async () => {
    try {
      const response = await axiosDocInstance.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/names?page=0`
      );
      const teams = response.data.data.teams;
      setUserTeams(teams);

      // Extract unique members across all teams
      const members = teams.flatMap((team) => team.members || []);
      // console.log(members);

      const uniqueMembers = Array.from(
        new Map(members.map((m) => [m._id, m])).values() // Ensure unique members by ID
      );
      // console.log({uniqueMembers});
      response.data?.data?.moreToCome && setTeamNameIsMore(response.data.data.moreToCome)
      
      setAssigneeList(uniqueMembers);
      setAssignerList(uniqueMembers);
    } catch (error) {
      console.error("Error fetching user teams:", error);
      message.error("Failed to fetch user teams.");
    }
  };

  const fetchMoreUserTeams = async () => {
    if (!teamNameIsMore) return;
  
    try {
      const response = await axiosDocInstance.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/names?page=${teamNamePage}`
      );
      const teams = response.data.data.teams;
  
      setUserTeams((prevTeams) => [...prevTeams, ...teams]); // Append new teams
  
      const members = teams.flatMap((team) => team.members || []);
      const uniqueMembers = Array.from(
        new Map(members.map((m) => [m._id, m])).values()
      );
  
      setAssigneeList((prevAssigneeList) => [
        ...prevAssigneeList,
        ...uniqueMembers,
      ]);
      setAssignerList((prevAssignerList) => [
        ...prevAssignerList,
        ...uniqueMembers,
      ]);
  
      if (response.data?.data?.moreToCome) {
        setTeamNamePage((prevPage) => prevPage + 1);
      } else {
        setTeamNameIsMore(false); // No more teams to fetch
      }
    } catch (error) {
      console.error("Error fetching user teams:", error);
      message.error("Failed to fetch more teams.");
    }
  };
  




  // const fetchUserTeams = async (pageToFetch = 0) => {
  //   if (!moreToCome || isLoading) return; // Exit if no more data or loading

  //   setIsLoading(true); // Set loading state
  //   try {
  //     // Use `pageToFetch` only if it's greater than 0
  //     const url =
  //       pageToFetch > 0
  //         ? `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/names?page=${pageToFetch}`
  //         : `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/names`;

  //     const response = await axiosDocInstance.get(url);
  //     const teams = response.data.data?.teams || [];
  //     const hasMore = response.data.data?.moreToCome;

  //     // Update state
  //     setCurrentTeams((prevTeams) => [...prevTeams, ...teams]);
  //     setUserTeams((prevTeams) => [...prevTeams, ...teams]);
  //     setMoreToCome(hasMore);
  //     setPage(pageToFetch); // Update the current page
  //   } catch (error) {
  //     console.error("Error fetching teams:", error);
  //     message.error("Failed to fetch teams.");
  //   } finally {
  //     setIsLoading(false); // Reset loading state
  //   }
  // };



  // Fetch tasks when filters change
  useEffect(() => {
    // Reset tasks and pages when filters change
    setOngoingTasks([]);
    setCompletedTasks([]);
    setOngoingPage(0);
    setCompletedPage(0);

    fetchOngoingTasks(true, 0); // Fetch fresh ongoing tasks
    fetchCompletedTasks(true, 0); // Fetch fresh completed tasks
  }, [assigner, assignee, team, onlySelf, taskCreated, taskUpdated]);



  const fetchOngoingTasks = async (reset = false, page = 0) => {
    try {
      const queryParams = buildQueryParams();
      const url =
      page <= 0
          ? `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks?${queryParams}`
          : `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks?${queryParams}${page > 0 ? `&page=${page}` : ""
          }`;

      const response = await axiosDocInstance.get(url);

      const tasksArray = response.data.data.tasks || [];
      setOngoingTasks((prev) => (reset ? tasksArray : [...prev, ...tasksArray]));
      setMoreOngoingToCome(response.data.data.moreToCome);
      setOngoingPage(page);
   // Update more ongoing tasks state
    } catch (error) {
      console.error("Error fetching ongoing tasks:", error);
      message.error("Failed to fetch ongoing tasks.");
    }
  };


  // Fetch completed tasks
  const fetchCompletedTasks = async (reset = false, page = 0) => {
    try {
      const queryParams = buildQueryParams();
      const url =
        page === 0
          ? `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks?${queryParams}&completed=true`
          : `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks?${queryParams}&completed=true&page=${page}`;

      const response = await axiosDocInstance.get(url);

      const tasksArray = response.data.data.tasks || [];
      setCompletedTasks((prev) => (reset ? tasksArray : [...prev, ...tasksArray]));
      setMoreCompletedToCome(response.data.data.moreToCome);
      setCompletedPage(page)
       // Update more completed tasks state
    } catch (error) {
      console.error("Error fetching completed tasks:", error);
      message.error("Failed to fetch completed tasks.");
    }
  };





  const buildQueryParams = () => {
    const queryParams = {};
    if (onlySelf =='teamFilter') {
      queryParams.teamFilter = "true"; 
    } 
    else if (onlySelf =='onlySelf') {
      queryParams.onlySelf = "true"; 
    }
    else if (team) {
      queryParams.team = team; // Apply "Team" filter with team ID
    }
    if (assigner) queryParams.assigner = assigner; // Apply assigner filter
    if (assignee) queryParams.assignee = assignee; // Apply assignee filter
    return new URLSearchParams(queryParams).toString();
  };







  // Fetch user teams when component mounts or userRole changes
  useEffect(() => {
    console.log('fetchUserTeams useEffect', {});
    if (userRole) {
      fetchUserTeams();
    }
  }, [userRole]);

  // Fetch ongoing and completed tasks on refresh triggers
  // useEffect(() => {
  //   fetchOngoingTasks(true, 0);
  // }, [refreshOngoing]);

  // useEffect(() => {
  //   fetchCompletedTasks(true, 0);
  // }, [refreshCompleted]);

  // Load next page for ongoing tasks when user scrolls to bottom
  const fetchNextOngoingPage = () => {
    if (moreOngoingToCome) {
      const nextPage = ongoingPage + 1;
      setOngoingPage(nextPage);
      fetchOngoingTasks(false, nextPage);
    }
  };

  // Load next page for completed tasks when user scrolls to bottom
  const fetchNextCompletedPage = () => {
    if (moreCompletedToCome) {
      const nextPage = completedPage + 1;
      setCompletedPage(nextPage);
      fetchCompletedTasks(false, nextPage);
    }
  };

  // Handlers for Refresh
  const triggerRefreshOngoing = () => {
    // setRefreshOngoing((prev) => !prev);
    fetchOngoingTasks(true, 0);

  };

  const triggerRefreshCompleted = () => {
    // setRefreshCompleted((prev) => !prev);
    fetchCompletedTasks(true, 0);
  };

  return (
    <>
      <TodoHeader
        assigner={assigner}
        setAssigner={setAssigner}
        assignee={assignee}
        setAssignee={setAssignee}
        team={team}
        setTeam={setTeam}
        onlySelf={onlySelf}
        setOnlySelf={setOnlySelf}
        userTeams={userTeams}
        availableUsers={availableUsers}
        setAvailableUsers={setAvailableUsers}
        userId={userId}
        assigneeList={assigneeList}
        setAssigneeList={setAssigneeList} // Ensure this is included
        assignerList={assignerList}
        setAssignerList={setAssignerList} // Ensure this is included
        fetchMoreUserTeams={fetchMoreUserTeams}

      />

      <AddTaskModal
        isVisible={isAddTaskModalVisible}
        handleCancel={() => setAddTaskModalVisible(false)}
        onTaskCreated={handleTaskCreated} // Pass task created handler
      />

      <EditTaskModal
        visible={isEditTaskModalVisible}
        onClose={() => setEditTaskModalVisible(false)}
        task={selectedTask}
        onTaskUpdated={handleTaskUpdated}
      />

      <div className="p-5">
        <div className="flex flex-col">
          {/* Ongoing tasks */}
          <div className="mb-10 h-3/5">
            <Ongoing
              tasks={ongoingTasks.map((task) => ({
                _id: task?._id,
                name: task?.name,
                description: task?.description,
                dueDate: task?.dueDate,
                priority: task?.priority,
                assigner: task?.assigner?.name || "Unassigned",
                assigner_id: task?.assigner?._id || null,
                assignee: task?.assignee?.name || "Unassigned",
                assignee_id: task?.assignee?._id || null,
                user_id: userId,
                team: task.team?.name || "Unassigned",
                team_id: task.team?._id || null,
              }))}
              setTasks={setOngoingTasks}
              fetchNextPage={fetchNextOngoingPage}
              ongoingRefresh={refreshOngoing}
              triggerCompletedRefresh={triggerRefreshCompleted}
              onTaskCreated={handleTaskCreated}
            // onEditTask={(task) => {
            //   if (task && task?._id) { // Check task validity before proceeding
            //     setSelectedTask(task);
            //     setEditTaskModalVisible(true);
            //   } else {
            //     console.error("Invalid task object:", task);
            //   }
            // }}
            />
          </div>

          {/* Completed tasks */}
          <div className="h-2/5">
            <Completed
              tasks={completedTasks.map((task) => ({
                _id: task?._id,
                name: task?.name,
                description: task?.description,
                dueDate: task?.dueDate,
                priority: task?.priority,
                assigner: task?.assigner?.name || "Unassigned",
                assigner_id: task?.assigner?._id || null,
                assignee: task?.assignee?.name || "Unassigned",
                assignee_id: task?.assignee?._id || null,
                user_id: userId,
                team: task.team?.name || "Unassigned",
              }))}
              setTasks={setCompletedTasks}
              fetchNextPage={fetchNextCompletedPage}
              completedrefresh={refreshCompleted}
              triggerOngoingRefresh={triggerRefreshOngoing}
            // onEditTask={(task) => {
            //   if (task && task?._id) { // Check task validity before proceeding
            //     setSelectedTask(task);
            //     setEditTaskModalVisible(true);
            //   } else {
            //     console.error("Invalid task object:", task);
            //   }
            // }}
            />
          </div>

        </div>
      </div>


    </>
  );
};

export default Todo;
