// import React, { useEffect, useState } from "react";
// import VaultAccess from '../Components/Vault/Pin/VaultAccess';
// import CreatePin from "../Components/Vault/Pin/CreatePin";

// const Vault = () => {
//   const [isPinSet, setIsPinSet] = useState(0);

//   useEffect(() => {
//     const fetchVaultStatus = async () => {
//       try {
//         const token = localStorage.getItem('sessionToken');
//         const response = await fetch('https://auth-comm-service.onrender.com/api/v1/vault/status', {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         const data = await response.json();
//         if(data.data.message === "vault-pin-not-set")
//         {
//           setIsPinSet(1);
//         }
//         else{
//           setIsPinSet(0);
//         }
//       } catch (error) {
//         console.error("Error fetching vault status:", error);
//       }
//     };

//     fetchVaultStatus();
//   }, []);

//   return (
//     <div>
//       {isPinSet ?  <CreatePin /> : <VaultAccess />}
//     </div>
//   );
// };

// export default Vault;















import React, { useEffect, useState } from "react";
import VaultAccess from "../Components/Vault/Pin/VaultAccess";
import CreatePin from "../Components/Vault/Pin/CreatePin";

const Vault = () => {
  const [isPinSet, setIsPinSet] = useState(null); // null for loading state

  useEffect(() => {
    const fetchVaultStatus = async () => {
      try {
        const token = localStorage.getItem("sessionToken");
        const response = await fetch(
          `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/vault/status`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (data.data.message === "vault-pin-not-set") {
          setIsPinSet(1); // Pin not set, show CreatePin
        } else {
          setIsPinSet(0); // Pin set, show VaultAccess
        }
      } catch (error) {
        console.error("Error fetching vault status:", error);
        setIsPinSet(false); // Handle error case gracefully
      }
    };

    fetchVaultStatus();
  }, []);

  if (isPinSet === null) {
    // Show loading state while waiting for backend response
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <div className="flex flex-col items-center gap-6 w-[428px] p-[60px] rounded-lg bg-white shadow-[0px_0px_4px_0px_rgba(0,0,0,0.10)]">
          {/* Header Bar */}
          <div className="w-full h-8 bg-gray-300 rounded"></div>

          {/* Profile Image Placeholder */}
          <div className="w-16 h-16 bg-gray-200 rounded-full"></div>

          {/* Subtitle Placeholder */}
          <div className="w-3/4 h-6 bg-gray-200 rounded"></div>

          {/* First Row of Boxes */}
          <div className="w-full flex justify-between gap-4">
            <div className="w-[14%] h-12 bg-gray-200 rounded"></div>
            <div className="w-[14%] h-12 bg-gray-200 rounded"></div>
            <div className="w-[14%] h-12 bg-gray-200 rounded"></div>
            <div className="w-[14%] h-12 bg-gray-200 rounded"></div>
          </div>

          {/* Second Subtitle */}
          <div className="w-full h-6 bg-gray-300 rounded mt-6"></div>

          {/* Second Row of Boxes */}
          <div className="w-full flex justify-between gap-4">
            <div className="w-[14%] h-12 bg-gray-200 rounded"></div>
            <div className="w-[14%] h-12 bg-gray-200 rounded"></div>
            <div className="w-[14%] h-12 bg-gray-200 rounded"></div>
            <div className="w-[14%] h-12 bg-gray-200 rounded"></div>
          </div>

          {/* Buttons */}
          <div className="w-full flex justify-between gap-4 mt-4">
            <div className="w-[48%] h-12 bg-gray-200 rounded"></div>
            <div className="w-[48%] h-12 bg-gray-400 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {isPinSet === 1 ? <CreatePin /> : <VaultAccess />}
    </div>
  );
};

export default Vault;