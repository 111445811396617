import React from 'react'
import Generate from '../layouts/Generate'

const generate = () => {
  return (
    <div>
        <Generate/>
    </div>
  )
}

export default generate