
import React, { useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import {
  FilterOutlined,
  UploadOutlined,
  FormOutlined,
  TeamOutlined,
  FileProtectOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  PlusOutlined,
  BarsOutlined,
  CalendarOutlined,
  FileOutlined,
  DiffOutlined,
  SortAscendingOutlined
} from '@ant-design/icons';

import AddDocInsideFolderModal from '../../Services/Modal/AddDocInsideFolderModal';
import FolderSelectionModal from '../../Services/Modal/FolderSelectionModal';
import { useAuth } from '../../authcontext';
import AddFolderModal from '../../Services/Modal/AddFolderModal';
import CommonDocHeader from '../../Services/Logic/CommonDocHeader'; // Import the DocHeader component

const FolderHeader = ({
  setSelectedOption,
  setSortOrder,
  setFilter,
  currentSortOption,
  selectedOption,
  sortOptions, // Dynamic sort options
  filterOptions, // Dynamic filter options
}) => {
  const [isAddDocModalVisible, setIsAddDocModalVisible] = useState(false); // Modal for adding documents
  const [isFolderSelectionVisible, setIsFolderSelectionVisible] = useState(false); // Modal for selecting folders
  const [selectedFolderId, setSelectedFolderId] = useState(null); // Selected folder ID
  const { userId } = useAuth(); // Get userId from authentication context
  const [sortOrder, setSortOrderState] = useState(1); // State for sorting order
  const [hoveredItem, setHoveredItem] = useState(null); // State for hovered item in the sort menu
  const [isAddFolderModalVisible, setIsAddFolderModalVisible] = useState(false); // Modal for adding folders

  // Open Add Folder Modal
  const openAddFolderModal = () => {
    setIsAddFolderModalVisible(true);
  };

  // Close Add Folder Modal
  const closeAddFolderModal = () => {
    setIsAddFolderModalVisible(false);
  };

  // Handle folder selection and open document upload modal
  const handleFolderSelect = (folderId) => {
    setSelectedFolderId(folderId);
    setIsFolderSelectionVisible(false);
    setIsAddDocModalVisible(true);
  };

  // Close the Add Document modal
  const closeAddDocModal = () => {
    setIsAddDocModalVisible(false);
  };

  // Handle sort order change
  const handleSortOrderChange = (newOrder, sortOption) => {
    setSortOrderState(newOrder); // Update sort order state
    setSortOrder(newOrder, sortOption); // Update the parent sort order state
  };

  // Filter dropdown menu
  const filterMenu = (
    <Menu>
      {filterOptions.map((option) => (
        <Menu.Item
          key={option.value}
          onClick={() => setFilter(option.value)}
          className="hover:bg-teal-100 transition-all"
          style={{
            backgroundColor: hoveredItem === option.value ? '#D1F8F0' : 'transparent',
            color: hoveredItem === option.value ? '#192028' : '#798897',
            padding: '8px 12px',
          }}
          onMouseEnter={() => setHoveredItem(option.value)}
          onMouseLeave={() => setHoveredItem(null)}
        >
          <div className="flex items-center">
            {option.label === 'Self Uploaded' && <FormOutlined style={{ marginRight: '8px' }} />}
            {option.label === 'Org-wide' && <FileProtectOutlined style={{ marginRight: '8px' }} />}
            {option.label === 'Team' && <TeamOutlined style={{ marginRight: '8px' }} />}
            {option.label}
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  // Sort dropdown menu
  const sortMenu = (
    <Menu
      style={{
        display: 'flex',
        width: '250px',
        padding: '4px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: '8px',
        background: '#FFF',
        boxShadow:
          '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
      }}
    >
      {sortOptions.map((option, index) => (
        <Menu.Item
          key={option.value}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            borderRadius: '4px',
            margin: '4px 0',
            padding: '8px 12px',
            color: hoveredItem === index ? '#1B2028' : '#798897',
            backgroundColor: hoveredItem === index ? '#D1F8F0' : 'transparent',
            transition: 'background-color 0.3s ease, color 0.3s ease',
          }}
          onMouseEnter={() => setHoveredItem(index)}
          onMouseLeave={() => setHoveredItem(null)}
        >
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              {option.label === 'Name' && <SortAscendingOutlined style={{ marginRight: '8px', color: hoveredItem === index ? '#1B2028' : '#798897', width: '16px', height: '16px' }} />}
              {option.label === 'Created at' && <CalendarOutlined style={{ marginRight: '8px', color: hoveredItem === index ? '#1B2028' : '#798897', width: '16px', height: '16px' }} />}
              {option.label === 'Modified on' && <FileOutlined style={{ marginRight: '8px', color: hoveredItem === index ? '#1B2028' : '#798897', width: '16px', height: '16px' }} />}
              {option.label === 'Number of Documents' && <DiffOutlined style={{ marginRight: '8px', color: hoveredItem === index ? '#1B2028' : '#798897', width: '16px', height: '16px' }} />}
              {option.label}
            </div>
            <div className="flex items-center">
              <ArrowUpOutlined
                style={{
                  color: currentSortOption === option.value && sortOrder === 1 ? '#1B2028' : '#798897',
                  marginRight: '4px',
                  cursor: 'pointer',
                }}
                className={currentSortOption === option.value && sortOrder === 1 ? 'text-teal-600' : ''}
                onClick={() => handleSortOrderChange(1, option.value)}
              />
              <ArrowDownOutlined
                style={{
                  color: currentSortOption === option.value && sortOrder === -1 ? '#1B2028' : '#798897',
                  cursor: 'pointer',
                }}
                className={currentSortOption === option.value && sortOrder === -1 ? 'text-teal-600' : ''}
                onClick={() => handleSortOrderChange(-1, option.value)}
              />
            </div>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      {/* Folder Header Layout */}
      <div className="flex justify-between items-center px-6 py-4 w-full h-20 box-border min-w-[1000px]">
        {/* Navigation Tabs */}
        <div className="flex items-center gap-6">
          {/* All Folders Tab */}
          <span
            className={`font-semibold text-lg cursor-pointer ${selectedOption === 'allFolders' ? 'text-[#192028]' : 'text-[#7D8B97]'}`}
            onClick={() => setSelectedOption('allFolders')}
          >
            All Folders
          </span>

          {/* Shared with Me Tab */}
          <span
            className={`font-semibold text-lg cursor-pointer ${selectedOption === 'sharedWithMe' ? 'text-[#192028]' : 'text-[#7D8B97]'}`}
            onClick={() => setSelectedOption('sharedWithMe')}
          >
            Shared with Me
          </span>

          {/* Pinned Documents Tab */}
          <span
            className={`font-semibold text-lg cursor-pointer ${selectedOption === 'pinnedDocument' ? 'text-[#192028]' : 'text-[#7D8B97]'}`}
            onClick={() => setSelectedOption('pinnedDocument')}
          >
            Pinned Documents
          </span>

          {/* Default Tab */}
          <span
            className={`font-semibold text-lg cursor-pointer ${selectedOption === 'default' ? 'text-[#192028]' : 'text-[#7D8B97]'}`}
            onClick={() => setSelectedOption('default')}
          >
            Default
          </span>
        </div>

        {/* Action Buttons or DocHeader */}
        <div className="flex items-center gap-6">
          {/* Render DocHeader only for 'default' option */}
          {selectedOption === 'default' || selectedOption === 'pinnedDocument'? (
             <CommonDocHeader/>
          ) : (
            <>
              {/* Filter Button */}
              <Dropdown overlay={filterMenu}>
                <Button
                  className="border rounded-md flex justify-between items-center px-4 py-2"
                  style={{
                    borderColor: '#D9D9D9',
                    borderRadius: '8px',
                    color: '#192028',
                    padding: '0',
                    height: '40px',
                    maxWidth: '150px',
                  }}
                >
                  <span style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '16px' }}>Filter</span>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #41EAD4',
                      borderRadius: '4px',
                      height: '40px',
                      width: '40px',
                    }}
                  >
                    <FilterOutlined style={{ color: '#41EAD4', fontSize: '16px' }} />
                  </span>
                </Button>
              </Dropdown>

              {/* Sort By Button */}
              <Dropdown overlay={sortMenu}>
                <Button
                  className="flex items-center justify-between rounded-lg border"
                  style={{
                    backgroundColor: '#FFF',
                    borderColor: '#D9D9D9',
                    color: '#192028',
                    borderRadius: '8px',
                    padding: '0',
                    height: '40px',
                    maxWidth: '150px',
                  }}
                >
                  <span style={{ flexGrow: 1, textAlign: 'left', paddingLeft: '16px' }}>Sort By</span>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #41EAD4',
                      borderRadius: '4px',
                      height: '40px',
                      width: '40px',
                    }}
                  >
                    <BarsOutlined style={{ color: '#41EAD4', fontSize: '16px' }} />
                  </span>
                </Button>
              </Dropdown>

              {/* Conditionally render Folder and Upload Document buttons based on selectedOption */}
              {selectedOption !== 'default' && (
                <>
                  {/* Add Folder Button */}
                  <Button
                    icon={<PlusOutlined />}
                    className="flex items-center gap-2"
                    onClick={openAddFolderModal}
                    style={{
                      borderRadius: '8px',
                      border: '1px solid #FF6F61',
                      backgroundColor: '#FFF',
                      color: '#FF6F61',
                      padding: '0px 16px',
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    Folder
                  </Button>

                  {/* Upload Document Button */}
                  <Button
                    icon={<UploadOutlined />}
                    className="flex items-center gap-2"
                    onClick={() => setIsFolderSelectionVisible(true)}
                    style={{
                      borderRadius: '8px',
                      border: '1px solid #FF6F61',
                      backgroundColor: '#FF6F61',
                      color: '#FFFFFF',
                      padding: '0px 16px',
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    Upload Document
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {/* Folder selection and document modals */}
      <FolderSelectionModal
        isVisible={isFolderSelectionVisible}
        handleCancel={() => setIsFolderSelectionVisible(false)}
        onFolderSelect={handleFolderSelect}
      />

      <AddDocInsideFolderModal
        isVisible={isAddDocModalVisible}
        handleCancel={closeAddDocModal}
        userId={userId}
        folderId={selectedFolderId}
      />

      {/* Add Folder Modal */}
      <AddFolderModal
        isVisible={isAddFolderModalVisible}
        userId={userId}
        handleCancel={closeAddFolderModal}
      />
    </>
  );
};

export default FolderHeader;




























