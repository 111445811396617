import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Select, MenuItem, makeStyles } from '@material-ui/core';
import { List as ListIcon, ViewModule as GridIcon, FilterList as FilterListIcon, Sort as SortIcon, CalendarToday as CalendarTodayIcon } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  select: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  fileRow: {
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid rgba(9, 30, 66, 0.14)',
  },
  clickableFileName: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: 'darkblue',
    },
  },
}));

const RecentlyViewed = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [showSortOptions, setShowSortOptions] = useState(false);

  const files = [
    { id: '123', name: 'Project Alpha', startDate: '2023-09-10', deadline: '2023-12-10' },
    { id: '456', name: 'Project Beta', startDate: '2023-10-05', deadline: '2024-01-05' },
  ];

  const handleFileClick = (docId) => {
    navigate(`/documents/${docId}/versions`);
  };

  return (
    <div>
      {/* Horizontal Divider */}
      <div style={{ width: '100%', height: '1px', border: '1px solid rgba(9, 30, 66, 0.14)' }}></div>

      {/* Header Section */}
      <div className="flex items-center justify-between p-4 rounded-lg mb-4">
        <div className="flex items-center space-x-4">
          <h3 className="text-lg font-medium">Recently Viewed</h3>
          <div className="flex space-x-2">
            <Button variant="contained" startIcon={<ListIcon />} className="bg-gray-200">
              List
            </Button>
            <Button variant="contained" startIcon={<GridIcon />}>
              Board
            </Button>
          </div>
        </div>

        {/* Search, Filter, Sort, Calendar */}
        <div className="flex items-center space-x-2">
          <TextField variant="outlined" size="small" placeholder="Search Project" />
          <Button variant="contained" startIcon={<FilterListIcon />} onClick={() => setShowFilterOptions(!showFilterOptions)}>
            Filter
          </Button>
          <Button variant="contained" startIcon={<SortIcon />} onClick={() => setShowSortOptions(!showSortOptions)}>
            Sort
          </Button>
          <Button variant="contained" startIcon={<CalendarTodayIcon />}>
            Calendar
          </Button>
        </div>
      </div>

      {/* Filter Options */}
      {showFilterOptions && (
        <div>
          <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)} className={classes.select}>
            <MenuItem value="name">Project Name</MenuItem>
            <MenuItem value="startDate">Start Date</MenuItem>
            <MenuItem value="deadline">Deadline</MenuItem>
          </Select>
          <Select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} className={classes.select}>
            <MenuItem value={1}>Ascending</MenuItem>
            <MenuItem value={-1}>Descending</MenuItem>
          </Select>
        </div>
      )}

      {/* Sort Options */}
      {showSortOptions && (
        <div>
          <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)} className={classes.select}>
            <MenuItem value="name">Project Name</MenuItem>
            <MenuItem value="startDate">Start Date</MenuItem>
            <MenuItem value="deadline">Deadline</MenuItem>
          </Select>
          <Select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} className={classes.select}>
            <MenuItem value={1}>Ascending</MenuItem>
            <MenuItem value={-1}>Descending</MenuItem>
          </Select>
        </div>
      )}

      {/* Recently Viewed File Rows */}
      <div>
        {files.map((file) => (
          <div key={file.id} className={classes.fileRow}>
            <div
              className={classes.clickableFileName}
              onClick={() => handleFileClick(file.id)}
            >
              {file.name}
            </div>
            <div>{new Date(file.startDate).toLocaleDateString()} - {new Date(file.deadline).toLocaleDateString()}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentlyViewed;
