import React, { useEffect, useState } from "react";
import axios from "axios";
import { message} from "antd";
import "antd/dist/reset.css";

const GeneralSettings = () => {
  const [userDetails, setUserDetails] = useState({
    _id: "",
    name: "",
    lastName: "",
    email: "",
    qualification: "",
    designation: "",
    department: "",
    manager: "",
    phoneNumber: "",
    address: "",
    city: "",
    state: "",
    pinCode: "",
    ssn: "",
  });

  const [loading, setLoading] = useState(true);
  const [modifiedFields, setModifiedFields] = useState({});
  const [errors, setErrors] = useState({});

  // Fetch user details on component mount
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem("sessionToken");
        const response = await axios.get(
          `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/generic/userDetails`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const userData = response.data.data.user;
        const userDetails = {
          _id: userData._id,
          name: userData.name,
          lastName: userData.lastName,
          email: userData.email,
          qualification: userData.userDetails?.qualification || "",
          designation: userData.userDetails?.designation || "",
          department: userData.userDetails?.department || "",
          manager: userData.userDetails?.manager || "",
          phoneNumber: userData.userDetails?.phoneNumber || "",
          address: userData.userDetails?.address || "",
          city: userData.userDetails?.city || "",
          state: userData.userDetails?.state || "",
          pinCode: userData.userDetails?.pinCode || "",
          ssn: userData.userDetails?.ssn || "",
        };

        setUserDetails(userDetails);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user details:", error);
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  // Validate fields
  const validateField = (name, value) => {
    const trimmedValue = value.trim();
    let error = "";

    const validationRules = {
      name: { min: 2, max: 20 },
      lastName: { min: 2, max: 20 },
      qualification: { min: 2, max: 20 },
      designation: { min: 2, max: 20 },
      department: { min: 2, max: 20 },
      manager: { min: 2, max: 20 },
      phoneNumber: { regex: /^[0-9]{10,15}$/, message: "Must contain 10-15 digits." },
      address: { min: 5, max: 100 },
      city: { min: 2, max: 20 },
      state: { min: 2, max: 20 },
      pinCode: { regex: /^[0-9]{5,10}$/, message: "Must contain 5-10 digits." },
    };

    const rules = validationRules[name];

    if (rules) {
      if (rules.min && trimmedValue.length < rules.min) {
        error = `Minimum ${rules.min} characters required.`;
      } else if (rules.max && trimmedValue.length > rules.max) {
        error = `Maximum ${rules.max} characters allowed.`;
      } else if (rules.regex && !rules.regex.test(trimmedValue)) {
        error = rules.message;
      }
    }

    return error;
  };

  // Handle input changes and track modified fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);

    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    setModifiedFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  // Handle save action to update only modified fields
  const handleSave = async () => {
    try {
      const token = localStorage.getItem("sessionToken");

      const metaDetailsFields = [
        "qualification",
        "designation",
        "department",
        "manager",
        "phoneNumber",
        "address",
        "city",
        "state",
        "pinCode",
        "ssn",
      ];
      const metaDetails = {};
      const topLevelFields = {};

      Object.keys(modifiedFields).forEach((key) => {
        if (metaDetailsFields.includes(key)) {
          metaDetails[key] = modifiedFields[key];
        } else {
          topLevelFields[key] = modifiedFields[key];
        }
      });

      const payload = {
        ...topLevelFields,
        ...(Object.keys(metaDetails).length > 0 ? { metaDetails } : {}),
      };

      await axios.patch(
        `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/generic/updateDetails`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setModifiedFields({});
      message.success('User details updated successfully');
    } catch (error) {
      console.error("Error updating user details:", error);
     message.error("Failed to update user details.")
    }
  };

  // Loading state
  if (loading) {
    return (
      <div className="flex justify-center items-center w-full min-h-[504px]">
        <div className="flex flex-col gap-6 w-full p-6 bg-white rounded-lg shadow-md">
          <h1 className="text-xl font-semibold text-[#192028] mb-6">
            Personal Details
          </h1>
          <div className="grid grid-cols-2 gap-x-12 gap-y-6 w-full">
            {Array(12)
              .fill()
              .map((_, index) => (
                <div
                  className="flex items-center gap-4"
                  key={`loading-${index}`}
                >
                  <div className="h-8 flex-1 bg-gray-200 animate-pulse"></div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col max-w-[1430px] min-h-[504px] p-6 items-start gap-6 rounded-lg bg-white shadow-md text-[#192028]">
      <h1 className="text-xl font-semibold text-[#192028] mb-6">
        Personal Details
      </h1>
      <div className="grid grid-cols-2 gap-x-12 gap-y-6 w-full">
        <div className="flex items-center gap-4">
          <label className="block text-sm font-semibold text-[#798897] min-w-[150px]">
            Employee ID:
          </label>
          <input
            type="text"
            value={userDetails._id}
            className="p-2 border border-[#41EAD4] rounded-md bg-[#F8F9FB] text-[#192028] flex-1 focus:outline-none"
            readOnly
          />
        </div>

        <div className="flex items-center gap-4">
          <label className="block text-sm font-semibold text-[#798897] min-w-[150px]">
            Email:
          </label>
          <input
            type="email"
            value={userDetails.email}
            className="p-2 border border-[#41EAD4] rounded-md bg-[#F8F9FB] text-[#192028] flex-1 focus:outline-none"
            readOnly
          />
        </div>

        {[
          { label: "First Name", name: "name" },
          { label: "Last Name", name: "lastName" },
          { label: "Qualification", name: "qualification" },
          { label: "Designation", name: "designation" },
          { label: "Department", name: "department" },
          { label: "Manager", name: "manager" },
          { label: "Phone Number", name: "phoneNumber" },
          { label: "Address", name: "address" },
          { label: "City", name: "city" },
          { label: "State", name: "state" },
          { label: "Pin Code", name: "pinCode" },
          { label: "SSN", name: "ssn" },
        ].map((field) => (
          <div className="flex items-center gap-4" key={field.name}>
            <label className="block text-sm font-semibold text-[#798897] min-w-[150px]">
              {field.label}:
            </label>
            <input
              type="text"
              name={field.name}
              value={userDetails[field.name]}
              onChange={handleChange}
              className={`p-2 border ${
                errors[field.name]
                  ? "border-red-500"
                  : "border-[#41EAD4]"
              } rounded-md bg-[#F8F9FB] text-[#192028] flex-1 focus:outline-none`}
            />
            {errors[field.name] && (
              <span className="text-red-500 text-sm">{errors[field.name]}</span>
            )}
          </div>
        ))}
      </div>

      <div className="flex justify-end gap-4 mt-6 w-full">
        <button
          onClick={handleSave}
          className="px-4 py-2 bg-[#41EAD4] text-[#192028] rounded-md"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default GeneralSettings;
