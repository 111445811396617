import axios from 'axios';

const axiosDocInstance = axios.create({
  baseURL: `${process.env.REACT_APP_DOC_SERVICE_URL}`,
});

axiosDocInstance.interceptors.request.use(
  (config) => {
    const sessionToken = localStorage.getItem('sessionToken');
    if (sessionToken) {
      config.headers.Authorization = `Bearer ${sessionToken}`;
    }
    if (config.baseURLOverride) {
      config.baseURL = config.baseURLOverride;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosDocInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default axiosDocInstance;

