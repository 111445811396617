import React, { useState, useEffect } from 'react';
import { UploadOutlined, FolderAddOutlined, TeamOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import { useAuth } from '../../authcontext';
import AddDocModal from '../../Services/Modal/AddDocModal';
import AddFolderModal from '../../Services/Modal/AddFolderModal';
import AddTeamModal from '../../Services/Modal/AddTeamModal';

const QuickActions = () => {
  const { userId, userName } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [loading, setLoading] = useState(true);
  console.log(userId);
  const actions = [
    {
      icon: <UploadOutlined style={{ fontSize: '24px', color: '#41EAD4' }} />,
      title: 'Drop file to Upload',
      action: 'upload',
    },
    {
      icon: <FolderAddOutlined style={{ fontSize: '24px', color: '#41EAD4' }} />,
      title: 'Create A New Folder',
      action: 'createFolder',
    },
    {
      icon: <TeamOutlined style={{ fontSize: '24px', color: '#41EAD4' }} />,
      title: 'Add New Team',
      action: 'createTeam',
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  const handleActionClick = (action) => {
    setCurrentAction(action);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentAction(null); // Reset current action on modal close
  };

  if (loading) {
    return (
      <div className="mb-6">
        <Skeleton.Input style={{ width: 200 }} active />
        <Skeleton.Input style={{ width: 150, margin: '16px 0' }} active />
        <div className="flex flex-wrap justify-between gap-4 md:flex-nowrap">
          {actions.map((action, index) => (
            <div
              key={index}
              className="border rounded-lg p-4 shadow-sm flex items-center w-full md:w-80 bg-white"
              style={{
                height: '80px',
                padding: '16px',
                borderRadius: '8px',
                border: '1px solid #99EDDE',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
              }}
            >
              <Skeleton.Avatar active size="large" shape="circle" />
              <div style={{ marginLeft: '16px' }}>
                <Skeleton.Input style={{ width: 120 }} active />
                <Skeleton.Input style={{ width: 80, marginTop: '8px' }} active />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="mb-6">
             <div className="text-2xl font-semibold text-gray-800 mb-4">
        Welcome Back, {userName}
      </div>
      <h2 className="text-xl font-medium text-gray-700 mb-4">Quick Actions</h2>
      <div className="flex flex-wrap justify-between gap-4 md:flex-nowrap">
        {actions.map((action, index) => (
          <div
            key={index}
            className="border rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow duration-300 flex items-center w-full md:w-80 bg-white"
            onClick={() => handleActionClick(action.action)}
            style={{
              height: '80px',
              padding: '16px',
              borderRadius: '8px',
              border: '1px solid #99EDDE',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
              cursor: 'pointer',
            }}
          >
            <div
              className="flex items-center justify-center rounded-lg"
              style={{
                width: '40px',
                height: '40px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
              }}
            >
              {action.icon}
            </div>
            <div style={{ marginLeft: '16px' }}>
              <h4
                className="font-semibold text-gray-900"
                style={{ fontSize: '14px', fontWeight: 600, lineHeight: '20px', color: '#1B2028' }}
              >
                {action.title}
              </h4>
            </div>
          </div>
        ))}
      </div>

      {/* Modals */}
      {currentAction === 'upload' && (
        <AddDocModal isVisible={isModalVisible} handleCancel={handleCancel} userId={userId} />
      )}
      {currentAction === 'createFolder' && (
        <AddFolderModal isVisible={isModalVisible} handleCancel={handleCancel} userId={userId} />
      )}
      {currentAction === 'createTeam' && (
        <AddTeamModal isModalVisible={isModalVisible} handleCancel={handleCancel} userId={userId} />
      )}
    </div>
  );
};

export default QuickActions;
