import React, { useEffect, useState, useRef } from 'react';
import { FilePdfOutlined, PushpinOutlined, MoreOutlined, FolderOutlined, ShareAltOutlined, DeleteOutlined } from '@ant-design/icons';
import { Avatar, Skeleton } from 'antd';
import axios from 'axios';
import { useAuth } from '../../authcontext';
import PinnedSvg from '../../../assets/SVG/PinnedDocumnet.svg';

const PinnedDocuments = () => {
  const { userId } = useAuth();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDropdown, setShowDropdown] = useState(null);
  const dropdownRef = useRef(null);

  const fetchPinnedDocuments = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DOC_SERVICE_URL}/documents/list/${userId}?onlyPinned=true&forDashboard=true`);
      const pinnedDocuments = response.data.data?.finalList || [];
      setDocuments(pinnedDocuments);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching pinned documents:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchPinnedDocuments();
    }
  }, [userId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = (id) => {
    setShowDropdown(showDropdown === id ? null : id);
  };

  const isEmpty = !loading && documents.length === 0;

  if (loading) {
    // Skeleton loading layout as per the provided image
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '440px',
          minHeight: '176px',
          padding: '12px',
          gap: '16px',
          borderRadius: '8px',
          border: '1px solid rgba(0, 0, 0, 0.1)',
          background: '#FFF',
          boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Skeleton.Button active style={{ width: '150px', height: '20px' }} />
          <Skeleton.Button active style={{ width: '80px', height: '20px' }} />
        </div>
        {[1, 2].map((_, index) => (
          <div key={index} style={{ display: 'flex', gap: '16px', alignItems: 'center', marginTop: '12px' }}>
            <Skeleton.Avatar active shape="square" size="large" />
            <div style={{ flex: 1 }}>
              <Skeleton.Button active style={{ width: '80%', height: '12px' }} />
              <Skeleton.Button active style={{ width: '60%', height: '12px', marginTop: '8px' }} />
            </div>
            <div style={{ display: 'flex', gap: '12px' }}>
              <Skeleton.Button active style={{ width: '60px', height: '20px' }} />
              <Skeleton.Button active style={{ width: '40px', height: '20px' }} />
              <Skeleton.Button active shape="circle" style={{ width: '20px', height: '20px' }} />
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '440px',
        minHeight: '176px',
        padding: '12px',
        gap: '8px',
        borderRadius: '8px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        background: '#FFF',
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.1)',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2 style={{ fontSize: '20px', fontWeight: 600, lineHeight: '28px' }}>Pinned Documents</h2>
        {!isEmpty &&
          <a href="#" className="text-teal-500 underline flex items-center space-x-1">View All</a>
        }
      </div>

      {isEmpty ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            minHeight: '158px',
          }}
        >
          <img src={PinnedSvg} alt="No Pinned Documents" style={{ maxWidth: '100px' }} />
          <h3 style={{ fontSize: '16px', fontWeight: 600, color: '#1B2028', marginBottom: '0' }}>
            Pin key documents
          </h3>
          <p style={{ fontSize: '12px', color: '#59616E', margin: '0' }}>
            Keep your important things safe by pinning them.
          </p>
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {documents.map((doc, index) => (
            <div
              key={doc._id}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px 12px',
                borderBottom: index !== documents.length - 1 ? '1px solid rgba(0, 0, 0, 0.05)' : 'none',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '12px', flex: 1 }}>
                <div
                  style={{
                    width: '44px',
                    height: '44px',
                    borderRadius: '4px',
                    border: '1px solid #CAD4DD',
                    backgroundColor: '#FFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FilePdfOutlined style={{ color: '#FF4D4F', fontSize: '24px' }} />
                </div>
                <div style={{ flex: 1 }}>
                  <h3
                    style={{
                      fontSize: '16px',
                      fontWeight: 600,
                      color: '#192028',
                      marginBottom: '0',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '150px',
                      position: 'relative',
                    }}
                    title={doc.docName}
                  >
                    {doc.docName.length > 12
                      ? `${doc.docName.substring(0, 10)}...`
                      : doc.docName}
                  </h3>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                    <p style={{ fontSize: '12px', color: '#59616E', marginBottom: '0' }}>
                      Document Type: {doc.docType}
                    </p>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                      <div style={{ borderLeft: '1px solid #CAD4DD', height: '24px' }}></div>
                      <Avatar
                        src="https://i.pravatar.cc/40?img=7"
                        alt="Shared User"
                        size={24}
                        style={{ marginRight: '4px', border: '2px solid #FFF' }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', alignItems: 'center', gap: '12px', position: 'relative' }}>
                <div
                  style={{
                    width: '32px',
                    height: '32px',
                    borderRadius: '50%',
                    backgroundColor: '#41EAD4',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0px 2px 0px rgba(5, 145, 255, 0.10)',
                  }}
                >
                  <PushpinOutlined style={{ color: '#FFF', fontSize: '16px' }} />
                </div>
                <MoreOutlined
                  style={{ fontSize: '16px', color: '#8C8C8C', cursor: 'pointer' }}
                  onClick={() => toggleDropdown(doc._id)}
                />
                {showDropdown === doc._id && (
                  <div
                    ref={dropdownRef}
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: '40px',
                      width: '200px',
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '4px',
                      backgroundColor: '#FFF',
                      borderRadius: '8px',
                      boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
                      zIndex: 10,
                    }}
                  >
                    <button className="flex items-center gap-2 w-full px-3 py-2 text-left text-[#1B2028] hover:bg-[#D1F8F0]">
                      <FolderOutlined /> File Location
                    </button>
                    <button className="flex items-center gap-2 w-full px-3 py-2 text-left text-[#1B2028] hover:bg-[#D1F8F0]">
                      <ShareAltOutlined /> Share
                    </button>
                    <button className="flex items-center gap-2 w-full px-3 py-2 text-left text-[#1B2028] hover:bg-[#D1F8F0]">
                      <DeleteOutlined /> Delete
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PinnedDocuments;
