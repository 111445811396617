
import React from 'react';
import { Navigate } from 'react-router-dom'; // Importing Navigate component for navigation
import Header from '../layouts/header'; // Assuming the correct path to Header component
import { useAuth } from '../authcontext';
import IPForm from '../IPForm'; 
import '../style.css'

function Profile() {
 
  const { userId, userRole, userEmail, authenticated } = useAuth(); 

  
  const profileData = {
    id: userId,
    email: userEmail, 
    role: userRole,
  };

  return (
    <>
    
      {/* <Header /> */}
      
      {/* Conditional rendering based on authentication status */}
      {authenticated ? (
        <>
          {/* Displaying profile information */}
          <h1><span className="goback" onClick={() => window.history.back()}>←</span> Profile</h1>
          <div className="profile">
            <div>
              <p className="title">ID</p>
              <p className="subtitle">{profileData.id}</p>
            </div>
            <div>
              <p className="title">Email/Username</p>
              <p className="subtitle">{profileData.email}</p>
            </div>
            <div>
              <p className="title">Role</p>
              <p className="subtitle">{profileData.role}</p>
            </div>
            <div>
              <p className="title">User Object</p>
              <pre className="subtitle">{JSON.stringify(profileData, null, 2)}</pre>
            </div>
            <IPForm/>
          </div>
        </>
      ) : (
        // If not authenticated, navigate to '/'
        <Navigate to="/" replace />
      )}
    </>
  );
}

// Exporting Profile component as default
export default Profile;


