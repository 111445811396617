import React, { useState } from 'react';
import { Modal, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useAuth } from '../../authcontext';

const LeaveTeamModal = ({ visible, onCancel, onConfirm, teamId, teamName }) => {
  const { userId } = useAuth(); // Getting userId from AuthContext
  const [loading, setLoading] = useState(false);

  // Function to handle team leaving action
  const handleLeaveTeam = async () => {
    setLoading(true); // Show loading state while API request is being made
    try {
      // Making the PATCH API call to leave the team
      const response = await axios.patch(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/${teamId}/leave?userId=${userId}`
      );

      if (response.status === 200) {
        message.success('You have successfully left the team.');
        onConfirm(); // Trigger the onConfirm callback to refresh the state or UI after success
      } else {
        message.error('Failed to leave the team. Please try again.');
      }
    } catch (error) {
      console.error('Error leaving team:', error);
      message.error('Something went wrong. Please try again later.');
    } finally {
      setLoading(false); // Hide loading state after API call is complete
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null} // Custom footer with buttons
      centered
      width={484} // Set width as per the design
      bodyStyle={{
        padding: '20px',
        textAlign: 'center',
        borderRadius: '8px',
      }}
    >
      {/* Centered Icon and Heading */}
      <div className="flex justify-center items-center mb-4">
        <UserOutlined style={{ fontSize: '24px', color: '#1B2028', marginRight: '8px' }} />
        <h3 className="text-lg font-semibold" style={{ color: '#1B2028' }}>Leave {teamName}</h3>
      </div>

      {/* Modal Text */}
      <p className="text-[#768090] mb-2">Are you sure you want to leave this team?</p>
      <p className="text-[#768090] mb-4">
        All the tasks assigned to you or assigned by you will be lost.
      </p>

      {/* Footer with buttons */}
      <div className="flex justify-end gap-4 mt-6">
        {/* Close Button */}
        <button
          onClick={onCancel}
          className="flex h-8 px-4 justify-center items-center gap-2 rounded-lg bg-[#C4CBD8] text-[#1B2028] shadow-[0px_2px_0px_rgba(0,0,0,0.05)]"
        >
          Close
        </button>
        {/* Leave Button */}
        <button
          onClick={handleLeaveTeam}
          className="flex h-8 px-4 justify-center items-center gap-2 rounded-lg bg-[#FF412E] text-white shadow-[0px_2px_0px_rgba(0,0,0,0.05)]"
          disabled={loading} // Disable button while loading
        >
          {loading ? 'Leaving...' : 'Leave'}
        </button>
      </div>
    </Modal>
  );
};

export default LeaveTeamModal;
