// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   Container,
//   Box,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableRow,
//   IconButton,
//   Chip,
//   Pagination,
//   Button,
//   Modal,
//   Typography,
//   Grid,
//   Paper,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   CircularProgress,
// } from "@mui/material";
// import {
//   Add as AddIcon,
//   Star as StarIcon,
//   Share as ShareIcon,
//   Delete as DeleteIcon,
//   Edit as EditIcon,
//   Visibility as VisibilityIcon,
// } from "@mui/icons-material";
// import { useAuth } from "../authcontext";
// import { useNavigate } from "react-router-dom";

// const ListDocument = () => {
// const [documents, setDocuments] = useState([]);
//   const navigate = useNavigate();
//   const { userId, authenticated } = useAuth();
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [showEditModal, setShowEditModal] = useState(false);
//   const [editedDocumentName, setEditedDocumentName] = useState("");
//   const [selectedDocumentId, setSelectedDocumentId] = useState(null);
//   const [documentUrl, setDocumentUrl] = useState("");
//   const [docType, setDocType] = useState("");
//   const [sortBy, setSortBy] = useState("uploadedAt"); // Default sort by "uploadedAt"
//   const [sortOrder, setSortOrder] = useState(-1); // Default sort order descending (-1)
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [file, setFile] = useState(null);
//   const [uploadStatus, setUploadStatus] = useState("");

//   const documentsPerPage = 10; // Number of documents per page

//   useEffect(() => {
//     if (authenticated) {
//         axios.get(`${process.env.REACT_APP_DOC_SERVICE_URL}/documents/list/${userId}`)
//         .then((response) => {
//           const formattedDocuments = response.data.map(doc => ({
//             ...doc,
//             uploadedAt: new Date(doc.uploadedAt).toLocaleDateString('en-GB'),
//             docSizeFormatted: formatSize(doc.docSize),
//           }));
//           setDocuments(formattedDocuments);
//           setLoading(false);
//         })
//         .catch((error) => {
//           setError(error.message);
//           setLoading(false);
//         });
//     }
//   }, [userId, authenticated, sortBy, sortOrder, currentPage]);


 

//   useEffect(() => {
//     if (authenticated) {
//       getDocumentList();
//     }
//   }, [userId, authenticated, sortBy, sortOrder, currentPage]);

//   // Fetch document list from the server
//   const getDocumentList = () => {
//     setLoading(true);
//     axios
//       .get(`${process.env.REACT_APP_DOC_SERVICE_URL}/documents/list/${userId}`, {
//         params: {
//           userId: userId,
//           page: currentPage,
//           sortBy: sortBy,
//           sortOrder: sortOrder,
//         },
//       })
//       .then((response) => {
//         const { documents, totalDocuments } = response.data;
//         const formattedDocuments = documents.map((doc) => ({
//           ...doc,
//           uploadedAt: new Date(doc.uploadedAt).toLocaleDateString("en-GB"),
//           docSizeFormatted: formatSize(doc.docSize),
//         }));
//         setDocuments(formattedDocuments);
//         setTotalPages(Math.ceil(totalDocuments / documentsPerPage));
//         setLoading(false);
//       })
//       .catch((error) => {
//         setError(error.message);
//         setLoading(false);
//       });
//   };

//   // Format document size to KB or MB
//   const formatSize = (sizeInBytes) => {
//     const sizeInMb = sizeInBytes / (1024 * 1024);
//     if (sizeInMb >= 1) {
//       return `${sizeInMb.toFixed(2)} MB`;
//     } else {
//       const sizeInKb = sizeInBytes / 1024;
//       return `${sizeInKb.toFixed(2)} KB`;
//     }
//   };

//   // Delete a document
//   const deleteDocument = (docId) => {
//     axios
//       .delete(`${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}`)
//       .then(() => {
//         setDocuments(documents.filter((document) => document._id !== docId));
//       })
//       .catch((error) => {
//         setError(error.message);
//       });
//   };

//   // View a document in a modal
//   const viewDocument = (fileName, versionId) => {
//     axios
//       .get(
//         `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/download?fileName=${fileName}&versionId=${versionId}&view=true`,
//         {
//           responseType: "arraybuffer",
//         }
//       )
//       .then((response) => {
//         const file = new Blob([response.data]);
//         const fileUrl = URL.createObjectURL(file);
//         setDocumentUrl(fileUrl);
//       })
//       .catch((error) => {
//         setError(error.message);
//       });
//   };

//   // Close the document view modal
//   const closeDocument = () => {
//     setDocumentUrl("");
//   };

//   // Handle closing of the edit modal
//   const handleEditModalClose = () => {
//     setShowEditModal(false);
//     setEditedDocumentName("");
//     setSelectedDocumentId(null);
//     setDocType("");
//   };

//   // Handle the click on the edit button
//   const handleEditButtonClick = (docId, docName, docType) => {
//     setSelectedDocumentId(docId);
//     setEditedDocumentName(docName);
//     setDocType(docType);
//     setShowEditModal(true);
//   };

//   // Submit the edited document
//   const handleEditSubmit = () => {
//     axios
//       .post(
//         `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/updateDocument/${selectedDocumentId}`,
//         {
//           docName: editedDocumentName,
//           docType: docType,
//         }
//       )
//       .then(() => {
//         setDocuments(
//           documents.map((document) => {
//             if (document._id === selectedDocumentId) {
//               return {
//                 ...document,
//                 docName: editedDocumentName,
//                 docType: docType,
//               };
//             }
//             return document;
//           })
//         );
//         handleEditModalClose();
//       })
//       .catch((error) => {
//         setError(error.message);
//       });
//   };

//   // Handle sorting by different fields
//   const handleSortByChange = (e) => {
//     setSortBy(e.target.value);
//     setCurrentPage(1); // Reset to the first page
//   };

//   // Handle sorting order change (ascending/descending)
//   const handleSortOrderChange = (e) => {
//     setSortOrder(parseInt(e.target.value));
//     setCurrentPage(1); // Reset to the first page
//   };

//   // Handle page change for pagination
//   const handlePageChange = (event, pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   // Toggle bookmark status for a document
//   const handleBookmarkToggle = async (documentId, status) => {
//     try {
//       setDocuments(
//         documents.map((document) => {
//           if (document._id === documentId) {
//             return { ...document, bookmark: status };
//           }
//           return document;
//         })
//       );
//       await axios.patch(
//         `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${documentId}/bookmark?status=${status}`
//       );
//     } catch (error) {
//       setError(error.response.data.message);
//     }
//   };

//   // Navigate to the detailed document view
//   const handleNavigation = (docId) => {
//     navigate(`/user/${userId}/documents/${docId}`);
//   };

//   // Handle the add document button click to trigger file input
//   const handleAddDocumentClick = () => {
//     document.getElementById("fileInput").click();
//   };

//   // Upload a file to the server
//   const uploadFile = async (e) => {
//     const selectedFile = e.target.files[0];
//     setFile(selectedFile);

//     try {
//       const formData = new FormData();
//       formData.append("file", selectedFile);
//       formData.append("userId", userId);
//       await axios.post(
//         `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/create`,
//         formData,
//         {
//           headers: { "Content-Type": "multipart/form-data" },
//         }
//       );

//       setUploadStatus("success");
//       getDocumentList(); // Refresh the document list after upload
//     } catch (error) {
//       console.error("Error uploading File:", error);
//       setUploadStatus("error");
//       setError(error.message);
//     }
//   };

//   return (
//     <Container sx={{ mb: 15, mt: 4, minWidth: '1840', maxHeight: '920' }}>
//       <Paper sx={{ p: 5, borderRadius: "20px" }}>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             mb: 2,
//           }}
//         >
//           <Box>
//             <Typography variant="h5" gutterBottom>
//               Dashboard
//             </Typography>
//             <Typography variant="subtitle1" gutterBottom>
//               Manage all your documents
//             </Typography>
//           </Box>
//           <Button
//             onClick={handleAddDocumentClick}
//             variant="contained"
//             startIcon={<AddIcon />}
//           >
//             Add Document
//           </Button>
//         </Box>

//         <Grid container spacing={3} sx={{ mb: 5 }}>
//           <Grid item xs={3}>
//             <Paper sx={{ p: 2, backgroundColor: "#2EAC0033" }}>
//               <Typography variant="h6">Total Documents</Typography>
//               <Typography variant="h4">124</Typography>
//               <Typography variant="body2">10% Past week</Typography>
//             </Paper>
//           </Grid>
//           <Grid item xs={3}>
//             <Paper sx={{ p: 2, backgroundColor: "#FFAA0033" }}>
//               <Typography variant="h6">Pending Documents</Typography>
//               <Typography variant="h4">30</Typography>
//               <Typography variant="body2">8% Past week</Typography>
//             </Paper>
//           </Grid>
//           <Grid item xs={3}>
//             <Paper sx={{ p: 2, backgroundColor: "#F0300033" }}>
//               <Typography variant="h6">Reviewed Documents</Typography>
//               <Typography variant="h4">45</Typography>
//               <Typography variant="body2">5% Past week</Typography>
//             </Paper>
//           </Grid>
//           <Grid item xs={3}>
//             <Paper sx={{ p: 2, backgroundColor: "#FACD0033" }}>
//               <Typography variant="h6">Completed Documents</Typography>
//               <Typography variant="h4">57</Typography>
//               <Typography variant="body2">14% Past week</Typography>
//             </Paper>
//           </Grid>
//         </Grid>

//         {/* Sort and Filter Controls */}
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             mb: 2,
//           }}
//         >
//           <Typography variant="h6">Documents</Typography>
//           <Box sx={{ display: "flex", gap: 2 }}>
//             <FormControl size="small" sx={{ width: "200px" }}>
//               <InputLabel>Sort By</InputLabel>
//               <Select
//                 value={sortBy}
//                 onChange={handleSortByChange}
//                 label="Sort By"
//               >
//                 <MenuItem value="uploadedAt">Uploaded At</MenuItem>
//                 <MenuItem value="docName">Document Name</MenuItem>
//                 <MenuItem value="docSize">Document Size</MenuItem>
//               </Select>
//             </FormControl>
//             <FormControl size="small" sx={{ width: "200px" }}>
//               <InputLabel>Sort Order</InputLabel>
//               <Select
//                 value={sortOrder.toString()}
//                 onChange={handleSortOrderChange}
//                 label="Sort Order"
//               >
//                 <MenuItem value="-1">Descending</MenuItem>
//                 <MenuItem value="1">Ascending</MenuItem>
//               </Select>
//             </FormControl>
//           </Box>
//         </Box>

//         {/* Loading Spinner */}
//         {loading ? (
//           <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
//             <CircularProgress />
//           </Box>
//         ) : (
//           <>
//             {/* Documents Table */}
//             <Table sx={{ "& .MuiTableCell-root": { borderBottom: "none" } }}>
//               <TableHead>
//                 <TableRow>
//                   <TableCell>Name</TableCell>
//                   <TableCell>Owner</TableCell>
//                   <TableCell>Status</TableCell>
//                   <TableCell>File Size</TableCell>
//                   <TableCell>Last Modified</TableCell>
//                   <TableCell>Actions</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {documents.map((document) => (
//                   <TableRow
//                     key={document._id}
//                     sx={{
//                       "&:not(:last-child)": {
//                         borderBottom: "1px solid rgba(224, 224, 224, 1)",
//                       },
//                     }}
//                   >
//                     <TableCell
//                       onClick={() => handleNavigation(document._id)}
//                       sx={{ cursor: "pointer", color: "blue" }}
//                     >
//                       {document.docName}
//                     </TableCell>
//                     <TableCell>{document.owner}</TableCell>
//                     <TableCell>
//                       <Chip
//                         label={document.status}
//                         color={
//                           document.status === "Pending"
//                             ? "warning"
//                             : document.status === "Viewed"
//                             ? "info"
//                             : document.status === "In Review"
//                             ? "secondary"
//                             : "success"
//                         }
//                       />
//                     </TableCell>
//                     <TableCell>{document.docSizeFormatted}</TableCell>
//                     <TableCell>{document.uploadedAt}</TableCell>
//                     <TableCell>
//                       <IconButton onClick={() => deleteDocument(document._id)}>
//                         <DeleteIcon />
//                       </IconButton>
//                       <IconButton
//                         onClick={() =>
//                           handleBookmarkToggle(
//                             document._id,
//                             !document.bookmark
//                           )
//                         }
//                       >
//                         <StarIcon
//                           color={document.bookmark ? "primary" : "disabled"}
//                         />
//                       </IconButton>
//                       <IconButton>
//                         <ShareIcon />
//                       </IconButton>
//                     </TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>

//             {/* Pagination */}
//             {totalPages > 1 && (
//               <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
//                 <Pagination
//                   count={totalPages}
//                   page={currentPage}
//                   onChange={handlePageChange}
//                   color="primary"
//                 />
//               </Box>
//             )}
//           </>
//         )}
//       </Paper>

//       {/* Document View Modal */}
//       <Modal open={documentUrl !== ""} onClose={closeDocument}>
//         <Box sx={{ p: 4, backgroundColor: "#fff", borderRadius: "8px" }}>
//           <iframe
//             src={documentUrl}
//             style={{ width: "100%", height: "500px" }}
//             title="Document"
//           />
//           <Button onClick={closeDocument} variant="contained">
//             Close
//           </Button>
//         </Box>
//       </Modal>

//       {/* Edit Document Modal */}
//       <Modal open={showEditModal} onClose={handleEditModalClose}>
//         <Box sx={{ p: 4, backgroundColor: "#fff", borderRadius: "8px" }}>
//           <Typography variant="h6">Edit Document</Typography>
//           <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
//             <FormControl fullWidth>
//               <InputLabel>Document Name</InputLabel>
//               <input
//                 type="text"
//                 value={editedDocumentName}
//                 onChange={(e) => setEditedDocumentName(e.target.value)}
//               />
//             </FormControl>
//             <FormControl fullWidth>
//               <InputLabel>Document Type</InputLabel>
//               <input
//                 type="text"
//                 value={docType}
//                 onChange={(e) => setDocType(e.target.value)}
//               />
//             </FormControl>
//           </Box>
//           <Button variant="contained" onClick={handleEditSubmit}>
//             Save
//           </Button>
//         </Box>
//       </Modal>

//       {/* File Upload Input (Hidden) */}
//       <input
//         type="file"
//         style={{ display: "none" }}
//         id="fileInput"
//         onChange={uploadFile}
//       />
//     </Container>
//   );
// };

// export default ListDocument;







import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../authcontext";

const Test = () => {
  const [data, setData] = useState([]);
  const { userId } = useAuth(); // Fetch userId from useAuth
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    // Ensure userId is available before making the API request
    if (!userId) {
      console.log("UserId not available yet.");
      return; // Exit if userId is not ready
    }

    const fetchData = async () => {
      try {
        setLoading(true); // Start loading
        const response = await axios.get(
          `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/list/${userId}?forDashboard=true`
        );
        setData(response.data.data.list);
        console.log("Fetched data:", response.data.data.list);
      } catch (error) {  
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Stop loading after fetching data
      }
    };
 
    fetchData();
  }, [userId]); // Re-run when userId is updated

  // Optionally, handle loading or no data UI
  if (loading) return <p>Loading...</p>;
  if (!data || data.length === 0) return <p>No data available</p>;

  return (
    <div>
      <h1>Document List</h1>
      <ul>
        {data.map((item) => (
          <li key={item._id}>
            <p><strong>Document Name:</strong> {item.docName}</p>
            <p><strong>Type:</strong> {item.docType}</p>
            <p><strong>Size:</strong> {item.docSize} bytes</p>
            <p><strong>Uploaded At:</strong> {new Date(item.uploadedAt).toLocaleString()}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Test;
