import { useState, useEffect } from "react";
import axios from "axios";

/**
 * Custom hook for debouncing and fetching paginated results.
 * @param {string} term - The term to debounce and fetch data for.
 * @param {number} page - The current page number for pagination.
 * @param {function} setPage - Function to update the page number.
 * @param {number} delay - The debounce delay in milliseconds (default: 500ms).
 * @returns {Object} - An object containing the debounced term, results, loading state, fetchMore function, and moreToCome flag.
 */
const useDebounceFetch = (term = "", page, setPage, delay = 500) => {
  const [debouncedTerm, setDebouncedTerm] = useState("a"); // Initial term set to 'a'
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [moreToCome, setMoreToCome] = useState(true);
  const [isFirstSearch, setIsFirstSearch] = useState(true); // Track the first search

  // Debounce logic for the search term
  useEffect(() => {
    const handler = setTimeout(() => {
      if (isFirstSearch) {
        // For the first search, use 'a' and then set the first search to false
        setDebouncedTerm("a");
        setIsFirstSearch(false);
      } else {
        setDebouncedTerm(term); // Update the debounced term for subsequent inputs
      }
      setResults([]); // Reset results for new search
      setPage(0); // Reset page for new search
      setMoreToCome(true); // Allow fetching new results
    }, delay);

    return () => clearTimeout(handler);
  }, [term, delay, setPage, isFirstSearch]);

  // Fetch data when debounced term or page changes
  useEffect(() => {
    const fetchData = async () => {
      if (!debouncedTerm || !moreToCome || loading) return; // Skip if no term, no more data, or already loading
      setLoading(true);
      try {
        const response = await axios.get(
          `https://auth-comm-service.onrender.com/api/v1/generic/search?term=${debouncedTerm}${
            page > 0 ? `&page=${page}` : ""
          }`
        );
        const newUsers = response.data?.data?.users || [];
        setResults((prevResults) =>
          page === 0 ? newUsers : [...prevResults, ...newUsers]
        ); // Reset results for new search or append for pagination
        setMoreToCome(response.data?.data?.moreToCome ?? false); // Update moreToCome based on the API response
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [debouncedTerm, page, moreToCome, loading]);

  // Function to fetch the next page of results
  const fetchMore = () => {
    if (!loading && moreToCome) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return { debouncedTerm, results, loading, fetchMore, moreToCome };
};

export default useDebounceFetch;
