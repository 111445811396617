import React, { useState } from "react";
import { PushpinOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import Pdf from "../../../assets/SVG/Pdf.svg";
import Jpg from "../../../assets/SVG/JPG.svg";
import zip from "../../../assets/SVG/zip.svg";
import Html from "../../../assets/SVG/Html.svg";
import Docs from "../../../assets/SVG/Docs.svg";

// File type mapping
const fileTypeMapping = {
  "image/jpeg": ["jpg", "jpeg", "png"],
  "image/png": ["png", "jpg", "jpeg"],
  "application/pdf": ["pdf"],
  "application/vnd.ms-excel": ["xls", "xlsx"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    "xls",
    "xlsx",
  ],
  "application/msword": ["doc", "docx"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    "doc",
    "docx",
  ],
  "application/vnd.ms-powerpoint": ["ppt", "pptx"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    "ppt",
    "pptx",
  ],
  "text/plain": ["txt"],
  "text/csv": ["csv"],
};

const getDocumentIcon = (docType) => {
  const type = docType.toLowerCase();

  // Check the file type mapping to determine which icon to use
  if (
    fileTypeMapping["application/pdf"].includes("pdf") &&
    type === "application/pdf"
  ) {
    return Pdf;
  } else if (
    fileTypeMapping["image/jpeg"].includes("jpg") &&
    type === "image/jpeg"
  ) {
    return Jpg;
  } else if (
    fileTypeMapping["image/png"].includes("png") &&
    type === "image/png"
  ) {
    return Jpg;
  }
  // Show Docs SVG for xls, xlsx, doc, docx, ms-word, ms-excel
  else if (
    fileTypeMapping["application/vnd.ms-excel"].includes("xls") ||
    fileTypeMapping[
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ].includes("xlsx") ||
    fileTypeMapping["application/msword"].includes("doc") ||
    fileTypeMapping[
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ].includes("docx") ||
    type === "application/vnd.ms-excel" ||
    type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
    type === "application/msword" ||
    type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ) {
    return Docs;
  }
  // Show Html icon for text/plain files
  else if (
    fileTypeMapping["text/plain"].includes("txt") &&
    type === "text/plain"
  ) {
    return Html;
  }
  // Default to zip icon for unlisted file types
  else {
    return zip;
  }
};

const DocCard = ({ doc, handleDocumentClick, handlePinToggle }) => {
  const [tags, SetTags] = useState("");
  // Function to convert file size
  const formatFileSize = (sizeInBytes) => {
    if (sizeInBytes < 1024 * 1024) {
      return `${(sizeInBytes / 1024).toFixed(2)} KB`; // Less than 1 MB, show in KB
    }
    return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`; // Show in MB
  };

  return (
    <div
      className="flex justify-between items-center p-4 rounded-lg border border-gray-200 shadow-sm bg-white gap-4"
      onClick={() => handleDocumentClick(doc._id)} // Navigation handler
    >
      {/* Document Icon */}
      <div className="flex items-center gap-5">
        <div className="w-[49px] h-[47px] rounded-md bg-white flex justify-center items-center">
          <img
            src={getDocumentIcon(doc.docType)}
            alt={`${doc.docType} Icon`}
            className="max-w-full"
          />
        </div>

        {/* Document Name and Date */}
        <div className="flex flex-col justify-center">
          <h3 className="text-base font-semibold text-black mb-1 truncate max-w-[200px]">
            {doc.docName}
          </h3>
          <p className="text-sm text-gray-500 mb-0">
            Uploaded on {new Date(doc.uploadedAt).toLocaleDateString()}
          </p>
        </div>
      </div>

      {/* Document Type and Versions */}
      <div className="flex flex-col justify-center items-start w-[150px]">
        <p className="text-base font-semibold text-black truncate max-w-[150px]">
          Type: <span className="font-normal">{doc.docType}</span>
        </p>
        <p className="text-sm font-semibold text-gray-500">
          Versions: <span>{doc.totalVersions}</span>
        </p>
      </div>

      {/* File Size and Uploaded By */}
      <div className="flex flex-col justify-center items-start w-[150px]">
        <p className="text-base font-semibold text-black">
          File size:{" "}
          <span className="font-normal">{formatFileSize(doc.docSize)}</span>
        </p>
        <div className="flex items-center gap-2">
          <p className="text-sm text-gray-500 font-medium">Uploaded by:</p>
          <Avatar src="https://randomuser.me/api/portraits/thumb/women/50.jpg" />
        </div>
      </div>

      {/* Tags Section */}
      <div className="flex items-center gap-2 w-[250px]">
        <p className="text-sm font-semibold text-gray-500">Tags:</p>
        <div className="flex items-center gap-2">
          <span className="bg-teal-100 text-teal-500 px-2 py-1 rounded-md">
            #Tag
          </span>
          <span className="bg-teal-100 text-teal-500 px-2 py-1 rounded-md">
            #Tag
          </span>
          <span className="bg-teal-100 text-teal-500 px-2 py-1 rounded-md">
            #Tag
          </span>
          <span className="bg-teal-100 text-teal-500 px-2 py-1 rounded-md">
            #Tag
          </span>
          <span className="text-teal-500">...</span>
        </div>
      </div>

      {/* Pushpin Icon to Pin/Unpin Documents */}
      <div
        className={`w-8 h-8 rounded-full ${
          doc.bookmarkStatus ? "bg-teal-400" : "bg-gray-300"
        } flex justify-center items-center shadow-md cursor-pointer`}
        onClick={(e) => {
          e.stopPropagation(); // Prevent navigation when clicking on pin
          handlePinToggle(doc._id, doc.bookmarkStatus); // Toggle the pin status
        }}
      >
        <PushpinOutlined className="text-white text-lg" />
      </div>
    </div>
  );
};

export default DocCard;
