// import React, { useState, useEffect, useRef } from 'react';
// import { Avatar, message, Spin } from 'antd';
// import { PlusOutlined } from '@ant-design/icons';
// import AddNewUserModal from '../Modal/AddNewUserAdminModal';

// const PendingSuperAdmin = () => {
//   const [pendingRequests, setPendingRequests] = useState([]);
//   const [page, setPage] = useState(0);
//   const [loading, setLoading] = useState(false);
//   const [hasMore, setHasMore] = useState(true);
//   const [loadingButtons, setLoadingButtons] = useState({});
//   const tableRef = useRef(null);
//   const [isModalVisible, setIsModalVisible] = useState(false);

//   const initialApiCall = useRef(false); // Flag to prevent multiple initial calls

//   // Fetch pending requests from the API
//   const fetchPendingRequests = async (currentPage) => {
//     try {
//       const url =
//         currentPage === 0
//           ? 'https://auth-comm-service.onrender.com/api/v1/admin/users?pending=true'
//           : `https://auth-comm-service.onrender.com/api/v1/admin/users?pending=true&page=${currentPage}`;
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
//         },
//       });
//       const data = await response.json();
//       if (response.ok) {
//         if (!data.data.moreToCome) {
//           setHasMore(false);
//         }
//         return data.data.users || [];
//       } else {
//         message.error(data.message || 'Failed to fetch pending requests');
//         return [];
//       }
//     } catch (error) {
//       console.error('Error fetching pending requests:', error);
//       message.error('Error fetching pending requests');
//       return [];
//     }
//   };

//   const loadMorePendingRequests = async () => {
//     if (loading || !hasMore) return;

//     setLoading(true);
//     try {
//       const newRequests = await fetchPendingRequests(page);
//       if (newRequests.length === 0) {
//         setHasMore(false);
//       } else {
//         setPendingRequests((prev) => [...prev, ...newRequests]);
//         setPage((prevPage) => prevPage + 1);
//       }
//     } catch (error) {
//       console.error('Error fetching pending requests:', error);
//     }
//     setLoading(false);
//   };

//   const regenerateLink = async (user) => {
//     setLoadingButtons((prev) => ({ ...prev, [user._id]: true }));

//     try {
//       const response = await fetch(
//         'https://auth-comm-service.onrender.com/api/v1/admin/resendGenPasswordMail',
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
//           },
//           body: JSON.stringify({
//             id: user._id,
//             email: user.email,
//             name: user.name,
//           }),
//         }
//       );

//       const data = await response.json();
//       if (response.ok) {
//         message.success('Link regenerated successfully');
//         setHasMore(true); 
//         setPendingRequests((prevRequests) =>
//           prevRequests.map((req) =>
//             req._id === user._id ? { ...req, updatedAt: new Date().toISOString() } : req
//           )
//         );
//       } else {
//         message.error(data.message || 'Failed to regenerate link');
//       }
//     } catch (error) {
//       console.error('Error regenerating link:', error);
//       message.error('Error regenerating link');
//     }

//     setLoadingButtons((prev) => ({ ...prev, [user._id]: false }));
//   };

//   const hasLinkExpired = (updatedAt) => {
//     const expirationDate = new Date(updatedAt);
//     expirationDate.setMinutes(expirationDate.getMinutes() + 15);
//     return expirationDate < new Date();
//   };

//   const calculateExpirationTime = (updatedAt) => {
//     const expirationDate = new Date(updatedAt);
//     expirationDate.setMinutes(expirationDate.getMinutes() + 15);
//     return expirationDate.toLocaleString();
//   };

//   useEffect(() => {
//     if (initialApiCall.current) return; // Prevent duplicate initial calls
//     initialApiCall.current = true; // Mark the API call as made
//     loadMorePendingRequests();
//   }, []); // Empty dependency array ensures this runs only once

//   const handleScroll = () => {
//     if (!tableRef.current) return;
//     const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
//     if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
//       loadMorePendingRequests();
//     }
//   };

//   if (loading && page === 0) {
//     return (
//       <div className="flex flex-col max-w-[1430px] p-3 rounded-lg bg-white shadow-sm overflow-hidden">
//         <div className="flex justify-between items-center mb-4">
//           <h2 className="text-lg font-semibold text-[#192028]">Pending Requests</h2>
//         </div>
//         <Spin size="large" className="mx-auto mt-10" />
//       </div>
//     );
//   }

//   return (
//     <div className="flex flex-col max-w-[1430px] p-6 rounded-lg bg-white shadow-md overflow-y-auto">
//       <div className="flex justify-between items-center mb-4">
//         <h2 className="text-lg font-semibold text-[#192028]">Pending Requests</h2>
//         <button
//           className="flex items-center px-4 py-2 text-red-600 border border-red-200 rounded-lg"
//           onClick={() => setIsModalVisible(true)}
//         >
//           <PlusOutlined className="mr-2" />
//           Create New User
//         </button>
//       </div>
//       <div
//         className="overflow-auto"
//         style={{ maxHeight: '552px' }}
//         onScroll={handleScroll}
//         ref={tableRef}
//       >
//         <table className="table-auto w-full border-separate" style={{ borderSpacing: '0 10px' }}>
//           <thead className="text-left text-sm font-semibold text-gray-700 bg-gray-50">
//             <tr>
//               <th className="px-4 py-2">Username</th>
//               <th className="px-4 py-2">Request At</th>
//               <th className="px-4 py-2">Status</th>
//               <th className="px-4 py-2">Created By</th>
//               <th className="px-4 py-2">Link Expires At</th>
//               <th className="px-4 py-2">Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {pendingRequests.map((user) => (
//               <tr key={user._id} className="bg-white shadow-sm rounded-lg">
//                 <td className="px-4 py-2">
//                   <div className="flex items-center gap-2">
//                     <Avatar src="https://i.pravatar.cc/30?img=64" alt="User Avatar" size={30} />
//                     <span>{`${user.name} ${user.lastName}`}</span>
//                   </div>
//                 </td>
//                 <td className="px-4 py-2">{new Date(user.updatedAt).toLocaleString()}</td>
//                 <td className="px-4 py-2">
//                   {hasLinkExpired(user.updatedAt) ? (
//                     <span className="px-2 py-1 text-gray-500 bg-gray-100 border border-gray-300 rounded-md">
//                       Expired
//                     </span>
//                   ) : (
//                     <span className="px-2 py-1 border border-yellow-500 text-yellow-500 rounded-md bg-yellow-100">
//                       Pending
//                     </span>
//                   )}
//                 </td>
//                 <td className="px-4 py-2">{user.createdBy?.name || 'N/A'}</td>
//                 <td className="px-4 py-2">
//                   {hasLinkExpired(user.updatedAt)
//                     ? 'N/A'
//                     : calculateExpirationTime(user.updatedAt)}
//                 </td>
//                 <td className="px-4 py-2">
//                   <button
//                     className={`px-6 py-2 rounded-lg ${
//                       hasLinkExpired(user.updatedAt)
//                         ? 'bg-[#41EAD4] text-[#192028]'
//                         : 'bg-[#D9DEE7] text-[#59616E]'
//                     }`}
//                     onClick={() => regenerateLink(user)}
//                     disabled={!hasLinkExpired(user.updatedAt) || loadingButtons[user._id]}
//                   >
//                     {loadingButtons[user._id] ? (
//                       <Spin size="small" style={{ marginRight: '8px' }} />
//                     ) : (
//                       'Regenerate Link'
//                     )}
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       {/* AddNewUserModal */}
//       <AddNewUserModal
//         isVisible={isModalVisible}
//         onClose={() => setIsModalVisible(false)}
//         isSuperAdmin={true}
//       />
//     </div>
//   );
// };

// export default PendingSuperAdmin;


















import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Button, message, Spin } from 'antd';
import AddNewUserModal from '../Modal/AddNewUserAdminModal';
import { PlusOutlined } from '@ant-design/icons';

const PendingSuperAdmin = () => {
  const [pendingRequests, setPendingRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [initialLoading, setInitialLoading] = useState(true); // Initial loading state for skeleton
  const [loading, setLoading] = useState(false); // Loading state for seamless scrolling
  const [hasMore, setHasMore] = useState(true); // Controls infinite scrolling
  const tableRef = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingUserId, setLoadingUserId] = useState(null); // Track the loading state for each user
  const [pendingMoreToCome, setPendingMoreToCome] = useState(true);

  const fetchPendingRequests = async (page) => {
    
    try {
      const url =
        page === 0
          ? `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/admin/users?pending=true`
          : `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/admin/users?pending=true&page=${page}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        if (!data.data.moreToCome) {
          setPendingMoreToCome(false);
        }
        return data.data.users || [];
      } else {
        message.error(data.message || 'Failed to fetch pending requests');
        return [];
      }
    } catch (error) {
      console.error('Error fetching pending requests:', error);
      message.error('Error fetching pending requests');
      return [];
    }
  };

  const loadMorePendingRequests = async () => {
    if (!hasMore || loading) return;

    setLoading(true);
    try {
      const newRequests = await fetchPendingRequests(page);
      if (newRequests.length === 0) {
        setHasMore(false);
      } else {
        setPendingRequests((prev) => [...prev, ...newRequests]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error('Error fetching pending requests:', error);
    }
    setLoading(false);
  };

  const regenerateLink = async (user) => {
    setLoadingUserId(user._id); // Set loading state for the specific user
    try {
      const response = await fetch(
        `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/admin/resendGenPasswordMail`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
          },
          body: JSON.stringify({
            id: user._id,
            email: user.email,
            name: user.name,
          }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        message.success('Link regenerated successfully');
        setHasMore(true); // Reset `hasMore` to allow pagination again
        setPage(1); // Reset page to 0 for fetching fresh data
        setPendingMoreToCome(true)
        setPendingRequests([])
        const updatedPendingRequests = await fetchPendingRequests(0); // Fetch fresh data
        setPendingRequests(updatedPendingRequests);
      

      } else {
        message.error(data.message || 'Failed to regenerate link');
      }
    } catch (error) {
      console.error('Error regenerating link:', error);
      message.error('Error regenerating link');
    } finally {
      setLoadingUserId(null); // Reset the loading state
    }
  };

  const hasLinkExpired = (updatedAt) => {
    const expirationDate = new Date(updatedAt);
    expirationDate.setMinutes(expirationDate.getMinutes() + 15);
    return expirationDate < new Date();
  };

  const calculateExpirationTime = (updatedAt) => {
    const expirationDate = new Date(updatedAt);
    expirationDate.setMinutes(expirationDate.getMinutes() + 15);
    return expirationDate.toLocaleString();
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      const initialRequests = await fetchPendingRequests(0);
      setPendingRequests(initialRequests);
      setInitialLoading(false);
    };
    fetchInitialData();
  }, []);

  const handleScroll = () => {
    if (!tableRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
      loadMorePendingRequests();
    }
  };

  return (
    <div className="flex flex-col max-w-[1430px] p-3 rounded-lg bg-white shadow-sm overflow-hidden">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Pending Requests</h2>
        <button
          className="flex items-center px-4 py-2 text-red-600 border border-red-200 rounded-lg"
          onClick={() => setIsModalVisible(true)}
        >
          <PlusOutlined className="mr-2" />
          Create New User
        </button>
      </div>
      <div
        className="overflow-auto"
        style={{ maxHeight: '552px' }}
        onScroll={handleScroll}
        ref={tableRef}
      >
        <table className="table-auto w-full border-separate" style={{ borderSpacing: '0 10px' }}>
          <thead className="text-left text-sm font-semibold text-gray-700 bg-gray-50">
            <tr>
              <th className="px-4 py-2">Username</th>
              <th className="px-4 py-2">Request At</th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2">Created By</th>
              <th className="px-4 py-2">Link Expires At</th>
              <th className="px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {initialLoading
              ? Array.from({ length: 5 }).map((_, index) => (
                  <tr key={index} className="bg-white shadow-sm rounded-lg">
                    <td className="px-4 py-2">
                      <div className="flex items-center gap-2">
                        <div className="w-8 h-8 bg-gray-300 rounded-full"></div>
                        <div className="w-24 h-4 bg-gray-300 rounded"></div>
                      </div>
                    </td>
                    <td className="px-4 py-2">
                      <div className="w-20 h-4 bg-gray-300 rounded"></div>
                    </td>
                    <td className="px-4 py-2">
                      <div className="w-20 h-4 bg-gray-300 rounded"></div>
                    </td>
                    <td className="px-4 py-2">
                      <div className="w-24 h-4 bg-gray-300 rounded"></div>
                    </td>
                    <td className="px-4 py-2">
                      <div className="w-32 h-4 bg-gray-300 rounded"></div>
                    </td>
                    <td className="px-4 py-2">
                      <div className="w-24 h-4 bg-gray-300 rounded"></div>
                    </td>
                  </tr>
                ))
              : pendingRequests.map((user) => (
                  <tr key={user._id} className="bg-white shadow-sm rounded-lg">
                    <td className="px-4 py-2">
                      <div className="flex items-center gap-2">
                        <Avatar src="https://i.pravatar.cc/30?img=64" alt="User Avatar" size={30} />
                        <span>{`${user.name} ${user.lastName}`}</span>
                      </div>
                    </td>
                    <td className="px-4 py-2">{new Date(user.updatedAt).toLocaleString()}</td>
                    <td className="px-4 py-2">
                      {hasLinkExpired(user.updatedAt) ? (
                        <span className="px-2 py-1 text-gray-500 bg-gray-100 border border-gray-300 rounded-md text-sm font-semibold">
                          Expired
                        </span>
                      ) : (
                        <span className="px-2 py-1 border border-yellow-500 text-yellow-500 rounded-md bg-yellow-100 text-sm font-semibold">
                          Pending
                        </span>
                      )}
                    </td>
                    <td className="px-4 py-2">{user.createdBy?.name || 'N/A'}</td>
                    <td className="px-4 py-2">
                      {hasLinkExpired(user.updatedAt)
                        ? 'N/A'
                        : calculateExpirationTime(user.updatedAt)}
                    </td>
                    <td className="px-4 py-2">
                      {hasLinkExpired(user.updatedAt) ? (
                        <button
                          className="px-4 py-1 w-full rounded-lg bg-[#41EAD4] text-[#192028] flex items-center justify-center"
                          onClick={() => regenerateLink(user)}
                          disabled={loadingUserId === user._id} // Disable button if loading
                        >
                          {loadingUserId === user._id ? (
                            <Spin size="small" />
                          ) : (
                            'Regenerate Link'
                          )}
                        </button>
                      ) : (
                        <button className="px-4 py-1 w-full rounded-lg bg-[#D9DEE7] text-[#59616E]" disabled>
                          Regenerate Link
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <AddNewUserModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        isSuperAdmin={true}
      />
    </div>
  );
};

export default PendingSuperAdmin;
