import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  Button,
  IconButton,
  InputAdornment,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import axios from "axios";
import { useAuth } from "../../authcontext";
import { DatePicker } from "@atlaskit/datetime-picker";

const ProjectForm = ({ onCreate }) => {
  const { userId, userEmail} = useAuth();
  const today = new Date().toISOString().split('T')[0];

  const [projectName, setProjectName] = useState("New Project 1");
  const [team, setTeam] = useState("");
  const [privacy, setPrivacy] = useState("open");
  const [deadline, setDeadline] = useState("");
  const [showSchedule, setShowSchedule] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCreate = async () => {
    if (showSchedule && endDate < startDate) {
      setError("End date cannot be earlier than start date.");
      return;
    }

    setLoading(true);
    const createdAt = showSchedule && startDate ? new Date(startDate).getTime() : Date.now();
    const finalDeadline = showSchedule && endDate ? new Date(endDate).getTime() : new Date(deadline).getTime();

    const projectData = {
      name: projectName,
      owner: userId,
      deadline: finalDeadline,
      createdAt,
      privacy,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NON_PROD_URL}/projects/`,
        projectData
      );
      setSuccess("Project created successfully");
      setError("");
      setProjectName("New Project 1");
      setDeadline("");
      setPrivacy("open");
      setTeam("");
      setStartDate("");
      setEndDate("");
      onCreate(response.data);
      setTimeout(() => {
        setSuccess("");
      }, 1500);
    } catch (err) {
      setError(
        err.response?.data?.error?.message || "Failed to create project"
      );
      setSuccess("");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div
        className="bg-white p-6 rounded-lg shadow-lg"
        style={{ width: "772px", height: "336px"}}
      >
        <div className="mb-4">
          <TextField
            label="Project Name"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small">
                    <Add />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="mb-4 grid grid-cols-2 gap-4">
          <TextField
            select
            label="Select a team"
            value={team}
            onChange={(e) => setTeam(e.target.value)}
            fullWidth
          >
            <MenuItem value="team1">Team 1</MenuItem>
            <MenuItem value="team2">Team 2</MenuItem>
          </TextField>
          <TextField
            select
            label="Select privacy"
            value={privacy}
            onChange={(e) => setPrivacy(e.target.value)}
            fullWidth
          >
            <MenuItem value="open">Open</MenuItem>
            <MenuItem value="private">Private</MenuItem>
          </TextField>
        </div>
        <div className="mb-4 grid grid-cols-2 gap-4">
          <TextField
            label="Add more members"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small">
                    <Add />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {!showSchedule ? (
            <div className="flex-1">
              <DatePicker
                label="Pick a deadline"
                value={deadline}
                onChange={(date) => setDeadline(date)}
                fullWidth
                minDate={today}
                styles={{ width: '100%', fontSize: '14px', padding: '4px' }}
              />
              <Typography
                variant="body2"
                color="primary"
                style={{ cursor: "pointer", marginTop: "8px" }}
                onClick={() => setShowSchedule(true)}
              >
                Schedule for later
              </Typography>
            </div>
          ) : (
            <>
              <div style={{ display: "grid" }}>
                <div style={{ display: "flex", gap: "16px" }}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    fullWidth
                    minDate={today}
                    styles={{ width: '100%', fontSize: '14px', padding: '4px' }}
                  />
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    fullWidth
                    minDate={startDate || today}
                    styles={{ width: '100%', fontSize: '14px', padding: '4px' }}
                  />
                </div>
                <Typography
                  variant="body2"
                  color="primary"
                  style={{ cursor: "pointer", marginTop: "8px" }}
                  onClick={() => setShowSchedule(false)}
                >
                  Schedule for today
                </Typography>
              </div>
            </>
          )}
        </div>
        {error && <Typography color="error">{error}</Typography>}
        {success && <Typography color="primary">{success}</Typography>}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          style={{ backgroundColor: "#41EAD4", color: "#172B4D" }}
          onClick={handleCreate}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Create project"}
        </Button>
      </div>
    </div>
  );
};

export default ProjectForm;
