// import React, { useState, useEffect } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import { message } from "antd";
// import AddNestedFolderModal from '../../Services/Modal/AddNestedFolderModal';
// import NestedFolderCard from '../../Services/UI/NestedFolderCard';
// import { useAuth } from "../../authcontext";
// import NestedFolderHeader from './NestedFolderHeader';
// import axios from "axios";
// import NestedFoldersEmptyState  from '../../Services/UI/EmptyState/NestedFoldersEmptyState'


// const NestedFolders = () => {
//   const { folderId } = useParams();
//   const { userId } = useAuth();
//   const [folderData, setFolderData] = useState(null);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [folders, setFolders] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [filter, setFilter] = useState(null);
//   const [sortOrder, setSortOrder] = useState({ order: 1, option: 'name' });
//   const navigate = useNavigate();
//   const [parentId, setParentId] = useState(null);
//   const [x, setX] = useState(null);
//   const [permission, setPermission] =useState(null)
//   const [folderType, setFolderType] = useState(null);
//   const [folderName, setFolderName] =useState(null);

//   useEffect(() => {
//     const fetchFolderData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_DOC_SERVICE_URL}/folder/${folderId}`);
//         const { folder } = response.data.data;
//         setX(folder);
//         setFolderData(folder);  // Set folder data to state
//         setParentId(folder.parentId || folder._id);  // Handle parentId
//         setPermission(folder.folderPermission);
//         setFolderType(folder.folderType);
//         setFolderName(folder.name);
//       } catch (error) {
//         message.error('Failed to load folder data');
//       }
//     };
//     fetchFolderData();
//   }, [folderId]);

//   const showAddFolderModal = () => {
//     setIsModalVisible(true);
//   };

//   const handleModalCancel = () => {
//     setIsModalVisible(false);
//   };

//   const handleFolderClick = (folderId) => {
//     navigate(`/documents/folders/${folderId}`);
//   };

//   const fetchFolders = async () => {
//     try {
//       let url = `${process.env.REACT_APP_DOC_SERVICE_URL}/folder/?userId=${userId}`;
//       if (folderId) url += `&immediateParentId=${folderId}`;
//       if (filter) url += `&filter=${filter}`;
//       if (sortOrder) url += `&sort=${sortOrder.option}&order=${sortOrder.order}`;
//       const response = await fetch(url);
//       const data = await response.json();
//       if (data && data.data && data.data.folders) {
//         setFolders(data.data.folders);
//       } else {
//         message.error("Failed to fetch folders");
//       }
//     } catch (error) {
//       message.error("Error fetching folders");
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchFolders();
//   }, [folderId, filter, sortOrder]);

//   return (
//     <div style={{ padding: "24px 16px", display: "flex", flexDirection: "column", gap: "16px" }}>
//       <NestedFolderHeader
//         showAddFolderModal={showAddFolderModal}
//         description={folderData?.description}  // Use folderData description
//         folderName={folderData?.name}  // Use folderData name
//         setSortOrder={setSortOrder}
//         setFilter={setFilter}
//         currentSortOption={sortOrder.option}
//       />
//       {loading ? (
//         <p>Loading folders...</p>
//       ) : (
//         <div style={{ display: "flex", flexDirection: "row", gap: "16px", overflowX: "auto", whiteSpace: "nowrap" }}>
//           {folders.length > 0 ? (
//             folders.map((folder) => (
//               <NestedFolderCard 
//                 key={folder._id} 
//                 folder={folder} 
//                 userId={userId} 
//                 permission={permission}
//                 folderName={folderName}
//                 folderType={folderType}
//                 handleFolderClick={() => handleFolderClick(folder._id)}
//                 style={{ flex: "0 0 auto" }}
//               />
//             ))
//           ) : (
//             <p>No folders available.</p>
//           )}
//         </div>
//       )}
//       {isModalVisible && (
//         <AddNestedFolderModal 
//           visible={isModalVisible} 
//           handleCancel={handleModalCancel} 
//           folderId={folderId}  
//           userId={userId} 
//           parentId={parentId}
//         />
//       )}
//     </div>
//   );
// };

// export default NestedFolders;











// import React, { useState, useEffect } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import { message } from "antd";
// import AddNestedFolderModal from '../../Services/Modal/AddNestedFolderModal';
// import NestedFolderCard from '../../Services/UI/NestedFolderCard';
// import { useAuth } from "../../authcontext";
// import NestedFolderHeader from './NestedFolderHeader';
// import axios from "axios";
// import NestedFoldersEmptyState from '../../Services/UI/EmptyState/NestedFoldersEmptyState'; // Import the EmptyState component

// const NestedFolders = () => {
//   const { folderId } = useParams();
//   const { userId } = useAuth();
//   const [folderData, setFolderData] = useState(null);
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [folders, setFolders] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [filter, setFilter] = useState(null);
//   const [sortOrder, setSortOrder] = useState({ order: 1, option: 'name' });
//   const navigate = useNavigate();
//   const [parentId, setParentId] = useState(null);
//   // const [x, setX] = useState(null);
//   const [permission, setPermission] = useState(null);
//   const [folderType, setFolderType] = useState(null);
//   const [folderName, setFolderName] = useState(null);
//   const [description, setDescription] =useState("");

//   // Fetch folder details and set the state
//   useEffect(() => {
//     const fetchFolderData = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_DOC_SERVICE_URL}/folder/${folderId}`);
//         const { folder } = response.data.data;
//         setParentId(folder.parentId || folder._id); // Handle parentId
//         setPermission(folder.folderPermission);
//         setFolderType(folder.folderType);
//         setFolderName(folder.name);
//         setDescription(folder.description);
//       } catch (error) {
//         message.error('Failed to load folder data');
//       }
//     };
//     fetchFolderData();
//   }, [folderId]);

//   const showAddFolderModal = () => {
//     setIsModalVisible(true);
//   };

//   const handleModalCancel = () => {
//     setIsModalVisible(false);
//   };

//   const handleFolderClick = (folderId) => {
//     navigate(`/documents/folders/${folderId}`);
//   };

//   // Fetch nested folders based on filter and sorting options
//   const fetchFolders = async () => {
//     try {
//       let url = `${process.env.REACT_APP_DOC_SERVICE_URL}/folder/?userId=${userId}`;
//       if (folderId) url += `&immediateParentId=${folderId}`;
//       if (filter) url += `&filter=${filter}`;
//       if (sortOrder) url += `&sort=${sortOrder.option}&order=${sortOrder.order}`;
//       const response = await fetch(url);
//       const data = await response.json();
//       if (data && data.data && data.data.folders) {
//          setFolders(data.data.folders);
//         if(folders)
//         { 
//         }

        
//       } else {
//         message.error("Failed to fetch folders");
//       }
//     } catch (error) {
//       message.error("Error fetching folders");
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchFolders();
//   }, [folderId, filter, sortOrder]);

//   return (
//     <div style={{ padding: "24px 16px", display: "flex", flexDirection: "column", gap: "16px" }}>
//       <NestedFolderHeader
//         showAddFolderModal={showAddFolderModal}
//         description={description} // Use folderData description
//         folderName={folderData?.name} // Use folderData name
//         setSortOrder={setSortOrder}
//         setFilter={setFilter}
//         currentSortOption={sortOrder.option}
//       />
//       {loading ? (
//         <p>Loading folders...</p>
//       ) : (
//         <div style={{ display: "flex", flexDirection: "row", gap: "16px", overflowX: "auto", whiteSpace: "nowrap" }}>
//           {folders.length > 0 ? (


//             folders.map((folder) => (
//               <NestedFolderCard
//                 key={folder._id}
//                 folder={folder}
//                 userId={userId}
//                 permission={permission}
//                 folderName={folderName}
//                 folderType={folderType}
//                 handleFolderClick={() => handleFolderClick(folder._id)}
//                 style={{ flex: "0 0 auto" }}
//               />
//             ))
//           ) : (
//             <div className="ml-80 px-8">
//             <NestedFoldersEmptyState /> 
//             </div>
//           )}
//         </div>
//       )}
//       {isModalVisible && (
//         <AddNestedFolderModal
//           visible={isModalVisible}
//           handleCancel={handleModalCancel}
//           folderId={folderId}
//           userId={userId}
//           parentId={parentId}
//         />
//       )}
//     </div>
//   );
// };

// export default NestedFolders;











import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import AddNestedFolderModal from '../../Services/Modal/AddNestedFolderModal';
import NestedFolderCard from '../../Services/UI/NestedFolderCard';
import { useAuth } from '../../authcontext';
import NestedFolderHeader from './NestedFolderHeader';
import axios from 'axios';
import NestedFoldersEmptyState from '../../Services/UI/EmptyState/NestedFoldersEmptyState';

const NestedFolders = () => {
  const { folderId } = useParams(); // Get the current folderId from the route
  const { userId } = useAuth(); // Get the current userId from AuthContext
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state
  const [folders, setFolders] = useState([]); // Holds the fetched folders
  const [loading, setLoading] = useState(true); // Loading state while fetching folders
  const [filter, setFilter] = useState(null); // Filter criteria state
  const [sortOrder, setSortOrder] = useState({ sortBy: 'name', sortOrder: 1 }); // Sort order state (ascending by name initially)
  const navigate = useNavigate(); // Navigation hook
  const [parentId, setParentId] = useState(null); // Parent folder ID state
  const [permission, setPermission] = useState(null); // Folder permission state
  const [folderType, setFolderType] = useState(null); // Folder type state
  const [folderName, setFolderName] = useState(null); // Folder name state
  const [description, setDescription] = useState(''); // Folder description state

  // Fetch folder details and set the state
  useEffect(() => {
    const fetchFolderData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_DOC_SERVICE_URL}/folder/${folderId}`);
        const { folder } = response.data.data;
        setParentId(folder.parentId || folder._id);
        setPermission(folder.folderPermission);
        setFolderType(folder.folderType);
        setFolderName(folder.name);
        setDescription(folder.description);
      } catch (error) {
        message.error('Failed to load folder data');
      }
    };
    fetchFolderData();
  }, [folderId]);

  // Show modal to add nested folder
  const showAddFolderModal = () => {
    setIsModalVisible(true);
  };

  // Hide modal
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  // Handle clicking a folder to navigate inside
  const handleFolderClick = (folderId) => {
    navigate(`/documents/folders/${folderId}`);
  };

  // Fetch nested folders based on filter and sorting options
  const fetchFolders = async () => {
    try {
      let url = `${process.env.REACT_APP_DOC_SERVICE_URL}/folder/?userId=${userId}`;
      if (folderId) url += `&immediateParentId=${folderId}`;
      if (filter) url += `&filter=${filter}`;
      if (sortOrder) url += `&sortBy=${sortOrder.sortBy}&sortOrder=${sortOrder.sortOrder}`;
      const response = await axios.get(url);
      const data = response.data;
      if (data && data.data && data.data.folders) {
        setFolders(data.data.folders);
      } else {
        message.error('Failed to fetch folders');
      }
    } catch (error) {
      message.error('Error fetching folders');
    } finally {
      setLoading(false);
    }
  };

  // Trigger folder fetching on component mount and when filter/sortOrder change
  useEffect(() => {
    fetchFolders();
  }, [folderId, filter, sortOrder]);

  return (
    <div style={{ padding: '24px 16px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <NestedFolderHeader
        showAddFolderModal={showAddFolderModal}
        description={description}
        folderName={folderName}
        setSortOrder={(sortOrder, sortBy) => setSortOrder({ sortOrder, sortBy })} // Set sorting function to the header component
        setFilter={setFilter} // Set filtering function to the header component
        currentSortOption={sortOrder.sortBy} // Pass current sort option to the header component
      />
      {loading ? (
        <p>Loading folders...</p>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '16px', overflowX: 'auto', whiteSpace: 'nowrap' }}>
          {folders.length > 0 ? (
            folders.map((folder) => (
              <NestedFolderCard
                key={folder._id}
                folder={folder}
                userId={userId}
                permission={permission}
                folderName={folder.name}
                folderType={folderType}
                handleFolderClick={() => handleFolderClick(folder._id)}
                style={{ flex: '0 0 auto' }}
              />
            ))
          ) : (
            <div className="ml-80 px-8">
              <NestedFoldersEmptyState />
            </div>
          )}
        </div>
      )}
      {isModalVisible && (
        <AddNestedFolderModal
          visible={isModalVisible}
          handleCancel={handleModalCancel}
          folderId={folderId}
          userId={userId}
          parentId={parentId}
        />
      )}
    </div>
  );
};

export default NestedFolders;
