import React, { useState, useRef } from "react";
import { Button, Input, Spin, message } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import axios from "axios";
import LockIcon from "../../../../assets/SVG/Vault.svg";
import VaultAccess from "./VaultAccess";

const ChangePin = ({ onBack }) => {
  const [showOldPin, setShowOldPin] = useState(false);
  const [showNewPin, setShowNewPin] = useState(false);
  const [showConfirmPin, setShowConfirmPin] = useState(false);
  const [oldPin, setOldPin] = useState(["", "", "", ""]);
  const [newPin, setNewPin] = useState(["", "", "", ""]);
  const [confirmPin, setConfirmPin] = useState(["", "", "", ""]);
  const [isSaving, setIsSaving] = useState(false);
  const [showVaultAccess, setShowVaultAccess] = useState(false);

  const oldPinRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const newPinRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const confirmPinRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const handlePinChange = (index, value, type) => {
    if (!/^\d?$/.test(value)) return;

    const pinArray = type === "old" ? [...oldPin] : type === "new" ? [...newPin] : [...confirmPin];
    pinArray[index] = value;

    if (type === "old") {
      setOldPin(pinArray);
      if (value && index < 3) oldPinRefs[index + 1].current.focus();
      if (!value && index > 0) oldPinRefs[index - 1].current.focus();
    } else if (type === "new") {
      setNewPin(pinArray);
      if (value && index < 3) newPinRefs[index + 1].current.focus();
      if (!value && index > 0) newPinRefs[index - 1].current.focus();
    } else {
      setConfirmPin(pinArray);
      if (value && index < 3) confirmPinRefs[index + 1].current.focus();
      if (!value && index > 0) confirmPinRefs[index - 1].current.focus();
    }
  };

  const validateInputs = () => {
    if (oldPin.includes("") || newPin.includes("") || confirmPin.includes("")) {
      message.error("All fields must be filled.");
      return false;
    }
    return true;
  };

  const clearAllInputs = () => {
    setOldPin(["", "", "", ""]);
    setNewPin(["", "", "", ""]);
    setConfirmPin(["", "", "", ""]);
    oldPinRefs[0].current.focus();
  };

  const handleSavePin = async () => {
    if (!validateInputs()) {
      clearAllInputs();
      return;
    }

    if (newPin.join("") !== confirmPin.join("")) {
      message.error("New PIN and Confirm PIN do not match.");
      clearAllInputs();
      return;
    }

    setIsSaving(true);

    try {
      const vaultToken = localStorage.getItem("sessionToken");
      await axios.patch(
        `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/vault/pin`,
        {
          oldPin: parseInt(oldPin.join("")),
          newPin: parseInt(newPin.join("")),
        },
        {
          headers: {
            Authorization: `Bearer ${vaultToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      message.success("PIN changed successfully!");
      clearAllInputs();

      setTimeout(() => setShowVaultAccess(true), 2000);
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 403) {
          message.error(data.error.message || "Vault is locked. Contact superadmin.");
        } else if (status === 401) {
          message.error("The old PIN you entered is incorrect. Please try again.");
        } else {
          message.error(data.error.message || "An unexpected error occurred. Please try again.");
        }
      } else {
        message.error("Unable to connect to the server. Please check your internet connection.");
      }
      clearAllInputs();
    } finally {
      setIsSaving(false);
    }
  };

  const iconButtonStyle = {
    color: "#59616E",
    background: "none",
    boxShadow: "none",
    outline: "none",
    border: "none",
    cursor: "pointer",
    transition: "color 0.3s",
  };

  if (showVaultAccess) {
    return <VaultAccess />;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center p-8 bg-white rounded-lg shadow-md w-[428px] max-h-[650px]">
        <h2 className="text-2xl font-semibold text-[#59616E] mb-4">Change Vault Pin</h2>
        <div className="my-4">
          <img src={LockIcon} alt="Lock Icon" className="w-[64px] h-[64px]" />
        </div>
        <p className="text-center text-[#798897] mb-6">
          To change your vault 4 digit PIN, follow the steps and secure your important documents in the vault.
        </p>

        {/* Form */}
        <form autoComplete="off">
          {/* Old PIN Input */}
          <div className="flex justify-between items-center self-stretch mb-4">
            <label className="text-lg font-semibold text-[#59616E]">Enter your Old 4 digit PIN</label>
            <Button
              icon={showOldPin ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              onClick={() => setShowOldPin(!showOldPin)}
              style={iconButtonStyle}
              onMouseDown={(e) => e.preventDefault()}
            />
          </div>
          <div className="flex justify-between gap-3 mb-4 w-full">
            {oldPin.map((digit, index) => (
              <Input
                key={index}
                ref={oldPinRefs[index]}
                type={showOldPin ? "text" : "password"}
                maxLength={1}
                value={digit}
                onChange={(e) => handlePinChange(index, e.target.value, "old")}
                onKeyDown={(e) => {
                  if (e.key === "Backspace" && !digit && index > 0) {
                    oldPinRefs[index - 1].current.focus();
                  }
                }}
                className="w-12 h-12 text-center border-gray-300 rounded"
                style={{ boxShadow: "none", outline: "none", borderColor: "#C4CBD8" }}
              />
            ))}
          </div>

          {/* New PIN Input */}
          <div className="flex justify-between items-center self-stretch mb-4">
            <label className="text-lg font-semibold text-[#59616E]">Enter a new 4 digit PIN</label>
            <Button
              icon={showNewPin ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              onClick={() => setShowNewPin(!showNewPin)}
              style={iconButtonStyle}
              onMouseDown={(e) => e.preventDefault()}
            />
          </div>
          <div className="flex justify-between gap-3 mb-4 w-full">
            {newPin.map((digit, index) => (
              <Input
                key={index}
                ref={newPinRefs[index]}
                type={showNewPin ? "text" : "password"}
                maxLength={1}
                value={digit}
                onChange={(e) => handlePinChange(index, e.target.value, "new")}
                onKeyDown={(e) => {
                  if (e.key === "Backspace" && !digit && index > 0) {
                    newPinRefs[index - 1].current.focus();
                  }
                }}
                className="w-12 h-12 text-center border-gray-300 rounded"
                style={{ boxShadow: "none", outline: "none", borderColor: "#C4CBD8" }}
              />
            ))}
          </div>

          {/* Confirm PIN Input */}
          <div className="flex justify-between items-center self-stretch mb-4">
            <label className="text-lg font-semibold text-[#59616E]">Confirm your 4 digit PIN</label>
            <Button
              icon={showConfirmPin ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              onClick={() => setShowConfirmPin(!showConfirmPin)}
              style={iconButtonStyle}
              onMouseDown={(e) => e.preventDefault()}
            />
          </div>
          <div className="flex justify-between gap-3 mb-6 w-full">
            {confirmPin.map((digit, index) => (
              <Input
                key={index}
                ref={confirmPinRefs[index]}
                type={showConfirmPin ? "text" : "password"}
                maxLength={1}
                value={digit}
                onChange={(e) => handlePinChange(index, e.target.value, "confirm")}
                onKeyDown={(e) => {
                  if (e.key === "Backspace" && !digit && index > 0) {
                    confirmPinRefs[index - 1].current.focus();
                  }
                }}
                className="w-12 h-12 text-center border-gray-300 rounded"
                style={{ boxShadow: "none", outline: "none", borderColor: "#C4CBD8" }}
              />
            ))}
          </div>
        </form>

        {/* Buttons */}
        <div className="flex w-full justify-end items-center gap-3 mt-4">
          <button
            className="px-6 py-2 rounded-md bg-[#C4CBD8] text-[#1B2028]"
            onClick={onBack}
            style={{ minWidth: "100px", minHeight: "40px" }}
          >
            Back
          </button>
          <button
            className={`px-6 py-2 rounded-md bg-[#41EAD4] text-[#192028] ${
              isSaving ? "cursor-not-allowed opacity-50" : ""
            }`}
            onClick={handleSavePin}
            disabled={isSaving}
            style={{ minWidth: "100px", minHeight: "40px" }}
          >
            {isSaving ? <Spin size="small" /> : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePin;
