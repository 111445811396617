import React, { useState } from 'react';

const VaultTabs = () => {
    const [activeTab, setActiveTab] = useState('uploaded');

    const tabs = [
        { label: 'Uploaded by you', value: 'uploaded' },
        { label: 'Shared Documents', value: 'shared' },
        { label: 'Flagged Documents', value: 'flagged' },
        { label: 'Pinned Documents', value: 'pinned' },
    ];

    return (
        <div className="flex items-center gap-4 h-8">
            {tabs.map((tab) => (
                <div
                    key={tab.value}
                    onClick={() => setActiveTab(tab.value)}
                    className={`cursor-pointer text-[14px] font-medium ${
                        activeTab === tab.value ? 'text-[#007768] border-b-2 border-[#007768]' : 'text-[#59616E]'
                    }`}
                    style={{ paddingBottom: '2px' }} // Adjust padding for underline alignment
                >
                    {tab.label}
                </div>
            ))}
        </div>
    );
};



export default VaultTabs;