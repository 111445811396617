import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import axios from 'axios';
import Brixlogo from '../../../assets/PNG/Brix-Logo.png';
import ExpiredTokenSVG from '../../../assets/SVG/expiredToken.svg'; 
import CheckmarkSVG from '../../../assets/SVG/Success.svg'; 
import ErrorSVG from '../../../assets/SVG/ErrorLoad.svg';

const ResetForm = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isExpiredToken, setIsExpiredToken] = useState(false);
  const [isDataFailed, setIsDataFailed] = useState(false); 
  const [dataFailedMessage, setDataFailedMessage] = useState('');
  const [expiredTokenMessage, setExpiredTokenMessage] = useState('');
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  useEffect(() => {
    if (!token) {
      setIsExpiredToken(true);
    }
  }, [token]);

  useEffect(() => {
    const isPasswordValid = validatePassword(newPassword);
    const isConfirmPasswordValid = newPassword === confirmPassword;

    setButtonDisabled(!(isPasswordValid && isConfirmPasswordValid));
  }, [newPassword, confirmPassword]);

  const validatePassword = (password) => {
    const minLength = /.{8,}/;
    const hasUppercase = /[A-Z]/;
    const hasLowercase = /[a-z]/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    return (
      minLength.test(password) &&
      hasUppercase.test(password) &&
      hasLowercase.test(password) &&
      hasSpecialChar.test(password)
    );
  };

  const handleReset = async () => {
    if (validatePassword(newPassword) && newPassword === confirmPassword) {
      setError('');
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/auth/resetPassword`,
          {
            token,
            password: newPassword,
          }
        );

        if (response.status === 201) {
          setIsPasswordResetSuccess(true); 
          setSuccessMessage(response.data.message); 

          // Redirect after showing success
          setTimeout(() => {
            navigate('/auth/login');
          }, 5000);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setIsExpiredToken(true);
          setExpiredTokenMessage(
            error.response.data.message || 'The token is expired or invalid.'
          );
        } else {
          setIsDataFailed(true);
          setDataFailedMessage('Something went wrong. Please try again later.');
        }
      }
    } else {
      setError('Passwords do not match or are invalid.');
    }
  };

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center px-4">
      <div className="absolute top-0 left-0 ml-[-150px]">
      <img
        src={Brixlogo}
        alt="Brix Logo"
        className="w-[116px] h-[44px] cursor-pointer"
        onClick={() => navigate("/auth/login")}
      />
      </div>

      {isExpiredToken ? (
        // Expired Token Screen
        <div className="flex flex-col items-center">
          <img src={ExpiredTokenSVG} alt="Expired Token" className="w-20 h-20 mb-4" />
          {expiredTokenMessage && (
            <p className="text-[16px] font-normal text-[#FF412E] text-center">
              {expiredTokenMessage}
            </p>
          )}
        </div>
      ) : isPasswordResetSuccess ? (
        // Success Screen
        <div className="flex flex-col items-center">
          <img src={CheckmarkSVG} alt="Success" className="w-20 h-20 mb-4" />
          <p className="text-[16px] font-normal text-[#121212] text-center">
            {successMessage}
          </p>
          <p className="text-[14px] font-light text-[#768090] text-center">
            You are now being redirected to the login page.
          </p>
        </div>
      ) : isDataFailed ? (
        // Data Failed Screen
        <div className="flex flex-col items-center">
          <img src={ErrorSVG} alt="Error" className="w-20 h-20 mb-4" />
          {dataFailedMessage && (
            <p className="text-[16px] font-normal text-[#FF412E] text-center">
              {dataFailedMessage}
            </p>
          )}
        </div>
      ) : (
        // Reset Password Form
        <div className="w-full max-w-md">
          <h2 className="text-[30px] font-semibold text-[#121212] mb-2 leading-[38px] font-sf-pro">
            Reset Password
          </h2>
          <p className="text-[16px] font-normal text-[#121212] mb-6 leading-[24px]">
            Enter a new password and confirm it to regain access.
          </p>

          <div className="flex flex-col items-start gap-1 mb-4 w-full">
            <label htmlFor="newPassword" className="text-[16px] text-[#1B2028] font-normal leading-[24px]">
              New Password
            </label>
            <div className="relative w-full">
              <input
                id="newPassword"
                type={isNewPasswordVisible ? 'text' : 'password'}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <span
                onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#768090] w-6 h-6 cursor-pointer"
              >
                {isNewPasswordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              </span>
            </div>
          </div>

          <div className="flex flex-col items-start gap-1 mb-2 w-full">
            <label htmlFor="confirmPassword" className="text-[16px] text-[#1B2028] font-normal leading-[24px]">
              Confirm Password
            </label>
            <div className="relative w-full">
              <input
                id="confirmPassword"
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <span
                onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#768090] w-6 h-6 cursor-pointer"
              >
                {isConfirmPasswordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              </span>
            </div>
          </div>

          {error && (
            <p className="text-[12px] font-normal text-[#FF412E] leading-[20px] mb-4">
              {error}
            </p>
          )}

          <button
            onClick={handleReset}
            disabled={buttonDisabled}
            className={`flex h-[40px] p-2 justify-center items-center gap-2 w-full rounded font-medium transition duration-300 
              ${buttonDisabled ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-[#FF6F61] text-white hover:bg-[#ff5a4d]'}`}>
            Reset
          </button>
        </div>
      )}
    </div>
  );
};

export default ResetForm;