import React, { useState, useEffect } from 'react';
import { Button, Dialog, IconButton } from '@material-ui/core';
import AddCircleIcon from '@atlaskit/icon/glyph/add-circle';
import ArrowBackIosNewIcon from '@atlaskit/icon/glyph/chevron-left-circle';
import ArrowForwardIosIcon from '@atlaskit/icon/glyph/chevron-right-circle';
import { ReactComponent as NoProjectsImage } from '../../assets/noprojects.svg';
import Header from '../layouts/header';
import RecentlyViewed from './../Components/Projects/RecentlyViewed';
import ProjectForm from './../Components/Projects/ProjectForm';
import ProjectTable from './../Components/Projects/ProjectTable';
import { useAuth } from './../authcontext';
import axios from 'axios';

const App = () => {
  const [open, setOpen] = useState(false);
  const [showImage, setShowImage] = useState(true);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const { userId, userEmail} = useAuth();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_NON_PROD_URL}/projects/?userId=${userId}`);
        const data = response.data;
        console.log(data);
        if (data && data.data.projects) {
          setProjects(data.data.projects);
          setShowImage(data.data.projects.length === 0);
        } else {
          console.error('Projects data is undefined or not in expected format:', data);
          setShowImage(true);
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, [userId]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = async (newProject) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_NON_PROD_URL}/projects`, newProject);
      const createdProject = response.data.project;
      setProjects([...projects, createdProject]);
      setShowImage(false);
      setOpen(false);
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };

  const handleCheckboxClick = (event, projectName) => {
    event.stopPropagation();
    setSelectedProject(projectName);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* <Header /> */}
      <div className="flex justify-between items-center p-4">
        <div className="flex flex-col items-center space-y-1">
          <div className="flex space-x-2">
            <IconButton size="small">
              <ArrowBackIosNewIcon fontSize="small" />
            </IconButton>
            <IconButton size="small">
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
          </div>
          <h1 style={{ color: '#172B4D' }}>ALL Projects</h1>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          style={{ backgroundColor: '#41EAD4', color: '#172B4D', width: '157px', height: '36px', fontSize: '12px', fontWeight: 'bold', textTransform: 'none' }}
          startIcon={<AddCircleIcon />}
        >
          Create Project
        </Button>
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth="md">
          <ProjectForm onCreate={handleCreate} />
      </Dialog>

      <div className="container mx-auto mt-8">
        {showImage ? (
          <div className="flex justify-center items-center min-h-screen mt-[-140px]">
            <NoProjectsImage />
          </div>
        ) : (
          <div className="p-4 w-full mt-[-50px]">
            <RecentlyViewed />
            <ProjectTable projects={projects} selectedProject={selectedProject} handleCheckboxClick={handleCheckboxClick} />
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
