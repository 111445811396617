import React from 'react';
import ScrollTeam from './Common/ScrollTeam';
import KnowTeam from './Common/KnowTeam';
import TeamMembers from './Common/TeamMembers';
import ViewAll from './Common/ViewAll';
import { useState } from 'react';

const teams = []; // Replace with your actual data


const Managed = ({ viewAll }) => {
  const [memberIds, setMemberIds] = useState([]);
  const hasTeams = teams.length > 0; // Check if there are any teams/cards

  return (
    <div>
      {/* Conditionally render based on viewAll prop */}
      {!viewAll ? (
        <div className="p-4">
          {/* First Row: ScrollTeam component */}
          <div>
            <ScrollTeam type="managed" onTeamSelect={(members) => setMemberIds(members)} />
          </div>

          {/* Second Row: KnowTeam and TeamMembers should ALWAYS be visible */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="md:col-span-1">
              {/* Show normal content if there are teams, otherwise empty state */}
              <KnowTeam hasTeams={hasTeams} />
            </div>
            <div className="md:col-span-2">
              {/* Show normal content if there are teams, otherwise empty state */}
              <TeamMembers memberIds={memberIds} />
            </div>
          </div>
        </div>
      ) : (
        <ViewAll type="managed" /> // Render the ViewAll component when viewAll is true
      )}
    </div>
  );
};

export default Managed;