// import React, { useState, useEffect } from 'react';
// import { Avatar, Button, message } from 'antd';
// import { PlusOutlined, FilterOutlined } from '@ant-design/icons';
// import AddNewUserModal from '../../Services/Modal/AddNewUserModal';

// const AdminPage = () => {
//   const [isModalVisible, setModalVisible] = useState(false);
//   const [pendingRequests, setPendingRequests] = useState([]);
//   const [users, setUsers] = useState([]);
//   const [loadingPending, setLoadingPending] = useState(true);
//   const [loadingUsers, setLoadingUsers] = useState(true);

//   // Function to open modal
//   const openModal = () => {
//     setModalVisible(true);
//   };

//   // Function to close modal
//   const closeModal = () => {
//     setModalVisible(false);
//   };

//   // Function to check if the link has expired
//   const hasLinkExpired = (updatedAt) => {
//     const expirationDate = new Date(updatedAt);
//     expirationDate.setMinutes(expirationDate.getMinutes() + 15);
//     return expirationDate < new Date();
//   };

//   // Function to regenerate link
//   const regenerateLink = async (user) => {
//     try {
//       const response = await fetch('https://auth-comm-service.onrender.com/api/v1/admin/resendGenPasswordMail', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
//         },
//         body: JSON.stringify({
//           id: user._id,
//           email: user.email,
//           name: user.name,
//         }),
//       });
//       const data = await response.json();
//       if (response.ok) {
//         message.success('Link regenerated successfully');
//         fetchPendingRequests(); // Refresh the list
//       } else {
//         message.error(data.message || 'Failed to regenerate link');
//       }
//     } catch (error) {
//       console.error('Error regenerating link:', error);
//       message.error('Error regenerating link');
//     }
//   };

//   // Fetch pending requests
//   const fetchPendingRequests = async () => {
//     try {
//       const response = await fetch('https://auth-comm-service.onrender.com/api/v1/admin/users?pending=true', {
//         method: 'GET',
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
//         },
//       });
//       const data = await response.json();
//       setPendingRequests(data.data.users || []);
//     } catch (error) {
//       console.error('Error fetching pending requests:', error);
//       message.error('Error fetching pending requests');
//     } finally {
//       setLoadingPending(false);
//     }
//   };

//   // Fetch all users
//   const fetchUsers = async () => {
//     try {
//       const response = await fetch('https://auth-comm-service.onrender.com/api/v1/admin/users', {
//         method: 'GET',
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
//         },
//       });
//       const data = await response.json();
//       setUsers(data.data.users || []);
//     } catch (error) {
//       console.error('Error fetching users:', error);
//       message.error('Error fetching users');
//     } finally {
//       setLoadingUsers(false);
//     }
//   };

//   // Load data on component mount
//   useEffect(() => {
//     fetchPendingRequests();
//     fetchUsers();
//   }, []);

//   return (
//     <div className=" flex flex-col space-y-8 min-h-screen ">
//       {/* Pending Requests Section */}
//       <div className="flex flex-col max-w-[1430px] p-6 rounded-lg bg-white shadow-md overflow-y-auto">
//         <div className="flex justify-between items-center mb-4">
//           <h2 className="text-lg font-semibold text-[#192028]">Pending Requests</h2>
//           <button
//             className="flex items-center px-4 py-2 text-red-600 border border-red-200 rounded-lg"
//             onClick={openModal}
//           >
//             <PlusOutlined className="mr-2" />
//             Create New User
//           </button>
//         </div>

//         {loadingPending ? (
//           <div className="space-y-4 animate-pulse">
//             {[...Array(5)].map((_, rowIndex) => (
//               <div key={rowIndex} className="flex items-center space-x-4 p-4 border-b border-gray-200">
//                 <div className="w-10 h-10 bg-gray-300 rounded-full"></div> {/* Placeholder for avatar */}
//                 <div className="flex-1 space-y-2">
//                   {/* <div className="h-4 bg-gray-300 rounded w-3/4"></div>  */}
//                   <div className="h-4 bg-gray-300 rounded w-1/2"></div> {/* Placeholder for designation */}
//                 </div>
//                 <div className="h-4 bg-gray-300 rounded" style={{ width: '80px' }}></div> {/* Placeholder for request date */}
//                 <div className="h-4 bg-gray-300 rounded" style={{ width: '150px' }}></div> {/* Placeholder for created by */}
//                 <div className="h-4 bg-gray-300 rounded" style={{ width: '80px' }}></div> {/* Placeholder for link expiry */}
//                 <div className="h-8 w-24 bg-gray-300 rounded-md"></div> {/* Placeholder for action button */}
//               </div>
//             ))}
//           </div>
//         ) : (
//           <table className="table-auto w-full border-separate" style={{ borderSpacing: '0 10px' }}>
//             <thead className="text-left text-sm font-semibold text-[#798897] bg-[#FCF9F9]">
//               <tr>
//                 <th className="px-4 py-2">Username</th>
//                 <th className="px-4 py-2">Request At</th>
//                 <th className="px-4 py-2">Status</th>
//                 <th className="px-4 py-2">Created By</th>
//                 <th className="px-4 py-2">Link Expires At</th>
//                 <th className="px-4 py-2">Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {pendingRequests.map((user) => {
//                 const linkExpired = hasLinkExpired(user.updatedAt);
//                 return (
//                   <tr key={user._id} className="bg-white shadow-sm rounded-lg">
//                     <td className="px-4 py-2">
//                       <div className="flex items-center gap-2">
//                         <Avatar src="https://i.pravatar.cc/30?img=64" alt="User Avatar" size={30} />
//                         <span>{`${user.name} ${user.lastName}`}</span>
//                       </div>
//                     </td>
//                     <td className="px-4 py-2">{new Date(user.updatedAt).toLocaleString()}</td>
//                     <td className="px-4 py-2">
//                       <span
//                         className={`px-3 py-1 border text-sm font-medium rounded-md ${
//                           linkExpired ? 'bg-gray-200 text-gray-600 border-gray-300' : 'bg-yellow-100 text-yellow-600 border-yellow-300'
//                         }`}
//                       >
//                         {linkExpired ? 'Expired' : 'Pending'}
//                       </span>
//                     </td>
//                     <td className="px-4 py-2">{user.createdBy?.name || 'N/A'}</td>
//                     <td className="px-4 py-2">
//                       {linkExpired ? 'N/A' : new Date(new Date(user.updatedAt).getTime() + 15 * 60000).toLocaleString()}
//                     </td>
//                     <td className="px-4 py-2">
//                       {linkExpired ? (
//                         <button
//                           className="px-4 py-1 rounded-lg bg-teal-500 text-white"
//                           onClick={() => regenerateLink(user)}
//                         >
//                           Regenerate Link
//                         </button>
//                       ) : (
//                         <button className="px-4 py-1 rounded-lg bg-gray-300 text-gray-500" disabled>
//                           Regenerate Link
//                         </button>
//                       )}
//                     </td>
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </table>
//         )}
//       </div>

//       {/* Users Section */}
//       <div className="flex flex-col max-w-[1430px] p-4 rounded-lg bg-white shadow-md overflow-auto">
//         <div className="flex justify-between items-center mb-4">
//           <h2 className="text-lg font-semibold text-[#192028]">Users</h2>
         
//         </div>

//         {loadingUsers ? (
//           <div className="space-y-4 animate-pulse">
//             {[...Array(8)].map((_, rowIndex) => (
//               <div key={rowIndex} className="flex items-center space-x-4 p-4 border-b border-gray-200">
//                 <div className="w-10 h-10 bg-gray-300 rounded-full"></div> {/* Placeholder for avatar */}
//                 <div className="flex-1 space-y-2">
//                   {/* <div className="h-4 bg-gray-300 rounded w-3/4"></div>  */}
//                   <div className="h-4 bg-gray-300 rounded w-1/2"></div> {/* Placeholder for designation */}
//                 </div>
//                 <div className="h-4 bg-gray-300 rounded" style={{ width: '80px' }}></div> {/* Placeholder for joined date */}
//                 <div className="h-4 bg-gray-300 rounded" style={{ width: '150px' }}></div> {/* Placeholder for email */}
//               </div>
//             ))}
//           </div>
//         ) : (
//           <table className="table-auto w-full border-separate" style={{ borderSpacing: '0 10px' }}>
//             <thead className="text-left text-sm font-semibold text-[#798897] bg-[#FCF9F9]">
//               <tr>
//                 <th className="px-4 py-2">Name</th>
//                 <th className="px-4 py-2">Designation</th>
//                 <th className="px-4 py-2">Joined Date</th>
//                 <th className="px-4 py-2">Email</th>
//               </tr>
//             </thead>
//             <tbody>
//               {users.map((user) => (
//                 <tr key={user._id} className="bg-white shadow-sm rounded-lg">
//                   <td className="px-4 py-2 flex items-center gap-2">
//                     <Avatar src="https://i.pravatar.cc/30?img=64" alt="User Avatar" size={30} />
//                     {`${user.name} ${user.lastName}`}
//                   </td>
//                   <td className="px-4 py-2">{user.type || 'N/A'}</td>
//                   <td className="px-4 py-2">{new Date(user.createdAt).toLocaleDateString()}</td>
//                   <td className="px-4 py-2">{user.email}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         )}
//       </div>

//       {/* Add New User Modal */}
//       <AddNewUserModal isVisible={isModalVisible} onClose={closeModal} isSuperAdmin={false} />
//     </div>
//   );
// };

// export default AdminPage;

































import React, { useState } from 'react';
import { message } from 'antd';
import PendingAdmin from '../../Services/SettingsTable/PendingAdmin';
import UsersAdmin from '../../Services/SettingsTable/UsersAdmin';

const SuperAdminPage = () => {
  const [pendingMoreToCome, setPendingMoreToCome] = useState(true);
  const [usersMoreToCome, setUsersMoreToCome] = useState(true);

  const fetchPendingRequests = async (page) => {
    if (!pendingMoreToCome) return []; // Stop API calls if moreToCome is false

    try {
      const url =
        page === 0
          ? `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/admin/users?pending=true`
          : `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/admin/users?pending=true&page=${page}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        // Update moreToCome state
        if (!data.data.moreToCome) {
          setPendingMoreToCome(false);
        }
        return data.data.users || [];
      } else {
        message.error(data.message || 'Failed to fetch pending requests');
        return [];
      }
    } catch (error) {
      console.error('Error fetching pending requests:', error);
      message.error('Error fetching pending requests');
      return [];
    }
  };

  const fetchUsersApi = async (page) => {
    if (!usersMoreToCome) return []; // Stop API calls if moreToCome is false

    try {
      const url =
        page === 0
          ? `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/admin/users`
          : `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/admin/users?page=${page}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('sessionToken')}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        // Update moreToCome state
        if (!data.data.moreToCome) {
          setUsersMoreToCome(false);
        }
        return data.data.users || [];
      } else {
        message.error(data.message || 'Failed to fetch users');
        return [];
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      message.error('Error fetching users');
      return [];
    }
  };

  return (
    <div className="flex flex-col space-y-8 min-h-screen p-6 bg-gray-100">
      {/* Pending Requests Section */}
      <PendingAdmin />

      {/* Users Section */}
      <UsersAdmin fetchUsersApi={fetchUsersApi} />
    </div>
  );
};

export default SuperAdminPage;
