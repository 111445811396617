import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useAuth } from "../../authcontext";
import DocCard from "../../Services/UI/DocCard";
import { message } from "antd"; // Importing Ant Design's message for notification
import { useNavigate } from "react-router-dom";
import DefaultDocEmpty from '../../Services/UI/EmptyState/DefaultDocEmpty'; // Importing DefaultDocEmpty for empty state
import DocLoading from "../../Services/UI/LoadingState/DocLoading"; // Importing DocLoading for loading state

const Default = () => {
  const navigate = useNavigate();
  const { userId } = useAuth(); // Retrieve user ID from auth context
  const [activeTab, setActiveTab] = useState("receivedDocuments"); // Active tab state
  const [documents, setDocuments] = useState([]); // Documents list state
  const [loading, setLoading] = useState(false); // Loading state
  const [page, setPage] = useState(0); // Page state for pagination
  const [moreToCome, setMoreToCome] = useState(true); // moreToCome flag from API
  const loaderRef = useRef(null); // Ref for the loader to detect when to load more
  const [currentStatus, setCurrentStatus] = useState({}); // Current pin status of documents

  // Function to handle tab switching
  const handleTabClick = (tabName) => {
    setActiveTab(tabName); // Change active tab
    setDocuments([]); // Reset documents list
    setPage(0); // Reset page for new API call
    setMoreToCome(true); // Reset moreToCome flag
  };

  // Function to fetch documents based on active tab and page
  const fetchDocuments = async (defaultType, currentPage) => {
    try {
      setLoading(true); // Start loading

      let url = `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/list/${userId}?defaultType=${defaultType}`;
      if (currentPage > 0) {
        url += `&page=${currentPage}`; // Append page number for subsequent calls
      }

      const response = await axios.get(url);
      const fetchedDocuments = response.data.data.finalList || [];
      const bookmarkStatuses = fetchedDocuments.reduce((acc, doc) => {
        acc[doc._id] = doc.bookmarkStatus;
        return acc;
      }, {});

      setDocuments((prevDocuments) => [...prevDocuments, ...fetchedDocuments]); // Append new documents to existing list
      setMoreToCome(response.data.data.moreToCome); // Update moreToCome flag
      setCurrentStatus((prevStatus) => ({ ...prevStatus, ...bookmarkStatuses })); // Set the current bookmark statuses
    } catch (error) {
      console.error("Error fetching documents:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Function to handle pin toggle (bookmarking)
  const handlePinToggle = async (docId) => {
    try {
      const newStatus = !currentStatus[docId]; // Toggle the current status
      setCurrentStatus((prevStatus) => ({
        ...prevStatus,
        [docId]: newStatus, // Update the status in the local state
      }));

      await axios.patch(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}/bookmark?status=${newStatus}&userId=${userId}`
      );

      message.success(
        newStatus
          ? "Document has been successfully pinned!"
          : "Document has been successfully unpinned!"
      );
    } catch (error) {
      console.error("Error toggling pin status:", error);
      message.error("Failed to update the document pin status.");
    }
  };

  // Handle navigation when a document is clicked
  const handleDocumentClick = (docId) => {
    navigate(`/documents/${docId}/versions`);
  };

  // Observe when the loader is visible and trigger loading more documents
  useEffect(() => {
    if (!loaderRef.current || loading || !moreToCome) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && moreToCome) {
          setPage((prevPage) => prevPage + 1); // Load next page when loader is visible
        }
      },
      { threshold: 1.0 }
    );

    observer.observe(loaderRef.current);

    return () => {
      if (loaderRef.current) observer.unobserve(loaderRef.current);
    };
  }, [loading, moreToCome]);

  useEffect(() => {
    // Determine the defaultType based on activeTab
    let defaultType = "own";
    if (activeTab === "teamSharedDocuments") {
      defaultType = "team";
    } else if (activeTab === "receivedDocuments") {
      defaultType = "org";
    }

    fetchDocuments(defaultType, page); // Fetch documents based on active tab and page
  }, [activeTab, page]);

  return (
    <div className="flex flex-col px-4">
      {/* Tab navigation */}
      <div className="flex justify-start space-x-8">
        <button
          className={`text-lg font-semibold ${
            activeTab === "receivedDocuments" ? "text-teal-700" : "text-gray-700"
          }`}
          onClick={() => handleTabClick("receivedDocuments")}
        >
          Received Documents
          {activeTab === "receivedDocuments" && (
            <div className="border-b-2 border-teal-600 mt-2"></div>
          )}
        </button>

        <button
          className={`text-lg font-semibold ${
            activeTab === "teamSharedDocuments" ? "text-teal-700" : "text-gray-700"
          }`}
          onClick={() => handleTabClick("teamSharedDocuments")}
        >
          Team Shared Documents
          {activeTab === "teamSharedDocuments" && (
            <div className="border-b-2 border-teal-600 mt-2"></div>
          )}
        </button>

        <button
          className={`text-lg font-semibold ${
            activeTab === "myUploadedFiles" ? "text-teal-700" : "text-gray-700"
          }`}
          onClick={() => handleTabClick("myUploadedFiles")}
        >
          My Uploaded Files
          {activeTab === "myUploadedFiles" && (
            <div className="border-b-2 border-teal-600 mt-2"></div>
          )}
        </button>
      </div>

      {/* Content Section */}
      <div className="mt-8">
        <div className="grid grid-cols-1 gap-4">
          {loading && documents.length === 0 ? ( // Show loading state while fetching documents
            <DocLoading />
          ) : documents.length > 0 ? (
            documents.map((doc) => (
              <DocCard
                key={doc._id}
                doc={doc}
                handleDocumentClick={handleDocumentClick}
                handlePinToggle={() => handlePinToggle(doc._id)}
              />
            ))
          ) : (
            !loading && <DefaultDocEmpty /> // Show empty state when no documents are available
          )}
        </div>

        {/* Loader for more documents */}
        {loading && documents.length > 0 && <DocLoading />} {/* Show loading for additional pages */}
        {!loading && moreToCome && <div ref={loaderRef} className="loader">Loading...</div>}
      </div>
    </div>
  );
};

export default Default;









