import React, { useState } from 'react';
import { EyeOutlined, MoreOutlined, EditOutlined, FileAddOutlined, FormOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import FoldersSvgicon from '../../../assets/SVG/Folders.svg';
import EditDetails from '../EditModal/EditFolderDetails';
import EditPermissions from '../EditModal/EditPermissionsFolderCardModal';
import AddDocInsideFolderModal from '../Modal/AddDocInsideFolderModal';
import { useAuth } from '../../authcontext';

const NestedFolderCard = ({ folder, handleFolderClick, permission, folderType, folderName }) => {
    const [isEditModalVisible, setEditModalVisible] = useState(false);
    const [isPermissionModalVisible, setPermissionModalVisible] = useState(false);
    const [isAddDocumentModalVisible, setAddDocumentModalVisible] = useState(false); 
    const { userId } = useAuth();
    const [hoveredItem, setHoveredItem] = useState(null);

    const getMenu = () => (
        <Menu
            style={{
                display: 'flex',
                width: '199px',
                padding: '4px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                borderRadius: '8px',
                background: '#FFF',
                boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
            }}
        >
            <Menu.Item
                key="1"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                    borderRadius: '4px',
                    margin: '4px 0',
                    padding: '8px 12px',
                    color: hoveredItem === 1 ? '#1B2028' : '#798897',
                    backgroundColor: hoveredItem === 1 ? '#D1F8F0' : 'transparent',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                }}
                onMouseEnter={() => setHoveredItem(1)}
                onMouseLeave={() => setHoveredItem(null)}
                onClick={() => setEditModalVisible(true)}
            >
                <EditOutlined style={{ marginRight: '8px', color: hoveredItem === 1 ? '#1B2028' : '#798897', width: '16px', height: '16px' }} />
                Edit Details
            </Menu.Item>
            <Menu.Item
                key="2"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                    borderRadius: '4px',
                    padding: '8px 12px',
                    color: hoveredItem === 2 ? '#1B2028' : '#798897',
                    margin: '4px 0',
                    backgroundColor: hoveredItem === 2 ? '#D1F8F0' : 'transparent',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                }}
                onMouseEnter={() => setHoveredItem(2)}
                onMouseLeave={() => setHoveredItem(null)}
                onClick={() => setPermissionModalVisible(true)}
            >
                <FormOutlined style={{ marginRight: '8px', color: hoveredItem === 2 ? '#1B2028' : '#798897', width: '16px', height: '16px' }} />
                Edit Permission
            </Menu.Item>
            <Menu.Item
                key="3"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '100%',
                    borderRadius: '4px',
                    padding: '8px 12px',
                    color: hoveredItem === 3 ? '#1B2028' : '#798897',
                    margin: '4px 0',
                    backgroundColor: hoveredItem === 3 ? '#D1F8F0' : 'transparent',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                }}
                onMouseEnter={() => setHoveredItem(3)}
                onMouseLeave={() => setHoveredItem(null)}
                onClick={() => setAddDocumentModalVisible(true)}
            >
                <FileAddOutlined style={{ marginRight: '8px', color: hoveredItem === 3 ? '#1B2028' : '#798897', width: '16px', height: '16px' }} />
                Add Document
            </Menu.Item>
        </Menu>
    );

    // Safety check for folder and its properties, fallback to defaults if undefined
    const progressWidth = folder && folder.items ? (folder.items / 30) * 100 : 0;

    return (
        <>
            <div
                key={folder?._id}
                className="border border-gray-100 rounded-lg p-4 bg-white shadow-sm relative cursor-pointer"
                style={{
                    maxWidth: '320px',
                    maxHeight: '240px',
                    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)',
                    borderRadius: '8px',
                }}
                onClick={() => handleFolderClick(folder?._id)}
            >
                {/* Folder Permission and Icons */}
                <div
                    style={{
                        position: 'absolute',
                        top: '12px',
                        left: '12px',
                        width: '30px',
                        height: '30px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '50%',
                        backgroundColor: permission === 'modify' ? '#41EAD4' : '#AEB8CA',
                        boxShadow: permission === 'modify' 
                            ? '0px 2px 6px 0px rgba(65, 234, 212, 0.50)' 
                            : '0px 2px 6px 0px rgba(174, 184, 202, 0.50)',
                    }}
                >
                    {permission=== 'modify' ? (
                        <FormOutlined style={{ fontSize: '20px', color: '#FFFFFF' }} />
                    ) : (
                        <EyeOutlined style={{ fontSize: '20px', color: '#FFFFFF' }} />
                    )}
                </div>

                {/* More Actions */}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        position: 'absolute',
                        top: '12px',
                        right: '12px',
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    {folderType && (
                        <span
                            style={{
                                display: 'flex',
                                padding: '1px 8px',
                                alignItems: 'center',
                                gap: '4px',
                                borderRadius: '4px',
                                border: '1px solid #4ED2BF',
                                backgroundColor: '#F8FEFD',
                                color: '#007768',
                                fontSize: '12px',
                                fontWeight: '600',
                            }}
                        >
                            {folderType}
                        </span>
                    )}

                    {permission === 'modify' && (
                        <Dropdown overlay={getMenu()} trigger={['click']}>
                            <MoreOutlined style={{ fontSize: '22px', color: '#768090', marginLeft: '8px', cursor: 'pointer' }} />
                        </Dropdown>
                    )}
                </div>

                {/* Folder Icon */}
                <div className="flex justify-center mb-2 mt-2 opacity-60">
                    <img src={FoldersSvgicon} alt="Folder Icon" width="48" height="48" />
                </div>

                {/* File and Folder Count */}
                <div className="flex justify-between items-center mb-2">
                    <span className="text-sm bg-gray-100 px-2 py-1 rounded-full" style={{ color: '#000000E0', fontWeight: '600', fontSize: '10px' }}>
                        {folder?.items || 0} Files
                    </span>
                    <span className="text-sm bg-gray-100 px-2 py-1 rounded-full" style={{ color: '#000000E0', fontWeight: '600', fontSize: '10px' }}>
                        {folder?.folderCount || 0} Folders
                    </span>
                </div>

                {/* Progress Bar */}
                <div className="h-1 w-full bg-gray-200 mb-4">
                    <div
                        className="h-full bg-teal-500"
                        style={{ width: `${progressWidth}%` }}
                    ></div>
                </div>

                {/* Folder Name */}
                <h3 className="text-sm font-semibold text-gray-900 mb-2">{folderName}</h3>

                {/* Last Modified Date */}
                <p className="text-xs text-gray-500 mt-1">
                    Last modified at {folder?.modifiedAt && new Date(folder.modifiedAt).toLocaleTimeString()} on {folder?.modifiedAt && new Date(folder.modifiedAt).toLocaleDateString()}
                </p>
            </div>

            {/* Modals */}
            <EditDetails folder={folder} isEditModalVisible={isEditModalVisible} setEditModalVisible={setEditModalVisible} />
            <EditPermissions folder={folder} isPermissionModalVisible={isPermissionModalVisible} setPermissionModalVisible={setPermissionModalVisible} />
            <AddDocInsideFolderModal
                isVisible={isAddDocumentModalVisible}
                handleCancel={() => setAddDocumentModalVisible(false)}
                userId={userId}
                folderId={folder?._id}
            />
        </>
    );
};

export default NestedFolderCard;























