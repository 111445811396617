import React, { useState, useEffect, useRef } from 'react';
import { Avatar, message } from 'antd';

const UsersSuperAdmin = ({ fetchUsersApi }) => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const tableRef = useRef(null);

  const fetchUsers = async (page) => {
    try {
      const response = await fetchUsersApi(page); // Fetch users from API
      if (response.length === 0) {
        setHasMore(false);
      } else {
        setUsers((prevUsers) => [...prevUsers, ...response]);
        setPage(page + 1);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      message.error('Failed to fetch users');
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = () => {
    if (!tableRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 10 && hasMore && !loading) {
      fetchUsers(page);
    }
  };

  useEffect(() => {
    fetchUsers(page);
  }, []);

  // Loading state for pending requests
  if (loading) {
    return (
      <div className="flex flex-col max-w-[1430px] p-3 rounded-lg bg-white shadow-sm overflow-hidden">
        <div className="flex justify-between items-center mb-4">
          <div className="h-6 w-40 bg-gray-200 rounded animate-pulse"></div> {/* Placeholder for the title */}
        </div>
        <div
          className="overflow-auto"
          style={{ maxHeight: '552px' }}
          onScroll={handleScroll}
          ref={tableRef}
        >
          <table className="table-auto w-full border-separate" style={{ borderSpacing: '0 10px' }}>
            <thead className="text-left text-sm font-semibold text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 py-2">
                  <div className="h-4 bg-gray-200 rounded animate-pulse" style={{ width: '100px' }}></div> 
                </th>
                <th className="px-4 py-2">
                  <div className="h-4 bg-gray-200 rounded animate-pulse" style={{ width: '120px' }}></div>
                </th>
                <th className="px-4 py-2">
                  <div className="h-4 bg-gray-200 rounded animate-pulse" style={{ width: '120px' }}></div> 
                </th>
                <th className="px-4 py-2">
                  <div className="h-4 bg-gray-200 rounded animate-pulse" style={{ width: '180px' }}></div> 
                </th>
              </tr>
            </thead>
            <tbody>
              {[...Array(5)].map((_, index) => (
                <tr key={index} className="bg-white shadow-sm rounded-lg">
                  <td className="px-4 py-2 flex items-center gap-2">
                    <div className="w-10 h-10 bg-gray-200 rounded-full animate-pulse"></div>
                    <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div> 
                  </td>
                  <td className="px-4 py-2">
                    <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div> 
                  </td>
                  <td className="px-4 py-2">
                    <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div> 
                  </td>
                  <td className="px-4 py-2">
                    <div className="h-4 w-48 bg-gray-200 rounded animate-pulse"></div> 
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col max-w-[1430px] p-3 rounded-lg bg-white shadow-sm overflow-hidden">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Users</h2>
      </div>
      <div
        className="overflow-auto"
        style={{ maxHeight: '552px' }}
        onScroll={handleScroll}
        ref={tableRef}
      >
        <table className="table-auto w-full border-separate" style={{ borderSpacing: '0 10px' }}>
          <thead className="text-left text-sm font-semibold text-gray-700 bg-gray-50">
            <tr>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Designation</th>
              <th className="px-4 py-2">Joined Date</th>
              <th className="px-4 py-2">Email</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user._id} className="bg-white shadow-sm rounded-lg">
                <td className="px-4 py-2 flex items-center gap-2">
                  <Avatar src="https://i.pravatar.cc/30?img=64" alt="User Avatar" size={30} />
                  <span>{`${user.name} ${user.lastName}`}</span>
                </td>
                <td className="px-4 py-2">
                  {user.type === 'admin' ? 'Administrator' : 'Regular User'}
                </td>
                <td className="px-4 py-2">{new Date(user.createdAt).toLocaleDateString()}</td>
                <td className="px-4 py-2">{user.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UsersSuperAdmin;