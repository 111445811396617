import React from 'react'

const reportWebVitals = () => {
  return (
    <div>
      
    </div>
  )
}

export default reportWebVitals
