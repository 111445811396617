import React, { useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { Input, Button, message } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import BrixLogo from "../../../../assets/PNG/Brix-Logo.png";
import ResetImage from "../../../../assets/PNG/Login.png";
import LockIcon from "../../../../assets/SVG/Vault.svg";
import SuccessIcon from "../../../../assets/SVG/Success.svg";
import ExpiredTokenIcon from "../../../../assets/SVG/expiredToken.svg";
import ErrorLoadingIcon from "../../../../assets/SVG/ErrorLoad.svg";
import { useNavigate } from "react-router-dom";

const ResetPin = () => {
  const navigate = useNavigate();
  const [showPin, setShowPin] = useState(false);
  const [newPin, setNewPin] = useState(["", "", "", ""]);
  const [confirmPin, setConfirmPin] = useState(["", "", "", ""]);
  const [searchParams] = useSearchParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isError, setIsError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const token = searchParams.get("token");

  const newPinRefs = useRef([]);
  const confirmPinRefs = useRef([]);

  const handlePinChange = (index, value, isConfirm, event) => {
    const pin = isConfirm ? [...confirmPin] : [...newPin];
    const refs = isConfirm ? confirmPinRefs : newPinRefs;

    if (/^\d*$/.test(value)) {
      pin[index] = value;
      isConfirm ? setConfirmPin(pin) : setNewPin(pin);

      if (value && index < 3) {
        refs.current[index + 1].focus();
      }
    }

    if (event.key === "Backspace" && !value && index > 0) {
      refs.current[index - 1].focus();
    }
  };

  const toggleShowPin = () => {
    setShowPin(!showPin);
  };

  const handleResetClick = async () => {
    if (newPin.join("") === confirmPin.join("")) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_AUTH_COM_SERVICE_URL}/vault/resetPin`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              pin: parseInt(newPin.join("")),
              token: token,
            }),
          }
        );

        const data = await response.json();

        if (response.ok) {
          setSuccessMessage(data.data.message || "PIN Reset Successfully");
          setIsSuccess(true);
        } else if (response.status === 401 && data.error.message.includes("expired")) {
          setErrorMessage(data.error.message || "Token has expired");
          setIsExpired(true);
        } else {
          message.error(data.error.message || "An error occurred");
        }
      } catch (error) {
        setIsError(true);
      }
    } else {
      message.error("PINs do not match");
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#F8F9FB] relative">
      {/* Brix Logo */}

      <img
        src={BrixLogo}
        alt="Brix Logo"
        className="absolute top-6 left-1/2 transform -translate-x-1/2 w-[116px] h-[44px]"
        onClick={() => navigate("/auth/login")}
      />

      {/* Success, Expired Token, or Error Loading Screen */}
      {isSuccess || isExpired || isError ? (
        <div className="flex items-center justify-center h-full">
          {/* Left Side - Login Illustration */}
          <div className="w-[50%] h-screen flex-shrink-0 flex items-center">
            <img
              src={ResetImage}
              alt="Login Illustration"
              className="object-cover max-w-full h-auto"
            />
          </div>

          {/* Right Side - Success, Expired Token, or Error */}
          <div className="flex items-center justify-center w-[50%] h-screen">
            <div className="text-center">
              <img
                src={
                  isSuccess
                    ? SuccessIcon
                    : isExpired
                      ? ExpiredTokenIcon
                      : ErrorLoadingIcon
                }
                alt={
                  isSuccess
                    ? "Success Icon"
                    : isExpired
                      ? "Expired Token Icon"
                      : "Error Loading Icon"
                }
                className="w-20 h-20 mb-4 mx-auto"
              />
              <h2 className="text-lg font-semibold text-[#59616E]">
                {isSuccess
                  ? successMessage
                  : isExpired
                    ? errorMessage
                    : "Error loading data"}
              </h2>
              <p className="text-sm text-[#798897] mt-2">
                {isSuccess
                  ? "You are now being redirected to the login page."
                  : isExpired
                    ? "Please request a new reset PIN link and try again."
                    : "Something went wrong. Please try again later."}
              </p>
            </div>
          </div>
        </div>
      ) : (
        // Reset PIN Form
        <div className="flex items-center justify-center h-full">
          {/* Left Side - Login Illustration */}
          <div className="w-[50%] h-screen flex-shrink-0 flex items-center ">
            <img
              src={ResetImage}
              alt="Login Illustration"
              className="object-cover max-w-full h-auto"
            />
          </div>

          {/* Right Side - Reset PIN Form */}
          <div className="flex flex-col items-center justify-center w-full max-w-sm mx-auto">
            <div className="flex flex-col items-center justify-center w-full p-6 bg-white rounded-lg shadow-md">
              <h2 className="text-2xl font-bold text-[#59616E] mb-2 text-center">
                Reset PIN
              </h2>
              <img src={LockIcon} alt="Lock Icon" className="w-16 h-16 mb-4" />

              <p className="text-[#798897] text-center w-full text-xs font-normal leading-5 mb-6">
                Enter a new PIN and confirm.
              </p>

              {/* New PIN Input */}
              <div className="flex items-center justify-between w-full mb-4">
                <label className="text-[#59616E] text-base font-normal leading-6">
                  Enter a new 4-digit PIN
                </label>
                <Button
                  icon={showPin ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                  className="border-none text-[#59616E]"
                  onClick={toggleShowPin}
                />
              </div>
              <div className="flex justify-between gap-2 w-full mb-4">
                {newPin.map((digit, index) => (
                  <Input
                    key={index}
                    type={showPin ? "text" : "password"}
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handlePinChange(index, e.target.value, false, e)}
                    onKeyDown={(e) => handlePinChange(index, e.target.value, false, e)}
                    ref={(el) => (newPinRefs.current[index] = el)}
                    className="w-12 h-12 text-center border rounded"
                  />
                ))}
              </div>

              {/* Confirm PIN Input */}
              <div className="flex items-center justify-between w-full mb-4">
                <label className="text-[#59616E] text-base font-normal leading-6">
                  Confirm your 4-digit PIN
                </label>
                <Button
                  icon={showPin ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                  className="border-none text-[#59616E]"
                  onClick={toggleShowPin}
                />
              </div>
              <div className="flex justify-between gap-2 w-full mb-6">
                {confirmPin.map((digit, index) => (
                  <Input
                    key={index}
                    type={showPin ? "text" : "password"}
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handlePinChange(index, e.target.value, true, e)}
                    onKeyDown={(e) => handlePinChange(index, e.target.value, true, e)}
                    ref={(el) => (confirmPinRefs.current[index] = el)}
                    className="w-12 h-12 text-center border rounded"
                  />
                ))}
              </div>

              {/* Reset Button */}
              <div
                className="flex items-center justify-end w-full gap-3"
                style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
              >
                {/* Close Button */}
                <Button
                  className="bg-[#C4CBD8] text-[#1B2028] py-2 px-6 rounded-md"
                  style={{
                    border: 'none',
                    cursor: 'pointer',
                    backgroundColor: '#C4CBD8',
                    color: '#1B2028',
                  }}
                >
                  Close
                </Button>

                {/* Reset Button */}
                <Button
                  className="bg-[#41EAD4] text-[#192028] py-2 px-6 rounded-md"
                  style={{
                    border: 'none',
                    cursor: 'pointer',
                    backgroundColor: '#41EAD4',
                    color: '#192028',
                  }}
                  onClick={handleResetClick}
                >
                  Reset
                </Button>
              </div>

            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetPin;