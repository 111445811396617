import React, { useState } from 'react';
import { Modal, Form, Input, Button, notification } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import axios from 'axios';

const EditDetails = ({ folder, isEditModalVisible, setEditModalVisible }) => {
  const [folderDetails, setFolderDetails] = useState({
    name: folder.name || '', // Default to empty string if undefined
    description: folder.description || '', // Default to empty string if undefined
    tags: ['Foundation', 'Structure', 'Steel', 'Cement', 'Sand', 'Slab'], // Sample tags
  });
  
  const folderDetailsUrl = `${process.env.REACT_APP_DOC_SERVICE_URL}/folder/${folder._id}`;

  const handleEditDetails = async () => {
    try {
      await axios.patch(folderDetailsUrl, {
        name: folderDetails.name,
        description: folderDetails.description,
      });
      notification.success({ message: 'Folder details updated successfully' });
      setEditModalVisible(false);
    } catch (error) {
      notification.error({ message: 'Failed to update folder details' });
    }
  };

  return (
    <Modal
      title={
        <div className="flex items-center text-2xl font-semibold">
          {folderDetails.name || 'XYZ Folder'}
          <EditOutlined className="ml-2 text-xl text-gray-400" />
        </div>
      }
      visible={isEditModalVisible}
      onCancel={() => setEditModalVisible(false)}
      footer={null}
      className="edit-folder-modal"
    >
      <Form layout="vertical" onFinish={handleEditDetails}>
        {/* Folder Name */}
        <Form.Item label="Folder Name" className="text-sm text-gray-500">
          <Input
            value={folderDetails.name}
            onChange={(e) =>
              setFolderDetails({ ...folderDetails, name: e.target.value })
            }
            maxLength={20}
            className="rounded-lg border border-gray-300 p-2"
          />
          <div className="text-right text-xs text-gray-400">
            {`${folderDetails.name?.length || 0}/20`} {/* Safe access and default to 0 */}
          </div>
        </Form.Item>

        {/* Description */}
        <Form.Item label="Description (Optional)" className="text-sm text-gray-500">
          <Input.TextArea
            value={folderDetails.description}
            onChange={(e) =>
              setFolderDetails({ ...folderDetails, description: e.target.value })
            }
            maxLength={50}
            className="rounded-lg border border-gray-300 p-2"
          />
          <div className="text-right text-xs text-gray-400">
            {`${folderDetails.description?.length || 0}/50`} {/* Safe access and default to 0 */}
          </div>
        </Form.Item>

        {/* Tags */}
        <Form.Item label="Add Tags" className="text-sm text-gray-500">
          <Input
            placeholder="#Tag"
            className="rounded-lg border border-gray-300 p-2"
            onPressEnter={(e) => {
              const newTag = e.target.value.trim();
              if (newTag && !folderDetails.tags.includes(newTag)) {
                setFolderDetails({
                  ...folderDetails,
                  tags: [...folderDetails.tags, newTag],
                });
                e.target.value = '';
              }
            }}
          />
          <div className="mt-2 flex flex-wrap gap-2">
            {folderDetails.tags.map((tag, index) => (
              <span
                key={index}
                className="bg-teal-100 text-teal-600 px-2 py-1 rounded-md"
              >{`#${tag}`}</span>
            ))}
          </div>
        </Form.Item>

        {/* Buttons */}
        <div className="flex justify-end gap-2 mt-4">
          <Button
            onClick={() => setEditModalVisible(false)}
            className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md"
          >
            Close
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="bg-teal-500 text-white px-4 py-2 rounded-md"
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditDetails;
