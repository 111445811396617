import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Spin, notification, message } from "antd";
import FolderCard from "../../Services/UI/DocCard";
import DocHeader from "./DocHeader";
import { useAuth } from "../../authcontext";
import FolderEmptyState from "../../Services/UI/EmptyState/DocEmptyState"; // Imported Empty State

const FolderDoc = () => {
  const [data, setData] = useState({ folders: [], documents: [] });
  const { userId } = useAuth();
  const { folderId } = useParams();
  const [loading, setLoading] = useState(true);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [sortOption, setSortOption] = useState("uploadedAt"); // Default sort option
  const [sortOrder, setSortOrder] = useState(1); // Default sort order (1 = Ascending)
  const [filterOn, setFilterOn] = useState(""); // Filter field
  const [filterValue, setFilterValue] = useState(""); // Filter value
  const navigate = useNavigate();

  // Allowed sort options based on API response
  const allowedSortOptions = ["docName", "docSize", "uploadedAt"];

  // Fetch folder content when sort, filter, or folderId changes
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Validate the sort option to ensure it's one of the allowed values
        const sortBy = allowedSortOptions.includes(sortOption) ? sortOption : "uploadedAt";

        // Build the params object for the API call
        const params = {
          sortBy: sortBy,  // Default sort by 'uploadedAt' or valid option
          sortOrder: sortOrder || 1,  // Default sort order is ascending
        };

        // Only include filter params if the filter has been selected
        if (filterOn && filterValue) {
          params.filterOn = filterOn;
          params.filterValue = filterValue;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/list/${userId}?folder=${folderId}`,
          {
            params: params,  // Attach the params to the request
          }
        );

        setData(response.data.data);
        setFilteredDocuments(response.data.data.finalList); // Set filtered documents
      } catch (error) {
        console.error("Error fetching folder content", error);
        notification.error({
          message: "Error",
          description: "Failed to fetch folder content.",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [folderId, sortOption, sortOrder, filterOn, filterValue]); // Fetch when these dependencies change

  // Function to handle clicking on a document
  const handleDocumentClick = (docId) => {
    navigate(`/documents/${docId}/versions`);
  };

  // Filter documents based on selected filter
  const filterDocuments = (filterOn, filterValue) => {
    setFilterOn(filterOn);
    setFilterValue(filterValue);
  };

  // Sort documents based on selected sort option and order
  const sortDocuments = (newOrder, sortOption) => {
    // Ensure the selected sort option is valid
    if (allowedSortOptions.includes(sortOption)) {
      setSortOrder(newOrder);
      setSortOption(sortOption);
    } else {
      notification.error({
        message: "Invalid Sort Option",
        description: "Please select a valid sort option.",
      });
    }
  };

  // Handle pin toggle
  const handlePinToggle = async (docId, currentStatus) => {
    try {
      const newStatus = !currentStatus;
      await axios.patch(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}/bookmark?status=${newStatus}&userId=${userId}`
      );
      setData((prevState) => ({
        ...prevState,
        documents: prevState.documents.map((doc) =>
          doc._id === docId ? { ...doc, bookmark: newStatus } : doc
        ),
      }));
      setFilteredDocuments((prevState) =>
        prevState.map((doc) =>
          doc._id === docId ? { ...doc, bookmark: newStatus } : doc
        )
      );
      const messageText = newStatus
        ? "The document has been successfully pinned!"
        : "The document has been successfully unpinned!";
      message.success(messageText);
    } catch (error) {
      console.error("Error updating bookmark status", error);
      notification.error({
        message: "Error",
        description: "Failed to update the bookmark status.",
      });
    }
  };

  // Show loading spinner while data is being fetched
  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Spin tip="Loading folder content..." />
      </div>
    );
  }

  // Render the folder content
  return (
    <div className="p-5">
      {/* Header component for filtering and sorting */}
      <DocHeader
        folderId={folderId}
        filterDocuments={filterDocuments} // Function to filter documents
        sortDocuments={sortDocuments} // Function to sort documents
        currentSortOption={sortOption}
        sortOrder={sortOrder}
      />

      <div className="mb-5">
        {filteredDocuments.length > 0 ? (
          <div className="space-y-4 mt-4">
            {filteredDocuments.map((doc) => (
              <FolderCard
                key={doc._id}
                doc={doc}
                handleDocumentClick={handleDocumentClick}
                handlePinToggle={handlePinToggle}
              />
            ))}
          </div>
        ) : (
          // Display empty state if no documents are found
          <FolderEmptyState />
        )}
      </div>
    </div>
  );
};

export default FolderDoc;
