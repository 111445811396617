import React from 'react';
import { Table, Avatar, Skeleton } from 'antd';

const TeamDeletionLog = ({ isLoading, teamData }) => {
  const columns = [
    { title: 'Team Name', dataIndex: 'teamName', key: 'teamName' },
    { title: 'Deleted At', dataIndex: 'deletedAt', key: 'deletedAt' },
    { title: 'Deleted By', dataIndex: 'deletedBy', key: 'deletedBy' },
  ];

  return isLoading ? <Skeleton active /> : <Table columns={columns} />;
};

export default TeamDeletionLog;
