import React from 'react';
// import Header from '../layouts/header';
import backgroundImage from '../../assets/PNG/Login.png';
import LoginForm from '../Services/Form/ForgotForm';
// import ResetForm from '../Services/Form/ResetForm';

const Login = () => {
  return (
    <div className="flex flex-row min-h-screen">

      <div
        className="flex-1 flex-col justify-end items-center flex-shrink-0"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: '#F8F9FB',
          maxWidth: '719px',
          maxHeight: '1024px',
          flexShrink: 0,
        }}
      />


      {/* Right side with the form and matching light teal background */}
      <div className="flex-1 flex flex-col items-center justify-center p-8"
        style={{
          backgroundColor: '#F8F9FB',
        }}>
        <LoginForm />

      </div>
    </div>
  );
};

export default Login;
