// InfoTab.jsx
import React from "react";
import { Tag } from "antd";

const InfoTab = ({ documentData }) => {
  return (
    <>
      <div className="grid grid-cols-2 w-full mb-2">
        <p className="text-[#768090]">
          <strong>File name:</strong> {documentData?.docName}
        </p>
      </div>
      <div className="grid grid-cols-2 w-full mb-2">
        <p className="text-[#768090]">
          <strong>Owner:</strong> Aaron
        </p>
        <p className="text-right text-[#768090]">
          <strong>Valid Till:</strong> Lorem Ipsum
        </p>
      </div>
      <div className="grid grid-cols-2 w-full mb-2">
        <p className="text-[#768090]">
          <strong>Uploaded:</strong>{" "}
          {new Date(documentData?.uploadedAt).toLocaleDateString()}
        </p>
        <p className="text-right text-[#768090]">
          <strong>Updated:</strong>{" "}
          {new Date(documentData?.updatedAt).toLocaleDateString()}
        </p>
      </div>
      <div className="grid grid-cols-2 w-full mb-4">
        <p className="text-[#768090]">
          <strong>Folder location:</strong> {documentData?.folder}
        </p>
      </div>

      {/* Tags Section */}
      <div className="mt-4">
        <div className="flex gap-2 flex-wrap mb-4">
          <p className="text-[#768090] mb-1">Tags</p>
          <Tag className="bg-[#D1F8F0] text-[#007768] px-2 py-1 rounded">
            #Tag
          </Tag>
        </div>
        <hr className="my-4 border-t border-gray-300" />
      </div>
    </>
  );
};

export default InfoTab;
