import React, { useState, useEffect } from 'react';
import { message, Button, Skeleton } from 'antd';
import axios from 'axios';
import { useAuth } from '../../authcontext';
import TeamSvg from '../../../assets/SVG/Myteams.svg';
import AddTeamModal from '../../Services/Modal/AddTeamModal';

const MyTeams = () => {
  const { userId } = useAuth(); // Get the userId from AuthContext
  const [teams, setTeams] = useState([]); // Initialize as an empty array
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track error state
  const [isModalVisible, setIsModalVisible] = useState(false); // Track modal visibility

  // Fetch team details (joined teams)
  const fetchTeams = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_DOC_SERVICE_URL}/teams?userId=${userId}`);
      setTeams(response.data.data.teams || []); // Ensure teams is always an array
      setIsLoading(false); // Stop loading once the data is fetched
    } catch (error) {
      setError(error); // Set error if the request fails
      setIsLoading(false); // Stop loading on error
      message.error('Failed to fetch teams');
    }
  };

  // Automatically fetch teams when the component is mounted or userId changes
  useEffect(() => {
    if (userId) {
      fetchTeams();
    }
  }, [userId]);

  // Show modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Hide modal and refresh teams
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  // Render loading state as per PNG design
  if (isLoading) {
    return (
      <div
        className="p-4 border border-gray-200 rounded-lg shadow-sm bg-white"
        style={{
          maxWidth: '440px',
          minHeight: '192px',
          padding: '12px',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '12px' }}>
          <Skeleton.Input active style={{ width: '60%', height: '24px' }} />
          <Skeleton.Button active style={{ width: '20%', height: '24px' }} />
        </div>
        {[1, 2].map((_, index) => (
          <div key={index} style={{ display: 'flex', gap: '12px', alignItems: 'center', paddingBottom: '8px' }}>
            <div style={{ display: 'flex', gap: '4px' }}>
              <Skeleton.Avatar active shape="circle" size="32px" />
              <Skeleton.Avatar active shape="circle" size="32px" />
              <Skeleton.Avatar active shape="circle" size="32px" />
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Skeleton.Input active style={{ width: '100%', height: '16px' }} />
              <Skeleton.Input active style={{ width: '80%', height: '16px' }} />
            </div>
          </div>
        ))}
      </div>
    );
  }

  // Render error state or empty state when no data is returned
  if (error || teams.length === 0) {
    return (
      <div
        className="flex flex-col items-center justify-center"
        style={{
          maxWidth: '440px',
          minHeight: '192px',
          padding: '12px 0 8px 0',
          gap: '8px',
          borderRadius: '8px',
          background: '#FFF',
        }}
      >
        <img src={TeamSvg} alt="No Teams" style={{ width: '80px', height: '80px' }} />
        <h3 style={{ fontSize: '16px', fontWeight: 600, color: '#192028' }}>No Teams Available</h3>
        <p style={{ fontSize: '12px', color: '#59616E' }}>
          To join or create a team, please contact your administrator.
        </p>
        <Button
          type="primary"
          onClick={showModal}
          style={{
            backgroundColor: '#41EAD4',
            color: '#192028',
            borderRadius: '8px',
            padding: '8px 24px',
            border: 'none',
          }}
        >
          Create New Team
        </Button>

        <AddTeamModal
          isModalVisible={isModalVisible}
          handleCancel={handleModalCancel}
          fetchTeams={fetchTeams}
          userId={userId}
        />
      </div>
    );
  }

  // Render teams list if data exists
  return (
    <div
      className="p-4 border border-gray-200 rounded-lg shadow-sm bg-white"
      style={{
        maxWidth: '440px',
        padding: '12px 0 8px 0',
        background: '#FFF',
        borderRadius: '8px',
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)',
      }}
    >
      {/* My Teams Header */}
      <div
        className="flex justify-between items-center"
        style={{
          minHeight: '32px',
          padding: '0px 12px',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <h2
          className="text-lg font-semibold text-gray-900"
          style={{
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
            margin: 0,
          }}
        >
          My Teams
        </h2>
        {teams.length > 0 && (
          <a
            href="#"
            className="hover:no-underline hover:text-[#007768] hover:bg-transparent"
            style={{
              color: '#007768',
              fontSize: '12px',
              fontWeight: 600,
              lineHeight: '22px',
              textDecoration: 'underline',
            }}
          >
            View All
          </a>
        )}
      </div>

      <hr
        style={{
          border: 'none',
          borderTop: '1px solid #CAD4DD',
          margin: '8px 0',
          width: '100%',
        }}
      />

      <div className="space-y-4">
        {teams.map((team) => (
          <div
            key={team._id}
            className="flex items-center"
            style={{
              padding: '8px 12px',
              alignItems: 'center',
              gap: '12px',
              background: '#FFF',
              borderRadius: '8px',
            }}
          >
            <div className="flex -space-x-2">
              {team.members.slice(0, 3).map((member, index) => (
                <div
                  key={index}
                  className="w-8 h-8 flex items-center justify-center text-sm font-semibold border-2 border-white rounded-full"
                  style={{
                    backgroundColor: index === 0 ? '#FF4D4F' : index === 1 ? '#1890FF' : '#8C8C8C',
                    color: 'white',
                  }}
                >
                  {member.charAt(0).toUpperCase()}
                </div>
              ))}
            </div>

            <div className="flex-1">
              <h3
                className="text-sm"
                style={{
                  color: '#1B2028',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {team.name}
              </h3>
              <p className="text-xs text-gray-500">{team.members.length} Team Members</p>
            </div>
          </div>
        ))}
      </div>

      <AddTeamModal
        isModalVisible={isModalVisible}
        handleCancel={handleModalCancel}
        fetchTeams={fetchTeams}
        userId={userId}
      />
    </div>
  );
};

export default MyTeams;
