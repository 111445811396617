import React, { useState } from 'react';
import { Modal, Button, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';

const DeleteTeamModal = ({ visible, onClose, teamId, onDeleteSuccess }) => {
  const [loading, setLoading] = useState(false);

  // Function to handle team deletion
  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/${teamId}`
      );
      
      if (response.status === 200) {
        message.success('Team deleted successfully!');
        onDeleteSuccess();  // Call success handler passed as prop
        onClose();  // Close the modal after success
      } else {
        message.error('Failed to delete the team. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting the team:', error);
      message.error('Error occurred while deleting the team.');
    } finally {
      setLoading(false);  // Stop loading indicator
    }
  };

  return (
    <Modal
      title={
        <div className="flex items-center justify-center gap-2">
          <DeleteOutlined /> Delete Team
        </div>
      }
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
      width={500}
    >
      <div className="text-center">
        <p className="text-gray-700">
          Are you sure you want to delete this team? If you delete this team, the team leader will no longer have the authority to add or remove members, and the team will be disbanded.
        </p>
        
        {/* Button layout with loading state */}
        <div className="flex justify-end gap-4 mt-6">
          <Button onClick={onClose} className="bg-gray-200 text-gray-600 px-4 py-2 rounded-md">
            Close
          </Button>
          <Button
            onClick={handleDelete}
            className="bg-[#FF412E] text-white px-4 py-2 rounded-md"
            loading={loading}  // Show loading spinner when request is in progress
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteTeamModal;
