import React from 'react';
import FoldersSvgicon from '../../../../assets/SVG/Folders.svg';

const EmptyState = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px', padding: '48px 0' }}>
      {/* Empty state */}
      <img src={FoldersSvgicon} alt="Empty folder" style={{ width: '150px', height: '150px' }} />
      <div style={{ textAlign: 'center' }}>
        <h3 style={{ fontWeight: 500, fontSize: '18px', color: '#1B2028' }}>There are No Nested Folders</h3>
        <p style={{ color: '#AEB8CA' }}>Create a Folder now</p>
      </div>
    </div>
  );
};

export default EmptyState;
