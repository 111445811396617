
// import React, { useState, useEffect, useRef } from "react";
// import { DatePicker, TimePicker, message } from "antd";
// import { ClockCircleOutlined } from "@ant-design/icons";
// import moment from "moment";
// import axios from "axios";

// const EditReminderModal = ({
//   isVisible,
//   onClose,
//   taskId, // Pass the task ID for the API call
//   currentReminder,
//   containerRef, // This ref ensures modal is contained inside Ongoing
// }) => {
//   const initialReminder = currentReminder;
//   const calendarRef = useRef(null);
//   const [selectedDate, setSelectedDate] = useState(
//     initialReminder ? moment(initialReminder) : moment()
//   );
//   const [selectedTime, setSelectedTime] = useState(
//     initialReminder ? moment(initialReminder) : moment()
//   );
//   const [isCalendarView, setIsCalendarView] = useState(true); // Toggle between calendar and time picker

//   useEffect(() => {
//     function handleClickOutside(event) {
//       if (calendarRef.current && !calendarRef.current.contains(event.target)) {
//         onClose(); // Close modal when clicking outside
//       }
//     }
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [calendarRef, onClose]);

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   const handleTimeChange = (time) => {
//     setSelectedTime(time);
//   };

//   const handleDateClick = (day) => {
//     const updatedDate = moment(selectedDate).date(day);
//     setSelectedDate(updatedDate); // Update selected date with the clicked day
//   };

//   const handlePrevMonth = () => {
//     const prevMonth = moment(selectedDate).subtract(1, "month");
//     const daysInPrevMonth = prevMonth.daysInMonth();

//     if (selectedDate.date() > daysInPrevMonth) {
//       setSelectedDate(prevMonth.date(daysInPrevMonth));
//     } else {
//       setSelectedDate(prevMonth);
//     }
//   };

//   const handleNextMonth = () => {
//     const nextMonth = moment(selectedDate).add(1, "month");
//     const daysInNextMonth = nextMonth.daysInMonth();

//     if (selectedDate.date() > daysInNextMonth) {
//       setSelectedDate(nextMonth.date(daysInNextMonth));
//     } else {
//       setSelectedDate(nextMonth);
//     }
//   };

//   const handleSaveReminder = async () => {
//     const reminder = moment(selectedDate)
//       .set({
//         hour: selectedTime.hour(),
//         minute: selectedTime.minute(),
//       })
//       .toISOString();

//     try {
//       // API call to update the reminder
//       const response = await axios.patch(
//         `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks/${taskId}`,
//         { reminder }
//       );

//       if (response.status === 200) {
//         message.success("Reminder updated successfully!");
//         onClose(); // Close the modal after successful update
//       }
//     } catch (error) {
//       message.error("Failed to update reminder.");
//       console.error("Error updating reminder:", error);
//     }
//   };

//   const daysInMonth = selectedDate.daysInMonth(); // Get number of days in the current month

//   return (
//     <>
//       {isVisible && (
//         <div
//           ref={calendarRef}
//           className="absolute p-4 bg-white rounded-lg shadow-lg z-50"
//           style={{
//             width: "345px",
//             left: "0", // Ensure positioning within Ongoing
//             top: "0",
//             transform: "translate(50%, 20%)",
//             maxHeight: `${containerRef.current?.clientHeight || 400}px`,
//             overflowY: "auto", // Prevent overflow
//           }}
//         >
//           <div className="flex justify-between gap-2 mb-4">
//             {/* Toggle between date and time picker on click */}
//             <DatePicker
//               value={selectedDate}
//               format="DD/MM/YYYY"
//               onClick={() => setIsCalendarView(true)} // Switch to calendar view
//               className="rounded-md"
//               style={{ width: "calc(50% - 4px)", height: "40px" }}
//               open={false} // Keep the default DatePicker closed
//             />

//             <TimePicker
//               value={selectedTime}
//               format="hh:mm A"
//               onClick={() => setIsCalendarView(false)} // Switch to time picker view
//               suffixIcon={<ClockCircleOutlined />}
//               use12Hours
//               className="rounded-md"
//               style={{ width: "calc(50% - 4px)", height: "40px" }}
//               open={false} // Keep the default TimePicker closed
//             />
//           </div>

//           {isCalendarView ? (
//             <div className="border-t pt-2">
//               {/* Calendar view with Month and Year Navigation */}
//               <div className="flex items-center justify-between text-gray-500 mb-2">
//                 <button onClick={handlePrevMonth} className="text-gray-500 hover:text-black">
//                   &#60;
//                 </button>
//                 <span className="font-medium">
//                   {moment(selectedDate).format("MMMM YYYY")}
//                 </span>
//                 <button onClick={handleNextMonth} className="text-gray-500 hover:text-black">
//                   &#62;
//                 </button>
//               </div>

//               {/* Days of the Week */}
//               <div className="grid grid-cols-7 text-center text-gray-500 font-medium mb-2">
//                 <span>Mon</span>
//                 <span>Tue</span>
//                 <span>Wed</span>
//                 <span>Thu</span>
//                 <span>Fri</span>
//                 <span>Sat</span>
//                 <span>Sun</span>
//               </div>

//               {/* Days in Month */}
//               <div className="grid grid-cols-7 text-center gap-y-2">
//                 {[...Array(daysInMonth)].map((_, i) => (
//                   <div
//                     key={i}
//                     className={`py-1 cursor-pointer ${
//                       i + 1 === selectedDate.date()
//                         ? "text-teal-600 border border-teal-600 rounded-full"
//                         : "text-gray-700"
//                     }`}
//                     onClick={() => handleDateClick(i + 1)} // Handle date click
//                   >
//                     {i + 1}
//                   </div>
//                 ))}
//               </div>
//             </div>
//           ) : (
//             <div className="border-t pt-2">
//               {/* Time Picker view */}
//               <TimePicker
//                 value={selectedTime}
//                 format="hh:mm A"
//                 onChange={handleTimeChange}
//                 suffixIcon={<ClockCircleOutlined />}
//                 use12Hours
//                 className="rounded-md w-full"
//               />
//             </div>
//           )}

//           {/* Save and Close buttons */}
//           <div className="flex justify-end mt-4 gap-2">
//             <button
//               onClick={onClose}
//               className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md"
//             >
//               Close
//             </button>
//             <button
//               onClick={handleSaveReminder}
//               className="bg-teal-600 text-white px-4 py-2 rounded-md"
//             >
//               Save
//             </button>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default EditReminderModal;



















import React, { useState, useEffect, useRef } from "react";
import { DatePicker, TimePicker, message } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";

const EditReminderModal = ({
  isVisible,
  onClose,
  taskId, // Pass the task ID for the API call
  currentReminder,
  containerRef, // Use the ref to ensure alignment within Ongoing
}) => {
  const initialReminder = currentReminder;
  const calendarRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(
    initialReminder ? moment(initialReminder) : moment()
  );
  const [selectedTime, setSelectedTime] = useState(
    initialReminder ? moment(initialReminder) : moment()
  );
  const [isCalendarView, setIsCalendarView] = useState(true); // Toggle between calendar and time picker

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        onClose(); // Close modal when clicking outside
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef, onClose]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const handleSaveReminder = async () => {
    const reminder = moment(selectedDate)
      .set({
        hour: selectedTime.hour(),
        minute: selectedTime.minute(),
      })
      .toISOString();

    try {
      // API call to update the reminder
      const response = await axios.patch(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks/${taskId}`,
        { reminder }
      );

      if (response.status === 200) {
        message.success("Reminder updated successfully!");
        onClose(); // Close the modal after successful update
      }
    } catch (error) {
      message.error("Failed to update reminder.");
      console.error("Error updating reminder:", error);
    }
  };

  const modalStyle = containerRef?.current
    ? {
        position: "absolute",
        left: containerRef.current.getBoundingClientRect().left + "px",
        top: containerRef.current.getBoundingClientRect().top + 20 + "px",
        width: "345px",
        maxHeight: "90vh",
        overflowY: "auto",
      }
    : {
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        width: "345px",
        maxHeight: "90vh",
        overflowY: "auto",
      };

  return (
    <>
      {isVisible && (
        <div
          ref={calendarRef}
          className="p-4 bg-white rounded-lg shadow-lg z-50"
          style={modalStyle}
        >
          <div className="flex justify-between gap-2 mb-4">
            <DatePicker
              value={selectedDate}
              format="DD/MM/YYYY"
              onClick={() => setIsCalendarView(true)}
              className="rounded-md"
              style={{ width: "calc(50% - 4px)", height: "40px" }}
              open={false}
            />
            <TimePicker
              value={selectedTime}
              format="hh:mm A"
              onClick={() => setIsCalendarView(false)}
              suffixIcon={<ClockCircleOutlined />}
              use12Hours
              className="rounded-md"
              style={{ width: "calc(50% - 4px)", height: "40px" }}
              open={false}
            />
          </div>

          {isCalendarView ? (
            <div className="border-t pt-2">
              <div className="flex items-center justify-between text-gray-500 mb-2">
                <button onClick={() => setSelectedDate(selectedDate.clone().subtract(1, "month"))}>
                  &#60;
                </button>
                <span className="font-medium">
                  {moment(selectedDate).format("MMMM YYYY")}
                </span>
                <button onClick={() => setSelectedDate(selectedDate.clone().add(1, "month"))}>
                  &#62;
                </button>
              </div>
              <div className="grid grid-cols-7 text-center text-gray-500 font-medium mb-2">
                {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
                  <span key={day}>{day}</span>
                ))}
              </div>
              <div className="grid grid-cols-7 text-center gap-y-2">
                {Array.from({ length: selectedDate.daysInMonth() }, (_, i) => (
                  <div
                    key={i}
                    className={`py-1 cursor-pointer ${
                      selectedDate.date() === i + 1
                        ? "text-teal-600 border border-teal-600 rounded-full"
                        : "text-gray-700"
                    }`}
                    onClick={() => setSelectedDate(selectedDate.clone().date(i + 1))}
                  >
                    {i + 1}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="border-t pt-2">
              <TimePicker
                value={selectedTime}
                format="hh:mm A"
                onChange={handleTimeChange}
                suffixIcon={<ClockCircleOutlined />}
                use12Hours
                className="rounded-md w-full"
              />
            </div>
          )}

          <div className="flex justify-end mt-4 gap-2">
            <button
              onClick={onClose}
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded-md"
            >
              Close
            </button>
            <button
              onClick={handleSaveReminder}
              className="bg-teal-600 text-white px-4 py-2 rounded-md"
            >
              Save
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default EditReminderModal;