

import React from 'react';
// import Header from '../layouts/header';
import backgroundImage from '../../assets/PNG/Login.png';

import GenerateForm from '../Services/Form/GenerateForm';

const Reset = () => {
  return (
    <div className="flex flex-row min-h-screen">

      <div
        className="flex-1 flex-col justify-end items-center flex-shrink-0"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          maxwidth: '719px',
          maxheight: '1024px',
          // backgroundColor: 'var(--Teal-Light-Whites-Grey_undertone, #F8F9FB)',  // Fallback background color
          flexShrink: 0,
        }}
      />

      {/* Right side with the form */}
      <div className="flex-1 flex flex-col items-center justify-center p-8"
        style={{
          backgroundColor: '#F8F9FB',
        }}>
        <GenerateForm/>
      </div>
    </div>
  );
};

export default Reset;
