import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Badge, Avatar } from '@material-ui/core';
import { Search as SearchIcon, Notifications as NotificationsIcon, Settings as SettingsIcon } from '@material-ui/icons';

const Navbar = ({ userRole, authenticated, handleLogout }) => {
  return (
    <header className="w-fixed h-14 px-4 flex justify-between items-center border-b border-gray-900 bg-white opacity-14">
      <div className="flex items-center">
        <div className="text-lg font-bold">LOGO</div>
        <nav className="ml-4 flex space-x-4">
          {authenticated &&<Link to="/app/dashboard" className="text-gray-600 hover:text-gray-900">Home</Link>}
          {authenticated &&<Link to="/app/documents" className="text-gray-600 hover:text-gray-900">Documents</Link>}
          {authenticated && <Link to="/user/profile" className="text-gray-600 hover:text-gray-900">Profile</Link>}
          {authenticated && <Link to="/user/projects" className="text-gray-600 hover:text-gray-900">Projects</Link>}
          {authenticated && <Link to="/user/test" className="text-gray-600 hover:text-gray-900">Test</Link>}
          {userRole === 'ADMIN' && <Link to="/admin/users" className="text-gray-600 hover:text-gray-900">Manage Users</Link>}
          {userRole === 'SUPER ADMIN' && <Link to="/superadmin/users" className="text-gray-600 hover:text-gray-900">Manage Users</Link>}
          {authenticated && <Link to="/app/test" className="text-gray-600 hover:text-gray-900">Test</Link>}
          {!authenticated && <Link to="/auth/login" className="text-gray-600 hover:text-gray-900">Login</Link>}
          {!authenticated && <Link to="/auth/register" className="text-gray-600 hover:text-gray-900">Register</Link>}
          {authenticated && <button onClick={handleLogout} className="text-gray-600 hover:text-gray-900">Logout</button>}
          {/* {!authenticated && <Link to="/app/test" className="text-gray-600 hover:text-gray-900">Test</Link>} */}
        </nav>
      </div>
      {authenticated && (
        <div className="flex items-center space-x-4">
          <IconButton>
            <SearchIcon className="text-gray-600" />
          </IconButton>
          <IconButton>
            <Badge badgeContent={9} color="error">
              <NotificationsIcon className="text-gray-600" />
            </Badge>
          </IconButton>
          <IconButton>
            <SettingsIcon className="text-gray-600" />
          </IconButton>
          <Avatar />
        </div>
      )}
    </header>
  );
};

export default Navbar;
