// import React, { useState } from 'react';
// import { EyeOutlined, MoreOutlined, EditOutlined, FileAddOutlined, FormOutlined } from '@ant-design/icons';
// import { Dropdown, Menu } from 'antd';
// import FoldersSvgicon from '../../../assets/SVG/Folders.svg';
// import EditDetails from '../EditModal/EditFolderDetails';
// import EditPermissions from '../EditModal/EditPermissionsFolderCardModal';
// import AddDocInsideFolderModal from '../Modal/AddDocInsideFolderModal';
// import { useAuth } from '../../authcontext';

// const FolderCard = ({ folder, handleFolderClick }) => {
//     const [isEditModalVisible, setEditModalVisible] = useState(false);
//     const [isPermissionModalVisible, setPermissionModalVisible] = useState(false);
//     const [isAddDocumentModalVisible, setAddDocumentModalVisible] = useState(false); 
//     const { userId } = useAuth();
//     const [hoveredItem, setHoveredItem] = useState(null);


//     const getMenu = () => (
//         <Menu
//             style={{
//                 display: 'flex',
//                 width: '199px',
//                 padding: '4px',
//                 flexDirection: 'column',
//                 alignItems: 'flex-start',
//                 borderRadius: '8px',
//                 background: '#FFF',
//                 boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
//             }}
//         >
//             <Menu.Item
//                 key="1"
//                 style={{
//                     display: 'flex',
//                     alignItems: 'center',
//                     justifyContent: 'flex-start',
//                     width: '100%',
//                     borderRadius: '4px',
//                     margin: '4px 0',
//                     padding: '8px 12px',
//                     color: hoveredItem === 1 ? '#1B2028' : '#798897',
//                     backgroundColor: hoveredItem === 1 ? '#D1F8F0' : 'transparent',
//                     transition: 'background-color 0.3s ease, color 0.3s ease',
//                 }}
//                 onMouseEnter={() => setHoveredItem(1)}
//                 onMouseLeave={() => setHoveredItem(null)}
//                 onClick={() => setEditModalVisible(true)}
//             >
//                 <EditOutlined style={{ marginRight: '8px', color: hoveredItem === 1 ? '#1B2028' : '#798897', width: '16px', height: '16px' }} />
//                 Edit Details
//             </Menu.Item>
//             <Menu.Item
//                 key="2"
//                 style={{
//                     display: 'flex',
//                     alignItems: 'center',
//                     justifyContent: 'flex-start',
//                     width: '100%',
//                     borderRadius: '4px',
//                     padding: '8px 12px',
//                     color: hoveredItem === 2 ? '#1B2028' : '#798897',
//                     margin: '4px 0',
//                     backgroundColor: hoveredItem === 2 ? '#D1F8F0' : 'transparent',
//                     transition: 'background-color 0.3s ease, color 0.3s ease',
//                 }}
//                 onMouseEnter={() => setHoveredItem(2)}
//                 onMouseLeave={() => setHoveredItem(null)}
//                 onClick={() => setPermissionModalVisible(true)}
//             >
//                 <FormOutlined style={{ marginRight: '8px', color: hoveredItem === 2 ? '#1B2028' : '#798897', width: '16px', height: '16px' }} />
//                 Edit Permission
//             </Menu.Item>
//             <Menu.Item
//                 key="3"
//                 style={{
//                     display: 'flex',
//                     alignItems: 'center',
//                     justifyContent: 'flex-start',
//                     width: '100%',
//                     borderRadius: '4px',
//                     padding: '8px 12px',
//                     color: hoveredItem === 3 ? '#1B2028' : '#798897',
//                     margin: '4px 0',
//                     backgroundColor: hoveredItem === 3 ? '#D1F8F0' : 'transparent',
//                     transition: 'background-color 0.3s ease, color 0.3s ease',
//                 }}
//                 onMouseEnter={() => setHoveredItem(3)}
//                 onMouseLeave={() => setHoveredItem(null)}
//                 onClick={() => setAddDocumentModalVisible(true)}
//             >
//                 <FileAddOutlined style={{ marginRight: '8px', color: hoveredItem === 3 ? '#1B2028' : '#798897', width: '16px', height: '16px' }} />
//                 Add Document
//             </Menu.Item>
//         </Menu>
//     );

//     const progressWidth = (folder.items / 30) * 100; // Calculate progress bar width based on items

//     return (
//         <>
//             <div
//                 key={folder._id}
//                 className="border border-gray-100 rounded-lg p-4 bg-white shadow-sm relative cursor-pointer"
//                 style={{
//                     maxWidth: '320px',
//                     maxHeight: '240px',
//                     boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)',
//                     borderRadius: '8px',
//                 }}
//                 onClick={() => handleFolderClick(folder._id)}
//             >
//                <div
//                 style={{
//                     position: 'absolute',
//                     top: '12px',
//                     left: '12px',
//                     width: '30px',
//                     height: '30px',
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     borderRadius: '50%',
//                     backgroundColor: folder.folderPermission === 'modify' ? '#41EAD4' : '#AEB8CA',
//                     boxShadow: folder.folderPermission === 'modify' 
//                         ? '0px 2px 6px 0px rgba(65, 234, 212, 0.50)' 
//                         : '0px 2px 6px 0px rgba(174, 184, 202, 0.50)',
//                 }}
//             >
//                 {folder.folderPermission === 'modify' ? (
//                     <FormOutlined
//                         style={{
//                             fontSize: '20px',
//                             color: '#FFFFFF',
//                         }}
//                     />
//                 ) : (
//                     <EyeOutlined
//                         style={{
//                             fontSize: '20px',
//                             color: '#FFFFFF',
//                         }}
//                     />
//                 )}
//             </div>

//                 <div
//                     style={{
//                         display: 'flex',
//                         justifyContent: 'flex-end',
//                         position: 'absolute',
//                         top: '12px',
//                         right: '12px',
//                     }}
//                     onClick={(e) => e.stopPropagation()}
//                 >
                   
//                   { folder.folderType
//                   && (<span
//                         style={{
//                             display: 'flex',
//                             padding: '1px 8px',
//                             alignItems: 'center',
//                             gap: '4px',
//                             borderRadius: '4px',
//                             border: '1px solid #4ED2BF',
//                             backgroundColor: '#F8FEFD',
//                             color: '#007768',
//                             fontSize: '12px',
//                             fontWeight: '600',
//                         }}
//                     >
//                         {folder.folderType}
//                     </span>)

// }


//                     {folder.folderPermission === 'modify' && (
//                         <Dropdown overlay={getMenu()} trigger={['click']}>
//                             <MoreOutlined
//                                 style={{ fontSize: '22px', color: '#768090', marginLeft: '8px', cursor: 'pointer' }}
//                             />
//                         </Dropdown>
//                     )}
//                 </div>

//                 <div className="flex justify-center mb-2 mt-2 opacity-60">
//                     <img src={FoldersSvgicon} alt="Folder Icon" width="48" height="48" />
//                 </div>

//                 <div className="flex justify-between items-center mb-2">
//                     <span
//                         className="text-sm bg-gray-100 px-2 py-1 rounded-full"
//                         style={{ color: '#000000E0', fontWeight: '600', fontSize: '10px' }}
//                     >
//                         {folder?.items} Files
//                     </span>
//                     <span
//                         className="text-sm bg-gray-100 px-2 py-1 rounded-full"
//                         style={{ color: '#000000E0', fontWeight: '600', fontSize: '10px' }}
//                     >
//                         {folder?.folderCount} Folders
//                     </span>
//                 </div>

//                 <div className="h-1 w-full bg-gray-200 mb-4">
//                     <div
//                         className="h-full bg-teal-500"
//                         style={{
//                             width: `${progressWidth}%`,
//                         }}
//                     ></div>
//                 </div>

//                 <h3 className="text-sm font-semibold text-gray-900 mb-2">{folder.name}</h3>

//                 <p className="text-xs text-gray-500 mt-1">
//                     Last modified at {new Date(folder.modifiedAt).toLocaleTimeString()} on {new Date(folder.modifiedAt).toLocaleDateString()}
//                 </p>
//             </div>

//             <EditDetails folder={folder} isEditModalVisible={isEditModalVisible} setEditModalVisible={setEditModalVisible} />
//             <EditPermissions folder={folder} isPermissionModalVisible={isPermissionModalVisible} setPermissionModalVisible={setPermissionModalVisible} />
//             <AddDocInsideFolderModal
//                 isVisible={isAddDocumentModalVisible}
//                 handleCancel={() => setAddDocumentModalVisible(false)}
//                 userId={userId}
//                 folderId={folder._id}
//             />
//         </>
//     );
// };

// const FolderCardSkeleton = () => (
//     <div className="border border-gray-100 rounded-lg p-4 bg-white shadow-sm max-w-xs h-60 flex justify-center items-center">
//         {/* Skeleton Placeholder */}
//     </div>
// );

// export { FolderCardSkeleton };

// export default FolderCard;






















import React, { useState } from 'react';
import { EyeOutlined, MoreOutlined, EditOutlined, FileAddOutlined, FormOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import FoldersSvgicon from '../../../assets/SVG/Folders.svg';
import EditDetails from '../EditModal/EditFolderDetails';
import EditPermissions from '../EditModal/EditPermissionsFolderCardModal';
import AddDocInsideFolderModal from '../Modal/AddDocInsideFolderModal';
import { useAuth } from '../../authcontext';
import ArrowPointer from '../../../assets/SVG/Polygon.svg'; // Import the arrow icon

const FolderCard = ({ folder, handleFolderClick }) => {
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isPermissionModalVisible, setPermissionModalVisible] = useState(false);
  const [isAddDocumentModalVisible, setAddDocumentModalVisible] = useState(false);
  const { userId } = useAuth();
  const [showTagPopup, setShowTagPopup] = useState(false);

  const [hoveredItem, setHoveredItem] = useState(null);

  const getMenu = () => (
    <Menu
      style={{
        display: 'flex',
        width: '199px',
        padding: '4px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: '8px',
        background: '#FFF',
        boxShadow: '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Menu.Item
        key="1"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
          borderRadius: '4px',
          margin: '4px 0',
          padding: '8px 12px',
          color: hoveredItem === 1 ? '#1B2028' : '#798897',
          backgroundColor: hoveredItem === 1 ? '#D1F8F0' : 'transparent',
          transition: 'background-color 0.3s ease, color 0.3s ease',
        }}
        onMouseEnter={() => setHoveredItem(1)}
        onMouseLeave={() => setHoveredItem(null)}
        onClick={() => setEditModalVisible(true)}
      >
        <EditOutlined style={{ marginRight: '8px', color: hoveredItem === 1 ? '#1B2028' : '#798897', width: '16px', height: '16px' }} />
        Edit Details
      </Menu.Item>
      <Menu.Item
        key="2"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
          borderRadius: '4px',
          padding: '8px 12px',
          color: hoveredItem === 2 ? '#1B2028' : '#798897',
          margin: '4px 0',
          backgroundColor: hoveredItem === 2 ? '#D1F8F0' : 'transparent',
          transition: 'background-color 0.3s ease, color 0.3s ease',
        }}
        onMouseEnter={() => setHoveredItem(2)}
        onMouseLeave={() => setHoveredItem(null)}
        onClick={() => setPermissionModalVisible(true)}
      >
        <FormOutlined style={{ marginRight: '8px', color: hoveredItem === 2 ? '#1B2028' : '#798897', width: '16px', height: '16px' }} />
        Edit Permission
      </Menu.Item>
      <Menu.Item
        key="3"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
          borderRadius: '4px',
          padding: '8px 12px',
          color: hoveredItem === 3 ? '#1B2028' : '#798897',
          margin: '4px 0',
          backgroundColor: hoveredItem === 3 ? '#D1F8F0' : 'transparent',
          transition: 'background-color 0.3s ease, color 0.3s ease',
        }}
        onMouseEnter={() => setHoveredItem(3)}
        onMouseLeave={() => setHoveredItem(null)}
        onClick={() => setAddDocumentModalVisible(true)}
      >
        <FileAddOutlined style={{ marginRight: '8px', color: hoveredItem === 3 ? '#1B2028' : '#798897', width: '16px', height: '16px' }} />
        Add Document
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div
        key={folder._id}
        className="border border-gray-100 rounded-lg p-4 bg-white shadow-sm relative cursor-pointer"
        style={{
          maxWidth: '320px',
          maxHeight: '240px',
          boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10)',
          borderRadius: '8px',
        }}
        onClick={() => handleFolderClick(folder._id)}
      >
        {/* EyeOutlined icon placed at top-left */}
        <div
          style={{
            position: 'absolute',
            top: '12px',
            left: '12px',
            width: '24px',
            height: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            backgroundColor: '#AEB8CA',
            boxShadow: '0px 2px 0px 0px rgba(174, 184, 202, 0.10)',
          }}
        >
          <EyeOutlined style={{ fontSize: '14px', color: '#FFFFFF' }} />
        </div>

        {/* Top-right Self tag and dropdown menu */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'absolute',
            top: '12px',
            right: '12px',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <span
            style={{
              display: 'flex',
              padding: '1px 8px',
              alignItems: 'center',
              gap: '4px',
              borderRadius: '4px',
              border: '1px solid #4ED2BF',
              backgroundColor: '#F8FEFD',
              color: '#007768',
              fontSize: '12px',
              fontWeight: '600',
            }}
          >
            Self
          </span>
          <Dropdown overlay={getMenu()} trigger={['click']}>
            <MoreOutlined
              style={{ fontSize: '22px', color: '#768090', marginLeft: '8px', cursor: 'pointer' }}
            />
          </Dropdown>
        </div>

        {/* Folder icon in the center */}
        <div className="flex justify-center mb-2 mt-2 opacity-60">
          <img src={FoldersSvgicon} alt="Folder Icon" width="48" height="48" />
        </div>

        {/* Files count and folder items */}
        {/* Files count and folder items */}
<div className="flex justify-start items-center mb-2 space-x-2">
  <span
    className="text-sm bg-gray-100 px-2 py-1 rounded-full"
    style={{ color: '#000000E0', fontWeight: '600', fontSize: '10px' }}
  >
    {folder?.items} Files
  </span>
  <span
    className="text-sm bg-gray-100 px-2 py-1 rounded-full"
    style={{ color: '#000000E0', fontWeight: '600', fontSize: '10px' }}
  >
    {folder?.folderCount} Folders
  </span>
</div>

        {/* Dynamic progress bar/slider */}
        <div className="h-1 w-full bg-gray-200 mb-4">
          <div
            className="h-full bg-teal-500"
            style={{
              width: `${(folder.items / 30) * 100}%`, // Dynamic width based on folder items
            }}
          ></div>
        </div>

        {/* Folder name with tags on the right */}
        <div className="flex justify-between items-center mb-2 relative">
          <h3 className="text-sm font-semibold text-gray-900">{folder.name}</h3>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              padding: '2px 4px',
              borderRadius: '4px',
              border: '1px solid #D9DEE7',
              background: '#F8FEFD',
              fontSize: '10px',
              color: '#798897',
              position: 'relative',
              cursor: 'pointer',
            }}
            onMouseEnter={() => setShowTagPopup(true)}
            onMouseLeave={() => setShowTagPopup(false)}
          >
            #Tags
            <InfoCircleOutlined style={{ fontSize: '12px' }} />

            {/* Stable arrow icon */}
            <img
              src={ArrowPointer}
              alt="Arrow Pointer"
              style={{
                position: 'absolute',
                left: 'calc(100% + 8px)', // Adjusts the SVG position to point from the tags to the popup
                top: '50%',
                transform: 'translateY(-50%)',
                width: '12px',
                height: '12px',
              }}
            />
            
            {/* Tag popup */}
            {showTagPopup && (
              <div
                style={{
                  position: 'absolute',
                  left: 'calc(100% + 24px)', // Positioning the tag popup relative to the arrow
                  top: '50%',
                  transform: 'translateY(-50%)',
                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px',
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                  padding: '8px',
                  zIndex: 10,
                }}
              >
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, max-content)', // Ensures 3 tags per line
                    gap: '8px',
                    borderRadius: '8px',
                  }}
                >
                  {['#HeavyMachinery', '#ConcreteJungle', '#Green', '#SiteSafety', '#Architects'].map((tag, index) => (
                    <span
                      key={index}
                      style={{
                        backgroundColor: '#D1F8F0',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        fontSize: '12px',
                        color: '#59616E',
                        fontWeight: '500',
                        whiteSpace: 'nowrap', // Prevents text from wrapping
                      }}
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Last modified date */}
        <p className="text-xs text-gray-500 mt-1">
          Last modified at {new Date(folder.modifiedAt).toLocaleTimeString()} on {new Date(folder.modifiedAt).toLocaleDateString()}
        </p>
      </div>

      {/* Modals */}
      <EditDetails folder={folder} isEditModalVisible={isEditModalVisible} setEditModalVisible={setEditModalVisible} />
      <EditPermissions folder={folder} isPermissionModalVisible={isPermissionModalVisible} setPermissionModalVisible={setPermissionModalVisible} />
      <AddDocInsideFolderModal
        isVisible={isAddDocumentModalVisible}
        handleCancel={() => setAddDocumentModalVisible(false)}
        userId={userId}
        folderId={folder._id}
      />
    </>
  );
};

const FolderCardSkeleton = () => (
    <div className="border border-gray-100 rounded-lg p-4 bg-white shadow-sm max-w-xs h-60 flex justify-center items-center">
        {/* Skeleton Placeholder */}
    </div>
);

export { FolderCardSkeleton };

export default FolderCard;