import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import TeamCard from '../../../Services/UI/TeamCard';
import EmptyTeamCard from '../../../Services/UI/EmptyState/EmptyTeamCard';
import { useAuth } from '../../../authcontext';

const ViewAll = ({ type }) => {
  const { userId } = useAuth();
  const [teams, setTeams] = useState([]);
  const [page, setPage] = useState(2);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const containerRef = useRef(null);
  const [viewAll, setViewAll] = useState(1);

  // Fetch initial teams from 1st and 2nd API calls sequentially
  const fetchInitialTeams = async () => {
    setLoading(true);
    try {
      const response1 = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?userId=${userId}&type=${type}&viewAll=true`
      );
      const initialTeams = response1.data.data.teamsList;

      const response2 = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?userId=${userId}&type=${type}&viewAll=true&page=1`
      );
      const pageOneTeams = response2.data.data.teamsList;

      setTeams([...initialTeams, ...pageOneTeams]);

      if (pageOneTeams.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching initial teams data:', error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchTeamsByPage = async (pageNum) => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/teams?userId=${userId}&type=${type}&viewAll=true&page=${pageNum}`
      );
      const fetchedTeams = response.data.data.teamsList;

      if (fetchedTeams.length === 0) {
        setHasMore(false);
      } else {
        setTeams((prevTeams) => [...prevTeams, ...fetchedTeams]);
      }
    } catch (error) {
      console.error('Error fetching paginated teams data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialTeams();
  }, [userId, type]);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;

      if (container) {
        if (
          container.scrollHeight - container.scrollTop <=
          container.clientHeight + 50 && hasMore && !loading
        ) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasMore, loading]);

  useEffect(() => {
    if (page > 2) {
      fetchTeamsByPage(page);
    }
  }, [page]);

  // Updated loading placeholders with a skeleton-like design
 // Updated loading placeholders with SVG icons and additional elements
const renderLoadingPlaceholders = () => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)', // 3-column grid layout
        gap: '20px',
      }}
    >
      {Array.from({ length: 9 }).map((_, index) => (
        <div
          key={index}
          style={{
            width: '372px',         // Specified width of the card
            height: '210px',        // Specified height of the card
            flexShrink: '0',        // Prevent shrinking of card
            background: '#FFF',     // Light grey background for the card
            borderRadius: '4px',    // Slight border radius for styling
            padding: '16px',        // Padding inside the card
            display: 'flex',        // Flexbox for card layout
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',    // Center the content vertically and horizontally
            gap: '12px',            // Gap between skeleton elements
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional shadow for card
          }}
        >
           {/* SVG Icon Placeholder */}
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0"
          y="0"
          width="40"
          height="40"
          rx="8" // Rounded corners for the rectangle
          fill="#E5E7EB" // Light grey color for the placeholder
        />
      </svg>



          {/* Skeleton for title */}
          <div
            style={{
              width: '80%',
              height: '20px',
              background: '#E5E7EB',  // Light grey color for the title placeholder
              borderRadius: '4px',
            }}
          ></div>

          {/* Skeleton for description lines */}
          <div
            style={{
              width: '100%',
              height: '12px',
              background: '#E5E7EB',  // Light grey color for text line
              borderRadius: '4px',
            }}
          ></div>
          <div
            style={{
              width: '90%',
              height: '12px',
              background: '#E5E7EB',  // Light grey color for another text line placeholder
              borderRadius: '4px',
            }}
          ></div>

          {/* Skeleton for button-like element */}
          <div
            style={{
              width: '50%',
              height: '30px',
              background: '#D1D5DB', // Grey placeholder for a button
              borderRadius: '4px',
            }}
          ></div>

                {/* Right side corner icon */}
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: 'absolute',
          top: '8px',
          right: '8px',
        }}
      >
        <rect
          x="0"
          y="0"
          width="24"
          height="24"
          rx="4" // Rounded corners for the rectangle
          fill="#D1D5DB" // Light grey color for the side icon
        />
      </svg>


          
        </div>
      ))}
    </div>
  );
};
              
                return (
                  <div
                    ref={containerRef}
                    style={{
                      height: '80vh',
                      overflowY: 'scroll',
                      padding: '20px',
                    }}
                  >
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)', // 3 columns per row, as per your design
                        gap: '20px', // Gap between cards
                      }}
                    >
                      {!loading && teams.length > 0
                        ? teams.map((team) => <TeamCard key={team._id} team={team} viewAll={viewAll} />)
                        : renderLoadingPlaceholders()}
                    </div>
              
                    {loading && <div>Loading more teams...</div>}
                  </div>
                );
              };
              
              export default ViewAll;
              