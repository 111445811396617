import React, { useState } from 'react';
import { Button, Tabs } from 'antd';
import AddTeamModal from '../../Services/Modal/AddTeamModal'; 
const { TabPane } = Tabs;

const TeamsHeader = ({ onTabChange, setViewAll }) => { // Accept setViewAll as a prop
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  const [activeKey, setActiveKey] = useState('managed'); // State for active tab

  const showModal = () => {
    setIsModalVisible(true); // Show modal when button is clicked
  };

  const handleCancel = () => {
    setIsModalVisible(false); // Close modal
  };

  const handleTabChange = (key) => {
    setActiveKey(key); 
    onTabChange?.(key); // Optional tab change handler
  };

  return (
    <div className="p-4">
      <>
        {/* Header section */}
        <div className="flex justify-between items-center mb-2">
          <h1 className="text-[#192028] font-semibold text-2xl" style={{ lineHeight: '28px', marginBottom: '0' }}>
            Teams
          </h1>
        </div>

        {/* Tabs for Managed and Joined teams */}
        <div className="flex justify-between items-center mb-2">
          <Tabs defaultActiveKey="managed" className="custom-tabs" onChange={handleTabChange}>
            <TabPane tab={<span className="text-[#007768] font-semibold">Managed team</span>} key="managed" />
            <TabPane tab={<span className="text-[#59616E] font-semibold">Joined</span>} key="joined" />
          </Tabs>

          {/* Create New Team Button */}
          <Button
            type="primary"
            className="create-new-team-btn"
            style={{
              display: 'flex',
              width: '180px',
              height: '40px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              borderRadius: '8px',
              border: '1px solid #FF6F61',
              background: '#FF6F61',
              color: '#FFFFFF',
            }}
            onClick={showModal} // Show modal when button is clicked
          >
            + Create New Team
          </Button>
        </div>

        {/* Conditionally render based on the active tab */}
        {activeKey === 'managed' ? (
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-lg font-semibold text-[#192028]" style={{ lineHeight: '24px', marginTop: '0px' }}>
              Manage team member access and privileges
            </h2>

            {/* View All button, visible for Managed teams */}
            <button
              className="underline text-[#768090] text-sm"
              style={{ lineHeight: '20px', padding: '0', marginRight: '0px' }}
              onClick={() => setViewAll(true)} // Trigger setViewAll to show the ViewAll component
            >
              View All
            </button>
          </div>
        ) : (
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-lg font-semibold text-[#192028]" style={{ lineHeight: '24px', marginTop: '0px' }}>
              Joined Teams
            </h2>

            {/* View All button, visible for Joined teams */}
            <button
              className="underline text-[#768090] text-sm"
              style={{ lineHeight: '20px', padding: '0', marginRight: '0px' }}
              onClick={() => setViewAll(true)} // Trigger setViewAll to show the ViewAll component
            >
              View All
            </button>
          </div>
        )}

        {/* AddTeamModal component */}
        <AddTeamModal
          isModalVisible={isModalVisible} // Modal visibility controlled here
          handleCancel={handleCancel} // Close modal
        />
      </>
    </div>
  );
};

export default TeamsHeader;
