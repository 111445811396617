import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd'; // Import Ant Design's Skeleton for loading state
import MyProfile from './MyProfile';
import AddCoManagerModal from '../../../Services/Modal/AddCoManagerModal';

const profiles = {
  owner: {
    name: 'Manager',
    department: 'Design',
    email: 'abcdee@gmail.com',
    phone: '+91-755-558-7391',
    imageUrl: 'https://randomuser.me/api/portraits/men/32.jpg',
  },
  manager: {
    name: 'Manager |',
    department: 'Marketing',
    email: 'manager@gmail.com',
    phone: '+91-755-111-1111',
    imageUrl: 'https://randomuser.me/api/portraits/men/33.jpg',
  },
  coManager: {
    name: 'Co-Manager',
    department: 'HR',
    email: 'co.manager@gmail.com',
    phone: '+91-755-222-2222',
    imageUrl: 'https://randomuser.me/api/portraits/men/34.jpg',
  },
};

const KnowTeam = () => {
  const [showProfile, setShowProfile] = useState(false);
  const [showAddCoManagerModal, setShowAddCoManagerModal] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(profiles.owner);
  const [loading, setLoading] = useState(true); // Loading state for profiles

  useEffect(() => {
    // Simulating data loading
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false once data is "fetched"
    }, 2000);

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  const handleClick = (profileKey) => {
    setSelectedProfile(profiles[profileKey]);
    setShowProfile(true);
  };

  const handleAddCoManagerClick = () => {
    setShowAddCoManagerModal(true);
  };

  const handleCloseProfile = () => {
    setShowProfile(false);
  };

  const handleCloseAddCoManagerModal = () => {
    setShowAddCoManagerModal(false);
  };

  return (
    <div className="relative w-full">
      {/* Heading Section (No Loading State) */}
      <div className="mb-4">
        <h2 className="text-lg font-semibold text-gray-900">Know your Team</h2>
        <p className="text-sm text-gray-600">Get to know your team's strengths and weaknesses</p>
      </div>

      {/* Team Members Section */}
      <div className="p-4 bg-white rounded-lg shadow-md" style={{ maxWidth: '348px', minHeight: '425px' }}>
        <div className="grid grid-cols-2 gap-x-2 gap-y-4">
          {/* Loading skeletons for each profile */}
          {loading ? (
            <>
              <Skeleton.Avatar active size={112} shape="circle" />
              <Skeleton.Avatar active size={112} shape="circle" />
              <Skeleton.Avatar active size={112} shape="circle" />
              <Skeleton.Input active size="small" style={{ width: 112, height: 112 }} />
            </>
          ) : (
            <>
              {/* Team Owner */}
              <div className="flex flex-col items-center gap-1 cursor-pointer" onClick={() => handleClick('owner')}>
                <img
                  src={profiles.owner.imageUrl}
                  alt="Team Owner"
                  className="w-28 h-28 rounded-full border-2 border-teal-400"
                />
                <p className="text-base font-medium text-gray-900">Manager</p>
              </div>

              {/* Manager */}
              <div className="flex flex-col items-center gap-1 cursor-pointer" onClick={() => handleClick('manager')}>
                <img
                  src={profiles.manager.imageUrl}
                  alt="Manager"
                  className="w-28 h-28 rounded-full border-2 border-teal-400"
                />
                <p className="text-base font-medium text-gray-900">Co-Manager</p>
              </div>

              {/* Add margin to separate the first row from the second */}
              <div className="col-span-2 h-4"></div>

              {/* Co-Manager */}
              <div className="flex flex-col items-center gap-1 cursor-pointer" onClick={() => handleClick('coManager')}>
                <img
                  src={profiles.coManager.imageUrl}
                  alt="Co-Manager"
                  className="w-28 h-28 rounded-full border-2 border-teal-400"
                />
                <p className="text-base font-medium text-gray-900">Co-Manager</p>
              </div>

              {/* Add Co-Manager */}
              <div className="flex flex-col items-center gap-1 cursor-pointer" onClick={handleAddCoManagerClick}>
                <div className="flex justify-center items-center w-28 h-28 rounded-full border-2 border-teal-400">
                  <PlusOutlined className="text-teal-400 text-3xl" />
                </div>
                <p className="text-base font-medium text-gray-900">Add Co-Manager</p>
              </div>
            </>
          )}
        </div>
      </div>

      {/* MyProfile Slide-in Panel */}
      {showProfile && (
        <div className="absolute top-0 right-0 z-10">
          <MyProfile profile={selectedProfile} onClose={handleCloseProfile} />
        </div>
      )}

      {/* Add Co-Manager Modal */}
      {showAddCoManagerModal && (
        <AddCoManagerModal visible={showAddCoManagerModal} onClose={handleCloseAddCoManagerModal} />
      )}
    </div>
  );
};

export default KnowTeam;