
import React from 'react'

const footer = () => {
  return (
    <div>
      {/* </main>
</body>
</html> */}
    </div>
  )
}

export default footer
