import React, { useState, useEffect } from "react";
import { Table } from "antd";
import axios from "axios";
import NoDataSvg from "../../../assets/SVG/RecentModified.svg"; // Adjust this path to your actual SVG location
import { useAuth } from "../../authcontext";
import { useParams } from "react-router-dom";
import { Alert } from "@mui/material";

const VersionHistory = ({ onSelectVersion }) => {
  const { docId } = useParams(); // Get the document ID from URL params
  const { userId } = useAuth(); // Get the user ID from authentication context

  const [data, setData] = useState([]); // Store the version history data
  const [loading, setLoading] = useState(false); // Handle loading state
  const [error, setError] = useState(null); // Handle any errors

  // Define columns for the table header based on your design
  const columns = [
    {
      title: <span style={{ fontWeight: '600', color: '#768090' }}>Version History</span>,
      dataIndex: "version",
      key: "version",
    },
    {
      title: <span style={{ fontWeight: '600', color: '#768090' }}>Owner</span>,
      dataIndex: "owner",
      key: "owner",
    },
    {
      title: <span style={{ fontWeight: '600', color: '#768090' }}>Date</span>,
      dataIndex: "date",
      key: "date",
      render: (text) => new Date(text).toLocaleDateString(), // Format date
    },
    {
      title: <span style={{ fontWeight: '600', color: '#768090' }}>File Size</span>,
      dataIndex: "fileSize",
      key: "fileSize",
      render: (text) => `${(text / 1024 / 1024).toFixed(1)} MB`, // Convert bytes to MB
    },
  ];

  // Fetch document version history data from the API
  useEffect(() => {
    const fetchVersionHistory = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${docId}`
        );
        const documentData = response.data.data[0]; // Assuming you get an array of documents and you need the first one

        // Transform the version data for the table
        const versionHistory = documentData.versions.map((version, index) => ({
          key: version.versionId, // Unique key for each row
          version: `Version ${documentData.versions.length - index}`, // Versioning logic
          owner: "Owner", // Placeholder text for the Owner, adjust as per your actual data
          date: version.createdAt, // Date the version was created
          fileSize: documentData.docSize, // Assuming the document size is the same for all versions
          versionId: version.versionId, // ID for the version
        }));

        setData(versionHistory); // Set the transformed data
        setLoading(false); // Stop loading
      } catch (err) {
        setError("Failed to fetch version history"); // Set an error message if the request fails
        setLoading(false); // Stop loading
      }
    };

    if (docId) {
      fetchVersionHistory(); // Fetch data if the document ID exists
    }
  }, [docId, userId]);

  return (
    <div
      className="flex flex-col justify-center items-center "
      style={{
        width: "380px", // Corrected width
        borderRadius: "8px",
        background: "#FFF",
        boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.10)",
        padding: "16px",
      }}
    >
      {/* Handle error display */}
      {error && (
        <Alert severity="error" style={{ marginBottom: "10px" }}>
          {error}
        </Alert>
      )}

      {/* Table for version history */}
      <Table
        columns={columns} // Define table columns
        dataSource={data} // Data to populate the table
        pagination={false} // Disable pagination
        loading={loading} // Show loading spinner when data is being fetched
        onRow={(record) => ({
          onClick: () => onSelectVersion(record.versionId), // Handle row click to select a document version
        })}
        scroll={{ y: 240 }} // Set the height for the vertical scroll (adjust as needed)
        style={{
          width: "100%",
          border: "none", // Remove the default table borders
          fontSize: "14px", // Font size of table content
        }}
        locale={{
          emptyText: (
            <div className="flex flex-col justify-center items-center">
              {/* Display when there is no version history */}
              <img src={NoDataSvg} alt="No Data" />

              {/* No Version History Available Message */}
              <p className="text-center text-[#192028] font-semibold mt-4" style={{ fontSize: "16px" }}>
                No Version History Available
              </p>

              {/* Additional message below */}
              <p className="text-center text-[#768090]" style={{ fontSize: "14px" }}>
                Version history will appear here once the document is updated.
              </p>
            </div>
          ),
        }}
      />
    </div>
  );
};

export default VersionHistory;
