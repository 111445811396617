import React, { useState } from 'react';
import { Modal, Form, Input, Button, Select, notification } from 'antd';
import axios from 'axios';

const { Option } = Select;

const EditPermissions = ({ folder, isPermissionModalVisible, setPermissionModalVisible }) => {
    const [assignUser, setAssignUser] = useState('');
    const [removeUser, setRemoveUser] = useState('');
    const [permission, setPermission] = useState('');

    const folderPermissionsUrl = `${process.env.REACT_APP_DOC_SERVICE_URL}/folder/${folder._id}/permissions`;

    const handleSavePermissions = async () => {
        try {
            const newPermissions = {
                assign: assignUser ? [{ entityId: assignUser, permissionType: permission }] : [],
                remove: removeUser ? [removeUser] : [],
            };
            await axios.patch(folderPermissionsUrl, newPermissions);
            notification.success({ message: 'Permissions updated successfully' });
            setPermissionModalVisible(false);
        } catch (error) {
            notification.error({ message: 'Failed to update permissions' });
        }
    };

    return (
        <Modal
            title={<div style={{ textAlign: 'center' }}>Edit Permissions</div>}
            visible={isPermissionModalVisible}
            onCancel={() => setPermissionModalVisible(false)}
            footer={null}
            centered
            width={600}
        >
            <div style={{ textAlign: 'center', marginBottom: '20px', color: '#768090' }}>
                {folder.name}
            </div>
            <Form layout="vertical" onFinish={handleSavePermissions}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Form.Item label="Assign User/ Team (Optional)" style={{ width: '48%' }}>
                        <Input
                            placeholder="User/Team"
                            value={assignUser}
                            onChange={(e) => setAssignUser(e.target.value)}
                            style={{ borderRadius: '8px' }}
                        />
                    </Form.Item>
                    <Form.Item label="Remove User/ Team (Optional)" style={{ width: '48%' }}>
                        <Input
                            placeholder="User/Team"
                            value={removeUser}
                            onChange={(e) => setRemoveUser(e.target.value)}
                            style={{ borderRadius: '8px' }}
                        />
                    </Form.Item>
                </div>
                <Form.Item label="Permission (Optional)">
                    <Select
                        placeholder="Select Permission"
                        value={permission}
                        onChange={(value) => setPermission(value)}
                        style={{ borderRadius: '8px' }}
                    >
                        <Option value="view">View</Option>
                        <Option value="modify">Modify</Option>
                    </Select>
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <Button
                        onClick={() => setPermissionModalVisible(false)}
                        style={{
                            backgroundColor: '#D0D5DD',
                            color: '#667085',
                            padding: '8px 16px',
                            marginRight: '12px',
                            borderRadius: '8px',
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                            backgroundColor: '#12B76A',
                            color: '#FFFFFF',
                            padding: '8px 16px',
                            borderRadius: '8px',
                        }}
                    >
                        Save
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default EditPermissions;
