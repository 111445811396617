

import React, { useState } from 'react';
import Header from '../layouts/header'; // Assuming Header component is in Header.js
import Footer from '../layouts/footer'; // Assuming Footer component is in Footer.js
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook for navigation
import '../style.css'

function Register() {

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    password2: ''
  });
  const [error, setError] = useState('');
  const [registrationSuccess, setRegistrationSuccess] = useState(false); // State to manage registration success message

  const navigate = useNavigate(); // Initializing useNavigate hook

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission

    try {
      // Send a POST request to register the user
      const response = await fetch(`${process.env.REACT_APP_RBAC_SERVICE_URL}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData) // Send form data as JSON
      });

      if (response.ok) {
        // Registration successful, handle accordingly
        setRegistrationSuccess(true); // Set registration success state to true
        setTimeout(() => {
          navigate('/auth/login'); // Navigate to the login page after 2 seconds
        }, 2000);
      } else {
        // Registration failed, handle accordingly
        const responseData = await response.json();
        setError(responseData.message); // Set error message received from the server
      }
    } catch (error) {
      // Handle errors during registration
      console.error('Error during registration:', error.message);
      setError('Something went wrong. Please try again later.');
    }
  };

  // Function to handle form input changes
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  return (
    <>
      <Header />
      <h1>Register</h1>
      <form id="registrationForm" className="lg-form" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Email/Username</label>
          <input type="text" name="email" id="email" value={formData.email} onChange={handleInputChange} />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input type="password" name="password" id="password" value={formData.password} onChange={handleInputChange} />
        </div>
        <div>
          <label htmlFor="password2">Confirm password</label>
          <input type="password" name="password2" id="password2" value={formData.password2} onChange={handleInputChange} />
        </div>
        {error && <div className="error">{error}</div>} {/* Display error message if exists */}
        {registrationSuccess && <div className="success">Registration Successful</div>} {/* Display success message if registration is successful */}
        <div>
          <input type="submit" value="Register" />
        </div>
      </form>
      <div>
        <p>
          Already have an account? <span><a href="/auth/login">Login</a></span>
        </p>
      </div>
      <Footer />
    </>
  );
}

export default Register;
