import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import axios from 'axios';
import { useAuth } from '../../authcontext';
import { useNavigate } from 'react-router-dom';
import PinnedSvg from '../../../assets/SVG/PinnedDocumnet.svg';
import DocCard from '../../Services/UI/DocCard'; // Import the DocCard component
import DocLoading from '../../Services/UI/LoadingState/DocLoading'; // Import the DocLoading component

const PinnedDocuments = ({ sortBy, sortOrder, filter }) => {
  const { userId } = useAuth();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Fetch pinned documents with sorting and filtering
  const fetchPinnedDocuments = async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams();
      queryParams.append('onlyPinned', 'true');
      if (sortBy) queryParams.append('sortBy', sortBy);
      if (sortOrder) queryParams.append('sortOrder', sortOrder);
      if (filter) queryParams.append('filterCriteria', filter);

      const response = await axios.get(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/list/${userId}?${queryParams.toString()}`
      );
      const pinnedDocuments = response.data.data?.finalList || [];
      setDocuments(pinnedDocuments);
    } catch (error) {
      console.error('Error fetching pinned documents:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchPinnedDocuments();
    }
  }, [userId, sortOrder, sortBy, filter]);

  const handlePinToggle = async (documentId) => {
    try {
      setDocuments((prevDocuments) =>
        prevDocuments.map((doc) =>
          doc._id === documentId ? { ...doc, pinned: false } : doc
        )
      );

      await axios.patch(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/documents/${documentId}/bookmark?status=false&userId=${userId}`
      );
      message.success('Document has been successfully unpinned!');
      await fetchPinnedDocuments();
    } catch (error) {
      console.error('Error unpinning document:', error);
      setError(error.message);
      message.error('Failed to unpin the document. Please try again.');
    }
  };

  const handleDocumentClick = (docId) => {
    navigate(`/documents/${docId}/versions`);
  };

  const isEmpty = !loading && documents.length === 0;

  return (
    <div className="flex flex-col mx-auto max-w-full gap-5 rounded-lg">
      {/* Card header */}
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold text-gray-800">Pinned Documents</h3>
      </div>

      {loading ? (
        // Use DocLoading component for loading state
        <DocLoading />
      ) : isEmpty ? (
        <div className="flex flex-col justify-center items-center gap-2 min-h-[158px]">
          <img src={PinnedSvg} alt="No Pinned Documents" className="max-w-[100px]" />
          <h3 className="text-lg font-semibold text-gray-900 mb-0">Pin key documents</h3>
          <p className="text-sm text-gray-500 mb-0">Keep your important things safe by pinning them.</p>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          {documents.map((doc) => (
            <DocCard
              key={doc._id}
              doc={doc}
              handleDocumentClick={handleDocumentClick}
              handlePinToggle={handlePinToggle}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default PinnedDocuments;
