import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, DatePicker, TimePicker, Button, Checkbox, message, Spin } from 'antd';
import { EditOutlined, CalendarOutlined, ClockCircleOutlined, FlagOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import { useAuth } from '../../authcontext';

const { TextArea } = Input;
const { Option } = Select;

const EditTaskModal = ({ visible, onClose, task, onTaskUpdated ,onTaskCreated}) => {
  
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [taskName, setTaskName] = useState('');
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [taskNameLength, setTaskNameLength] = useState(0);
  const [userTeams, setUserTeams] = useState([]);
  const [assigneeList, setAssigneeList] = useState([]);
  const [selfAssign, setSelfAssign] = useState(false); // State to handle self-assign checkbox
  const { userId,userName } = useAuth(); // Get user email from auth context fetchAssigneeTeam
  const [nameErr, setNameErr] = useState('');

  const [isfetchAssigneeTeamCalled, setIsfetchAssigneeTeam] = useState(true);

console.log(task);

  useEffect(() => {
    // Fetch teams on modal load
    const fetchTeams = async () => {
      try {
        const endpoint = `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/names`;
        const response = await axios.get(endpoint);
        setUserTeams(response.data.data.teams || []);
      } catch (error) {
        message.error('Failed to fetch teams.');
      }
    };

    if (visible) {
      fetchTeams();
    }
  }, [visible]);


  const fetchAssigneeTeam = async () => {
    try {
      const endpoint = `${process.env.REACT_APP_DOC_SERVICE_URL}/teams/${task.team_id}/members`;
      const response = await axios.get(endpoint);
      
      const responseMemberList = response.data.data.memberList || [];

      const formattedAssigneeList = responseMemberList.map(member => ({
        name: member.userName,  // Transform userName to name
        _id: member.id          // Use id as _id
      }));
      
      setAssigneeList(formattedAssigneeList);
          console.log(response.data.data.memberList ,formattedAssigneeList,'response.data.data.memberList ');
      
    } catch (error) {
      setAssigneeList([])
      message.error('Failed to fetch teams.');
    }
  };


  useEffect(() => {
    if (task) {
 
      setTaskName(task.name);
      setTaskNameLength(task.name.length);
      form.setFieldsValue({
        description: task.description,
        dueDate: dayjs(task.dueDate),
        priority: task.priority,
        assignedTo: task.assignee_id,
        // reminderDate: task.reminder ? dayjs(task.reminder) : null,
        team: task.team,
      });
      setDescriptionLength(task.description ? task.description.length : 0);
      if (task.team) {
        const selectedTeam = userTeams.find((team) => team?._id === task.team);
        // setAssigneeList(selectedTeam?.members || []);

        if(task.team_id && isfetchAssigneeTeamCalled){
          setIsfetchAssigneeTeam(false)
          fetchAssigneeTeam()
        }
      }
      if(task.team_id==''||task.team_id==null || (task.assigner_id == task.assignee_id && task.team_id!=null)){
        setSelfAssign(true)
        setAssigneeList([{_id: userId, name: userName}])
        form.setFieldsValue({ assignedTo: userId })
      }
    }


  }, [task, userTeams, form]);
console.log({assigneeList});

  const handleTeamChange = (teamId) => {
    if(!selfAssign){
      form.setFieldsValue({ assignedTo: '' }); // Reset the assignedTo field
      const selectedTeam = userTeams.find((team) => team?._id === teamId);

      setAssigneeList(selectedTeam?.members || []);
    }

    form.setFieldsValue({ team: teamId });
  };

  const handleSelfAssignChange = (e) => {
    const checked = e.target.checked;
    setSelfAssign(checked);
    if (checked) {
      form.setFieldsValue({ assignedTo: userId }); // Set assignedTo to current user's email when checked
    } else {
      form.setFieldsValue({ assignedTo: task.assignee_id }); // Reset to the original assignedTo value if unchecked
    }
  };

  console.log('Assigned To:', form.getFieldValue('assignedTo')); // Check the value
  

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);
  
      // Compare initial task data with form values
      const modifiedFields = {};
      if (taskName !== task.name) modifiedFields.name = taskName;
      if(taskName==''&& taskName !== task.name){
        setNameErr('* Name is required')  
        return
      }
      if (values.description !== task.description) modifiedFields.description = values.description;
      if (values.dueDate?.toISOString() !== task.dueDate) modifiedFields.dueDate = values.dueDate?.toISOString();
      if (values.priority !== task.priority) modifiedFields.priority = values.priority;
      if ((selfAssign ? userName : values.assignedTo) !== task.assignee_id) {
        modifiedFields.assignee = selfAssign ? userId : values.assignedTo;
      }
      // if(selfAssign)modifiedFields.team = '';
      if (values.team !== task.team) modifiedFields.team = values.team;
  
      // If no fields were modified, do not proceed
      if (Object.keys(modifiedFields).length === 0) {
        message.info("No changes to save.");
        setLoading(false);
        return;
      }
  
      const response = await axios.patch(
        `${process.env.REACT_APP_DOC_SERVICE_URL}/tasks/${task?._id}`,
        modifiedFields
      );
  
      if (response.status === 200) {
        message.success("Task updated successfully");
        onTaskCreated()
        onClose(); 
      }
    } catch (error) {
      message.error("Failed to update task.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={850}
      padding={40}
    >
      <div className="w-full  mx-auto text-center px-50 py-30 p-8">
        {/* Task Name */}
        <div className="mb-4">
          <div className="flex flex-col items-center gap-1">
            <Input
              value={taskName}
              onChange={(e) => {
                setNameErr('')
                setTaskName(e.target.value);
                setTaskNameLength(e.target.value.length);
              }}
              className="font-bold text-xl w-3/5  text-center border-none outline-none shadow-none"
              placeholder="Edit Task Name"
              maxLength={30}
              prefix={<EditOutlined />}
              style={{ width: '30%' }}
            />
            <span className="text-gray-500 text-sm">{`${taskNameLength}/30`}</span>
            {nameErr && <span className="text-red-500 text-sm">{nameErr}</span>}

          </div>
        </div>

        <Form form={form} layout="vertical" className="w-full">
          {/* Self Assign checkbox */}
          <div className="flex justify-between items-center mb-2">
            <span></span>
            <Checkbox
              className="text-base"
              onChange={handleSelfAssignChange}
              checked={selfAssign}
            >
              Self Assign
            </Checkbox>
          </div>

          {/* Task Description */}
          <Form.Item
            label="Task Description (Optional)"
            name="description"
            className="mb-2"
          >
            <Input
              placeholder="Enter task description"
              maxLength={100}
              onChange={(e) => {
                setDescriptionLength(e.target.value.length);
                form.setFieldsValue({ description: e.target.value });
              }}
              className="w-full"
            />
          </Form.Item>

          {/* Due Date */}
          <Form.Item
            name="dueDate"
            label="Select Due Date"
            rules={[{ required: true, message: 'Please select due date' }]}
          >
            <DatePicker
              placeholder="Select due date"
              suffixIcon={<CalendarOutlined />}
              className="w-full"
              inputReadOnly

            />
          </Form.Item>

          {/* Team and Assigned To */}
          <div className="flex gap-4">
            <Form.Item
              name="team"
              label="Team (Optional)"
              className="flex-1"
            >
              <Select
                placeholder="Select team"
                options={userTeams.map((team) => ({
                  value: team?._id,
                  label: team.name,
                }))}
                onChange={handleTeamChange}
                // disabled={selfAssign}
                className="w-full"
              />
            </Form.Item>
            <Form.Item
              name="assignedTo"
              label="Assigned To"
              className="flex-1"
            >
              <Select
                placeholder="Select assignee"
                options={
                  selfAssign
                    ? [
                        {
                          value: userId, // Use the current user's ID
                          label: userName, // Use the current user's name
                        },
                      ]
                    : assigneeList.map((member) => ({
                        value: member?._id,
                        label: member.name || "Unnamed Member",
                      }))
                }
                disabled={selfAssign}
                className="w-full"
              />
            </Form.Item>
          </div>

          {/* Priority and Reminder */}
          <div className="flex justify-between gap-4">
            <Form.Item
              name="priority"
              label="Mark Priority"
              className="flex-1"
            >
              <Select
                defaultValue="high"
                // options={[
                //   { value: 'high', label: 'High' },
                //   { value: 'medium', label: 'Medium' },
                //   { value: 'low', label: 'Low' },
                // ]}
                className="w-full"
              >
                 <Option value="high">
                <FlagOutlined style={{ color: "red", marginRight: "8px" }} />
                High
              </Option>
              <Option value="medium">
                <FlagOutlined style={{ color: "orange", marginRight: "8px" }} />
                Medium
              </Option>
              <Option value="low">
                <FlagOutlined style={{ color: "yellow", marginRight: "8px" }} />
                Low
              </Option>
              </Select>

            </Form.Item>
            <Form.Item
              // label="Set a Reminder (Optional)"
              name="reminderDate"
              className="flex-1"
            >
              {/* <div className="flex gap-2 items-center">
                <DatePicker placeholder="Reminder date" suffixIcon={<CalendarOutlined />} className="flex-1" />
                <TimePicker placeholder="Reminder time" suffixIcon={<ClockCircleOutlined />} className="flex-1" />
              </div> */}
            </Form.Item>
          </div>

          {/* Buttons */}
          <Form.Item>
            <div className="flex justify-end items-center gap-4">
              <Button
                className="bg-gray-300 text-black border-none rounded-10 h-10 w-20 cursor-default"
                onClick={onClose}
                style={{
                  backgroundColor: "#d1d5db",
                  color: "black",
                  pointerEvents: loading ? "none" : "auto",
                }}
                disabled={loading} // Disable when loading (optional)
              >
                Close
              </Button>

              {/* Save Button - Static Teal */}
              <Button
                className={`flex items-center justify-center bg-teal-400 text-black border-none rounded-10 h-10 w-20 ${loading ? "cursor-not-allowed opacity-75" : ""
                  }`}
                onClick={handleSave}
                disabled={loading}
                style={{
                  backgroundColor: "#41EAD4",
                  color: "black",
                  pointerEvents: loading ? "none" : "auto",
                  transition: "none",
                }}
              >
                {loading ? <Spin size="small" /> : "Save"}
              </Button>
            </div>

          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
 
export default EditTaskModal;




















