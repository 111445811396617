import React, { useState } from 'react';
import { CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Modal, Spin } from "antd";

const DeleteTaskModal = ({ isVisible, onClose, onDelete, task }) => {
  const [loading, setLoading] = useState(false); // Define the loading state

  const handleDelete = async () => {
    setLoading(true); // Start the spinner
    try {
      await onDelete(); // Call the provided onDelete function
    } catch (error) {
      console.error("Error deleting task:", error);
    } finally {
      setLoading(false); // Stop the spinner
    }
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      footer={null}
      closable={false}
      centered
      bodyStyle={{ padding: '30px' }}
      width={780}
    >
      {/* Close Icon (X) in the top-right corner */}
      <button
        onClick={onClose}
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
      >
        <CloseOutlined style={{ fontSize: '20px' }} />
      </button>

      {/* Delete Icon */}
      <div className="flex justify-center items-center w-16 h-16 rounded-full mx-auto">
        <CloseCircleOutlined
          style={{
            fontSize: '40px', // Adjust size to match your design
            color: '#FF6F61' // Red color to match the design
          }}
        />
      </div>

      {/* Title */}
      <h2 className="text-2xl font-semibold text-gray-900 text-center">Delete</h2>

      {/* Description */}
      <p className="text-gray-600 text-center mt-2">
        Are you sure you want to delete the task <strong>{task?.name}</strong>? <br />
        This task will be deleted permanently.
      </p>

      {/* Footer buttons aligned to the right */}
      <div className="flex justify-end gap-4 mt-6">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md focus:outline-none"
          style={{ minWidth: "100px", minHeight: "40px" }}
        >
          Close
        </button>

        {/* Delete Button with Spinner */}
        <button
          onClick={handleDelete}
          className={`flex items-center justify-center bg-red-500 text-white px-4 py-2 rounded-md focus:outline-none ${
            loading ? "cursor-not-allowed opacity-75" : ""
          }`}
          disabled={loading}
          style={{ minWidth: "100px", minHeight: "40px" }}
        >
          {loading ? <Spin size="small" /> : "Delete"}
        </button>
      </div>
    </Modal>
  );
};

export default DeleteTaskModal;